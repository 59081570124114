import { createSelector } from "reselect";

// Assuming state.GeoPerState is your state slice
const getGeoPerState = (state) => state.GeoPerState;

export const selectStateData = createSelector(
  [
    getGeoPerState,
    (_, filterType) => filterType,
    (_, __, locationName) => locationName,
  ],
  (geoPerState, filterType, locationName) =>
    geoPerState?.[filterType]?.[locationName] || []
);
