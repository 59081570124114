import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import { selectTodayResult } from "./selectors";
import { todayResult as reduxTodayResult } from "../../../common/data/TodaysInsights/hourlyROWData";

const ROWStackedColumn = ({ dataColors, showSeriesType }) => {
  // const reduxTodayResult = useSelector(selectTodayResult);
  const [times, setTimes] = useState([]);
  const [revenueSeries, setRevenueSeries] = useState([]);
  const [transactionsSeries, setTransactionsSeries] = useState([]);

  const columnChartColors = getChartColorsArray(dataColors);

  useEffect(() => {
    if (reduxTodayResult) {
      const formatTimes = (data) => {
        if (!data || !data.daywise) return [];
        return Object.keys(data.daywise).map((key) => {
          const date = new Date(key);
          const hours = date.getUTCHours().toString().padStart(2, "0");
          return `${hours}:00`;
        });
      };

      const formatSeries = (data, seriesType) => {
        if (!data || !data.daywise) return [];
        const consolidatedKeysSet = new Set();
        Object.values(data.daywise).forEach((dayData) => {
          dayData.consolidated.forEach((item) => {
            consolidatedKeysSet.add(item.key);
          });
        });

        // Convert Set to Array
        const consolidatedKeys = Array.from(consolidatedKeysSet);

        const seriesData = consolidatedKeys.map((key) => {
          return {
            name: key,
            data: Object.keys(data.daywise).map((timestamp) => {
              const consolidatedItem = data.daywise[
                timestamp
              ].consolidated.find((item) => item.key === key);
              return consolidatedItem ? consolidatedItem[seriesType] : 0;
            }),
          };
        });

        return seriesData;
      };

      setTimes(formatTimes(reduxTodayResult));
      setRevenueSeries(formatSeries(reduxTodayResult, "revenue"));
      setTransactionsSeries(formatSeries(reduxTodayResult, "transactions"));
    }
  }, [reduxTodayResult]);

  const series =
    showSeriesType === "transactions" ? transactionsSeries : revenueSeries;
  // console.log(series);
  const options = {
    chart: {
      type: "bar",
      height: 355,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    dataLabels: {
      enabled: false,
      formatter: (val) => {
        return val + "L";
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: times,
    },
    fill: {
      opacity: 1,
    },
    colors: columnChartColors,
    yaxis: {
      labels: {
        formatter: function (val) {
          return val !== undefined ? (val / 1000).toFixed(0) + "" : ""; // Format y-axis values in thousands
        },
      },
      title: {
        text: "DATA IN THOUSANDS",
      },
      min: 0,
      tickAmount: 6,
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        formatter: function (val) {
          return val !== undefined ? val : "";
        },
      },
      y: {
        show: true,
        formatter: function (val) {
          return val !== undefined
            ? parseInt(val.toFixed(0)).toLocaleString()
            : ""; // Display actual values in tooltip without formatting
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        className="apex-charts"
        series={series}
        options={options}
        type="bar"
        height={350}
      />
    </React.Fragment>
  );
};

export default ROWStackedColumn;
