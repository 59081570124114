import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";

const arrayColors = [
  "#1B2431",
  "#78AD2F",
  "#2BA558",
  "#009978",
  "#00898D",
  "#007893",
];

function GeoTable({ chartData, loader }) {
  const [tableData, setTableData] = useState([]);
  const [headerValues, setHeaderValues] = useState([]);

  //   const { chartData, loader } = useSelector((state) => state.Geo);
  //   console.log("ChartData of Content Type Analysis", chartData);

  useEffect(() => {
    if (chartData && chartData.length > 0) {
      const headers = Object.keys(chartData[0]).map((key) => {
        switch (key) {
          case "country":
            return "Countries";
          case "state":
            return "States";
          case "city":
            return "Cities";
          case "users":
            return "Users";
          case "pc_users":
            return "% Contribution";
          case "mou":
            return "MOU";
          case "pc_mou":
            return "% Contribution";
          case "mpu":
            return "MPU";
          default:
            return key;
        }
      });
      setHeaderValues(headers);
      //   const contentTypes = ["show", "movie", "clips", "others", "total"];
      const formattedData = chartData.map((item) => {
        const location = item.city || item.country || item.state || "Unknown";
        return [
          location,
          (parseInt(item.users, 10) || 0).toLocaleString(),
          (parseInt(item.pc_users, 10) || 0).toLocaleString(),
          (parseInt(item.mou, 10) || 0).toLocaleString(),
          (parseInt(item.pc_mou, 10) || 0).toLocaleString(),
          (parseInt(item.mpu, 10) || 0).toLocaleString(),
        ];
      });
      //   console.log(formattedData);
      setTableData(formattedData);
      //   setLoader(false);
    }
  }, [chartData]);
  return (
    <React.Fragment>
      {!loader ? (
        <div className="table-responsive sh-table-styling">
          <Table className="table-bordered table-nowrap mb-0" size="sm">
            <thead className="text-white text-center">
              <tr className="table-row sh-table-header">
                {headerValues.map((value, index) => (
                  <th
                    scope="col"
                    style={
                      index === 0
                        ? { width: "50%", backgroundColor: arrayColors[index] }
                        : { backgroundColor: arrayColors[index] }
                    }
                    key={index}
                  >
                    {value}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((valuesArr, index) => (
                <tr key={index} className="sh-table-text">
                  {valuesArr.map((value, i) => (
                    <td
                      key={i}
                      className="align-middle"
                      style={{ textAlign: i === 0 ? "left" : "center" }}
                    >
                      {value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center mt-4"
          style={{ height: "350px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default GeoTable;
