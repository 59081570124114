import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Table } from "reactstrap";
// import Components
import FilterSection from "../../RevenueMatrix/Subscription/filterSection";
import DateDropdown from "../../HealthMatrix/DateDropdown";
import { getFTWData } from "../../../slices/ConsumptionHighlights/FTW/thunk";
import {
  getLastMonthDateRange,
  getYTDDateRange,
} from "../../../helpers/dateUtils";
import FTWTable from "./ftwTable";

const filterOptions = {
  acq_type: {
    All: "organic,performance_guj,performance_edgy,affiliate",
    Organic: "organic",
    "Performance Guj": "performance_guj",
    "Performance Edgy": "peformance_edgy",
    Affiliate: "affiliate",
  },
  user_type: {
    All: "Free,Paid",
    Free: "Free",
    Paid: "Paid",
  },
  platforms: {
    All: "android,web,external,ios",
    Android: "android",
    Web: "web",
    Tv: "external",
    iOS: "ios",
  },
  category: {
    All: "gujarati,non_gujarati",
    Gujarati: "gujarati",
    "Non Gujarati": "non_gujarati",
  },
  region: {
    All: "India,row",
    India: "India",
    ROW: "row",
  },
};

function FTW() {
  const dispatch = useDispatch();
  const [customDateRange, setCustomDateRange] = useState(
    getLastMonthDateRange()
  );
  const [filters, setFilters] = useState({
    start_date: customDateRange.start_date,
    end_date: customDateRange.end_date,
    acq_type: filterOptions.acq_type.All,
    user_type: filterOptions.user_type.All,
    platforms: filterOptions.platforms.All,
    category: filterOptions.category.All,
    region: filterOptions.region.All,
  });
  const [loader, setLoader] = useState(true);

  const { chartData } = useSelector((state) => state.FTW);
  //   console.log("FTW Table Data", chartData);

  //   useEffect(() => {
  //     if (chartData.length > 0 && chartData) {
  //       const contentTypes = ["movie", "show", "clips", "others"];
  //       const noOfStreamsData = contentTypes.map((item) => {
  //         const stream = chartData.find((obj) => obj.content_type === item);
  //         return parseInt(stream.streams);
  //       });
  //       const mouData = contentTypes.map((item) => {
  //         const mou = chartData.find((obj) => obj.content_type === item);
  //         return parseInt(mou.mou);
  //       });
  //       setStreamsData(noOfStreamsData);
  //       setMousData(mouData);
  //       setLoader(false);
  //     }
  //   }, [chartData]);
  //   console.log("Content Type Chart Data", chartData);

  //   useEffect(() => {
  //     setSelectedOption(filters.filter_type);
  //   }, [filters.filter_type]);

  const fetchData = async (dateRange) => {
    const dataObject = {
      ...filters,
      ...dateRange,
    };
    // dispatch(getFTWData(dataObject));
  };

  useEffect(() => {
    fetchData(customDateRange);
  }, [customDateRange, filters]);

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const handleDateRangeChange = (range) => {
    setCustomDateRange(range);
    setFilters((prevFilters) => ({
      ...prevFilters,
      start_date: range.startDate,
      end_date: range.endDate,
    }));
  };

  const handleResetFilters = () => {
    setFilters({
      daily_wise_data: true,
      platforms: filterOptions.platforms.All,
      user_type: filterOptions.user_type.All,
      region: filterOptions.region.All,
      category: filterOptions.category.All,
    });
  };
  return (
    <React.Fragment>
      <Row>
        <Container
          fluid
          className="customContainerStyle"
          style={{ height: "512px" }}
        >
          <Row>
            <Col style={{ padding: "20px" }}>
              <h4 className="card-heading-text">FTW</h4>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-between">
              <FilterSection
                filterOptions={filterOptions}
                filters={filters}
                handleFilterChange={handleFilterChange}
                handleResetFilters={handleResetFilters}
              />
              <DateDropdown
                onDateRangeChange={handleDateRangeChange}
                options={["Last Month", "3 Months", "1 Year", "Custom"]}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <FTWTable />
            </Col>
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}

export default FTW;
