import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CountUp from "react-countup";
import { Card, Col, CardText } from "reactstrap";
import riseImage from "../../../assets/images/saranyuImages/Group 4.svg";
import downfallImage from "../../../assets/images/saranyuImages/Group 4 (1).svg";
import { ottData } from "../../../common/data/BusinessOverview/widgetsData";

const OttCard = ({ columnSpace }) => {
  // const loader = false;
  function getSuffix(value) {
    switch (true) {
      case value >= 100000:
        return "L";
      case value >= 1000:
        return "K";
      default:
        return "";
    }
  }
  // console.log("OttData", ottData);

  const getRoundedValue = (value) => {
    if (value >= 100000) return (Number(value) / 100000).toFixed(2);
    if (value >= 1000) return (Number(value) / 1000).toFixed(2);
    return value;
  };

  function isRupeeCard(chartName) {
    return ["Revenue", "ARPU", "CPS", "Total Spends", "ROAS"].includes(
      chartName
    );
  }

  return (
    <React.Fragment>
      {Object.keys(ottData).map((key, index) => {
        const widgetCard = ottData[key];

        // Check if widgetCard is defined and has necessary properties
        if (
          !widgetCard ||
          typeof widgetCard.chart_data === "undefined" ||
          typeof widgetCard.percentage === "undefined"
        ) {
          return null; // Skip rendering this card if data is not available
        }

        const percentage = Math.abs(Number(widgetCard.percentage.toFixed(2)));
        const roundedValue = getRoundedValue(widgetCard.chart_data);
        const decimals = widgetCard.chart_data >= 1000 ? 1 : 0;

        return (
          <Col md={columnSpace} key={index}>
            <Card className="ott-card d-flex justify-content-between flex-row">
              <div className="d-flex flex-column justify-content-between">
                <div className="widget-name pt-0">{widgetCard.chart_name}</div>
                <CardText
                  className="widget-percentage d-flex align-items-center"
                  style={{
                    color: widgetCard.percentage > 0 ? "#00B69B" : "#FF0049",
                  }}
                >
                  <img
                    src={widgetCard.percentage > 0 ? riseImage : downfallImage}
                    alt={widgetCard.percentage > 0 ? "Rise" : "Downfall"}
                  />
                  {percentage}%
                </CardText>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center widget-counter">
                <CountUp
                  start={0}
                  end={roundedValue}
                  decimals={decimals}
                  duration={4}
                  suffix={getSuffix(widgetCard.chart_data)}
                  prefix={isRupeeCard(widgetCard.chart_name) ? "₹ " : ""}
                />
              </div>
            </Card>
          </Col>
        );
      })}
    </React.Fragment>
  );
};

export default OttCard;
