import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "reactstrap";

import { getAutoDebitData } from "../../../slices/HealthMatrix/AutoDebitMandate/thunk";
import DateDropdown from "../../HealthMatrix/DateDropdown"; // Adjusted import path
import FilterSection from "../../RevenueMatrix/Subscription/filterSection"; // Adjusted import path
import AutoDebitTable from "./autoDebitTable";
import { getMTDDateRange } from "../../../helpers/dateUtils";

const filterOptions = {
  platforms: {
    All: "android,web,tv,ios",
    Android: "android",
    Web: "web",
    Tv: "tv",
    iOS: "ios",
  },
  region: {
    All: "india,row",
    India: "india",
    ROW: "row",
  },
  acq_type: {
    All: "Type1,Type2",
    Type1: "Type1",
    Type2: "Type2",
  },
  instrument: {
    All: "Instrument1,Instrument2",
    Instrument1: "Instrument1",
    Instrument2: "Instrument2",
  },
};

function AutoDebitMandate() {
  const dispatch = useDispatch();

  const [customDateRange, setCustomDateRange] = useState(getMTDDateRange());
  const [filters, setFilters] = useState({
    start_date: customDateRange.startDate,
    end_date: customDateRange.endDate,
    daily_wise_data: true,
    platforms: filterOptions.platforms.All,
    region: filterOptions.region.All,
    acq_type: filterOptions.acq_type.All,
    instrument: filterOptions.instrument.All,
  });

  const fetchData = async (dateRange) => {
    const dataObject = {
      ...filters,
      ...dateRange,
    };
    // dispatch(getAutoDebitData(dataObject));
  };

  useEffect(() => {
    fetchData(customDateRange);
  }, [customDateRange, filters]);

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const handleDateRangeChange = (range) => {
    setCustomDateRange(range);
    setFilters((prevFilters) => ({
      ...prevFilters,
      start_date: range.startDate,
      end_date: range.endDate,
    }));
  };

  const handleResetFilters = () => {
    setFilters({
      daily_wise_data: true,
      platforms: filterOptions.platforms.All,
      region: filterOptions.region.All,
      acq_type: filterOptions.acq_type.All,
      instrument: filterOptions.instrument.All,
    });
  };

  return (
    <React.Fragment>
      <Container fluid className="customContainerStyle">
        <Row>
          <Col md="12" style={{ padding: "20px" }}>
            <h4 className="card-heading-text-black">Auto Debit Mandate</h4>
          </Col>
          <Col md="12" className="mb-4 d-flex justify-content-between">
            <FilterSection
              filterOptions={filterOptions}
              filters={filters}
              handleFilterChange={handleFilterChange}
              handleResetFilters={handleResetFilters}
            />
            <DateDropdown
              onDateRangeChange={handleDateRangeChange}
              options={["MTD", "Last Month", "3 Months", "Custom"]}
            />
          </Col>
        </Row>
        <AutoDebitTable />
      </Container>
    </React.Fragment>
  );
}

export default AutoDebitMandate;
