import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import { tableDataFromState } from "../../../../common/data/BusinessOverview/acquisitionData";
import { FastField } from "formik";

const tableHeaderColors = [
  "#1B2431",
  "#78AD2F",
  "#2BA558",
  "#009978",
  "#00898D",
  "#007893",
];

function AcquisitionTable() {
  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [loader, setLoader] = useState(true);

  // Fetch status and tableData from the Redux store
  // const dataStatus = useSelector((state) => state.Acquisition.status);
  // const tableDataFromState = useSelector(
  //   (state) => state.Acquisition.tableData
  // );

  useEffect(() => {
    // Only process data if status is 'succeeded'
    if (loader && tableDataFromState.length > 0) {
      const tableObject = tableDataFromState[0];
      const headerValues = Object.keys(tableObject);

      // Format the header names
      const formattedHeaderValues = headerValues.map((key) => {
        switch (key) {
          case "type":
            return "Type";
          case "revenue":
            return "Revenue";
          case "spends":
            return "Spends";
          case "subs":
            return "SUBS";
          case "cps":
            return "CPS";
          case "arpu":
            return "ARPU";
          default:
            return key;
        }
      });

      // Format data for the table
      const formattedTableData = tableDataFromState.map((item) =>
        Object.values(item).map((value, index) => {
          // Convert string numbers to integers and format with commas
          if (typeof value === "string" && !isNaN(value)) {
            return Number(value).toLocaleString();
          }
          // Convert numbers to integers and format with commas
          if (typeof value === "number") {
            return value.toLocaleString();
          }
          return value ?? "N/A";
        })
      );

      // Set data and headers
      setTableData(formattedTableData);
      setTableHeaders(formattedHeaderValues);
      setLoader(false);
    }
  }, [tableDataFromState]);

  // Determine loading state based on the status
  // const isLoading = dataStatus === "loading";

  return (
    <React.Fragment>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "400px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="table-responsive ms-4 me-4 mt-4 sh-table-styling">
          <Table className="table-nowrap mb-0 table-bordered" size="sm">
            <thead className="sh-table-header text-center">
              <tr className="table-row">
                {tableHeaders.map((header, index) => (
                  <th
                    scope="col"
                    style={{ backgroundColor: `${tableHeaderColors[index]}` }}
                    key={index}
                    className="text-center"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((eachRow, rowIndex) => (
                <tr
                  key={rowIndex}
                  className="sh-table-text"
                  style={{
                    backgroundColor:
                      rowIndex === tableData.length - 1 ? "#E8E8E8" : "",
                  }}
                >
                  {eachRow.map((eachColumn, colIndex) => (
                    <td
                      key={colIndex}
                      className={
                        tableHeaders[colIndex] === "Type"
                          ? "text-start align-middle"
                          : "text-center align-middle"
                      }
                    >
                      {eachColumn}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </React.Fragment>
  );
}

export default AcquisitionTable;
