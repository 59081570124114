import React, { useState, useEffect, useRef } from "react";
import Keycloak from "keycloak-js";

 
export const client = new Keycloak({
  url: process.env.REACT_APP_KEY_CLOAK_ENDPOINT || "https://dataauth.shemaroome.com/",
  realm: process.env.REACT_APP_KEY_KLOAK_REALM || "Shemaroo",
  clientId: process.env.REACT_APP_KEY_KLOAK_CLIENT_ID || "react-auth",
});
 
const useAuth = () => {
  const isRun = useRef(false);
  const [token, setToken] = useState(null);
  const [isLogin, setLogin] = useState(false);

  useEffect(() => {
    if (isRun.current) return;

    isRun.current = true;
  
    client
      .init({
        onLoad: "login-required",
        checkLoginIframe: false
      })
      .then((res) => {
        setLogin(res);
        setToken(client.token);
      });
  }, []);

  return [isLogin, token];
};
 
export default useAuth