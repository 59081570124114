import { createAsyncThunk } from "@reduxjs/toolkit";
import { getGeo as getGeoApi } from "../../../helpers/fakebackend_helper";

export const getGeoData = createAsyncThunk(
  "Consumption Highlights/Geographic Contribution",
  async (data) => {
    try {
      const response = getGeoApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
