import React from "react";
import {
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import filterImage from "../../../assets/images/saranyuImages/filter.svg";
import resetFilterImage from "../../../assets/images/saranyuImages/Reset Filter.svg";

const FilterSection = ({
  filterOptions,
  filters,
  handleFilterChange,
  handleResetFilters,
}) => {
  // Helper function to get the display name for the selected filter
  const getDisplayName = (filterType) => {
    if (filters[filterType] === filterOptions[filterType].All) {
      return filterType
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      // return filterType.charAt(0).toUpperCase() + filterType.slice(1); // Capitalize the filter type name
    }
    return (
      Object.keys(filterOptions[filterType]).find(
        (key) => filterOptions[filterType][key] === filters[filterType]
      ) || "Select"
    );
  };

  return (
    <div className="d-flex justify-content-center ms-1">
      <button className="sh-funnel-filter">
        <img src={filterImage} alt="filter" />
      </button>
      {Object.keys(filterOptions).map((filter) => (
        <UncontrolledDropdown key={filter}>
          <DropdownToggle
            tag="button"
            className="sh-filter-dropdown sh-filter-text"
            id="dropdownMenuButton"
          >
            &nbsp; &nbsp;{getDisplayName(filter)} &nbsp;&nbsp;
            <i className="mdi mdi-chevron-down"></i>
          </DropdownToggle>
          <DropdownMenu>
            {Object.keys(filterOptions[filter]).map((optionKey) => (
              <DropdownItem
                key={optionKey}
                onClick={() =>
                  handleFilterChange(filter, filterOptions[filter][optionKey])
                }
              >
                {optionKey}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      ))}
      <button className="sh-filter-reset" onClick={handleResetFilters}>
        <img src={resetFilterImage} alt="reset filter" />
      </button>
    </div>
  );
};

export default FilterSection;
