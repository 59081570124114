import { createSlice } from "@reduxjs/toolkit";

import { getPackwiseData } from "./thunk";

const initialState = {
  tableData: [],
  chartData: [],
  dailyWiseData: [],
  status: "idle",
  errorMsg: "",
};

const PackwiseContributionSlice = createSlice({
  name: "PackwiseContribution",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getPackwiseData.fulfilled, (state, action) => {
      // console.log("reducer packwise", action.payload);
      if (action.payload.success) {
        state.tableData = action.payload?.data?.chart_data;
        state.chartData = action.payload?.data?.daily_wise_data;
        state.dailyWiseData = action.payload?.data?.daily_wise_data;
        state.status = "succeeded";
      }
    });
    builder.addCase(getPackwiseData.rejected, (state, action) => {
      state.errorMsg = action.error.message;
      state.status = "failed";
    });
  },
});

export default PackwiseContributionSlice.reducer;
