import { createSlice } from "@reduxjs/toolkit";
import { getGeoData } from "./thunk";

export const initialState = {
  chartData: [],
  loader: false,
  error: "",
};

const GeoSlice = createSlice({
  name: "Geographic Contribution",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getGeoData.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getGeoData.fulfilled, (state, action) => {
      state.chartData = action.payload.chart_data;
      state.loader = false;
    });
    builder.addCase(getGeoData.rejected, (state, action) => {
      state.error = action.error.message;
      state.loader = false;
    });
  },
});

export default GeoSlice.reducer;
