const totalDau = [
  {
    dau: "64745",
    date: "2024-06-01",
  },
  {
    dau: "68048",
    date: "2024-06-02",
  },
  {
    dau: "59909",
    date: "2024-06-03",
  },
  {
    dau: "99998",
    date: "2024-06-04",
  },
  {
    dau: "58159",
    date: "2024-06-05",
  },
  {
    dau: "86874",
    date: "2024-06-06",
  },
  {
    dau: "88975",
    date: "2024-06-07",
  },
  {
    dau: "103466",
    date: "2024-06-08",
  },
  {
    dau: "108557",
    date: "2024-06-09",
  },
  {
    dau: "96997",
    date: "2024-06-10",
  },
  {
    dau: "98535",
    date: "2024-06-11",
  },
  {
    dau: "99015",
    date: "2024-06-12",
  },
  {
    dau: "96458",
    date: "2024-06-13",
  },
  {
    dau: "97609",
    date: "2024-06-14",
  },
  {
    dau: "99952",
    date: "2024-06-15",
  },
  {
    dau: "101301",
    date: "2024-06-16",
  },
  {
    dau: "97105",
    date: "2024-06-17",
  },
  {
    dau: "99678",
    date: "2024-06-18",
  },
  {
    dau: "100739",
    date: "2024-06-19",
  },
];

const paidDau = [
  {
    pdau: "15218",
    date: "2024-06-01",
  },
  {
    pdau: "16475",
    date: "2024-06-02",
  },
  {
    pdau: "12936",
    date: "2024-06-03",
  },
  {
    pdau: "18201",
    date: "2024-06-04",
  },
  {
    pdau: "14091",
    date: "2024-06-05",
  },
  {
    pdau: "22635",
    date: "2024-06-06",
  },
  {
    pdau: "22045",
    date: "2024-06-07",
  },
  {
    pdau: "24550",
    date: "2024-06-08",
  },
  {
    pdau: "23894",
    date: "2024-06-09",
  },
  {
    pdau: "19703",
    date: "2024-06-10",
  },
  {
    pdau: "20715",
    date: "2024-06-11",
  },
  {
    pdau: "18922",
    date: "2024-06-12",
  },
  {
    pdau: "20059",
    date: "2024-06-13",
  },
  {
    pdau: "20844",
    date: "2024-06-14",
  },
  {
    pdau: "22145",
    date: "2024-06-15",
  },
];

export { totalDau, paidDau };
