// state.todayResult = action.payload.todayResult;
//         state.yesterdayResult = action.payload.yesterdayResult;
//         state.lastWeekResult = action.payload.lastWeekResult;

const todayResult = {
  daywise: {
    "2024-08-21T00:30:00.000Z": {
      transactions: 1,
      revenue: 399,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 399,
        },
      ],
    },
    "2024-08-21T02:30:00.000Z": {
      transactions: 2,
      revenue: 139,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 19,
        },
        {
          transactions: 1,
          key: "Yearly",
          revenue: 120,
        },
      ],
    },
    "2024-08-21T03:30:00.000Z": {
      transactions: 1,
      revenue: 29,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 29,
        },
      ],
    },
    "2024-08-21T05:30:00.000Z": {
      transactions: 1,
      revenue: 69,
      consolidated: [
        {
          transactions: 1,
          key: "Yearly",
          revenue: 69,
        },
      ],
    },
    "2024-08-21T08:30:00.000Z": {
      transactions: 1,
      revenue: 29,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 29,
        },
      ],
    },
    "2024-08-21T09:30:00.000Z": {
      transactions: 1,
      revenue: 1500,
      consolidated: [
        {
          transactions: 1,
          key: "Yearly",
          revenue: 1500,
        },
      ],
    },
    "2024-08-21T10:30:00.000Z": {
      transactions: 2,
      revenue: 58,
      consolidated: [
        {
          transactions: 2,
          key: "Quarterly",
          revenue: 58,
        },
      ],
    },
    "2024-08-21T12:30:00.000Z": {
      transactions: 3,
      revenue: 1568,
      consolidated: [
        {
          transactions: 2,
          key: "Yearly",
          revenue: 1539,
        },
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 29,
        },
      ],
    },
    "2024-08-21T13:30:00.000Z": {
      transactions: 2,
      revenue: 829,
      consolidated: [
        {
          transactions: 2,
          key: "Quarterly",
          revenue: 829,
        },
      ],
    },
    "2024-08-21T14:30:00.000Z": {
      transactions: 3,
      revenue: 857,
      consolidated: [
        {
          transactions: 2,
          key: "Quarterly",
          revenue: 58,
        },
        {
          transactions: 1,
          key: "Yearly",
          revenue: 799,
        },
      ],
    },
    "2024-08-21T17:30:00.000Z": {
      transactions: 2,
      revenue: 1569,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 1500,
        },
        {
          transactions: 1,
          key: "Yearly",
          revenue: 69,
        },
      ],
    },
  },
  Quarterly: {
    transactions: 100,
    revenue: 2000,
  },
  Yearly: {
    transactions: 7,
    revenue: 4000,
  },
};
const yesterdayResult = {
  daywise: {
    "2024-08-19T23:30:00.000Z": {
      transactions: 1,
      revenue: 19,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 19,
        },
      ],
    },
    "2024-08-20T00:30:00.000Z": {
      transactions: 1,
      revenue: 29,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 29,
        },
      ],
    },
    "2024-08-20T02:30:00.000Z": {
      transactions: 4,
      revenue: 106,
      consolidated: [
        {
          transactions: 3,
          key: "Quarterly",
          revenue: 57,
        },
        {
          transactions: 1,
          key: "Yearly",
          revenue: 49,
        },
      ],
    },
    "2024-08-20T03:30:00.000Z": {
      transactions: 2,
      revenue: 48,
      consolidated: [
        {
          transactions: 2,
          key: "Quarterly",
          revenue: 48,
        },
      ],
    },
    "2024-08-20T04:30:00.000Z": {
      transactions: 1,
      revenue: 800,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 800,
        },
      ],
    },
    "2024-08-20T06:30:00.000Z": {
      transactions: 1,
      revenue: 39,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 39,
        },
      ],
    },
    "2024-08-20T07:30:00.000Z": {
      transactions: 1,
      revenue: 29,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 29,
        },
      ],
    },
    "2024-08-20T10:30:00.000Z": {
      transactions: 1,
      revenue: 29,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 29,
        },
      ],
    },
    "2024-08-20T11:30:00.000Z": {
      transactions: 2,
      revenue: 1519,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 19,
        },
        {
          transactions: 1,
          key: "Yearly",
          revenue: 1500,
        },
      ],
    },
    "2024-08-20T12:30:00.000Z": {
      transactions: 1,
      revenue: 800,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 800,
        },
      ],
    },
    "2024-08-20T13:30:00.000Z": {
      transactions: 2,
      revenue: 68,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 19,
        },
        {
          transactions: 1,
          key: "Yearly",
          revenue: 49,
        },
      ],
    },
    "2024-08-20T14:30:00.000Z": {
      transactions: 4,
      revenue: 1307,
      consolidated: [
        {
          transactions: 4,
          key: "Quarterly",
          revenue: 1307,
        },
      ],
    },
    "2024-08-20T15:30:00.000Z": {
      transactions: 3,
      revenue: 77,
      consolidated: [
        {
          transactions: 3,
          key: "Quarterly",
          revenue: 77,
        },
      ],
    },
    "2024-08-20T16:30:00.000Z": {
      transactions: 3,
      revenue: 1108,
      consolidated: [
        {
          transactions: 2,
          key: "Quarterly",
          revenue: 1028,
        },
        {
          transactions: 1,
          key: "Yearly",
          revenue: 80,
        },
      ],
    },
    "2024-08-20T17:30:00.000Z": {
      transactions: 2,
      revenue: 711,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 12,
        },
        {
          transactions: 1,
          key: "Yearly",
          revenue: 699,
        },
      ],
    },
    "2024-08-20T18:30:00.000Z": {
      transactions: 1,
      revenue: 19,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 19,
        },
      ],
    },
    "2024-08-20T19:30:00.000Z": {
      transactions: 3,
      revenue: 767,
      consolidated: [
        {
          transactions: 2,
          key: "Yearly",
          revenue: 738,
        },
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 29,
        },
      ],
    },
    "2024-08-20T20:30:00.000Z": {
      transactions: 6,
      revenue: 204,
      consolidated: [
        {
          transactions: 3,
          key: "Quarterly",
          revenue: 77,
        },
        {
          transactions: 3,
          key: "Yearly",
          revenue: 127,
        },
      ],
    },
    "2024-08-20T21:30:00.000Z": {
      transactions: 2,
      revenue: 427,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 29,
        },
        {
          transactions: 1,
          key: "Yearly",
          revenue: 398,
        },
      ],
    },
    "2024-08-20T22:30:00.000Z": {
      transactions: 1,
      revenue: 39,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 39,
        },
      ],
    },
    "2024-08-20T23:30:00.000Z": {
      transactions: 6,
      revenue: 194,
      consolidated: [
        {
          transactions: 4,
          key: "Quarterly",
          revenue: 116,
        },
        {
          transactions: 2,
          key: "Yearly",
          revenue: 78,
        },
      ],
    },
  },
  Quarterly: {
    transactions: 300,
    revenue: 4000,
  },
  Yearly: {
    transactions: 100,
    revenue: 3000,
  },
};
const lastWeekResult = {
  daywise: {
    "2024-08-12T23:30:00.000Z": {
      transactions: 1,
      revenue: 800,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 800,
        },
      ],
    },
    "2024-08-13T00:30:00.000Z": {
      transactions: 1,
      revenue: 39,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 39,
        },
      ],
    },
    "2024-08-13T01:30:00.000Z": {
      transactions: 1,
      revenue: 800,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 800,
        },
      ],
    },
    "2024-08-13T02:30:00.000Z": {
      transactions: 3,
      revenue: 2339,
      consolidated: [
        {
          transactions: 2,
          key: "Quarterly",
          revenue: 839,
        },
        {
          transactions: 1,
          key: "Yearly",
          revenue: 1500,
        },
      ],
    },
    "2024-08-13T03:30:00.000Z": {
      transactions: 1,
      revenue: 19,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 19,
        },
      ],
    },
    "2024-08-13T05:30:00.000Z": {
      transactions: 1,
      revenue: 39,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 39,
        },
      ],
    },
    "2024-08-13T07:30:00.000Z": {
      transactions: 1,
      revenue: 800,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 800,
        },
      ],
    },
    "2024-08-13T08:30:00.000Z": {
      transactions: 1,
      revenue: 800,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 800,
        },
      ],
    },
    "2024-08-13T09:30:00.000Z": {
      transactions: 2,
      revenue: 829,
      consolidated: [
        {
          transactions: 2,
          key: "Quarterly",
          revenue: 829,
        },
      ],
    },
    "2024-08-13T11:30:00.000Z": {
      transactions: 3,
      revenue: 87,
      consolidated: [
        {
          transactions: 3,
          key: "Quarterly",
          revenue: 87,
        },
      ],
    },
    "2024-08-13T12:30:00.000Z": {
      transactions: 1,
      revenue: 800,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 800,
        },
      ],
    },
    "2024-08-13T13:30:00.000Z": {
      transactions: 2,
      revenue: 1600,
      consolidated: [
        {
          transactions: 2,
          key: "Quarterly",
          revenue: 1600,
        },
      ],
    },
    "2024-08-13T14:30:00.000Z": {
      transactions: 6,
      revenue: 1075,
      consolidated: [
        {
          transactions: 4,
          key: "Quarterly",
          revenue: 887,
        },
        {
          transactions: 2,
          key: "Yearly",
          revenue: 188,
        },
      ],
    },
    "2024-08-13T15:30:00.000Z": {
      transactions: 3,
      revenue: 1269,
      consolidated: [
        {
          transactions: 2,
          key: "Yearly",
          revenue: 469,
        },
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 800,
        },
      ],
    },
    "2024-08-13T16:30:00.000Z": {
      transactions: 3,
      revenue: 2400,
      consolidated: [
        {
          transactions: 3,
          key: "Quarterly",
          revenue: 2400,
        },
      ],
    },
    "2024-08-13T17:30:00.000Z": {
      transactions: 5,
      revenue: 1096,
      consolidated: [
        {
          transactions: 3,
          key: "Yearly",
          revenue: 257,
        },
        {
          transactions: 2,
          key: "Quarterly",
          revenue: 839,
        },
      ],
    },
    "2024-08-13T20:30:00.000Z": {
      transactions: 10,
      revenue: 2518,
      consolidated: [
        {
          transactions: 8,
          key: "Quarterly",
          revenue: 949,
        },
        {
          transactions: 2,
          key: "Yearly",
          revenue: 1569,
        },
      ],
    },
    "2024-08-13T21:30:00.000Z": {
      transactions: 3,
      revenue: 57,
      consolidated: [
        {
          transactions: 3,
          key: "Quarterly",
          revenue: 57,
        },
      ],
    },
    "2024-08-13T22:30:00.000Z": {
      transactions: 1,
      revenue: 800,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 800,
        },
      ],
    },
    "2024-08-13T23:30:00.000Z": {
      transactions: 5,
      revenue: 919,
      consolidated: [
        {
          transactions: 4,
          key: "Quarterly",
          revenue: 880,
        },
        {
          transactions: 1,
          key: "Yearly",
          revenue: 39,
        },
      ],
    },
  },
  Quarterly: {
    transactions: 400,
    revenue: 15000,
  },
  Yearly: {
    transactions: 100,
    revenue: 40000,
  },
};

export { todayResult, yesterdayResult, lastWeekResult };
