// import PropTypes from "prop-types";
// import React, { useEffect } from "react";
// import { Navigate } from "react-router-dom";

// import { logoutUser } from "../../slices/thunks";

// //redux
// import { useSelector, useDispatch } from "react-redux";

// import withRouter from "../../Components/Common/withRouter";
// import { createSelector } from "reselect";

// const Logout = (props) => {
//   const dispatch = useDispatch();


//   const logoutData = createSelector(
//     (state) => state.Login,
//     (isUserLogout) => isUserLogout.isUserLogout
//   );
//   // Inside your component
//   const isUserLogout = useSelector(logoutData);

//   useEffect(() => {
//     dispatch(logoutUser());
//   }, [dispatch]);

//   if (isUserLogout) {
//     return <Navigate to="/login" />;
//   }

//   return <></>;
// };

// Logout.propTypes = {
//   history: PropTypes.object,
// };


// export default withRouter(Logout);





import React, { useEffect } from "react";
import PropTypes from "prop-types";
import withRouter from "../../Components/Common/withRouter";


//redux
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { client } from "../../utils/useAuth";

const Logout = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  
  useEffect(() => {
    // dispatch(logoutUser(history));
    // client.logout({ redirectUri: 'http://localhost:3000/' })
    client.logout({ redirectUri: window.location.href.replace("/logout","/") })
    
  }, []);

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);