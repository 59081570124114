import React, { useState } from "react";
import ClosingPlb from "./PlbAnalysis/closingPlb";
import StreamAndDidNotStream from "./StreamAndDidNotStream/index";
import InstallAndUninstallAnalysis from "./InstallAndUninstallAnalysis/index";
import ActiveUserAnalysis from "./ActiveUserAnalysis/index";
import AutoDebitMandate from "./AutoDebitMandate";

const HealthMatrix = () => {
  document.title = "Health Matrix | Saranyu - Admin & Dashboard";
  return (
    <React.Fragment>
      <div className="page-content">
        <ClosingPlb />
        <ActiveUserAnalysis />
        <InstallAndUninstallAnalysis />
        <StreamAndDidNotStream />
        <AutoDebitMandate />
      </div>
    </React.Fragment>
  );
};

export default HealthMatrix;
