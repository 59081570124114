import { createAsyncThunk } from "@reduxjs/toolkit";

import { getSubsArpuCps as getSubsArpuCpsApi } from "../../../helpers/fakebackend_helper";
export const getSubsNonGujaratiData = createAsyncThunk(
  "RevenueMatrix/Subs ARPU CPS Non Gujarati",
  async (data) => {
    try {
      const response = getSubsArpuCpsApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
