import { createSlice } from "@reduxjs/toolkit";
import { getStreamAndDidNotStreamData } from "./thunk";

export const initialState = {
  data: null,
  status: "idle",
  error: {},
  loader: false,
};

const StreamAndDidNotStreamSlice = createSlice({
  name: "StreamAndDidNotStream",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getStreamAndDidNotStreamData.pending, (state) => {
      state.status = "loading";
      state.loader = true;
    });
    builder.addCase(getStreamAndDidNotStreamData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.status = "succeeded";
        state.data = action.payload.data;
        state.loader = false;
      }
    });
    builder.addCase(getStreamAndDidNotStreamData.rejected, (state, action) => {
      state.error = action.error.message;
      state.status = "failed";
      state.loader = false;
    });
  },
});

export default StreamAndDidNotStreamSlice.reducer;
