const tableData = [
  {
    month: "2024-01-01",
    dau: 100,
    mau: "20000",
    streaming_dau: 5000.428571428572,
    streaming_mau: "43000",
  },
  {
    month: "2024-03-01",
    dau: 90,
    mau: "40000",
    streaming_dau: 6000.1578947368425,
    streaming_mau: "88000",
  },
  {
    month: "2024-04-01",
    dau: 80,
    mau: "54000",
    streaming_dau: 6000.551724137931,
    streaming_mau: "130000",
  },
  {
    month: "2024-05-01",
    dau: 0,
    mau: "49000",
    streaming_dau: 9000.636363636364,
    streaming_mau: "130000",
  },
  {
    month: "2024-06-01",
    dau: 80,
    mau: "51000",
    streaming_dau: 9000.611111111111,
    streaming_mau: "130000",
  },
];
const dauData = [
  {
    dau: "69101",
    date: "2024-08-01",
  },
  {
    dau: "63307",
    date: "2024-08-02",
  },
  {
    dau: "69272",
    date: "2024-08-03",
  },
  {
    dau: "69070",
    date: "2024-08-04",
  },
  {
    dau: "63913",
    date: "2024-08-05",
  },
  {
    dau: "66125",
    date: "2024-08-06",
  },
  {
    dau: "67039",
    date: "2024-08-07",
  },
  {
    dau: "66224",
    date: "2024-08-08",
  },
  {
    dau: "71654",
    date: "2024-08-09",
  },
  {
    dau: "78662",
    date: "2024-08-10",
  },
  {
    dau: "77378",
    date: "2024-08-11",
  },
  {
    dau: "73523",
    date: "2024-08-12",
  },
  {
    dau: "74131",
    date: "2024-08-13",
  },
  {
    dau: "93061",
    date: "2024-08-14",
  },
  {
    dau: "88817",
    date: "2024-08-15",
  },
  {
    dau: "89325",
    date: "2024-08-16",
  },
  {
    dau: "107966",
    date: "2024-08-17",
  },
  {
    dau: "118655",
    date: "2024-08-18",
  },
  {
    dau: "105639",
    date: "2024-08-19",
  },
];
const prevStreamingDauData = [
  {
    streaming_dau: "20631",
    event_date: "2024-07-01",
  },
  {
    streaming_dau: "21034",
    event_date: "2024-07-02",
  },
  {
    streaming_dau: "20879",
    event_date: "2024-07-03",
  },
  {
    streaming_dau: "20667",
    event_date: "2024-07-04",
  },
  {
    streaming_dau: "19846",
    event_date: "2024-07-05",
  },
  {
    streaming_dau: "21678",
    event_date: "2024-07-06",
  },
  {
    streaming_dau: "24708",
    event_date: "2024-07-07",
  },
  {
    streaming_dau: "19082",
    event_date: "2024-07-08",
  },
  {
    streaming_dau: "20135",
    event_date: "2024-07-09",
  },
  {
    streaming_dau: "18777",
    event_date: "2024-07-10",
  },
  {
    streaming_dau: "18551",
    event_date: "2024-07-11",
  },
  {
    streaming_dau: "18702",
    event_date: "2024-07-12",
  },
  {
    streaming_dau: "19346",
    event_date: "2024-07-13",
  },
  {
    streaming_dau: "18785",
    event_date: "2024-07-14",
  },
  {
    streaming_dau: "15636",
    event_date: "2024-07-15",
  },
  {
    streaming_dau: "15614",
    event_date: "2024-07-16",
  },
  {
    streaming_dau: "16730",
    event_date: "2024-07-17",
  },
  {
    streaming_dau: "19188",
    event_date: "2024-07-18",
  },
  {
    streaming_dau: "19531",
    event_date: "2024-07-19",
  },
  {
    streaming_dau: "21021",
    event_date: "2024-07-20",
  },
];
const streamingDauData = [
  {
    streaming_dau: "14180",
    event_date: "2024-08-01",
  },
  {
    streaming_dau: "14187",
    event_date: "2024-08-02",
  },
  {
    streaming_dau: "18831",
    event_date: "2024-08-03",
  },
  {
    streaming_dau: "17764",
    event_date: "2024-08-04",
  },
  {
    streaming_dau: "14086",
    event_date: "2024-08-05",
  },
  {
    streaming_dau: "14508",
    event_date: "2024-08-06",
  },
  {
    streaming_dau: "14834",
    event_date: "2024-08-07",
  },
  {
    streaming_dau: "15548",
    event_date: "2024-08-08",
  },
  {
    streaming_dau: "16625",
    event_date: "2024-08-09",
  },
  {
    streaming_dau: "19031",
    event_date: "2024-08-10",
  },
  {
    streaming_dau: "19098",
    event_date: "2024-08-11",
  },
  {
    streaming_dau: "15586",
    event_date: "2024-08-12",
  },
  {
    streaming_dau: "16721",
    event_date: "2024-08-13",
  },
  {
    streaming_dau: "23239",
    event_date: "2024-08-14",
  },
  {
    streaming_dau: "22533",
    event_date: "2024-08-15",
  },
  {
    streaming_dau: "21369",
    event_date: "2024-08-16",
  },
  {
    streaming_dau: "25157",
    event_date: "2024-08-17",
  },
  {
    streaming_dau: "27036",
    event_date: "2024-08-18",
  },
  {
    streaming_dau: "21866",
    event_date: "2024-08-19",
  },
];
const prevDauData = [
  {
    dau: "79775",
    date: "2024-07-01",
  },
  {
    dau: "83818",
    date: "2024-07-02",
  },
  {
    dau: "83085",
    date: "2024-07-03",
  },
  {
    dau: "80868",
    date: "2024-07-04",
  },
  {
    dau: "78027",
    date: "2024-07-05",
  },
  {
    dau: "85090",
    date: "2024-07-06",
  },
  {
    dau: "88676",
    date: "2024-07-07",
  },
  {
    dau: "74559",
    date: "2024-07-08",
  },
  {
    dau: "77898",
    date: "2024-07-09",
  },
  {
    dau: "70970",
    date: "2024-07-10",
  },
  {
    dau: "71214",
    date: "2024-07-11",
  },
  {
    dau: "76904",
    date: "2024-07-12",
  },
  {
    dau: "77625",
    date: "2024-07-13",
  },
  {
    dau: "75910",
    date: "2024-07-14",
  },
  {
    dau: "68117",
    date: "2024-07-15",
  },
  {
    dau: "60847",
    date: "2024-07-16",
  },
  {
    dau: "62455",
    date: "2024-07-17",
  },
  {
    dau: "67382",
    date: "2024-07-18",
  },
  {
    dau: "69504",
    date: "2024-07-19",
  },
  {
    dau: "75728",
    date: "2024-07-20",
  },
];

export {
  tableData,
  dauData,
  prevDauData,
  streamingDauData,
  prevStreamingDauData,
};
