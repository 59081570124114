const paidUserData = {
  existing_users: [
    {
      start_day_of_month: "2024-01-01",
      user_status: "existing",
      users: "18800",
      mou: 1091234,
      mpu: 10,
    },
    {
      start_day_of_month: "2024-03-01",
      user_status: "existing",
      users: "108800",
      mou: 109124,
      mpu: 10,
    },
    {
      start_day_of_month: "2024-04-01",
      user_status: "existing",
      users: "78800",
      mou: 101234,
      mpu: 10,
    },
    {
      start_day_of_month: "2024-05-01",
      user_status: "existing",
      users: "90800",
      mou: 109234,
      mpu: 10,
    },
    {
      start_day_of_month: "2024-06-01",
      user_status: "existing",
      users: "108800",
      mou: 1091234,
      mpu: 10,
    },
    {
      start_day_of_month: "Total",
      user_status: "existing",
      users: "108800",
      mou: 1091234,
      mpu: 50,
    },
  ],
  new_users: [
    {
      start_day_of_month: "2024-01-01",
      user_status: "new",
      users: "94561",
      mou: 143000,
      mpu: 10,
    },
    {
      start_day_of_month: "2024-03-01",
      user_status: "new",
      users: "114561",
      mou: 143000,
      mpu: 10,
    },
    {
      start_day_of_month: "2024-04-01",
      user_status: "new",
      users: "124561",
      mou: 143000,
      mpu: 10,
    },
    {
      start_day_of_month: "2024-05-01",
      user_status: "new",
      users: "134561",
      mou: 143000,
      mpu: 10,
    },
    {
      start_day_of_month: "2024-06-01",
      user_status: "new",
      users: "144561",
      mou: 143000,
      mpu: 10,
    },
    {
      start_day_of_month: "Total",
      user_status: "new",
      users: "154561",
      mou: 143000,
      mpu: 10,
    },
  ],
};

export { paidUserData };
