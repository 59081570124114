import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import { organicData as data } from "../../../common/data/RevenueMatrix/organicData";

const headerColors = ["#1B2431", "#78AD2F", "#2BA558", "#009978", "#00898D"];
const categoryColors = ["#FFA600", "#BAAF00", "#78AD2F"];
const headerArray = [
  "Performance",
  "Packs",
  "Revenue",
  "Subscriptions",
  "ARPU",
];

const packOrder = [
  "Monthly",
  "Quarterly",
  "Half Yearly",
  "Yearly",
  "Two Yearly",
  "Total",
];

function OrganicTable() {
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(true);

  // const { data, loader } = useSelector((state) => state.OrganicTransactions);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const formattedData = Object.keys(data).reduce((acc, performanceKey) => {
        if (performanceKey !== "grand") {
          const performanceData = data[performanceKey]
            .map((pack) => ({
              packwise: pack.packwise,
              revenue: parseInt(pack.revenue, 10) || 0,
              subs: parseInt(pack.subs, 10) || 0,
              arpu: parseInt(pack.arpu, 10) || 0,
            }))
            .sort((a, b) => {
              const indexA = packOrder.indexOf(a.packwise);
              const indexB = packOrder.indexOf(b.packwise);
              return indexA - indexB;
            });

          acc.push({
            performance: `${performanceKey
              .charAt(0)
              .toUpperCase()}${performanceKey.slice(1)}`,
            data: performanceData,
          });
        }
        return acc;
      }, []);

      setTableData(formattedData);
      setLoader(false);
    }
  }, [data]);

  const grandTotalData = data?.grand?.[0] || null;

  return (
    <React.Fragment>
      {!loader ? (
        <div className="table-responsive sh-table-styling mb-4">
          <Table className="table-nowrap table-bordered mb-0" size="sm">
            <thead className="sh-table-header text-center">
              <tr className="table-row">
                {headerArray.map((value, index) => (
                  <th
                    scope="col"
                    key={index}
                    style={{
                      backgroundColor: headerColors[index],
                      padding: "0.5rem",
                      textAlign: "center",
                    }}
                  >
                    {value}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((category, index) => (
                <React.Fragment key={index}>
                  <tr
                    style={{
                      backgroundColor:
                        categoryColors[index % categoryColors.length],
                      color: "white",
                      fontWeight: "700",
                      fontSize: "16px",
                    }}
                  >
                    <td
                      rowSpan={category.data.length + 1}
                      className="text-center align-middle"
                      style={{ padding: "0.5rem" }}
                    >
                      {category.performance}
                    </td>
                  </tr>
                  {category.data.map((item, idx) => (
                    <tr
                      key={idx}
                      style={{
                        backgroundColor:
                          item.packwise.toLowerCase() === "total"
                            ? categoryColors[index % categoryColors.length]
                            : "inherit",
                        color:
                          item.packwise.toLowerCase() === "total"
                            ? "white"
                            : "inherit",
                        fontSize: "16px",
                      }}
                    >
                      <td style={{ padding: "0.24rem", textAlign: "left" }}>
                        {item.packwise}
                      </td>
                      <td style={{ padding: "0.24rem", textAlign: "center" }}>
                        {item.revenue.toLocaleString()}
                      </td>
                      <td style={{ padding: "0.24rem", textAlign: "center" }}>
                        {item.subs.toLocaleString()}
                      </td>
                      <td style={{ padding: "0.24rem", textAlign: "center" }}>
                        {item.arpu ? item.arpu.toLocaleString() : "N/A"}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
              {grandTotalData && (
                <tr
                  style={{
                    backgroundColor: "#146588",
                    color: "white",
                    fontSize: "16px",
                  }}
                >
                  <td
                    className="text-center"
                    style={{ padding: "0.5rem", fontWeight: "700" }}
                  >
                    Grand Total
                  </td>
                  <td style={{ padding: "0.5rem", textAlign: "left" }}>
                    {grandTotalData.packwise}
                  </td>
                  <td style={{ padding: "0.5rem", textAlign: "center" }}>
                    {parseInt(grandTotalData.revenue, 10).toLocaleString()}
                  </td>
                  <td style={{ padding: "0.5rem", textAlign: "center" }}>
                    {parseInt(grandTotalData.subs, 10).toLocaleString()}
                  </td>
                  <td style={{ padding: "0.5rem", textAlign: "center" }}>
                    {grandTotalData.arpu
                      ? parseInt(grandTotalData.arpu, 10).toLocaleString()
                      : "N/A"}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "400px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default OrganicTable;
