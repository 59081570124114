import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Table } from "reactstrap";
// import Components
import FilterSection from "../../RevenueMatrix/Subscription/filterSection";
import DateDropdown from "../../HealthMatrix/DateDropdown";
import {
  getMTDDateRange,
  getQTDDateRange,
  getYTDDateRange,
} from "../../../helpers/dateUtils";

import { BasicHeatmap } from "./HourlyConsumptionAnalysisChart";

import { getHourlyConsumptionAnalysisData } from "../../../slices/ConsumptionHighlights/HourlyConsumptionAnalysis/thunk";

const filterOptions = {
  user_type: {
    All: "Free,Paid",
    Free: "Free",
    Paid: "Paid",
  },
  acq_type: {
    All: "organic,performance_guj,performance_edgy,affiliate",
    Organic: "organic",
    "Performance Guj": "performance_guj",
    "Performance Edgy": "peformance_edgy",
    Affiliate: "affiliate",
  },
  platforms: {
    All: "android,web,external,ios",
    Android: "android",
    Web: "web",
    Tv: "external",
    iOS: "ios",
  },
  category: {
    All: "gujarati,non_gujarati",
    Gujarati: "gujarati",
    "Non Gujarati": "non_gujarati",
  },
  region: {
    All: "India,row",
    India: "India",
    ROW: "row",
  },
};

function HourlyConsumptionAnalysis() {
  const dispatch = useDispatch();
  const [customDateRange, setCustomDateRange] = useState(getMTDDateRange());
  const [filters, setFilters] = useState({
    start_date: customDateRange.start_date,
    end_date: customDateRange.end_date,
    acq_type: filterOptions.acq_type.All,
    user_type: filterOptions.user_type.All,
    platforms: filterOptions.platforms.All,
    category: filterOptions.category.All,
    region: filterOptions.region.All,
  });

  const fetchData = async (dateRange) => {
    const dataObject = {
      ...filters,
      ...dateRange,
    };
    // dispatch(getHourlyConsumptionAnalysisData(dataObject));
  };

  useEffect(() => {
    fetchData(customDateRange);
  }, [customDateRange, filters]);

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const handleDateRangeChange = (range) => {
    setCustomDateRange(range);
    setFilters((prevFilters) => ({
      ...prevFilters,
      start_date: range.startDate,
      end_date: range.endDate,
    }));
  };

  const handleResetFilters = () => {
    setFilters({
      daily_wise_data: true,
      platforms: filterOptions.platforms.All,
      user_type: filterOptions.user_type.All,
      region: filterOptions.region.All,
      category: filterOptions.category.All,
    });
  };
  return (
    <React.Fragment>
      <Row>
        <Container
          fluid
          className="customContainerStyle"
          style={{ height: "960px" }}
        >
          <Row>
            <Col style={{ padding: "20px" }}>
              <h4 className="card-heading-text">Hourly Consumption Analysis</h4>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-between">
              <FilterSection
                filterOptions={filterOptions}
                filters={filters}
                handleFilterChange={handleFilterChange}
                handleResetFilters={handleResetFilters}
              />
              <DateDropdown
                onDateRangeChange={handleDateRangeChange}
                options={["MTD", "Last Month", "1 Year", "Custom"]}
              />
            </Col>
          </Row>
          <Row>
            <BasicHeatmap />
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}

export default HourlyConsumptionAnalysis;
