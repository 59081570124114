import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Table, Card } from "reactstrap";

function GeoPerState() {
  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card className="card-styling" style={{ height: "264px" }}>
            <div className="table-responsive ms-4 me-4 mt-4 sh-table-styling">
              <Table className="table-nowrap mb-0 table-bordered" size="sm">
                <thead className="text-white text-center">
                  <tr className="table-row sh-table-header">
                    <th
                      colSpan={4}
                      style={{ backgroundColor: "#1A3E5A", color: "white" }}
                    >
                      Ahmedabad
                    </th>
                  </tr>
                  <tr className="table-row">
                    <th scope="col" style={{ backgroundColor: "#1B2431" }}>
                      Titles
                    </th>
                    <th scope="col" style={{ backgroundColor: "#78AD2F" }}>
                      Users
                    </th>
                    <th scope="col" style={{ backgroundColor: "#2BA558" }}>
                      MOU
                    </th>
                    <th scope="col" style={{ backgroundColor: "#009978" }}>
                      MPU
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Lagan Special</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Vash</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Angithee 3</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Ek Anjaan Rishtey Ka Guilt 3</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Hu Ane Tu</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
        <Col>
          <Card className="card-styling" style={{ height: "264px" }}>
            <div className="table-responsive ms-4 me-4 mt-4 sh-table-styling">
              <Table className="table-nowrap mb-0 table-bordered" size="sm">
                <thead className="text-white text-center">
                  <tr className="table-row sh-table-header">
                    <th
                      colSpan={4}
                      style={{ backgroundColor: "#1A3E5A", color: "white" }}
                    >
                      Surat
                    </th>
                  </tr>
                  <tr className="table-row">
                    <th scope="col" style={{ backgroundColor: "#1B2431" }}>
                      Titles
                    </th>
                    <th scope="col" style={{ backgroundColor: "#78AD2F" }}>
                      Users
                    </th>
                    <th scope="col" style={{ backgroundColor: "#2BA558" }}>
                      MOU
                    </th>
                    <th scope="col" style={{ backgroundColor: "#009978" }}>
                      MPU
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Lagan Special</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Vash</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Angithee 3</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Ek Anjaan Rishtey Ka Guilt 3</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Hu Ane Tu</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="card-styling" style={{ height: "264px" }}>
            <div className="table-responsive ms-4 me-4 mt-4 sh-table-styling">
              <Table className="table-nowrap mb-0 table-bordered" size="sm">
                <thead className="text-white text-center">
                  <tr className="table-row sh-table-header">
                    <th
                      colSpan={4}
                      style={{ backgroundColor: "#1A3E5A", color: "white" }}
                    >
                      Mumbai
                    </th>
                  </tr>
                  <tr className="table-row">
                    <th scope="col" style={{ backgroundColor: "#1B2431" }}>
                      Titles
                    </th>
                    <th scope="col" style={{ backgroundColor: "#78AD2F" }}>
                      Users
                    </th>
                    <th scope="col" style={{ backgroundColor: "#2BA558" }}>
                      MOU
                    </th>
                    <th scope="col" style={{ backgroundColor: "#009978" }}>
                      MPU
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Lagan Special</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Vash</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Angithee 3</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Ek Anjaan Rishtey Ka Guilt 3</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Hu Ane Tu</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
        <Col>
          <Card className="card-styling" style={{ height: "264px" }}>
            <div className="table-responsive ms-4 me-4 mt-4 sh-table-styling">
              <Table className="table-nowrap mb-0 table-bordered" size="sm">
                <thead className="text-white text-center">
                  <tr className="table-row sh-table-header">
                    <th
                      colSpan={4}
                      style={{ backgroundColor: "#1A3E5A", color: "white" }}
                    >
                      Vadodara
                    </th>
                  </tr>
                  <tr className="table-row">
                    <th scope="col" style={{ backgroundColor: "#1B2431" }}>
                      Titles
                    </th>
                    <th scope="col" style={{ backgroundColor: "#78AD2F" }}>
                      Users
                    </th>
                    <th scope="col" style={{ backgroundColor: "#2BA558" }}>
                      MOU
                    </th>
                    <th scope="col" style={{ backgroundColor: "#009978" }}>
                      MPU
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Lagan Special</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Vash</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Angithee 3</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Ek Anjaan Rishtey Ka Guilt 3</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Hu Ane Tu</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="card-styling" style={{ height: "264px" }}>
            <div className="table-responsive ms-4 me-4 mt-4 sh-table-styling">
              <Table className="table-nowrap mb-0 table-bordered" size="sm">
                <thead className="text-white text-center">
                  <tr className="table-row sh-table-header">
                    <th
                      colSpan={4}
                      style={{ backgroundColor: "#1A3E5A", color: "white" }}
                    >
                      Rajkot
                    </th>
                  </tr>
                  <tr className="table-row">
                    <th scope="col" style={{ backgroundColor: "#1B2431" }}>
                      Titles
                    </th>
                    <th scope="col" style={{ backgroundColor: "#78AD2F" }}>
                      Users
                    </th>
                    <th scope="col" style={{ backgroundColor: "#2BA558" }}>
                      MOU
                    </th>
                    <th scope="col" style={{ backgroundColor: "#009978" }}>
                      MPU
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Lagan Special</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Vash</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Angithee 3</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Ek Anjaan Rishtey Ka Guilt 3</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Hu Ane Tu</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
        <Col>
          <Card className="card-styling" style={{ height: "264px" }}>
            <div className="table-responsive ms-4 me-4 mt-4 sh-table-styling">
              <Table className="table-nowrap mb-0 table-bordered" size="sm">
                <thead className="text-white text-center">
                  <tr className="table-row sh-table-header">
                    <th
                      colSpan={4}
                      style={{ backgroundColor: "#1A3E5A", color: "white" }}
                    >
                      Pune
                    </th>
                  </tr>
                  <tr className="table-row">
                    <th scope="col" style={{ backgroundColor: "#1B2431" }}>
                      Titles
                    </th>
                    <th scope="col" style={{ backgroundColor: "#78AD2F" }}>
                      Users
                    </th>
                    <th scope="col" style={{ backgroundColor: "#2BA558" }}>
                      MOU
                    </th>
                    <th scope="col" style={{ backgroundColor: "#009978" }}>
                      MPU
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Lagan Special</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Vash</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Angithee 3</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Ek Anjaan Rishtey Ka Guilt 3</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Hu Ane Tu</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="card-styling" style={{ height: "264px" }}>
            <div className="table-responsive ms-4 me-4 mt-4 sh-table-styling">
              <Table className="table-nowrap mb-0 table-bordered" size="sm">
                <thead className="text-white text-center">
                  <tr className="table-row sh-table-header">
                    <th
                      colSpan={4}
                      style={{ backgroundColor: "#1A3E5A", color: "white" }}
                    >
                      Bengaluru
                    </th>
                  </tr>
                  <tr className="table-row">
                    <th scope="col" style={{ backgroundColor: "#1B2431" }}>
                      Titles
                    </th>
                    <th scope="col" style={{ backgroundColor: "#78AD2F" }}>
                      Users
                    </th>
                    <th scope="col" style={{ backgroundColor: "#2BA558" }}>
                      MOU
                    </th>
                    <th scope="col" style={{ backgroundColor: "#009978" }}>
                      MPU
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Lagan Special</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Vash</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Angithee 3</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Ek Anjaan Rishtey Ka Guilt 3</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Hu Ane Tu</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
        <Col>
          <Card className="card-styling" style={{ height: "264px" }}>
            <div className="table-responsive ms-4 me-4 mt-4 sh-table-styling">
              <Table className="table-nowrap mb-0 table-bordered" size="sm">
                <thead className="text-white text-center">
                  <tr className="table-row sh-table-header">
                    <th
                      colSpan={4}
                      style={{ backgroundColor: "#1A3E5A", color: "white" }}
                    >
                      Delhi
                    </th>
                  </tr>
                  <tr className="table-row">
                    <th scope="col" style={{ backgroundColor: "#1B2431" }}>
                      Titles
                    </th>
                    <th scope="col" style={{ backgroundColor: "#78AD2F" }}>
                      Users
                    </th>
                    <th scope="col" style={{ backgroundColor: "#2BA558" }}>
                      MOU
                    </th>
                    <th scope="col" style={{ backgroundColor: "#009978" }}>
                      MPU
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Lagan Special</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Vash</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Angithee 3</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Ek Anjaan Rishtey Ka Guilt 3</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Hu Ane Tu</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="card-styling" style={{ height: "264px" }}>
            <div className="table-responsive ms-4 me-4 mt-4 sh-table-styling">
              <Table className="table-nowrap mb-0 table-bordered" size="sm">
                <thead className="text-white text-center">
                  <tr className="table-row sh-table-header">
                    <th
                      colSpan={4}
                      style={{ backgroundColor: "#1A3E5A", color: "white" }}
                    >
                      Hyderabad
                    </th>
                  </tr>
                  <tr className="table-row">
                    <th scope="col" style={{ backgroundColor: "#1B2431" }}>
                      Titles
                    </th>
                    <th scope="col" style={{ backgroundColor: "#78AD2F" }}>
                      Users
                    </th>
                    <th scope="col" style={{ backgroundColor: "#2BA558" }}>
                      MOU
                    </th>
                    <th scope="col" style={{ backgroundColor: "#009978" }}>
                      MPU
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Lagan Special</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Vash</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Angithee 3</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Ek Anjaan Rishtey Ka Guilt 3</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Hu Ane Tu</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
        <Col>
          <Card className="card-styling" style={{ height: "264px" }}>
            <div className="table-responsive ms-4 me-4 mt-4 sh-table-styling">
              <Table className="table-nowrap mb-0 table-bordered" size="sm">
                <thead className="text-white text-center">
                  <tr className="table-row sh-table-header">
                    <th
                      colSpan={4}
                      style={{ backgroundColor: "#1A3E5A", color: "white" }}
                    >
                      Lucknow
                    </th>
                  </tr>
                  <tr className="table-row">
                    <th scope="col" style={{ backgroundColor: "#1B2431" }}>
                      Titles
                    </th>
                    <th scope="col" style={{ backgroundColor: "#78AD2F" }}>
                      Users
                    </th>
                    <th scope="col" style={{ backgroundColor: "#2BA558" }}>
                      MOU
                    </th>
                    <th scope="col" style={{ backgroundColor: "#009978" }}>
                      MPU
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Lagan Special</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Vash</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Angithee 3</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Ek Anjaan Rishtey Ka Guilt 3</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>Hu Ane Tu</td>
                    <td>270</td>
                    <td>16000</td>
                    <td>75</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default GeoPerState;
