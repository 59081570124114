import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import { tabelData as dataFromState } from "../../../common/data/HealthMatrix/autoDebitData";

function AutoDebitFunnelChart({ dataColors }) {
  var funnelChartColors = getChartColorsArray(dataColors);

  const [seriesData, setSeriesData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loader, setLoader] = useState(true);
  // const dataFromState = useSelector((state) => state.AutoDebit?.tableData);
  // const { loader } = useSelector((state) => state.AutoDebit);

  useEffect(() => {
    if (Array.isArray(dataFromState) && dataFromState.length > 0) {
      const filterData = dataFromState.find((obj) => obj.period === "Total");
      if (filterData) {
        const { due_for_renewal, success, churn_out } = filterData;

        // Convert to numbers
        const numericValues = [
          { value: Number(due_for_renewal), category: "Due For Renewal" },
          { value: Number(success), category: "Success" },
          { value: Number(churn_out), category: "Churn Out" },
        ];

        // Sort by value in descending order
        numericValues.sort((a, b) => b.value - a.value);

        // Extract sorted values and categories
        const sortedValues = numericValues.map((item) => item.value);
        const sortedCategories = numericValues.map((item) => item.category);

        const formattedSeries = sortedValues.map((v, index) => ({
          x: sortedCategories[index],
          y: sortedValues[index],
        }));
        setCategories(sortedCategories);
        setSeriesData(formattedSeries);
        setLoader(false);
      }
    }
  }, [dataFromState]);

  //   series: [
  //     {
  //       name: "Funnel Series",
  //       data: [
  //         {
  //           x: "Sourced",
  //           y: 138
  //         },
  //         {
  //           x: "Assessed",
  //           y: 99
  //         },
  //         {
  //           x: "Technical",
  //           y: 75
  //         },
  //         {
  //           x: "Offered",
  //           y: 30
  //         },
  //       ],
  //     },
  //   ]

  const series = [
    {
      name: "Auto Debit Mandate",
      data: seriesData,
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 200,
      toolbar: {
        show: false,
      },
    },
    colors: funnelChartColors,
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
        barHeight: "60%",
        isFunnel: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
      },
      style: {
        fontSize: "16px",
        fontFamily: "Helvetica, Arial, sans-serif",
        // fontWeight: 700,
        colors: ["#000000"],
      },
      dropShadow: {
        enabled: true,
      },
    },
    xaxis: {
      categories: categories,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        formatter: function (val) {
          return val !== undefined ? val : ""; // Display actual values on x-axis in tooltip
        },
      },
      y: {
        show: true,
        formatter: function (val) {
          return val !== undefined
            ? parseInt(val.toFixed(0)).toLocaleString()
            : ""; // Display actual values in tooltip without formatting
        },
      },
    },
  };

  return (
    <React.Fragment>
      {!loader ? (
        <ReactApexChart
          dir="ltr"
          className="apex-charts"
          series={series}
          options={options}
          type="bar"
          height={350}
        />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "350px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default AutoDebitFunnelChart;
