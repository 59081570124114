import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CountUp from "react-countup";
import { Card, Col, CardText } from "reactstrap";
import riseImage from "../../../assets/images/saranyuImages/Group 4.svg";
import downfallImage from "../../../assets/images/saranyuImages/Group 4 (1).svg";
import { widgetsData } from "../../../common/data/BusinessOverview/widgetsData";

const WidgetsCard = ({ columnSpace }) => {
  // const [loading, setLoading] = useState(true);
  // const widgetsData = useSelector((state) => state.BusinessOverviewWidgets);

  // useEffect(() => {
  //   // Check if all necessary data fields are defined
  //   if (
  //     widgetsData.dauData &&
  //     widgetsData.paidDauData &&
  //     widgetsData.streamingDauData &&
  //     widgetsData.mauData &&
  //     widgetsData.paidMauData &&
  //     widgetsData.streamingMauData
  //   ) {
  //     setLoading(false); // Once all data is loaded, set loading to false
  //   }
  // }, [
  //   widgetsData.dauData,
  //   widgetsData.paidDauData,
  //   widgetsData.streamingDauData,
  //   widgetsData.mauData,
  //   widgetsData.paidMauData,
  //   widgetsData.streamingMauData,
  // ]);

  const getSuffix = (value) => {
    if (value >= 100000) return "L";
    if (value >= 1000) return "K";
    return "";
  };

  const getRoundedValue = (value) => {
    //console.log(typeof value);
    if (value >= 100000) return (Number(value) / 100000).toFixed(2);
    if (value >= 1000) return (Number(value) / 1000).toFixed(2);
    return value;
  };

  // Define the desired order of widgets
  const widgetOrder = [
    "dauData",
    "mauData",
    "streamingDauData",
    "streamingMauData",
    "paidDauData",
    "paidMauData",
  ];

  return (
    <React.Fragment>
      {widgetOrder.map((key, index) => {
        const widgetCard = widgetsData[key];

        // Check if widgetCard is defined and has necessary properties
        if (
          !widgetCard ||
          typeof widgetCard.chart_data === "undefined" ||
          typeof widgetCard.percentage === "undefined"
        ) {
          return null; // Skip rendering this card if data is not available
        }

        // Get absolute percentage value to remove the minus sign
        const percentage = Math.abs(Number(widgetCard.percentage.toFixed(2)));

        return (
          <Col md={columnSpace} key={index}>
            <Card className="widget-card d-flex justify-content-between flex-row">
              <div className="d-flex flex-column justify-content-between">
                <div className="widget-name pt-0">{widgetCard.chart_name}</div>
                <CardText
                  className="widget-percentage d-flex align-items-center"
                  style={{
                    color: widgetCard.percentage > 0 ? "#00B69B" : "#FF0049",
                  }}
                >
                  <img
                    src={widgetCard.percentage > 0 ? riseImage : downfallImage}
                    alt={widgetCard.percentage > 0 ? "Rise" : "Downfall"}
                  />
                  {percentage}%
                </CardText>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center widget-counter">
                <CountUp
                  start={0}
                  end={getRoundedValue(widgetCard.chart_data)}
                  decimals={1}
                  duration={4}
                  suffix={getSuffix(widgetCard.chart_data)}
                />
              </div>
            </Card>
          </Col>
        );
      })}
    </React.Fragment>
  );
};

export default WidgetsCard;
