import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const ContentTypePieChart = ({ dataColors, data, title, contentTypes }) => {
  var chartPieBasicColors = getChartColorsArray(dataColors);
  const [series, setSeries] = useState([]);
  useEffect(() => {
    if (data && data.length > 0) {
      setSeries(data);
    }
  }, [data]);

  //   console.log("ContentTypePieChart", data);
  var options = {
    chart: {
      height: 300,
      type: "pie",
    },
    labels: contentTypes.map((item) => capitalizeFirstLetter(item)),
    legend: {
      position: "bottom",
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },
    title: {
      text: title,
      align: "bottom",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#263238",
      },
    },
    colors: chartPieBasicColors,
    tooltip: {
      enabled: true,
      // x: {
      //   show: true,
      //   formatter: function (val) {
      //     return val !== undefined ? val : ""; // Display actual values on x-axis in tooltip
      //   },
      // },
      y: {
        formatter: function (val) {
          return val !== undefined
            ? parseInt(val.toFixed(0)).toLocaleString()
            : ""; // Display actual values in tooltip without formatting
        },
      },
    },
  };
  return (
    <React.Fragment>
      {series.length > 0 ? (
        <ReactApexChart
          dir="ltr"
          className="apex-charts"
          series={series}
          options={options}
          type="pie"
          height={300}
        />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center mt-4"
          style={{ height: "200px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export { ContentTypePieChart };
