// state.data = action.payload.data.chart_data;

const inorganicData = {
  affliate: [
    {
      packwise: "Total",
      revenue: 123000,
      subs: 250,
      arpu: 1900.3740982480247,
      percentage: 1,
      s_percentage: 1,
    },
    {
      packwise: "Quarterly",
      revenue: 18000,
      subs: 40,
      arpu: 444,
      percentage: 0.1454702164336334,
      s_percentage: 0.19069767441860466,
    },
    {
      packwise: "Half Yearly",
      revenue: 1100,
      subs: 20,
      arpu: 999,
      percentage: 0.009560748260231119,
      s_percentage: 0.009302325581395349,
    },
    {
      packwise: "Monthly",
      revenue: 5000,
      subs: 30,
      arpu: 100,
      percentage: 0.047404711741045776,
      s_percentage: 0.13953488372093023,
    },
    {
      packwise: "Yearly",
      revenue: 99999,
      subs: 120,
      arpu: 700,
      percentage: 0.7975643235650897,
      s_percentage: 0.6604651162790698,
    },
  ],
  gujurati: [
    {
      packwise: "Total",
      revenue: 74000,
      subs: 1000,
      arpu: 2000,
      percentage: 1,
      s_percentage: 1,
    },
    {
      packwise: "Quarterly",
      revenue: "100000",
      subs: 200,
      arpu: 400,
      percentage: 0.1371232713543695,
      s_percentage: 0.17049666419570053,
    },
    {
      packwise: "Half Yearly",
      revenue: "14000",
      subs: 25,
      arpu: 500,
      percentage: 0.019800880366279482,
      s_percentage: 0.018532246108228317,
    },
    {
      packwise: "Two Yearly",
      revenue: "999",
      subs: 10,
      arpu: 999,
      percentage: 0.0013370111176690235,
      s_percentage: 0.0007412898443291327,
    },
    {
      packwise: "Yearly",
      revenue: "57000",
      subs: 800,
      arpu: 700,
      percentage: 0.768432083448766,
      s_percentage: 0.6056338028169014,
    },
    {
      packwise: "Monthly",
      revenue: "54000",
      subs: 200,
      arpu: 200,
      percentage: 0.073306753712916,
      s_percentage: 0.20459599703484063,
    },
  ],
  edgy: [
    {
      packwise: "Total",
      revenue: 1000000,
      subs: 1000,
      arpu: 1000,
      r_percentage: 1,
      s_percentage: 1,
    },
    {
      packwise: "Quarterly",
      revenue: "310000",
      subs: 700,
      arpu: 400,
      r_percentage: 0.29216199432221435,
      s_percentage: 0.3552433517310587,
    },
    {
      packwise: "Half Yearly",
      revenue: "41000",
      subs: 90,
      arpu: 570,
      r_percentage: 0.03808385823278921,
      s_percentage: 0.03462117410938284,
    },
    {
      packwise: "Monthly",
      revenue: "51000",
      subs: 200,
      arpu: 200,
      r_percentage: 0.047997804293825407,
      s_percentage: 0.13095835423983943,
    },
    {
      packwise: "Yearly",
      revenue: "670000",
      subs: 550,
      arpu: 700,
      r_percentage: 0.621756343151171,
      s_percentage: 0.479177119919719,
    },
  ],
  grand: [
    {
      revenue: 1954605,
      subs: 3557,
      arpu: 549.5094180489176,
    },
  ],
};

export { inorganicData };
