// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { getGeoPerState as getGeoPerStateApi } from "../../../helpers/fakebackend_helper";

// export const getGeoPerStateData = createAsyncThunk(
//   "Consumption Highlights/Geo Per State",
//   async (data) => {
//     try {
//       const response = await getGeoPerStateApi(data);
//       return response;
//     } catch (error) {
//       return error;
//     }
//   }
// );

// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { getGeoPerState as getGeoPerStateApi } from "../../../helpers/fakebackend_helper";

// export const getGeoPerStateData = createAsyncThunk(
//   "Consumption Highlights/Geo Per State",
//   async ({ filters, stateName }) => {
//     try {
//       const response = await getGeoPerStateApi(filters);
//       return { stateName, data: response.data };
//     } catch (error) {
//       return error;
//     }
//   }
// );
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getGeoPerState as getGeoPerStateApi } from "../../../helpers/fakebackend_helper";

export const getGeoPerStateData = createAsyncThunk(
  "Consumption Highlights/Geo Per State",
  async ({ filters, filterType, locationName }) => {
    try {
      const response = await getGeoPerStateApi(filters);
      return { filterType, locationName, data: response.data };
    } catch (error) {
      return error;
    }
  }
);
