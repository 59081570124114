import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table, Card } from "reactstrap";

const arrayColors = [
  "#1B2431",
  "#78AD2F",
  "#2BA558",
  "#009978",
  "#00898D",
  "#007893",
  "#009978",
  "#78AD2F",
  "#2BA558",
  "#009978",
  "#00898D",
  "#007893",
  "#2BA558",
];

const headerValues = ["Titles", "Users", "Revenue"];
import { titleChartData as chartData } from "../../../common/data/RevenueMatrix/titleContribution";

function CardontributionTable() {
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(true);
  // const { chartData, loader } = useSelector((state) => state.TitleContribution);

  useEffect(() => {
    if (chartData.length > 0) {
      const data = chartData.map((item) => [
        item.title || "",
        (parseInt(item.Users, 10) || 0).toLocaleString(),
        (parseInt(item.revenue, 10) || 0).toLocaleString(),
      ]);
      setTableData(data);
      setLoader(false);
    }
  }, [chartData]);

  return (
    <React.Fragment>
      {!loader ? (
        <div className="table-responsive sh-table-styling">
          <Table className="table-bordered table-nowrap mb-0" size="sm">
            <thead className="text-white text-center">
              <tr className="table-row sh-table-header">
                {headerValues.map((value, index) => (
                  <th
                    scope="col"
                    style={{ backgroundColor: `${arrayColors[index]}` }}
                    key={index}
                  >
                    {value}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((valuesArr, index) => (
                <tr key={index} className="sh-table-text">
                  {valuesArr.map((value, i) => (
                    <td
                      key={i}
                      className="align-middle"
                      style={{ textAlign: i === 0 ? "left" : "center" }}
                    >
                      {value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "374px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default CardontributionTable;
