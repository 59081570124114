import { createAsyncThunk } from "@reduxjs/toolkit";

// import needed API helpers.
import { getCategoryContribution as getCategoryContributionApi } from "../../../helpers/fakebackend_helper";
import { getPlatformContribution as getPlatformContributionApi } from "../../../helpers/fakebackend_helper";


export const getCategoryContributionData = createAsyncThunk(
  "platformCategory/categoryData",
  async (data) => {
    try {
      const response = getCategoryContributionApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getPlatformContributionData = createAsyncThunk(
  "platformCategory/platformData",
  async (data) => {
    try {
      const response = getPlatformContributionApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);