// state.chartData = action.payload.data.chart_data;

const titleChartData = [
  {
    title: "Kaisa Yeh Fitoor",
    revenue: 1000.10191645958,
    Users: "2000",
    percentage: 3.1893350369135174,
    r: 13662139,
  },
  {
    title: "Crime World",
    revenue: 5000.86762404355,
    Users: "5000",
    percentage: 3.8575488176647816,
    r: 13662139,
  },
  {
    title: "Ek Anjaan Rishtey Ka Guilt 2",
    revenue: 3000.10638456317,
    Users: "1000",
    percentage: 5.967008947123826,
    r: 13662139,
  },
  {
    title: "Angithee 2",
    revenue: 7000.32273244206,
    Users: "1000",
    percentage: 6.871073377522439,
    r: 13662139,
  },
  {
    title: "Hu Ane Tu",
    revenue: 3000.51174380496,
    Users: "1000",
    percentage: 7.805803290892391,
    r: 13662139,
  },
  {
    title: "Flame – An Untold Love Story",
    revenue: 3000.74644260405,
    Users: "2000",
    percentage: 8.387002600766678,
    r: 13662139,
  },
  {
    title: "Vash",
    revenue: 3000.1003905345,
    Users: "1000",
    percentage: 11.037906577276845,
    r: 13662139,
  },
  {
    title: "Angithee 3",
    revenue: 7000.639714756,
    Users: "3000",
    percentage: 17.053058461347362,
    r: 13662139,
  },
  {
    title: "Ek Anjaan Rishtey Ka Guilt 3",
    revenue: 9000.1085666277,
    Users: "4000",
    percentage: 22.447565609945787,
    r: 13662139,
  },
  {
    title: "Lagan Special",
    revenue: 2000.3571018295,
    Users: "4000",
    percentage: 32.59148757624186,
    r: 13662139,
  },
];

export { titleChartData };
