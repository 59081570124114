import { createSlice } from "@reduxjs/toolkit";
import { getOrganicTransactionsData } from "./thunk";

export const initialState = {
  data: {},
  status: "idle",
  error: null,
  loader: false,
};

const OrganicTransactionsSlice = createSlice({
  name: "OrganicTransactions",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganicTransactionsData.pending, (state, action) => {
      state.status = "loading";
      state.loader = true;
    });
    builder.addCase(getOrganicTransactionsData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.status = "succeeded";
        state.data = action.payload.data.chart_data;
        state.loader = false;
      }
    });
    builder.addCase(getOrganicTransactionsData.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
      state.loader = false;
    });
  },
});

export default OrganicTransactionsSlice.reducer;
