import { createSlice } from "@reduxjs/toolkit";

import { getDauChartData } from "./thunk";

const initialState = {
  totalDau: null,
  loader: false,
};

const DauChartSlice = createSlice({
  name: "DashboardDauChart",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getDauChartData.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getDauChartData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.loader = false;
        state.totalDau = action.payload.data.daily_wise_data;
      }
    });
  },
});

export default DauChartSlice.reducer;
