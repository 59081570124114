import { createAsyncThunk } from "@reduxjs/toolkit";

import { getOrganicTransactions as getOrganicTransactionsApi } from "../../../helpers/fakebackend_helper";

export const getOrganicTransactionsData = createAsyncThunk(
  "RevenueMatrix/Organic Transactions",
  async (data) => {
    try {
      const response = getOrganicTransactionsApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
