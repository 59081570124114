import { createAsyncThunk } from "@reduxjs/toolkit";

// import needed API helpers.
import { getStickinessPlatform as getStickinessPlatformApi } from "../../../helpers/fakebackend_helper";
import { getStickinessStream as getStickinessStreamApi } from "../../../helpers/fakebackend_helper";
import { getStickinessPaid as getStickinessPaidApi } from "../../../helpers/fakebackend_helper";

export const getStickinessPlatformData = createAsyncThunk(
  "stickiness/platform",
  async (data) => {
    try {
      const response = getStickinessPlatformApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getStickinessStreamData = createAsyncThunk(
  "stickiness/streaming",
  async (data) => {
    try {
      const response = getStickinessStreamApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getStickinessPaidData = createAsyncThunk(
  "stickiness/paid",
  async (data) => {
    try {
      const response = getStickinessPaidApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
