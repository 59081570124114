import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Card, Col } from "reactstrap";
import { getPackwiseData } from "../../../../slices/businessOverview/packwiseContribution/thunk";

import PackwiseTable from "./packwiseTable";
import PackwiseChart from "./packwiseChart";
import DateDropdown from "../../BusinessOverview/DateDropdown";
import { getMTDDateRange } from "../../../../helpers/dateUtils"; // import functions

function PackwiseContribution() {
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState(getMTDDateRange());
  const [selectedValue, setSelectedValue] = useState("subscriptions");

  // useEffect(() => {
  //   // console.log("useEffect for dispatch called in PackwiseContribution");
  //   const someObject = {
  //     ...dateRange,
  //     period: "month",
  //     daily_wise_data: true,
  //   };
  //   dispatch(getPackwiseData(someObject));
  // }, [dateRange, dispatch]);

  const handleDateRangeChange = (range) => {
    setDateRange(range);
  };

  const handleRadioChange = (e) => {
    setSelectedValue(e.target.id);
  };

  return (
    <React.Fragment>
      <Card className="card-styling" style={{ height: "426px" }}>
        <Row>
          <Col
            md="12"
            className="d-flex justify-content-between card-heading-column"
          >
            <h4 className="card-heading-text">Packwise Contribution</h4>
            <DateDropdown
              onDateRangeChange={handleDateRangeChange}
              options={["Last Month", "3 Months", "1 Year", "Custom"]}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <PackwiseTable />
          </Col>
          <Col md="6">
            <Row>
              <Col className="d-flex justify-content-center">
                <div className="d-flex align-items-center">
                  <div className="d-flex justify-content-center me-2">
                    <input
                      type="radio"
                      id="subscriptions"
                      name="packwise"
                      className="sh-radio-button me-1"
                      defaultChecked
                      onChange={handleRadioChange}
                    />
                    <label htmlFor="subscriptions" className="sh-radio-text">
                      Subscriptions
                    </label>
                  </div>
                  <div className="d-flex justify-content-center me-2">
                    <input
                      type="radio"
                      id="revenue"
                      name="packwise"
                      className="sh-radio-button me-1"
                      onChange={handleRadioChange}
                    />
                    <label htmlFor="revenue" className="sh-radio-text">
                      Revenue
                    </label>
                  </div>
                  <div className="d-flex justify-content-center me-2">
                    <input
                      type="radio"
                      id="arpu"
                      name="packwise"
                      className="sh-radio-button me-1"
                      onChange={handleRadioChange}
                    />
                    <label htmlFor="arpu" className="sh-radio-text">
                      ARPU
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col style={{ height: "320px" }}>
                <PackwiseChart
                  dataColors='["#146588","#00898D","#2BA558","#BAAF00","#FFA600"]'
                  selectedValue={selectedValue}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
}

export default PackwiseContribution;
