// selectors.js
import { createSelector } from 'reselect';

const selectHourlyTrend = (state) => state.HourlyTrendIN;

export const selectTodayResult = createSelector(
  [selectHourlyTrend],
  (hourlyTrendIN) => hourlyTrendIN.todayResult
);

export const selectYesterdayResult = createSelector(
  [selectHourlyTrend],
  (hourlyTrendIN) => hourlyTrendIN.yesterdayResult
);

export const selectLastWeekResult = createSelector(
  [selectHourlyTrend],
  (hourlyTrendIN) => hourlyTrendIN.lastWeekResult
);
