import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { formatMonthArray } from "../../../helpers/formatMonth";
import {
  MOUChartData,
  MPUChartData,
} from "../../../common/data/EcomDashboard/mouOverview";
import { FastField } from "formik";

const Line = ({ dataColors }) => {
  const [MPUseries, setMPUseries] = useState([]);
  const [MOUseries, setMOUseries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [loader1, setLoader1] = useState(true);
  const [loader2, setLoader2] = useState(true);

  // const chartLoaderMou = useSelector(
  //   (state) => state.MOUsOverviewData.chartLoaderMou
  // );
  // const chartLoaderMpu = useSelector(
  //   (state) => state.MOUsOverviewData.chartLoaderMpu
  // );
  // const MOUChartData = useSelector(
  //   (state) => state.MOUsOverviewData.MOUChartData
  // );

  // const MPUChartData = useSelector(
  //   (state) => state.MOUsOverviewData.MPUChartData
  // );

  useEffect(() => {
    if (MOUChartData && MPUChartData) {
      const MOUseries = MOUChartData.map((user) => Math.round(user.mou));
      const MPUseries = MPUChartData.map((user) => Math.round(user.total_mpu));
      const labels = MPUChartData.map((user) => user.date);

      const formattedDates = labels.map(formatMonthArray);
      setMOUseries(MOUseries);
      setMPUseries(MPUseries);
      setLabels(formattedDates);
      setLoader1(false);
      setLoader2(false);
    }
  }, [MOUChartData, MPUChartData]);

  const series = [
    {
      type: "column",
      name: "Total MOU",
      //data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160],
      data: MOUseries,
    },
    {
      type: "line",
      name: "Total MPU",
      //data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16,],
      data: MPUseries,
    },
  ];
  const options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [0, 4],
      curve: "smooth",
    },
    title: {
      text: "",
      style: {
        fontWeight: 600,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "20%",
        borderRadius: 4,
        borderRadiusApplication: "end",
      },
    },
    markers: {
      size: 4,
      colors: undefined,
      strokeColors: "",
      strokeWidth: 2,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      categories: labels,
    },
    yaxis: [
      {
        labels: {
          formatter: function (val) {
            return val !== undefined ? (val / 1000).toFixed(0) + "" : "";
          },
        },
        title: {
          text: "",
          style: {
            fontWeight: 600,
          },
        },
        min: 0,
        tickAmount: 6,
      },
      {
        opposite: true,
        title: {
          text: "",
          style: {
            fontWeight: 600,
          },
        },
      },
    ],
    colors: ["#146588", "#FFA600"],
    tooltip: {
      enabled: true,
      // x: {
      //   show: true,
      //   formatter: function (val) {
      //     return val !== undefined ? val : '';
      //   },
      // },
      y: {
        formatter: function (val) {
          return val !== undefined
            ? parseInt(val.toFixed(0)).toLocaleString()
            : "";
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  return (
    <React.Fragment>
      {!loader1 && !loader2 ? (
        <ReactApexChart
          dir="ltr"
          className="apex-charts"
          options={options}
          series={series}
          type="line"
          height={350}
        />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "400px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export { Line };
