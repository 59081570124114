import { createAsyncThunk } from "@reduxjs/toolkit";

// import needed API helpers.
import { getStreamAndDidNotStream as getStreamAndDidNotStreamApi } from "../../../helpers/fakebackend_helper";

export const getStreamAndDidNotStreamData = createAsyncThunk(
  "HealthMatrix/Stream And Did Not Stream",
  async (data) => {
    try {
      const response = getStreamAndDidNotStreamApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
