import React from "react";
import { Navigate } from "react-router-dom";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";


//APi Key
import APIKey from "../pages/APIKey/index";

// import PrivecyPolicy from "../pages/Pages/PrivacyPolicy";
// import TermsCondition from "../pages/Pages/TermsCondition";

//Dashboard
import DashboardEcommerce from "../pages/DashboardEcommerce";
import HealthMatrix from "../pages/HealthMatrix";
import RevenueMatrix from "../pages/RevenueMatrix";
import ConsumptionInsights from "../pages/ConsumptionInsights";
import TodaysHighlights from "../pages/TodaysHighlights";

const authProtectedRoutes = [
  { path: "/dashboard", component: <DashboardEcommerce /> },
  { path: "/health-matrix", component: <HealthMatrix /> },
  { path: "/revenue-matrix", component: <RevenueMatrix /> },
  { path: "/consumption-insights", component: <ConsumptionInsights /> },
  { path: "/todays-highlights", component: <TodaysHighlights /> },

  //Api Key
  // { path: "/apps-api-key", component: <APIKey /> },

  // { path: "/pages-privacy-policy", component: <PrivecyPolicy /> },
  // { path: "/pages-terms-condition", component: <TermsCondition /> },

  //User Profile
  // { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  // { path: "/login", component: <Login /> },
  // { path: "/forgot-password", component: <ForgetPasswordPage /> },
  // { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
