import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Table } from "reactstrap";
// import Components
import { ContentTypePieChart } from "./ContentTypePieChart";
import FilterSection from "../../RevenueMatrix/Subscription/filterSection";
import DateDropdown from "../../HealthMatrix/DateDropdown";
import { getContentTypeData } from "../../../slices/ConsumptionHighlights/ContentTypeAnalysis/thunk";
import { getMTDDateRange, getYTDDateRange } from "../../../helpers/dateUtils";
import ContentTypeTable from "./ContentTypeTable";
import { contentChartData as chartData } from "../../../common/data/ConnsumptionInsights/contentData";

const filterOptions = {
  user_type: {
    All: "Free,Paid",
    Free: "Free",
    Paid: "Paid",
  },
  platforms: {
    All: "android,web,external,ios",
    Android: "android",
    Web: "web",
    Tv: "external",
    iOS: "ios",
  },
  category: {
    All: "gujarati,non_gujarati",
    Gujarati: "gujarati",
    "Non Gujarati": "non_gujarati",
  },
  region: {
    All: "India,row",
    India: "India",
    ROW: "row",
  },
};

function ContentTypeAnalysis() {
  const dispatch = useDispatch();
  const [customDateRange, setCustomDateRange] = useState(getMTDDateRange());
  const [filters, setFilters] = useState({
    start_date: customDateRange.start_date,
    end_date: customDateRange.end_date,
    user_type: filterOptions.user_type.All,
    platforms: filterOptions.platforms.All,
    category: filterOptions.category.All,
    region: filterOptions.region.All,
    filter_type: "all",
  });
  const [selectedOption, setSelectedOption] = useState("all");
  const [streamsData, setStreamsData] = useState([]);
  const [mousData, setMousData] = useState([]);
  const [contents, setContents] = useState([]);
  const [loader, setLoader] = useState(true);

  // const { chartData, loader } = useSelector((state) => state.ContentType);

  useEffect(() => {
    if (chartData.length > 0 && chartData) {
      //   const contentTypes = ["movie", "show", "clips", "others"];
      const contentTypes = chartData
        .filter((item) => item.content_type !== "total")
        .map((item) => item.content_type);
      // console.log("contentTypes", contentTypes);
      const noOfStreamsData = contentTypes.map((item) => {
        const stream = chartData.find((obj) => obj.content_type === item);
        return parseInt(stream.streams);
      });
      const mouData = contentTypes.map((item) => {
        const mou = chartData.find((obj) => obj.content_type === item);
        return parseInt(mou.mou);
      });
      setStreamsData(noOfStreamsData);
      setMousData(mouData);
      setContents(contentTypes);
      setLoader(false);
    }
  }, [chartData]);
  //   console.log("Content Type Chart Data", chartData);

  useEffect(() => {
    setSelectedOption(filters.filter_type);
  }, [filters.filter_type]);

  const fetchData = async (dateRange) => {
    const dataObject = {
      ...filters,
      ...dateRange,
    };
    // dispatch(getContentTypeData(dataObject));
  };

  useEffect(() => {
    fetchData(customDateRange);
  }, [customDateRange, filters]);

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const handleDateRangeChange = (range) => {
    setCustomDateRange(range);
    setFilters((prevFilters) => ({
      ...prevFilters,
      start_date: range.startDate,
      end_date: range.endDate,
    }));
  };

  const handleResetFilters = () => {
    setFilters({
      daily_wise_data: true,
      platforms: filterOptions.platforms.All,
      user_type: filterOptions.user_type.All,
      region: filterOptions.region.All,
      category: filterOptions.category.All,
      filter_type: "all",
    });
    setSelectedOption("all");
  };

  const handleRadioChange = (event) => {
    const value = event.target.id;
    setSelectedOption(value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      filter_type: value,
    }));
  };
  return (
    <React.Fragment>
      <Row>
        <Container
          fluid
          className="customContainerStyle"
          style={{ height: "486px" }}
        >
          <Row>
            <Col style={{ padding: "20px" }}>
              <h4 className="card-heading-text">Content Type Analysis</h4>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-between" md="12">
              <FilterSection
                filterOptions={filterOptions}
                filters={filters}
                handleFilterChange={handleFilterChange}
                handleResetFilters={handleResetFilters}
              />
              <DateDropdown
                onDateRangeChange={handleDateRangeChange}
                options={["MTD", "Last Month", "3 Months", "1 Year", "Custom"]}
              />
            </Col>
            <Col md="6" className="mt-4 d-flex justify-content-center">
              <div className="d-flex align-items-center me-5">
                <div className="d-flex justify-content-center me-2">
                  <input
                    type="radio"
                    id="all"
                    name="ContentTypeAnalysis"
                    className="sh-radio-button me-1"
                    checked={selectedOption === "all"}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="all" className="sh-radio-text">
                    All
                  </label>
                </div>
                <div className="d-flex justify-content-center me-2">
                  <input
                    type="radio"
                    id="svod"
                    name="ContentTypeAnalysis"
                    className="sh-radio-button me-1"
                    checked={selectedOption === "svod"}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="svod" className="sh-radio-text">
                    SVOD Content
                  </label>
                </div>
                <div className="d-flex justify-content-center me-2">
                  <input
                    type="radio"
                    id="avod"
                    name="ContentTypeAnalysis"
                    className="sh-radio-button me-1"
                    checked={selectedOption === "avod"}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="avod" className="sh-radio-text">
                    AVOD Content
                  </label>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={6}>
              <ContentTypeTable loader={loader} />
            </Col>
            <Col xl={6}>
              {!loader ? (
                <Row>
                  <Col md="6">
                    <ContentTypePieChart
                      dataColors='["#146588","#00898D","#2BA558","#BAAF00"]'
                      data={streamsData}
                      contentTypes={contents}
                      title="No. of Streams"
                    />
                  </Col>
                  <Col md="6">
                    <ContentTypePieChart
                      dataColors='["#146588","#00898D","#2BA558","#BAAF00"]'
                      data={mousData}
                      contentTypes={contents}
                      title="MOU’s"
                    />
                  </Col>
                </Row>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "300px" }}
                >
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}

export default ContentTypeAnalysis;
