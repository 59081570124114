import { createSlice } from "@reduxjs/toolkit";
import { getSubsArpuCpsData } from "./thunk";

export const initialState = {
  allchartData: [],
  loader: false,
  error: "",
};

const SubsArpuCpsSlice = createSlice({
  name: "SubsArpuCps/All",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getSubsArpuCpsData.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getSubsArpuCpsData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.allchartData = action.payload.data.chart_data.all;
        state.loader = false;
      }
    });
    builder.addCase(getSubsArpuCpsData.rejected, (state, action) => {
      state.error = action.error.message;
      state.loader = false;
    });
  },
});

export default SubsArpuCpsSlice.reducer;
