import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import { organicData as data } from "../../../common/data/RevenueMatrix/organicData";

const OrganicChart = ({ dataColors, selectedValue }) => {
  // const { data, loader } = useSelector((state) => state.OrganicTransactions);

  const [revenueSeries, setRevenueSeries] = useState([]);
  const [subsSeries, setSubsSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loader, setLoader] = useState(true);

  const chartColumnStacked100Colors = getChartColorsArray(dataColors);

  useEffect(() => {
    if (!data) return;

    const chartData = { ...data }; // Create a copy of the data to avoid mutating original data
    const categories = Object.keys(chartData).filter(
      (category) => category !== "grand"
    ); // Filter out 'grand'
    const packsSet = new Set();

    // Collect all unique packwise types excluding "Total"
    categories.forEach((category) => {
      chartData[category]
        .filter((item) => item.packwise !== "Total") // Filter out "Total"
        .forEach((item) => {
          packsSet.add(item.packwise);
        });
    });

    const packs = Array.from(packsSet);
    const revenueSeries = [];
    const subsSeries = [];

    packs.forEach((pack) => {
      const revenueData = [];
      const subsData = [];
      categories.forEach((category) => {
        const categoryData = chartData[category]
          .filter((item) => item.packwise !== "Total") // Filter out "Total"
          .find((item) => item.packwise === pack);
        revenueData.push(categoryData ? Math.round(categoryData.revenue) : 0);
        subsData.push(categoryData ? Math.round(categoryData.subs) : 0);
      });
      revenueSeries.push({ name: pack, data: revenueData });
      subsSeries.push({ name: pack, data: subsData });
    });

    setRevenueSeries(revenueSeries);
    setSubsSeries(subsSeries);
    setCategories(categories);
    setLoader(false);
  }, [data]);

  const getSeries = (selectedValue) => {
    let series;
    switch (selectedValue) {
      case "subscriptions":
        series = subsSeries ? subsSeries : [];
        break;
      case "revenue":
        series = revenueSeries ? revenueSeries : [];
        break;
      default:
        series = [];
    }
    return series;
  };

  const options = {
    chart: {
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: categories.map((category) => category.toUpperCase()),
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
        // borderRadius: 4,
        // borderRadiusApplication: "end",
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
    },
    colors: chartColumnStacked100Colors,
    tooltip: {
      enabled: true,
      // x: {
      //   show: true,
      //   formatter: function (val) {
      //     return val !== undefined ? val : ''; // Display actual values on x-axis in tooltip
      //   },
      // },
      y: {
        formatter: function (val) {
          return val !== undefined
            ? parseInt(val.toFixed(0)).toLocaleString()
            : ""; // Display actual values in tooltip without formatting
        },
      },
    },
  };

  return (
    <React.Fragment>
      {!loader ? (
        <ReactApexChart
          dir="ltr"
          className="apex-charts"
          series={getSeries(selectedValue)}
          options={options}
          type="bar"
          height={400}
        />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "400px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default OrganicChart;
