import { createSlice } from "@reduxjs/toolkit";
import { getSubsGujaratiData } from "./gujaratiThunk";

export const initialState = {
  gujaratiChartData: [],
  loader: false,
  error: "",
};

const SubsGujaratiSlice = createSlice({
  name: "SubsArpuCps/Gujarati",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getSubsGujaratiData.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getSubsGujaratiData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.gujaratiChartData = action.payload.data.chart_data.gdata;
        state.loader = false;
      }
    });
    builder.addCase(getSubsGujaratiData.rejected, (state, action) => {
      state.error = action.error.message;
      state.loader = false;
    });
  },
});

export default SubsGujaratiSlice.reducer;
