// state.chartData = action.payload.data.chart_data.cData;

const topTitlesData = [
  {
    video_name: "Miss Teacher",
    mou: 498765.11225091666,
    Users: "1235",
    mpu: 223.1664931771439,
    completed_90: "8",
    happiness_score: 5,
  },
  {
    video_name: "Paurashpur Season 2",
    mou: 498765.11225091666,
    Users: "1235",
    mpu: 223.1664931771439,
    completed_90: "8",
    happiness_score: 5,
  },
  {
    video_name: "Jab We Met",
    mou: 498765.11225091666,
    Users: "1235",
    mpu: 223.1664931771439,
    completed_90: "8",
    happiness_score: 5,
  },
  {
    video_name: "India News Haryana",
    mou: 498765.11225091666,
    Users: "1235",
    mpu: 223.1664931771439,
    completed_90: "8",
    happiness_score: 5,
  },
  {
    video_name: "Superhit Gujarati Movies",
    mou: 498765.11225091666,
    Users: "1235",
    mpu: 223.1664931771439,
    completed_90: "8",
    happiness_score: 5,
  },
  {
    video_name: "Marvelous Malhar",
    mou: 498765.11225091666,
    Users: "1235",
    mpu: 223.1664931771439,
    completed_90: "8",
    happiness_score: 5,
  },
  {
    video_name: "My Boyfriend",
    mou: 498765.11225091666,
    Users: "1235",
    mpu: 223.1664931771439,
    completed_90: "8",
    happiness_score: 5,
  },
  {
    video_name: "Hunterrr",
    mou: 498765.11225091666,
    Users: "1235",
    mpu: 223.1664931771439,
    completed_90: "8",
    happiness_score: 5,
  },
  {
    video_name: "Siddharth Randeria Special",
    mou: 498765.11225091666,
    Users: "1235",
    mpu: 223.1664931771439,
    completed_90: "8",
    happiness_score: 5,
  },
  {
    video_name: "Chumbak TV",
    mou: 498765.11225091666,
    Users: "1235",
    mpu: 223.1664931771439,
    completed_90: "8",
    happiness_score: 5,
  },
];

export { topTitlesData };
