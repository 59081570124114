export const formatMonth = () => {
  const dateObject = new Date();
  const monthAbbr = dateObject.toLocaleString("default", { month: "short" });
  const yearLastTwoDigits = dateObject.getFullYear().toString().slice(-2);
  const day = dateObject.getDate();
  return `${monthAbbr}-${day}`;
};

export const formatMonthArray = (dateString) => {
  const dateObject = new Date(dateString);
  const monthAbbr = dateObject.toLocaleString("default", { month: "short" });
  const yearLastTwoDigits = dateObject.getFullYear().toString().slice(-2);
  const day = dateObject.getDate();
  return `${monthAbbr}-${day}`;
};
