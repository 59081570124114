// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   Col,
//   Container,
//   Row,
//   Card,
//   UncontrolledDropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
//   CardTitle,
// } from "reactstrap";

// import filterImage from "../../../assets/images/saranyuImages/filter.svg";
// import resetFilterImage from "../../../assets/images/saranyuImages/Reset Filter.svg";

// import DateDropdown from "../../../helpers/DateDropdown";
// import InorganicTable from "./InorganicTable";
// import InOrganicStackedColumn from "./inOrganicStackedColumn";
// import { getMTDDateRange } from "../../../helpers/dateUtils";

// import { getInorganicTransactionsData } from "../../../slices/RevenueMatrix/InorganicTransactions/thunk";

// const filterOptions = {
//   source: {
//     All: "Google,FB",
//     Google: "Google",
//     FB: "FB",
//   },
//   platforms: {
//     All: "android,ios,web,external",
//     Android: "android",
//     Web: "web",
//     Tv: "external",
//     iOS: "ios",
//   },
//   region: {
//     All: "IN,ROW",
//     India: "IN",
//     ROW: "ROW",
//   },
// };

// const InorganicTransactions = () => {
//   const dispatch = useDispatch();

//   const [customDateRange, setCustomDateRange] = useState(getMTDDateRange());
//   const [selectedValue, setSelectedValue] = useState("revenue");

//   const [filters, setFilters] = useState({
//     start_date: customDateRange.startDate,
//     end_date: customDateRange.endDate,
//     daily_wise_data: true,
//     platforms: "android,ios,web", // ios, web
//     region: "All",
//     source: "All",
//   });

//   const fetchData = async (dateRange) => {
//     const dataObject = {
//       ...filters,
//       ...dateRange,
//     };
//     dispatch(getInorganicTransactionsData(dataObject));
//   };

//   useEffect(() => {
//     fetchData(customDateRange);
//   }, [customDateRange, filters]);

//   const [selectedFilters, setSelectedFilters] = useState({
//     source: "Source",
//     platforms: "Platform",
//     region: "Region",
//   });

//   const handleFilterChange = (filterType, value) => {
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       [filterType]: value,
//     }));
//     setSelectedFilters((prevSelected) => ({
//       ...prevSelected,
//       [filterType]:
//         value || filterType.charAt(0).toUpperCase() + filterType.slice(1),
//     }));
//   };

//   const handleDateRangeChange = (range) => {
//     setCustomDateRange(range);
//     console.log(range);
//   };

//   const handleResetFilters = () => {
//     // const defaultDateRange = getMTDDateRange();
//     // setCustomDateRange(defaultDateRange);
//     setFilters({
//       // start_date: defaultDateRange.startDate,
//       // end_date: defaultDateRange.endDate,
//       daily_wise_data: true,
//       source: "All",
//       platforms: "android,ios,web",
//       region: "All",
//     });
//     setSelectedFilters({
//       source: "Source",
//       platforms: "Platform",
//       region: "Region",
//     });
//   };

//   const handleRadioChange = (e) => {
//     setSelectedValue(e.target.id);
//   };

//   return (
//     <React.Fragment>
//       <Container fluid className="customContainerStyle">
//         <Row>
//           <Col style={{ padding: "20px" }}>
//             <h4 className="card-heading-text">Inorganic Transactions</h4>
//           </Col>
//         </Row>
//         <Row className="mb-4">
//           <Col className="d-flex justify-content-between">
//             <div className="d-flex justify-content-start ms-2">
//               <button className="sh-funnel-filter">
//                 <img src={filterImage} alt="filter" />
//               </button>
//               {["source", "platforms", "region"].map((filter) => (
//                 <UncontrolledDropdown key={filter}>
//                   <DropdownToggle
//                     tag="button"
//                     className="sh-filter-dropdown sh-filter-text"
//                     id="dropdownMenuButton"
//                   >
//                     &nbsp; &nbsp;{selectedFilters[filter]} &nbsp;&nbsp;
//                     <i className="mdi mdi-chevron-down"></i>
//                   </DropdownToggle>
//                   <DropdownMenu>
//                     {filter === "source" &&
//                       ["Google", "FB"].map((source) => (
//                         <DropdownItem
//                           key={source}
//                           onClick={() =>
//                             handleFilterChange(
//                               filter,
//                               source === "Source" ? "" : source.toLowerCase()
//                             )
//                           }
//                         >
//                           {source}
//                         </DropdownItem>
//                       ))}
//                     {filter === "platforms" &&
//                       ["Android", "iOS", "Web", "TV"].map((platform) => (
//                         <DropdownItem
//                           key={platform}
//                           onClick={() =>
//                             handleFilterChange(
//                               filter,
//                               platform === "Platform"
//                                 ? ""
//                                 : platform.toLowerCase()
//                             )
//                           }
//                         >
//                           {platform}
//                         </DropdownItem>
//                       ))}
//                     {filter === "region" &&
//                       ["IN", "ROW"].map((region) => (
//                         <DropdownItem
//                           key={region}
//                           onClick={() =>
//                             handleFilterChange(
//                               filter,
//                               region === "Region" ? "" : region.toLowerCase()
//                             )
//                           }
//                         >
//                           {region}
//                         </DropdownItem>
//                       ))}
//                   </DropdownMenu>
//                 </UncontrolledDropdown>
//               ))}
//               <button className="sh-filter-reset" onClick={handleResetFilters}>
//                 <img src={resetFilterImage} alt="reset filter" />
//               </button>
//             </div>
//             <DateDropdown
//               onDateRangeChange={handleDateRangeChange}
//               options={["MTD", "Last Month", "3 Months", "Custom"]}
//             />
//           </Col>
//         </Row>
//         <Row>
//           <Col md="7">
//             <InorganicTable />
//           </Col>
//           <Col md="5">
//             <Card className="card-styling" style={{ height: "638px" }}>
//               <Row className="mt-2">
//                 <Col className="d-flex justify-content-center">
//                   <div className="d-flex align-items-center">
//                     <div className="d-flex justify-content-center me-2">
//                       <input
//                         type="radio"
//                         id="revenue"
//                         name="inorganic"
//                         className="sh-radio-button me-1"
//                         defaultChecked
//                         onChange={handleRadioChange}
//                       />
//                       <label htmlFor="revenue" className="sh-radio-text">
//                         Revenue
//                       </label>
//                     </div>
//                     <div className="d-flex justify-content-center me-2">
//                       <input
//                         type="radio"
//                         id="subscriptions"
//                         name="inorganic"
//                         className="sh-radio-button me-1"
//                         onChange={handleRadioChange}
//                       />
//                       <label htmlFor="subscriptions" className="sh-radio-text">
//                         Subscriptions
//                       </label>
//                     </div>
//                   </div>
//                 </Col>
//               </Row>
//               <Row>
//                 <Col>
//                   <InOrganicStackedColumn
//                     dataColors='["#146588","#007893","#009978","#2BA558","#78AD2F"]'
//                     selectedValue={selectedValue}
//                   />
//                 </Col>
//               </Row>
//             </Card>
//           </Col>
//         </Row>
//       </Container>
//     </React.Fragment>
//   );
// };

// export default InorganicTransactions;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Card } from "reactstrap";

import DateDropdown from "../../../helpers/DateDropdown"; // Adjusted import path
import FilterSection from "../../RevenueMatrix/Subscription/filterSection"; // Adjusted import path
import InorganicTable from "./InorganicTable";
import InOrganicStackedColumn from "./inOrganicStackedColumn";
import { getMTDDateRange } from "../../../helpers/dateUtils";

import { getInorganicTransactionsData } from "../../../slices/RevenueMatrix/InorganicTransactions/thunk";

const filterOptions = {
  source: {
    All: "Google,FB",
    Google: "Google",
    FB: "FB",
  },
  platforms: {
    All: "android,ios,web",
    Android: "android",
    Web: "web",
    // Tv: "external",
    iOS: "ios",
  },
  region: {
    All: "IN,ROW",
    India: "IN",
    ROW: "ROW",
  },
};

const InorganicTransactions = () => {
  const dispatch = useDispatch();

  const [customDateRange, setCustomDateRange] = useState(getMTDDateRange());
  const [selectedValue, setSelectedValue] = useState("revenue");

  // const data = useSelector((state) => state.InorganicTransactions.data);

  const [filters, setFilters] = useState({
    start_date: customDateRange.startDate,
    end_date: customDateRange.endDate,
    daily_wise_data: true,
    platforms: filterOptions.platforms.All,
    region: filterOptions.region.All,
    source: filterOptions.source.All,
  });

  const fetchData = async (dateRange) => {
    const dataObject = {
      ...filters,
      ...dateRange,
    };
    // dispatch(getInorganicTransactionsData(dataObject));
  };

  useEffect(() => {
    fetchData(customDateRange);
  }, [customDateRange, filters]);

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const handleDateRangeChange = (range) => {
    setCustomDateRange(range);
    setFilters((prevFilters) => ({
      ...prevFilters,
      start_date: range.startDate,
      end_date: range.endDate,
    }));
  };

  const handleResetFilters = () => {
    setFilters({
      daily_wise_data: true,
      platforms: filterOptions.platforms.All,
      region: filterOptions.region.All,
      source: filterOptions.source.All,
    });
  };

  const handleRadioChange = (e) => {
    setSelectedValue(e.target.id);
  };

  return (
    <React.Fragment>
      <Container fluid className="customContainerStyle">
        <Row>
          <Col style={{ padding: "20px" }}>
            <h4 className="card-heading-text">Inorganic Transactions</h4>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col className="d-flex justify-content-between">
            <FilterSection
              filterOptions={filterOptions}
              filters={filters}
              handleFilterChange={handleFilterChange}
              handleResetFilters={handleResetFilters}
            />
            <DateDropdown
              onDateRangeChange={handleDateRangeChange}
              options={["MTD", "Last Month", "3 Months", "Custom"]}
            />
          </Col>
        </Row>
        <Row>
          <Col md="7">
            <InorganicTable />
          </Col>
          <Col md="5">
            <Card className="card-styling" style={{ height: "638px" }}>
              <Row className="mt-2">
                <Col className="d-flex justify-content-center">
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-center me-2">
                      <input
                        type="radio"
                        id="revenue"
                        name="inorganic"
                        className="sh-radio-button me-1"
                        defaultChecked
                        onChange={handleRadioChange}
                      />
                      <label htmlFor="revenue" className="sh-radio-text">
                        Revenue
                      </label>
                    </div>
                    <div className="d-flex justify-content-center me-2">
                      <input
                        type="radio"
                        id="subscriptions"
                        name="inorganic"
                        className="sh-radio-button me-1"
                        onChange={handleRadioChange}
                      />
                      <label htmlFor="subscriptions" className="sh-radio-text">
                        Subscriptions
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <InOrganicStackedColumn
                    dataColors='["#146588","#007893","#009978","#2BA558","#78AD2F"]'
                    selectedValue={selectedValue}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default InorganicTransactions;
