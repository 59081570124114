import { createSlice } from "@reduxjs/toolkit";
import { getPaidUserContributionData } from "./thunk";

export const initialState = {
  chartData: [],
  loader: false,
  error: "",
};

const PaidUserContributionSlice = createSlice({
  name: "Paid User Contribution",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getPaidUserContributionData.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getPaidUserContributionData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.chartData = action.payload.data.chart_data;
        state.loader = false;
      }
    });
    builder.addCase(getPaidUserContributionData.rejected, (state, action) => {
      state.error = action.error.message;
      state.loader = false;
    });
  },
});

export default PaidUserContributionSlice.reducer;
