import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import RevenueColumnChart from "./revenueColumnChart";
import DateDropdown from "../../HealthMatrix/DateDropdown";
import { getLastSixMonthsDateRange } from "../../../helpers/dateUtils";
import { getRevenueTrendData } from "../../../slices/RevenueMatrix/RevenueTrend/thunk";
import FilterSection from "../Subscription/filterSection";

const filterOptions = {
  platforms: {
    All: "android,web,tv,ios",
    Android: "android",
    Web: "web",
    Tv: "tv",
    iOS: "ios",
  },
  region: {
    All: "india,row",
    India: "india",
    ROW: "row",
  },
};

const dropdownOptions = ["6 Months", "Custom"];

function RevenueTrend() {
  const dispatch = useDispatch();
  const [customDateRange, setCustomDateRange] = useState(
    getLastSixMonthsDateRange()
  );
  const [filters, setFilters] = useState({
    // start_date: customDateRange.startDate,
    // end_date: customDateRange.endDate,
    ...customDateRange,
    platforms: filterOptions.platforms.All,
    region: filterOptions.region.All,
    filter_type: "all",
  });
  const [selectedOption, setSelectedOption] = useState("all");

  const fetchData = async (dateRange) => {
    const dataObject = {
      ...filters,
      ...dateRange,
    };
    // dispatch(getRevenueTrendData(dataObject));
  };

  useEffect(() => {
    setSelectedOption(filters.filter_type);
  }, [filters.filter_type]);

  useEffect(() => {
    fetchData(customDateRange);
  }, [customDateRange, filters]);

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const handleDateRangeChange = (range) => {
    setCustomDateRange(range);
    setFilters((prevFilters) => ({
      ...prevFilters,
      start_date: range.startDate,
      end_date: range.endDate,
    }));
  };

  const handleResetFilters = () => {
    setFilters({
      // start_date: newDateRange.start_date,
      // end_date: newDateRange.end_date,
      ...customDateRange,
      platforms: filterOptions.platforms.All,
      region: filterOptions.region.All,
      filter_type: "all",
    });
    setSelectedOption("all");
  };

  const handleRadioChange = (event) => {
    const value = event.target.id;
    setSelectedOption(value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      filter_type: value,
    }));
  };

  return (
    <React.Fragment>
      <Container
        fluid
        className="customContainerStyle"
        style={{ height: "568px" }}
      >
        <Row>
          <Col style={{ padding: "20px" }}>
            <h4 className="card-heading-text">Revenue Trend</h4>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between">
            <FilterSection
              filterOptions={filterOptions}
              filters={filters}
              handleFilterChange={handleFilterChange}
              handleResetFilters={handleResetFilters}
            />
            <DateDropdown
              onDateRangeChange={handleDateRangeChange}
              options={dropdownOptions}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="d-flex justify-content-center">
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-center me-2">
                <input
                  type="radio"
                  id="all"
                  name="revenueTrend"
                  className="sh-radio-button me-1"
                  checked={selectedOption === "all"}
                  onChange={handleRadioChange}
                />
                <label htmlFor="all" className="sh-radio-text">
                  All
                </label>
              </div>
              <div className="d-flex justify-content-center me-2">
                <input
                  type="radio"
                  id="gujarati"
                  name="revenueTrend"
                  className="sh-radio-button me-1"
                  checked={selectedOption === "gujarati"}
                  onChange={handleRadioChange}
                />
                <label htmlFor="gujarati" className="sh-radio-text">
                  Gujarati
                </label>
              </div>
              <div className="d-flex justify-content-center me-2">
                <input
                  type="radio"
                  id="non-gujarati"
                  name="revenueTrend"
                  className="sh-radio-button me-1"
                  checked={selectedOption === "non-gujarati"}
                  onChange={handleRadioChange}
                />
                <label htmlFor="non-gujarati" className="sh-radio-text">
                  Non Gujarati
                </label>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <RevenueColumnChart dataColors='["#146588", "#00898D","#007893", "#009978"]' />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default RevenueTrend;

// import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
// import { Col, Container, Row } from "reactstrap";
// import RevenueColumnChart from "./revenueColumnChart";
// import DateDropdown from "../../HealthMatrix/DateDropdown";
// import { getLastSixMonthsDateRange } from "../../../helpers/dateUtils";
// import { getRevenueTrendData } from "../../../slices/RevenueMatrix/RevenueTrend/thunk";
// import FilterSection from "../Subscription/filterSection";

// const filterOptions = {
//   platforms: {
//     Android: "android",
//     Web: "web",
//     Tv: "tv",
//     iOS: "ios",
//     All: "android,web,tv,ios",
//   },
//   region: {
//     India: "india",
//     ROW: "row",
//     All: "india,row",
//   },
// };

// const dropdownOptions = ["6 Months", "Custom"];

// function RevenueTrend() {
//   const dispatch = useDispatch();
//   const [customDateRange, setCustomDateRange] = useState(
//     getLastSixMonthsDateRange()
//   );
//   const [filters, setFilters] = useState({
//     ...customDateRange,
//     platforms: filterOptions.platforms.All,
//     region: filterOptions.region.All,
//     filter_type: "all",
//   });
//   const [selectedOption, setSelectedOption] = useState("all");

//   const fetchData = async (dateRange) => {
//     const dataObject = {
//       ...filters,
//       ...dateRange,
//     };
//     dispatch(getRevenueTrendData(dataObject));
//   };

//   useEffect(() => {
//     setSelectedOption(filters.filter_type);
//   }, [filters.filter_type]);

//   useEffect(() => {
//     fetchData(customDateRange);
//   }, [customDateRange, filters]);

//   const handleFilterChange = (filterType, value) => {
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       [filterType]: value,
//     }));
//   };

//   const handleDateRangeChange = (range) => {
//     setCustomDateRange(range);
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       start_date: range.startDate,
//       end_date: range.endDate,
//     }));
//   };

//   // State to hold the reset function from DateDropdown
//   // const [resetDateDropdown, setResetDateDropdown] = useState(null);

//   const handleResetFilters = () => {
//     const newDateRange = getLastSixMonthsDateRange();
//     setFilters({
//       ...newDateRange,
//       platforms: filterOptions.platforms.All,
//       region: filterOptions.region.All,
//       filter_type: "all",
//     });
//     setSelectedOption("all");
//     setCustomDateRange(newDateRange);
//     // setResetDateDropdown(true);
//     // Call the reset function in DateDropdown
//     // if (resetDateDropdown) resetDateDropdown();
//   };

//   return (
//     <React.Fragment>
//       <Container
//         fluid
//         className="customContainerStyle"
//         style={{ height: "568px" }}
//       >
//         <Row className="pb-2">
//           <Col className="d-flex justify-content-between align-items-center">
//             <h5 className="title">Revenue Trends</h5>
//           </Col>
//         </Row>
//         <Row>
//           <Col className="d-flex justify-content-between">
//             <FilterSection
//               filterOptions={filterOptions}
//               filters={filters}
//               handleFilterChange={handleFilterChange}
//               handleResetFilters={handleResetFilters}
//             />
//             <DateDropdown
//               onDateRangeChange={handleDateRangeChange}
//               options={dropdownOptions}
//               setReset={resetDateDropdown} // Pass setReset function
//             />
//           </Col>
//         </Row>
//         <Row className="mt-4">
//           <Col className="d-flex justify-content-center">
//             <div className="d-flex align-items-center">
//               <div className="d-flex justify-content-center me-2">
//                 <input
//                   type="radio"
//                   id="all"
//                   name="revenueTrend"
//                   className="sh-radio-button me-1"
//                   checked={selectedOption === "all"}
//                   onChange={handleRadioChange}
//                 />
//                 <label htmlFor="all" className="sh-radio-text">
//                   All
//                 </label>
//               </div>
//               <div className="d-flex justify-content-center me-2">
//                 <input
//                   type="radio"
//                   id="gujarati"
//                   name="revenueTrend"
//                   className="sh-radio-button me-1"
//                   checked={selectedOption === "gujarati"}
//                   onChange={handleRadioChange}
//                 />
//                 <label htmlFor="gujarati" className="sh-radio-text">
//                   Gujarati
//                 </label>
//               </div>
//               <div className="d-flex justify-content-center me-2">
//                 <input
//                   type="radio"
//                   id="non-gujarati"
//                   name="revenueTrend"
//                   className="sh-radio-button me-1"
//                   checked={selectedOption === "non-gujarati"}
//                   onChange={handleRadioChange}
//                 />
//                 <label htmlFor="non-gujarati" className="sh-radio-text">
//                   Non Gujarati
//                 </label>
//               </div>
//             </div>
//           </Col>
//         </Row>
//         <Row>
//           <Col>
//             <RevenueColumnChart dataColors='["#146588", "#00898D","#007893", "#009978"]' />
//           </Col>
//         </Row>
//       </Container>
//     </React.Fragment>
//   );
// }

// export default RevenueTrend;
