import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, Col, Row } from "reactstrap";

import {
  getDauData,
  getPaidDauData,
  getStreamingDauData,
  getMauData,
  getPaidMauData,
  getStreamingMauData,
} from "../../../slices/businessOverview/widgetCard/thunk";
import { getDauChartData } from "../../../slices/businessOverview/dauChart/thunk";

import OttCard from "./ottCard";
import { DauChart } from "./dauChart";
import WidgetsCard from "./widgetsCard";
import {
  getArpuData,
  getBrandSpendsData,
  getCpsData,
  getPerformanceSpendsData,
  getRevenueData,
  getRoasData,
  getSubscriptionData,
  getTotalSpendsData,
} from "../../../slices/businessOverview/ott/thunk";
import StickinessCard from "./stickinessCard";

import {
  getStickinessPlatformData,
  getStickinessStreamData,
  getStickinessPaidData,
} from "../../../slices/businessOverview/stickiness/thunk";
import AcquisitionDashboard from "./Acquisition";
import PackwiseContribution from "./PackwiseContribution";
import DateDropdown from "./DateDropdown";

import {
  getMTDDateRange,
  getYTDDateRange,
  getLastMonthDateRange,
  getQTDDateRange,
} from "../../../helpers/dateUtils";

// console.log("getMTDDateRange", getMTDDateRange());
// console.log(getYTDDateRange());
// console.log(getLastMonthDateRange());
// console.log(getQTDDateRange());

const BusinessOverview = () => {
  const dispatch = useDispatch();

  const apiConfig = [
    { action: getDauChartData, daily_wise_data: true, prev: true },
    { action: getDauData, daily_wise_data: true },
    { action: getPaidDauData, daily_wise_data: true },
    { action: getStreamingDauData, daily_wise_data: false },
    { action: getMauData, daily_wise_data: false },
    { action: getPaidMauData, daily_wise_data: false },
    { action: getStreamingMauData, daily_wise_data: false },
    { action: getRevenueData, daily_wise_data: false },
    { action: getSubscriptionData, daily_wise_data: false },
    { action: getArpuData, daily_wise_data: false },
    { action: getCpsData, daily_wise_data: false },
    { action: getTotalSpendsData, daily_wise_data: false },
    { action: getPerformanceSpendsData, daily_wise_data: false },
    { action: getBrandSpendsData, daily_wise_data: false },
    { action: getRoasData, daily_wise_data: false },
    { action: getStickinessPlatformData, daily_wise_data: false },
    { action: getStickinessStreamData, daily_wise_data: false },
    { action: getStickinessPaidData, daily_wise_data: false },
  ];

  const fetchData = async (dateRange) => {
    apiConfig.forEach(({ action, daily_wise_data, prev }) => {
      const params = { ...dateRange, period: "month", daily_wise_data };
      if (prev && action === getDauChartData) {
        params.prev = true;
      }
      // dispatch(action(params));
    });
  };

  useEffect(() => {
    fetchData(getMTDDateRange());
  }, [dispatch]);

  const handleDateRangeChange = (dateRange) => {
    fetchData(dateRange);
  };

  return (
    <React.Fragment>
      <Row>
        <Card className="customContainerStyle">
          <Row>
            <Col
              md="12"
              className="d-flex justify-content-between"
              style={{ padding: "15px" }}
            >
              <h4 className="card-heading-text-black">Business Overview</h4>
              <DateDropdown
                onDateRangeChange={handleDateRangeChange}
                options={["Last Month", "3 Months", "1 Year", "Custom"]}
              />
            </Col>
          </Row>
          <Row>
            <OttCard columnSpace={3} />
          </Row>
          <Row>
            <Col xl={6}>
              <Card className="widget-background-card">
                <Row>
                  <WidgetsCard columnSpace={6} />
                </Row>
                <StickinessCard columnSpace={4} />
              </Card>
            </Col>
            <Col xl={6}>
              <DauChart dataColors='["#146588","#00898D","#2BA558","#BAAF00","#FFA600"]' />
            </Col>
          </Row>
          <AcquisitionDashboard />
          <PackwiseContribution />
        </Card>
      </Row>
    </React.Fragment>
  );
};

export default BusinessOverview;
