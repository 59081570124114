// state.todayResult = action.payload.todayResult;
//         state.yesterdayResult = action.payload.yesterdayResult;
//         state.lastWeekResult = action.payload.lastWeekResult;

const todayResult = {
  daywise: {
    "2024-08-20T23:30:00.000Z": {
      transactions: 66,
      revenue: 40613,
      consolidated: [
        {
          transactions: 34,
          key: "Quarterly",
          revenue: 15002,
        },
        {
          transactions: 23,
          key: "Yearly",
          revenue: 16770,
        },
        {
          transactions: 9,
          key: "Two Yearly",
          revenue: 8841,
        },
      ],
    },
    "2024-08-21T00:30:00.000Z": {
      transactions: 34,
      revenue: 21766,
      consolidated: [
        {
          transactions: 15,
          key: "Yearly",
          revenue: 10485,
        },
        {
          transactions: 14,
          key: "Quarterly",
          revenue: 6286,
        },
        {
          transactions: 5,
          key: "Two Yearly",
          revenue: 4995,
        },
      ],
    },
    "2024-08-21T01:30:00.000Z": {
      transactions: 10,
      revenue: 5740,
      consolidated: [
        {
          transactions: 5,
          key: "Quarterly",
          revenue: 2245,
        },
        {
          transactions: 5,
          key: "Yearly",
          revenue: 3495,
        },
      ],
    },
    "2024-08-21T02:30:00.000Z": {
      transactions: 58,
      revenue: 28042,
      consolidated: [
        {
          transactions: 40,
          key: "Quarterly",
          revenue: 17910,
        },
        {
          transactions: 8,
          key: "Yearly",
          revenue: 5992,
        },
        {
          transactions: 7,
          key: "Monthly",
          revenue: 1393,
        },
        {
          transactions: 3,
          key: "Two Yearly",
          revenue: 2747,
        },
      ],
    },
    "2024-08-21T03:30:00.000Z": {
      transactions: 9,
      revenue: 5541,
      consolidated: [
        {
          transactions: 5,
          key: "Quarterly",
          revenue: 2295,
        },
        {
          transactions: 2,
          key: "Two Yearly",
          revenue: 1848,
        },
        {
          transactions: 2,
          key: "Yearly",
          revenue: 1398,
        },
      ],
    },
    "2024-08-21T04:30:00.000Z": {
      transactions: 2,
      revenue: 1148,
      consolidated: [
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 449,
        },
        {
          transactions: 1,
          key: "Yearly",
          revenue: 699,
        },
      ],
    },
    "2024-08-21T05:30:00.000Z": {
      transactions: 28,
      revenue: 13887,
      consolidated: [
        {
          transactions: 19,
          key: "Quarterly",
          revenue: 8482,
        },
        {
          transactions: 5,
          key: "Two Yearly",
          revenue: 4179,
        },
        {
          transactions: 3,
          key: "Monthly",
          revenue: 597,
        },
        {
          transactions: 1,
          key: "Yearly",
          revenue: 629,
        },
      ],
    },
    "2024-08-21T06:30:00.000Z": {
      transactions: 4,
      revenue: 2296,
      consolidated: [
        {
          transactions: 2,
          key: "Quarterly",
          revenue: 898,
        },
        {
          transactions: 2,
          key: "Yearly",
          revenue: 1398,
        },
      ],
    },
    "2024-08-21T07:30:00.000Z": {
      transactions: 20,
      revenue: 12910,
      consolidated: [
        {
          transactions: 9,
          key: "Yearly",
          revenue: 5521,
        },
        {
          transactions: 6,
          key: "Quarterly",
          revenue: 2694,
        },
        {
          transactions: 5,
          key: "Two Yearly",
          revenue: 4695,
        },
      ],
    },
    "2024-08-21T08:30:00.000Z": {
      transactions: 59,
      revenue: 33344,
      consolidated: [
        {
          transactions: 35,
          key: "Quarterly",
          revenue: 15568,
        },
        {
          transactions: 12,
          key: "Yearly",
          revenue: 8338,
        },
        {
          transactions: 9,
          key: "Two Yearly",
          revenue: 8841,
        },
        {
          transactions: 3,
          key: "Monthly",
          revenue: 597,
        },
      ],
    },
    "2024-08-21T09:30:00.000Z": {
      transactions: 21,
      revenue: 13329,
      consolidated: [
        {
          transactions: 12,
          key: "Yearly",
          revenue: 7788,
        },
        {
          transactions: 6,
          key: "Quarterly",
          revenue: 2694,
        },
        {
          transactions: 3,
          key: "Two Yearly",
          revenue: 2847,
        },
      ],
    },
    "2024-08-21T10:30:00.000Z": {
      transactions: 29,
      revenue: 20371,
      consolidated: [
        {
          transactions: 17,
          key: "Yearly",
          revenue: 11533,
        },
        {
          transactions: 7,
          key: "Two Yearly",
          revenue: 6593,
        },
        {
          transactions: 5,
          key: "Quarterly",
          revenue: 2245,
        },
      ],
    },
    "2024-08-21T11:30:00.000Z": {
      transactions: 113,
      revenue: 62871,
      consolidated: [
        {
          transactions: 58,
          key: "Quarterly",
          revenue: 25840,
        },
        {
          transactions: 28,
          key: "Yearly",
          revenue: 19558,
        },
        {
          transactions: 15,
          key: "Two Yearly",
          revenue: 14685,
        },
        {
          transactions: 11,
          key: "Monthly",
          revenue: 2189,
        },
        {
          transactions: 1,
          key: "Half Yearly",
          revenue: 599,
        },
      ],
    },
    "2024-08-21T12:30:00.000Z": {
      transactions: 36,
      revenue: 24665,
      consolidated: [
        {
          transactions: 14,
          key: "Yearly",
          revenue: 9331,
        },
        {
          transactions: 12,
          key: "Two Yearly",
          revenue: 10889,
        },
        {
          transactions: 10,
          key: "Quarterly",
          revenue: 4445,
        },
      ],
    },
    "2024-08-21T13:30:00.000Z": {
      transactions: 45,
      revenue: 32686,
      consolidated: [
        {
          transactions: 24,
          key: "Yearly",
          revenue: 15657,
        },
        {
          transactions: 14,
          key: "Two Yearly",
          revenue: 13886,
        },
        {
          transactions: 7,
          key: "Quarterly",
          revenue: 3143,
        },
      ],
    },
    "2024-08-21T14:30:00.000Z": {
      transactions: 131,
      revenue: 74954,
      consolidated: [
        {
          transactions: 70,
          key: "Quarterly",
          revenue: 30791,
        },
        {
          transactions: 37,
          key: "Yearly",
          revenue: 25637,
        },
        {
          transactions: 18,
          key: "Two Yearly",
          revenue: 17332,
        },
        {
          transactions: 6,
          key: "Monthly",
          revenue: 1194,
        },
      ],
    },
    "2024-08-21T15:30:00.000Z": {
      transactions: 38,
      revenue: 23567,
      consolidated: [
        {
          transactions: 18,
          key: "Quarterly",
          revenue: 8037,
        },
        {
          transactions: 14,
          key: "Yearly",
          revenue: 9536,
        },
        {
          transactions: 6,
          key: "Two Yearly",
          revenue: 5994,
        },
      ],
    },
    "2024-08-21T16:30:00.000Z": {
      transactions: 17,
      revenue: 10183,
      consolidated: [
        {
          transactions: 8,
          key: "Quarterly",
          revenue: 3592,
        },
        {
          transactions: 8,
          key: "Yearly",
          revenue: 5592,
        },
        {
          transactions: 1,
          key: "Two Yearly",
          revenue: 999,
        },
      ],
    },
  },
  Quarterly: {
    transactions: 300,
    revenue: 15000,
  },
  Yearly: {
    transactions: 200,
    revenue: 15000,
  },
  "Two Yearly": {
    transactions: 100,
    revenue: 10000,
  },
  Monthly: {
    transactions: 30,
    revenue: 5000,
  },
  "Half Yearly": {
    transactions: 10,
    revenue: 900,
  },
};
const yesterdayResult = {
  daywise: {
    "2024-08-19T23:30:00.000Z": {
      transactions: 44,
      revenue: 29073,
      consolidated: [
        {
          transactions: 20,
          key: "Quarterly",
          revenue: 8747,
        },
        {
          transactions: 13,
          key: "Yearly",
          revenue: 9337,
        },
        {
          transactions: 11,
          key: "Two Yearly",
          revenue: 10989,
        },
      ],
    },
    "2024-08-20T00:30:00.000Z": {
      transactions: 37,
      revenue: 22343,
      consolidated: [
        {
          transactions: 17,
          key: "Quarterly",
          revenue: 7638,
        },
        {
          transactions: 17,
          key: "Yearly",
          revenue: 11708,
        },
        {
          transactions: 3,
          key: "Two Yearly",
          revenue: 2997,
        },
      ],
    },
    "2024-08-20T01:30:00.000Z": {
      transactions: 19,
      revenue: 11356,
      consolidated: [
        {
          transactions: 12,
          key: "Yearly",
          revenue: 8213,
        },
        {
          transactions: 7,
          key: "Quarterly",
          revenue: 3143,
        },
      ],
    },
    "2024-08-20T02:30:00.000Z": {
      transactions: 42,
      revenue: 19908,
      consolidated: [
        {
          transactions: 26,
          key: "Quarterly",
          revenue: 11674,
        },
        {
          transactions: 8,
          key: "Yearly",
          revenue: 5442,
        },
        {
          transactions: 6,
          key: "Monthly",
          revenue: 1194,
        },
        {
          transactions: 2,
          key: "Two Yearly",
          revenue: 1598,
        },
      ],
    },
    "2024-08-20T03:30:00.000Z": {
      transactions: 13,
      revenue: 7039,
      consolidated: [
        {
          transactions: 8,
          key: "Quarterly",
          revenue: 3642,
        },
        {
          transactions: 2,
          key: "Two Yearly",
          revenue: 1998,
        },
        {
          transactions: 2,
          key: "Yearly",
          revenue: 1398,
        },
        {
          transactions: 1,
          key: "Monthly",
          revenue: 1,
        },
      ],
    },
    "2024-08-20T04:30:00.000Z": {
      transactions: 3,
      revenue: 1597,
      consolidated: [
        {
          transactions: 2,
          key: "Quarterly",
          revenue: 898,
        },
        {
          transactions: 1,
          key: "Yearly",
          revenue: 699,
        },
      ],
    },
    "2024-08-20T05:30:00.000Z": {
      transactions: 18,
      revenue: 10082,
      consolidated: [
        {
          transactions: 10,
          key: "Quarterly",
          revenue: 4490,
        },
        {
          transactions: 3,
          key: "Two Yearly",
          revenue: 2997,
        },
        {
          transactions: 3,
          key: "Yearly",
          revenue: 2197,
        },
        {
          transactions: 2,
          key: "Monthly",
          revenue: 398,
        },
      ],
    },
    "2024-08-20T06:30:00.000Z": {
      transactions: 5,
      revenue: 3045,
      consolidated: [
        {
          transactions: 3,
          key: "Quarterly",
          revenue: 1347,
        },
        {
          transactions: 1,
          key: "Two Yearly",
          revenue: 999,
        },
        {
          transactions: 1,
          key: "Yearly",
          revenue: 699,
        },
      ],
    },
    "2024-08-20T07:30:00.000Z": {
      transactions: 12,
      revenue: 7938,
      consolidated: [
        {
          transactions: 8,
          key: "Yearly",
          revenue: 5592,
        },
        {
          transactions: 3,
          key: "Quarterly",
          revenue: 1347,
        },
        {
          transactions: 1,
          key: "Two Yearly",
          revenue: 999,
        },
      ],
    },
    "2024-08-20T08:30:00.000Z": {
      transactions: 80,
      revenue: 46771,
      consolidated: [
        {
          transactions: 47,
          key: "Quarterly",
          revenue: 21004,
        },
        {
          transactions: 16,
          key: "Yearly",
          revenue: 11284,
        },
        {
          transactions: 14,
          key: "Two Yearly",
          revenue: 13886,
        },
        {
          transactions: 3,
          key: "Monthly",
          revenue: 597,
        },
      ],
    },
    "2024-08-20T09:30:00.000Z": {
      transactions: 19,
      revenue: 11438,
      consolidated: [
        {
          transactions: 9,
          key: "Yearly",
          revenue: 5916,
        },
        {
          transactions: 8,
          key: "Quarterly",
          revenue: 3524,
        },
        {
          transactions: 2,
          key: "Two Yearly",
          revenue: 1998,
        },
      ],
    },
    "2024-08-20T10:30:00.000Z": {
      transactions: 29,
      revenue: 20153,
      consolidated: [
        {
          transactions: 12,
          key: "Yearly",
          revenue: 8388,
        },
        {
          transactions: 9,
          key: "Quarterly",
          revenue: 3973,
        },
        {
          transactions: 8,
          key: "Two Yearly",
          revenue: 7792,
        },
      ],
    },
    "2024-08-20T11:30:00.000Z": {
      transactions: 141,
      revenue: 84757,
      consolidated: [
        {
          transactions: 66,
          key: "Quarterly",
          revenue: 29780,
        },
        {
          transactions: 37,
          key: "Yearly",
          revenue: 26413,
        },
        {
          transactions: 27,
          key: "Two Yearly",
          revenue: 26375,
        },
        {
          transactions: 11,
          key: "Monthly",
          revenue: 2189,
        },
      ],
    },
    "2024-08-20T12:30:00.000Z": {
      transactions: 37,
      revenue: 25225,
      consolidated: [
        {
          transactions: 16,
          key: "Yearly",
          revenue: 10864,
        },
        {
          transactions: 11,
          key: "Quarterly",
          revenue: 4871,
        },
        {
          transactions: 10,
          key: "Two Yearly",
          revenue: 9490,
        },
      ],
    },
    "2024-08-20T13:30:00.000Z": {
      transactions: 44,
      revenue: 26707,
      consolidated: [
        {
          transactions: 22,
          key: "Yearly",
          revenue: 12928,
        },
        {
          transactions: 14,
          key: "Quarterly",
          revenue: 5887,
        },
        {
          transactions: 8,
          key: "Two Yearly",
          revenue: 7892,
        },
      ],
    },
    "2024-08-20T14:30:00.000Z": {
      transactions: 176,
      revenue: 105700,
      consolidated: [
        {
          transactions: 78,
          key: "Quarterly",
          revenue: 34631,
        },
        {
          transactions: 57,
          key: "Yearly",
          revenue: 42110,
        },
        {
          transactions: 26,
          key: "Two Yearly",
          revenue: 25974,
        },
        {
          transactions: 15,
          key: "Monthly",
          revenue: 2985,
        },
      ],
    },
    "2024-08-20T15:30:00.000Z": {
      transactions: 40,
      revenue: 26312,
      consolidated: [
        {
          transactions: 21,
          key: "Yearly",
          revenue: 13379,
        },
        {
          transactions: 9,
          key: "Quarterly",
          revenue: 4041,
        },
        {
          transactions: 9,
          key: "Two Yearly",
          revenue: 8891,
        },
        {
          transactions: 1,
          key: "Monthly",
          revenue: 1,
        },
      ],
    },
    "2024-08-20T16:30:00.000Z": {
      transactions: 37,
      revenue: 26614,
      consolidated: [
        {
          transactions: 20,
          key: "Yearly",
          revenue: 12581,
        },
        {
          transactions: 12,
          key: "Two Yearly",
          revenue: 11838,
        },
        {
          transactions: 5,
          key: "Quarterly",
          revenue: 2195,
        },
      ],
    },
    "2024-08-20T17:30:00.000Z": {
      transactions: 144,
      revenue: 90098,
      consolidated: [
        {
          transactions: 63,
          key: "Quarterly",
          revenue: 27956,
        },
        {
          transactions: 38,
          key: "Yearly",
          revenue: 27485,
        },
        {
          transactions: 33,
          key: "Two Yearly",
          revenue: 32667,
        },
        {
          transactions: 10,
          key: "Monthly",
          revenue: 1990,
        },
      ],
    },
    "2024-08-20T18:30:00.000Z": {
      transactions: 45,
      revenue: 29702,
      consolidated: [
        {
          transactions: 27,
          key: "Yearly",
          revenue: 16320,
        },
        {
          transactions: 10,
          key: "Two Yearly",
          revenue: 9790,
        },
        {
          transactions: 8,
          key: "Quarterly",
          revenue: 3592,
        },
      ],
    },
    "2024-08-20T19:30:00.000Z": {
      transactions: 51,
      revenue: 32182,
      consolidated: [
        {
          transactions: 23,
          key: "Yearly",
          revenue: 15077,
        },
        {
          transactions: 18,
          key: "Quarterly",
          revenue: 8014,
        },
        {
          transactions: 10,
          key: "Two Yearly",
          revenue: 9091,
        },
      ],
    },
    "2024-08-20T20:30:00.000Z": {
      transactions: 227,
      revenue: 144928,
      consolidated: [
        {
          transactions: 91,
          key: "Quarterly",
          revenue: 40272,
        },
        {
          transactions: 75,
          key: "Yearly",
          revenue: 54117,
        },
        {
          transactions: 47,
          key: "Two Yearly",
          revenue: 46553,
        },
        {
          transactions: 11,
          key: "Monthly",
          revenue: 2189,
        },
        {
          transactions: 3,
          key: "Half Yearly",
          revenue: 1797,
        },
      ],
    },
    "2024-08-20T21:30:00.000Z": {
      transactions: 86,
      revenue: 60154,
      consolidated: [
        {
          transactions: 49,
          key: "Yearly",
          revenue: 32791,
        },
        {
          transactions: 20,
          key: "Two Yearly",
          revenue: 19730,
        },
        {
          transactions: 17,
          key: "Quarterly",
          revenue: 7633,
        },
      ],
    },
    "2024-08-20T22:30:00.000Z": {
      transactions: 71,
      revenue: 49409,
      consolidated: [
        {
          transactions: 32,
          key: "Yearly",
          revenue: 22098,
        },
        {
          transactions: 20,
          key: "Quarterly",
          revenue: 8980,
        },
        {
          transactions: 19,
          key: "Two Yearly",
          revenue: 18331,
        },
      ],
    },
    "2024-08-20T23:30:00.000Z": {
      transactions: 137,
      revenue: 69163,
      consolidated: [
        {
          transactions: 70,
          key: "Quarterly",
          revenue: 31330,
        },
        {
          transactions: 36,
          key: "Yearly",
          revenue: 27664,
        },
        {
          transactions: 26,
          key: "Monthly",
          revenue: 5174,
        },
        {
          transactions: 5,
          key: "Two Yearly",
          revenue: 4995,
        },
      ],
    },
  },
  Quarterly: {
    transactions: 600,
    revenue: 200000,
  },
  Yearly: {
    transactions: 555,
    revenue: 30000,
  },
  "Two Yearly": {
    transactions: 200,
    revenue: 27000,
  },
  Monthly: {
    transactions: 80,
    revenue: 18000,
  },
  "Half Yearly": {
    transactions: 30,
    revenue: 1000,
  },
};
const lastWeekResult = {
  daywise: {
    "2024-08-12T23:30:00.000Z": {
      transactions: 65,
      revenue: 43304,
      consolidated: [
        {
          transactions: 29,
          key: "Quarterly",
          revenue: 12645,
        },
        {
          transactions: 19,
          key: "Two Yearly",
          revenue: 18831,
        },
        {
          transactions: 17,
          key: "Yearly",
          revenue: 11828,
        },
      ],
    },
    "2024-08-13T00:30:00.000Z": {
      transactions: 22,
      revenue: 12228,
      consolidated: [
        {
          transactions: 15,
          key: "Quarterly",
          revenue: 6735,
        },
        {
          transactions: 5,
          key: "Yearly",
          revenue: 3495,
        },
        {
          transactions: 2,
          key: "Two Yearly",
          revenue: 1998,
        },
      ],
    },
    "2024-08-13T01:30:00.000Z": {
      transactions: 11,
      revenue: 6035,
      consolidated: [
        {
          transactions: 6,
          key: "Quarterly",
          revenue: 2645,
        },
        {
          transactions: 5,
          key: "Yearly",
          revenue: 3390,
        },
      ],
    },
    "2024-08-13T02:30:00.000Z": {
      transactions: 37,
      revenue: 21614,
      consolidated: [
        {
          transactions: 24,
          key: "Quarterly",
          revenue: 10627,
        },
        {
          transactions: 8,
          key: "Yearly",
          revenue: 6392,
        },
        {
          transactions: 4,
          key: "Two Yearly",
          revenue: 3996,
        },
        {
          transactions: 1,
          key: "Half Yearly",
          revenue: 599,
        },
      ],
    },
    "2024-08-13T03:30:00.000Z": {
      transactions: 15,
      revenue: 8635,
      consolidated: [
        {
          transactions: 10,
          key: "Quarterly",
          revenue: 4540,
        },
        {
          transactions: 4,
          key: "Yearly",
          revenue: 3096,
        },
        {
          transactions: 1,
          key: "Two Yearly",
          revenue: 999,
        },
      ],
    },
    "2024-08-13T05:30:00.000Z": {
      transactions: 22,
      revenue: 11029,
      consolidated: [
        {
          transactions: 18,
          key: "Quarterly",
          revenue: 8033,
        },
        {
          transactions: 2,
          key: "Two Yearly",
          revenue: 1998,
        },
        {
          transactions: 1,
          key: "Monthly",
          revenue: 199,
        },
        {
          transactions: 1,
          key: "Yearly",
          revenue: 799,
        },
      ],
    },
    "2024-08-13T06:30:00.000Z": {
      transactions: 4,
      revenue: 2846,
      consolidated: [
        {
          transactions: 2,
          key: "Yearly",
          revenue: 1398,
        },
        {
          transactions: 1,
          key: "Quarterly",
          revenue: 449,
        },
        {
          transactions: 1,
          key: "Two Yearly",
          revenue: 999,
        },
      ],
    },
    "2024-08-13T07:30:00.000Z": {
      transactions: 11,
      revenue: 7243,
      consolidated: [
        {
          transactions: 5,
          key: "Two Yearly",
          revenue: 4995,
        },
        {
          transactions: 3,
          key: "Quarterly",
          revenue: 501,
        },
        {
          transactions: 3,
          key: "Yearly",
          revenue: 1747,
        },
      ],
    },
    "2024-08-13T08:30:00.000Z": {
      transactions: 50,
      revenue: 31055,
      consolidated: [
        {
          transactions: 24,
          key: "Quarterly",
          revenue: 10708,
        },
        {
          transactions: 15,
          key: "Yearly",
          revenue: 11128,
        },
        {
          transactions: 10,
          key: "Two Yearly",
          revenue: 9020,
        },
        {
          transactions: 1,
          key: "Monthly",
          revenue: 199,
        },
      ],
    },
    "2024-08-13T09:30:00.000Z": {
      transactions: 16,
      revenue: 10164,
      consolidated: [
        {
          transactions: 11,
          key: "Yearly",
          revenue: 7469,
        },
        {
          transactions: 4,
          key: "Quarterly",
          revenue: 1796,
        },
        {
          transactions: 1,
          key: "Two Yearly",
          revenue: 899,
        },
      ],
    },
    "2024-08-13T10:30:00.000Z": {
      transactions: 9,
      revenue: 5591,
      consolidated: [
        {
          transactions: 4,
          key: "Quarterly",
          revenue: 1796,
        },
        {
          transactions: 4,
          key: "Yearly",
          revenue: 2796,
        },
        {
          transactions: 1,
          key: "Two Yearly",
          revenue: 999,
        },
      ],
    },
    "2024-08-13T11:30:00.000Z": {
      transactions: 130,
      revenue: 99556,
      consolidated: [
        {
          transactions: 65,
          key: "Two Yearly",
          revenue: 64935,
        },
        {
          transactions: 44,
          key: "Quarterly",
          revenue: 19492,
        },
        {
          transactions: 20,
          key: "Yearly",
          revenue: 14530,
        },
        {
          transactions: 1,
          key: "Half Yearly",
          revenue: 599,
        },
      ],
    },
    "2024-08-13T12:30:00.000Z": {
      transactions: 24,
      revenue: 15126,
      consolidated: [
        {
          transactions: 10,
          key: "Quarterly",
          revenue: 4490,
        },
        {
          transactions: 10,
          key: "Yearly",
          revenue: 6640,
        },
        {
          transactions: 4,
          key: "Two Yearly",
          revenue: 3996,
        },
      ],
    },
    "2024-08-13T13:30:00.000Z": {
      transactions: 38,
      revenue: 25824,
      consolidated: [
        {
          transactions: 17,
          key: "Two Yearly",
          revenue: 14083,
        },
        {
          transactions: 11,
          key: "Yearly",
          revenue: 7269,
        },
        {
          transactions: 10,
          key: "Quarterly",
          revenue: 4472,
        },
      ],
    },
    "2024-08-13T14:30:00.000Z": {
      transactions: 151,
      revenue: 105939,
      consolidated: [
        {
          transactions: 62,
          key: "Two Yearly",
          revenue: 57289,
        },
        {
          transactions: 53,
          key: "Quarterly",
          revenue: 23535,
        },
        {
          transactions: 34,
          key: "Yearly",
          revenue: 24317,
        },
        {
          transactions: 1,
          key: "Half Yearly",
          revenue: 599,
        },
        {
          transactions: 1,
          key: "Monthly",
          revenue: 199,
        },
      ],
    },
    "2024-08-13T15:30:00.000Z": {
      transactions: 26,
      revenue: 17211,
      consolidated: [
        {
          transactions: 9,
          key: "Quarterly",
          revenue: 3973,
        },
        {
          transactions: 9,
          key: "Yearly",
          revenue: 6391,
        },
        {
          transactions: 8,
          key: "Two Yearly",
          revenue: 6847,
        },
      ],
    },
    "2024-08-13T16:30:00.000Z": {
      transactions: 21,
      revenue: 13180,
      consolidated: [
        {
          transactions: 13,
          key: "Yearly",
          revenue: 8038,
        },
        {
          transactions: 5,
          key: "Quarterly",
          revenue: 2495,
        },
        {
          transactions: 3,
          key: "Two Yearly",
          revenue: 2647,
        },
      ],
    },
    "2024-08-13T17:30:00.000Z": {
      transactions: 120,
      revenue: 83067,
      consolidated: [
        {
          transactions: 56,
          key: "Quarterly",
          revenue: 25081,
        },
        {
          transactions: 42,
          key: "Two Yearly",
          revenue: 41308,
        },
        {
          transactions: 22,
          key: "Yearly",
          revenue: 16678,
        },
      ],
    },
    "2024-08-13T18:30:00.000Z": {
      transactions: 26,
      revenue: 17624,
      consolidated: [
        {
          transactions: 10,
          key: "Yearly",
          revenue: 6290,
        },
        {
          transactions: 8,
          key: "Quarterly",
          revenue: 3592,
        },
        {
          transactions: 8,
          key: "Two Yearly",
          revenue: 7742,
        },
      ],
    },
    "2024-08-13T19:30:00.000Z": {
      transactions: 31,
      revenue: 20219,
      consolidated: [
        {
          transactions: 12,
          key: "Yearly",
          revenue: 7788,
        },
        {
          transactions: 11,
          key: "Quarterly",
          revenue: 4939,
        },
        {
          transactions: 8,
          key: "Two Yearly",
          revenue: 7492,
        },
      ],
    },
    "2024-08-13T20:30:00.000Z": {
      transactions: 205,
      revenue: 136251,
      consolidated: [
        {
          transactions: 96,
          key: "Quarterly",
          revenue: 42581,
        },
        {
          transactions: 55,
          key: "Yearly",
          revenue: 41024,
        },
        {
          transactions: 53,
          key: "Two Yearly",
          revenue: 52047,
        },
        {
          transactions: 1,
          key: "Half Yearly",
          revenue: 599,
        },
      ],
    },
    "2024-08-13T21:30:00.000Z": {
      transactions: 46,
      revenue: 30025,
      consolidated: [
        {
          transactions: 23,
          key: "Yearly",
          revenue: 14798,
        },
        {
          transactions: 12,
          key: "Quarterly",
          revenue: 5388,
        },
        {
          transactions: 11,
          key: "Two Yearly",
          revenue: 9839,
        },
      ],
    },
    "2024-08-13T22:30:00.000Z": {
      transactions: 57,
      revenue: 39120,
      consolidated: [
        {
          transactions: 19,
          key: "Quarterly",
          revenue: 8418,
        },
        {
          transactions: 19,
          key: "Two Yearly",
          revenue: 18531,
        },
        {
          transactions: 19,
          key: "Yearly",
          revenue: 12171,
        },
      ],
    },
    "2024-08-13T23:30:00.000Z": {
      transactions: 109,
      revenue: 63141,
      consolidated: [
        {
          transactions: 68,
          key: "Quarterly",
          revenue: 30732,
        },
        {
          transactions: 36,
          key: "Yearly",
          revenue: 27564,
        },
        {
          transactions: 5,
          key: "Two Yearly",
          revenue: 4845,
        },
      ],
    },
  },
  Quarterly: {
    transactions: 500,
    revenue: 23000,
  },
  "Two Yearly": {
    transactions: 300,
    revenue: 330000,
  },
  Yearly: {
    transactions: 30,
    revenue: 240000,
  },
  "Half Yearly": {
    transactions: 40,
    revenue: 2000,
  },
  Monthly: {
    transactions: 30,
    revenue: 5000,
  },
};

export { todayResult, yesterdayResult, lastWeekResult };
