const widgetsData = {
  dauData: {
    chart_name: "DAU",
    percentage: -0.6085083403441056,
    chart_data: 1000.42105263157,
  },
  paidDauData: {
    chart_name: "Paid DAU",
    percentage: 1.0398865637731525,
    chart_data: 2000.933333333334,
  },
  streamingDauData: {
    chart_name: "Streaming DAU",
    percentage: 49.59437615811293,
    chart_data: "3000",
  },
  mauData: {
    chart_name: "MAU",
    percentage: 30.329506134004404,
    chart_data: "4000",
  },
  paidMauData: {
    chart_name: "Paid MAU",
    percentage: -0.9802383630300522,
    chart_data: 5000.533333333333,
  },
  streamingMauData: {
    chart_name: "Streaming MAU",
    percentage: 40.110678313074644,
    chart_data: "7000",
  },
};

const ottData = {
  revenueData: {
    chart_name: "Revenue",
    percentage: 5.486240138657943,
    chart_data: 10000,
  },

  subscriptionData: {
    chart_name: "Subscription",
    percentage: 5.688568034359865,
    chart_data: 5000,
  },

  arpuData: {
    chart_name: "ARPU",
    percentage: -0.1692047377326565,
    chart_data: 600,
  },

  cpsData: {
    chart_name: "CPS",
    percentage: 10.352,
    chart_data: 4000,
  },

  totalSpendsData: {
    chart_name: "Total Spends",
    percentage: 12.352,
    chart_data: 300,
  },

  performanceSpendsData: {
    chart_name: "Performance Spends",
    percentage: -12.352,
    chart_data: 400,
  },

  brandSpendsdata: {
    chart_name: "Brand Spends",
    percentage: 7.352,
    chart_data: 40,
  },

  roasData: {
    chart_name: "ROAS",
    percentage: 8.212,
    chart_data: 500,
  },
};

const stickinessWidgetsData = {
  platformData: {
    chart_name: "Stickiness Platform",
    chart_data: 6.666666666666667,
  },
  streamingData: {
    chart_name: "Stickiness Streaming",
    chart_data: 7.666666666666667,
  },
  paidData: {
    chart_name: "Stickiness Paid",
    chart_data: 8.666666666666667,
  },
};

export { ottData, widgetsData, stickinessWidgetsData };
