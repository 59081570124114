import { createAsyncThunk } from "@reduxjs/toolkit";

import { getTitleContribution as getTitleContributionApi } from "../../../helpers/fakebackend_helper";
export const getTitleContributionData = createAsyncThunk(
  "RevenueMatrix/Title Contribution",
  async (data) => {
    try {
      const response = getTitleContributionApi(data);
      // console.log("Title Contribution dataObject", data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
