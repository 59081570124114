import React, { useState } from "react";
import { Row, Col, Container } from "reactstrap";

import AnalysisTableCard from "./analysisTableCard";
import AnalysisChartCard from "./analysisChartCard";

function ActiveUserAnalysis() {
  return (
    <React.Fragment>
      <Container
        fluid
        className="customContainerStyle"
        // style={{ height: "468px" }}
      >
        <Row>
          <Col md="12" style={{ padding: "20px" }}>
            <h4 className="card-heading-text-black">Active User Analysis</h4>
          </Col>
        </Row>
        <Row>
          <AnalysisTableCard />
          <AnalysisChartCard />
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default ActiveUserAnalysis;
