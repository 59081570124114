import { createSlice } from "@reduxjs/toolkit";
import {
  getCategoryContributionData,
  getPlatformContributionData,
} from "./thunk";

export const initialState = {
  usersCategoryContribution: null,
  MOUCategoryContribution: null,
  platformContribution: null,
  usersPlatformContribution: null,
  MOUPlatformContribution: null,
  categoryLoader: false,
  platformLoader: false,
  status: "idle",
  error: {},
};

const PlatformCategorySlice = createSlice({
  name: "PlatformCategory",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getCategoryContributionData.pending, (state, action) => {
      state.categoryLoader = true;
    });
    builder.addCase(getCategoryContributionData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.usersCategoryContribution = action.payload.data.chart_data.Users;
        state.MOUCategoryContribution = action.payload.data.MOU;
        state.categoryLoader = false;
        state.status = "succeeded";
      }
    });
    builder.addCase(getCategoryContributionData.rejected, (state, action) => {
      state.error = action.error.message;
      state.categoryLoader = false;
      state.status = "failed";
    });
    builder.addCase(getPlatformContributionData.pending, (state, action) => {
      state.platformLoader = true;
    });
    builder.addCase(getPlatformContributionData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.usersPlatformContribution = action.payload.data.chart_data.Users;
        state.MOUPlatformContribution = action.payload.data.chart_data.mou;
        state.platformContribution = action.payload.data;
        state.platformLoader = false;
        state.status = "succeeded";
      }
    });
    builder.addCase(getPlatformContributionData.rejected, (state, action) => {
      state.error = action.error.message;
      state.platformLoader = false;
      state.status = "failed";
    });
  },
});

export default PlatformCategorySlice.reducer;
