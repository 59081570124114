import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card } from "reactstrap";
// import Components

import DateDropdown from "../../HealthMatrix/DateDropdown";
import { getLastSixMonthsDateRange } from "../../../helpers/dateUtils";
import { getPaidUserContributionData } from "../../../slices/ConsumptionHighlights/PaidUserContribution/thunk";
import FilterSection from "../../RevenueMatrix/Subscription/filterSection";
import PaidUserTable from "./paidUserTable";
import PaidUserChart from "./paidUserChart";
// import { PaidUserChart } from "./paidUserChart";

const filterOptions = {
  platforms: {
    All: "android,ios,web,external",
    Android: "android",
    Web: "web",
    Tv: "external",
    iOS: "ios",
  },
  region: {
    All: "India,row",
    India: "India",
    ROW: "row",
  },
};

function PaidUserContribution() {
  const dispatch = useDispatch();
  const [customDateRange, setCustomDateRange] = useState(
    getLastSixMonthsDateRange()
  );
  const [filters, setFilters] = useState({
    start_date: customDateRange.start_date,
    end_date: customDateRange.end_date,
    platforms: filterOptions.platforms.All,
    region: filterOptions.region.All,
  });

  const fetchData = async (dateRange) => {
    const dataObject = {
      ...filters,
      ...dateRange,
    };
    // dispatch(getPaidUserContributionData(dataObject));
  };

  useEffect(() => {
    fetchData(customDateRange);
  }, [customDateRange, filters]);

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const handleDateRangeChange = (range) => {
    setCustomDateRange(range);
    setFilters((prevFilters) => ({
      ...prevFilters,
      start_date: range.startDate,
      end_date: range.endDate,
    }));
  };

  const handleResetFilters = () => {
    setFilters({
      daily_wise_data: true,
      user_type: filterOptions.user_type.All,
      platforms: filterOptions.platforms.All,
      region: filterOptions.region.All,
    });
  };
  return (
    <React.Fragment>
      <Row>
        <Container
          fluid
          className="customContainerStyle"
          //   style={{ height: "501px" }}
        >
          <Row>
            <Col style={{ padding: "20px" }}>
              <h4 className="card-heading-text">Paid User Contribution</h4>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="d-flex justify-content-between">
              <FilterSection
                filterOptions={filterOptions}
                filters={filters}
                handleFilterChange={handleFilterChange}
                handleResetFilters={handleResetFilters}
              />
              <DateDropdown
                onDateRangeChange={handleDateRangeChange}
                options={["6 Months", "1 Year", "Custom"]}
              />
            </Col>
          </Row>
          <Row>
            <Col xl={6}>
              <PaidUserTable />
            </Col>
            <Col xl={6}>
              <Card className="card-styling" style={{ height: "341px" }}>
                <PaidUserChart dataColors='["#146588","#BAAF00"]' />
              </Card>
            </Col>
          </Row>
        </Container>
      </Row>
    </React.Fragment>
  );
}

export default PaidUserContribution;
