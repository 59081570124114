import { createSlice } from "@reduxjs/toolkit";
import { getAutoDebitData } from "./thunk";

export const initialState = {
  tableData: [],
  loader: false,
  error: "",
};

const AutoDebitSlice = createSlice({
  name: "AutoDebitMandate",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getAutoDebitData.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getAutoDebitData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.tableData = action.payload?.data?.chart_data;
        state.loader = false;
      }
    });
    builder.addCase(getAutoDebitData.rejected, (state, action) => {
      state.error = action.error.message;
      state.loader = false;
    });
  },
});

export default AutoDebitSlice.reducer;
