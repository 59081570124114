import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Card, Col } from "reactstrap";
import groupImage from "../../../assets/images/saranyuImages/Group 328.svg";
import b2cImage from "../../../assets/images/saranyuImages/Group 160.svg";
import b2bImage from "../../../assets/images/saranyuImages/Group 161.svg";
import PLBAnalysisChart from "./PLBAnalysisChart";
import DateDropdown from "../../HealthMatrix/DateDropdown";
import { getMTDDateRange } from "../../../helpers/dateUtils";
import { getPlbAnalysisData } from "../../../slices/PlbAnalysis/thunk";
import {
  totalPlb,
  b2b,
  b2c,
  plbChartData,
} from "../../../common/data/HealthMatrix/plbData";

function ClosingPlb() {
  const dispatch = useDispatch();
  // const { totalPlb, b2b, b2c, plbChartData, status, error } = useSelector(
  //   (state) => state.PLBAnalysis
  // );

  const [selectedRegion, setSelectedRegion] = useState("all"); // Default to "all"
  const [customDateRange, setCustomDateRange] = useState(getMTDDateRange());
  const [initial, setInitial] = useState(true);

  useEffect(() => {
    fetchPlbAnalysisData(customDateRange);
  }, [customDateRange]);

  const fetchPlbAnalysisData = async (dateRange) => {
    const dataObject = initial
      ? { initial: true, filter_type: selectedRegion }
      : {
          ...dateRange,
          filter_type: selectedRegion,
          initial: false,
        };
    // dispatch(getPlbAnalysisData(dataObject));
  };

  const handleSelectedValue = (event) => {
    setSelectedRegion(event.target.id);
  };

  const handleDateRangeChange = (range) => {
    setCustomDateRange(range);
    setInitial(false); // Subsequent calls are not initial
  };

  // Transform the dailyWiseData for the chart
  const plbChartSeries = [
    {
      name: "B2C",
      data: plbChartData ? plbChartData.map((item) => item.b2c) : [],
    },
    {
      name: "B2B",
      data: plbChartData ? plbChartData.map((item) => item.b2b) : [],
    },
  ];

  const label = plbChartData ? plbChartData.map((item) => item.date) : [];

  return (
    <React.Fragment>
      <Container
        fluid
        className="customContainerStyle"
        style={{ height: "538px" }}
      >
        <Row>
          <Col
            md="12"
            className="d-flex justify-content-between"
            style={{ padding: "20px" }}
          >
            <h4 className="card-heading-text-black">Closing PLB Analysis</h4>
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-center me-2">
                <input
                  type="radio"
                  id="all"
                  name="plb"
                  className="sh-radio-button me-1"
                  checked={selectedRegion === "all"}
                  onChange={handleSelectedValue}
                />
                <label htmlFor="all" className="sh-radio-text">
                  All
                </label>
              </div>
              <div className="d-flex justify-content-center">
                <input
                  type="radio"
                  id="india"
                  name="plb"
                  className="sh-radio-button me-1"
                  checked={selectedRegion === "india"}
                  onChange={handleSelectedValue}
                />
                <label htmlFor="india" className="sh-radio-text">
                  India
                </label>
              </div>
            </div>
            <DateDropdown
              onDateRangeChange={handleDateRangeChange}
              options={["MTD", "Last Month", "Custom"]}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Row className="mt-4">
              <Col md="9">
                <div className="d-flex align-items-center">
                  <img src={groupImage} className="me-3" />
                  &nbsp;
                  <div>
                    <p
                      className="mb-2 widget-name"
                      style={{ color: "#000000" }}
                    >
                      Total PLB
                    </p>
                    <h4
                      className="mb-0 widget-counter"
                      style={{ color: "#000000" }}
                    >
                      {parseInt(totalPlb.toFixed(0)).toLocaleString()}
                    </h4>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="d-flex flex-column">
                  <div className="mb-3">
                    <img
                      src={b2cImage}
                      style={{ height: "140px", width: "140px" }}
                    />
                    <div className="d-flex flex-column align-items-center">
                      <p
                        className="mb-1 widget-name"
                        style={{ color: "#000000" }}
                      >
                        B2C
                      </p>
                      <h4
                        className="mb-0 widget-name"
                        style={{ color: "#000000" }}
                      >
                        {parseInt(b2c.toFixed(0)).toLocaleString()}
                      </h4>
                    </div>
                  </div>
                  <div>
                    <img
                      src={b2bImage}
                      style={{ height: "140px", width: "140px" }}
                    />
                    <div className="d-flex flex-column align-items-center">
                      <p
                        className="mb-1 widget-name"
                        style={{ color: "#000000" }}
                      >
                        B2B
                      </p>
                      <h4
                        className="mb-0 widget-name"
                        style={{ color: "#000000" }}
                      >
                        {parseInt(b2b.toFixed(0)).toLocaleString()}
                      </h4>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col style={{ marginTop: "55px" }} md="6">
            <Card className="card-styling " style={{ height: "338px" }}>
              <PLBAnalysisChart
                dataColors='["#2BA558", "#146588"]'
                series={plbChartSeries}
                labels={label}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default ClosingPlb;
