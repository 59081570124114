import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import ReactApexChart from "react-apexcharts";
import { formatMonthArray } from "../../../helpers/formatMonth";
import {
  dauData as dauDataFromState,
  prevDauData as prevDauDataFromState,
  streamingDauData as streamingDauDataFromState,
  prevStreamingDauData as prevStreamingDauDataFromState,
} from "../../../common/data/HealthMatrix/activeUserAnalysisData";

const AnalysisChart = ({ dataColors, loader, selectedValue }) => {
  //console.log("selectedValue",selectedValue)
  var chartColors = getChartColorsArray(dataColors);
  const [dauData, setDauData] = useState([]);
  const [streamingDauData, setStreamingDauData] = useState([]);
  const [prevDauData, setPrevDauData] = useState([]);
  const [prevStreamingDauData, setPrevStreamingDauData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [loader1, setLoader1] = useState(true);
  const [loader2, setLoader2] = useState(true);

  // const dauDataFromState = useSelector(
  //   (state) => state.ActiveUserAnalysis?.dauData
  // );

  // const prevDauDataFromState = useSelector(
  //   (state) => state.ActiveUserAnalysis?.prevDauData
  // );

  // const { dauLoader, streamingDauLoader } = useSelector(
  //   (state) => state.ActiveUserAnalysis
  // );

  // const streamingDauDataFromState = useSelector(
  //   (state) => state.ActiveUserAnalysis?.streamingDauData
  // );

  // const prevStreamingDauDataFromState = useSelector(
  //   (state) => state.ActiveUserAnalysis?.prevStreamingDauData
  // );

  useEffect(() => {
    if (dauDataFromState && streamingDauDataFromState) {
      setDauData(dauDataFromState.map((obj) => obj.dau));
      setStreamingDauData(
        streamingDauDataFromState.map((obj) => obj.streaming_dau)
      );
      setPrevDauData(prevDauDataFromState.map((obj) => obj.dau));
      setPrevStreamingDauData(
        prevStreamingDauDataFromState.map((obj) => obj.streaming_dau)
      );
      const labels = dauDataFromState.map((obj) => obj.date);

      const formattedDates = labels.map(formatMonthArray);

      setLabels(formattedDates);
      setLoader1(false);
      setLoader2(false);
    }
  }, [dauDataFromState, streamingDauDataFromState]);

  var dauSeries = [
    {
      name: "DAU",
      data: dauData ? dauData : [],
    },
    {
      name: "DAU (Previous Period)",
      data: prevDauData ? prevDauData : [],
    },
  ];

  var streamingDauSeries = [
    {
      name: "Streaming DAU",
      data: streamingDauData ? streamingDauData : [],
    },
    {
      name: "Streaming DAU (Previous Period)",
      data: prevStreamingDauData ? prevStreamingDauData : [],
    },
  ];

  const getSeries = (selectedValue) => {
    let series;
    switch (selectedValue) {
      case "DAU":
        series = dauSeries ? dauSeries : [];
        break;
      case "StreamingDAU":
        series = streamingDauSeries ? streamingDauSeries : [];
        break;
      default:
        series = [];
    }
    return series;
  };

  var options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: chartColors,
    dataLabels: {
      enabled: false,
    },
    // stroke: {
    //   width: [4,3],
    //   curve: "smooth",
    // },
    stroke: {
      width: [4, 4],
      curve: "straight",
      dashArray: [0, 4, 3],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val !== undefined ? (val / 1000).toFixed(0) + "" : ""; // Format y-axis values in thousands
        },
      },
      title: {
        text: "DATA IN THOUSANDS",
      },
      min: 0,
      tickAmount: 6, // Adjust this as per your requirement
      // You can set specific min and max ranges
      // min: 0,
      // max: 50000, // Example max value, adjust based on your data
    },
    xaxis: {
      labels: {
        show: false,
        formatter: function (val) {
          return val !== undefined ? val : ""; // Display actual values on x-axis
        },
      },
      axisBorder: {
        show: true,
      },
      categories: labels,
    },
    markers: {
      size: 5,
      colors: chartColors,
      strokeColors: "#fff",
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    tooltip: {
      enabled: true,
      // x: {
      //   show: true,
      //   formatter: function (val) {
      //     return val !== undefined ? val : ""; // Display actual values on x-axis in tooltip
      //   },
      // },
      y: {
        formatter: function (val) {
          return val !== undefined
            ? parseInt(val.toFixed(0)).toLocaleString()
            : ""; // Display actual values in tooltip without formatting
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  return (
    <React.Fragment>
      {!loader1 && !loader2 ? (
        <ReactApexChart
          dir="ltr"
          height="80%"
          options={options}
          series={getSeries(selectedValue)}
          type="line"
          className="apex-charts"
        />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "300px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AnalysisChart;
