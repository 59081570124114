import { createAsyncThunk } from "@reduxjs/toolkit";

import { getActiveUserAnalysis as getActiveUserAnalysisApi } from "../../../helpers/fakebackend_helper";
import { getDau as getDauApi } from "../../../helpers/fakebackend_helper";
import { getStreamingDau as getStreamingDauApi } from "../../../helpers/fakebackend_helper";

export const getActiveUserAnalysisData = createAsyncThunk(
  "HealthMatrix/Active User Analysis",
  async (data) => {
    try {
      const response = getActiveUserAnalysisApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// export const getCombinedDauData = createAsyncThunk(
//   "HealthMatrix/Active User Analysis/CombinedDau",
//   async (data, { rejectWithValue }) => {
//     try {
//       const [dauResponse, streamingDauResponse] = Promise.all([
//         getDauApi(data),
//         getStreamingDauApi(data),
//       ]);

//       return {
//         dauData: dauResponse,
//         streamingDauData: streamingDauResponse,
//       };
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

export const getDauData = createAsyncThunk(
  "HealthMatrix/Active User Analysis/Dau",
  async (data) => {
    try {
      const response = getDauApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getStreamingDauData = createAsyncThunk(
  "HealthMatrix/Active User Analysis/StreamingDau",
  async (data) => {
    try {
      const response = getStreamingDauApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
