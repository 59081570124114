import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Card } from "reactstrap";
import DateDropdown from "../../HealthMatrix/DateDropdown";
import { getMTDDateRange } from "../../../helpers/dateUtils";
import { getGeoData } from "../../../slices/ConsumptionHighlights/Geo/thunk";
import FilterSection from "../../RevenueMatrix/Subscription/filterSection";
import GeoTable from "./geoTable";
import GeoPerStateTable from "./geoPerStateTable";
import { geoChartData as chartData } from "../../../common/data/ConnsumptionInsights/geodata";
import GeoPerState from "./geoPerState";

const filterOptions = {
  user_type: {
    All: "Free,paid",
    Free: "Free",
    Paid: "paid",
  },
  platforms: {
    All: "android,web,ios,external",
    Android: "android",
    Web: "web",
    Tv: "external",
    iOS: "ios",
  },
  category: {
    All: "gujarati,non_gujarati",
    Gujarati: "gujarati",
    "Non Gujarati": "non_gujarati",
  },
};

function GeographicContribution() {
  const dispatch = useDispatch();
  const [geoPerStateData, setGeoPerStateData] = useState([]);
  const [customDateRange, setCustomDateRange] = useState(getMTDDateRange());
  const [filters, setFilters] = useState({
    start_date: customDateRange.start_date,
    end_date: customDateRange.end_date,
    user_type: filterOptions.user_type.All,
    platforms: filterOptions.platforms.All,
    category: filterOptions.category.All,
    filter_type: "ROW",
  });
  const [selectedOption, setSelectedOption] = useState("ROW");
  const [loader, setLoader] = useState(true);
  //   const [allData, setAllData] = useState([]);

  // const { chartData, loader } = useSelector((state) => state.Geo);
  //console.log(chartData);

  useEffect(() => {
    if (chartData.length > 0) {
      const formattedData = chartData.map((item) => {
        const types = [
          { key: "city", filter_type: "city" },
          { key: "state", filter_type: "state" },
          { key: "country", filter_type: "ROW" },
        ];

        const found = types.find((type) => item[type.key]);

        return found
          ? { filter_type: found.filter_type, [found.key]: item[found.key] }
          : { filter_type: "Unknown", unknown: "Unknown" };
      });
      setGeoPerStateData(formattedData);
      setLoader(false);
    }
  }, [chartData]);

  useEffect(() => {
    setSelectedOption(filters.filter_type);
  }, [filters.filter_type]);

  const fetchData = async (dateRange) => {
    const dataObject = {
      ...filters,
      ...dateRange,
    };
    // dispatch(getGeoData(dataObject));
  };

  useEffect(() => {
    fetchData(customDateRange);
  }, [customDateRange, filters]);

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const handleDateRangeChange = (range) => {
    setCustomDateRange(range);
    setFilters((prevFilters) => ({
      ...prevFilters,
      start_date: range.startDate,
      end_date: range.endDate,
    }));
  };

  const handleResetFilters = () => {
    setFilters({
      daily_wise_data: true,
      user_type: filterOptions.user_type.All,
      platforms: filterOptions.platforms.All,
      category: filterOptions.category.All,
      filter_type: "ROW",
    });
    setSelectedOption("ROW");
  };

  const handleRadioChange = (event) => {
    const value = event.target.id;
    setSelectedOption(value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      filter_type: value,
    }));
  };

  return (
    <React.Fragment>
      <Row>
        <Container
          fluid
          className="customContainerStyle"
          style={{ height: "1929px" }}
        >
          <Row>
            <Col style={{ padding: "20px" }}>
              <h4 className="card-heading-text">Geographic Contribution </h4>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="d-flex justify-content-between">
              <FilterSection
                filterOptions={filterOptions}
                filters={filters}
                handleFilterChange={handleFilterChange}
                handleResetFilters={handleResetFilters}
              />
              <div className="d-flex align-items-center me-5">
                <div className="d-flex justify-content-center me-2">
                  <input
                    type="radio"
                    id="ROW"
                    name="GeoContribution"
                    className="sh-radio-button me-1"
                    checked={selectedOption === "ROW"}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="ROW" className="sh-radio-text">
                    ROW
                  </label>
                </div>
                <div className="d-flex justify-content-center me-2">
                  <input
                    type="radio"
                    id="indian_states"
                    name="GeoContribution"
                    className="sh-radio-button me-1"
                    checked={selectedOption === "indian_states"}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="indian_states" className="sh-radio-text">
                    Indian States
                  </label>
                </div>
                <div className="d-flex justify-content-center me-2">
                  <input
                    type="radio"
                    id="indian_cities"
                    name="GeoContribution"
                    className="sh-radio-button me-1"
                    checked={selectedOption === "indian_cities"}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="indian_cities" className="sh-radio-text">
                    Indian Cities
                  </label>
                </div>
              </div>
              <DateDropdown
                onDateRangeChange={handleDateRangeChange}
                options={["MTD", "Last Month", "1 Year", "Custom"]}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <GeoTable chartData={chartData} loader={loader} />
            </Col>
          </Row>
          {/* <Row> */}
          {/* {geoPerStateData.length > 0
              ? geoPerStateData.map((location, index) => (
                  <Col md="6" key={index}>
                    <Card className="card-styling" style={{ height: "264px" }}>
                      <GeoPerStateTable
                        customDateRange={customDateRange}
                        location={location}
                      />
                    </Card>
                  </Col>
                ))
              : null} */}
          {/* </Row> */}
          <GeoPerState />
        </Container>
      </Row>
    </React.Fragment>
  );
}

export default GeographicContribution;
