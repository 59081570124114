import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Container, Row, Card } from "reactstrap";
import ContributionPie from "./contributionPieChart";
import { getTitleContributionData } from "../../../slices/RevenueMatrix/TitleContribution/thunk";
import { getLastMonthDateRange } from "../../../helpers/dateUtils";
import DateDropdown from "../../HealthMatrix/DateDropdown";
import CardontributionTable from "./contributionTable";

function TitleContribution() {
  const dispatch = useDispatch();
  const [customDateRange, setCustomDateRange] = useState(
    getLastMonthDateRange()
  );
  const [filters, setFilters] = useState({
    start_date: customDateRange.start_date,
    end_date: customDateRange.end_date,
    filter_type: "all",
  });

  const [selectedOption, setSelectedOption] = useState("all");

  //   useEffect(() => {
  //     if (
  //       allchartData.length > 0 &&
  //       gujaratiChartData.length > 0 &&
  //       nonGujaratiChartData.length > 0
  //     ) {
  //       setAllData(allchartData);
  //       setGujaratiData(gujaratiChartData);
  //       setNonGujaratiData(nonGujaratiChartData);
  //     }
  //   }, [allchartData, gujaratiChartData, nonGujaratiChartData]);

  useEffect(() => {
    setSelectedOption(filters.filter_type);
  }, [filters.filter_type]);

  const fetchData = async (dateRange) => {
    const dataObject = {
      ...filters,
      ...dateRange,
      //   start_date: "2023-06-01",
      //   end_date: "2024-06-19",
    };
    // dispatch(getTitleContributionData(dataObject));
  };

  useEffect(() => {
    fetchData(customDateRange);
  }, [customDateRange, filters]);

  //   const handleFilterChange = (filterType, value) => {
  //     setFilters((prevFilters) => ({
  //       ...prevFilters,
  //       [filterType]: value,
  //     }));

  //     switch (filterType) {
  //       case "platforms":
  //         setSelectedPlatform(value || "Platform");
  //         break;
  //       case "region":
  //         setSelectedRegion(value || "Region");
  //         break;
  //       default:
  //         break;
  //     }
  //   };

  const handleDateRangeChange = (range) => {
    setCustomDateRange(range);
    setFilters((prevFilters) => ({
      ...prevFilters,
      start_date: range.startDate,
      end_date: range.endDate,
    }));
  };
  //   const handleResetFilters = () => {
  //     setFilters({
  //       daily_wise_data: true,
  //       platforms: "android,ios,web,external",
  //       user_type: "Paid,Free",
  //       region: "india,row",
  //       filter_type: "all",
  //     });
  //     setSelectedPlatform("Platform");
  //     setSelectedRegion("Region");
  //     setSelectedOption("all");
  //   };

  const handleRadioChange = (event) => {
    const value = event.target.id;
    setSelectedOption(value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      filter_type: value,
    }));
  };
  return (
    <React.Fragment>
      <Container
        fluid
        className="customContainerStyle"
        style={{ height: "554px" }}
      >
        <Row>
          <Col style={{ padding: "20px" }}>
            <h4 className="card-heading-text">Title Contribution</h4>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between">
            <div className="d-flex align-items-center ms-5 sh-radios-container">
              <div className="d-flex justify-content-center me-2">
                <input
                  type="radio"
                  id="all"
                  name="titleContribution"
                  className="sh-radio-button me-1"
                  checked={selectedOption === "all"}
                  onChange={handleRadioChange}
                />
                <label htmlFor="all" className="sh-radio-text">
                  All
                </label>
              </div>
              <div className="d-flex justify-content-center me-2">
                <input
                  type="radio"
                  id="gujarati"
                  name="titleContribution"
                  className="sh-radio-button me-1"
                  checked={selectedOption === "gujarati"}
                  onChange={handleRadioChange}
                />
                <label htmlFor="gujarati" className="sh-radio-text">
                  Gujarati
                </label>
              </div>
              <div className="d-flex justify-content-center me-2">
                <input
                  type="radio"
                  id="non-gujuarati"
                  name="titleContribution"
                  className="sh-radio-button me-1"
                  checked={selectedOption === "non-gujuarati"}
                  onChange={handleRadioChange}
                />
                <label htmlFor="nonGujarati" className="sh-radio-text">
                  Non Gujarati
                </label>
              </div>
            </div>
            <DateDropdown
              onDateRangeChange={handleDateRangeChange}
              options={["Last Month", "Custom"]}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md="6">
            <CardontributionTable />
          </Col>
          <Col md="6">
            <Card className="card-styling " style={{ height: "374px" }}>
              <ContributionPie dataColors='["#146588", "#007893", "#00898D","#009978", "#2BA558", "#78AD2F","#BAAF00","#FFA600","#146588","#007893"]' />
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default TitleContribution;
