import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Card, Col, CardText } from "reactstrap";
import riseImage from "../../../assets/images/saranyuImages/Group 4.svg";
import downfallImage from "../../../assets/images/saranyuImages/Group 4 (1).svg";
import {
  totalMPUData,
  paidMPUData,
} from "../../../common/data/EcomDashboard/mouOverview";

const Widgets = ({ columnSpace }) => {
  const [widgets, setWidgets] = useState([]);
  const [loader, setLoader] = useState(true);
  // const paidMpuWidgetLoader = useSelector(
  //   (state) => state.MOUsOverviewData.paidMpuWidgetLoader
  // );

  // const totalMPUData = useSelector(
  //   (state) => state.MOUsOverviewData.totalMPUData
  // );
  // const paidMPUData = useSelector(
  //   (state) => state.MOUsOverviewData.paidMPUData
  // );

  useEffect(() => {
    if (paidMPUData && totalMPUData) {
      const ecomWidgets = [
        {
          label: "MPU",
          total_label: "All Users",
          paid_label: "Paid Users",
          total_mins: `${Math.round(totalMPUData.chart_data.total_mpu)} mins`,
          paid_mins: `${Math.round(paidMPUData.chart_data.paid_mpu)} mins`,
          total_percentage: totalMPUData.percentage,
          paid_percentage: paidMPUData.percentage,
        },
      ];

      setWidgets(ecomWidgets);
      setLoader(false);
    }
  }, [paidMPUData, totalMPUData]);

  function getSuffix(value) {
    if (value >= 100000) return "L";
    if (value >= 1000) return "K";
    return "";
  }

  function getRoundedValue(value) {
    if (value >= 100000) return Math.round(value / 100000);
    if (value >= 1000) return Math.round(value / 1000);
    return value;
  }

  return (
    <React.Fragment>
      {!loader ? (
        widgets.map((item, key) => {
          const totalPercentage =
            item.total_percentage !== undefined
              ? Math.abs(Number(item.total_percentage.toFixed(2)))
              : 0;
          const paidPercentage =
            item.paid_percentage !== undefined
              ? Math.abs(Number(item.paid_percentage.toFixed(2)))
              : 0;

          return (
            <Col md={columnSpace} key={key}>
              <Card className="mou-widget-card d-flex flex-column justify-content-between">
                <CardText
                  className="widget-name"
                  style={{ marginBottom: "0px", width: "150px" }}
                >
                  {item.label}
                </CardText>
                <Row style={{ color: "white" }}>
                  <Col className="mpu-widget-text" md="5">
                    {item.total_label}
                  </Col>
                  <Col
                    className="mpu-widget-text"
                    md="3"
                    style={{
                      color: totalPercentage > 0 ? "#00B69B" : "#FF0049",
                    }}
                  >
                    <img
                      src={totalPercentage > 0 ? riseImage : downfallImage}
                      alt="trend indicator"
                    />
                    {totalPercentage}%
                  </Col>
                  <Col className="mpu-widget-text ps-5" md="4">
                    {item.total_mins}
                  </Col>
                </Row>
                <Row style={{ color: "white" }}>
                  <Col className="mpu-widget-text" md="5">
                    {item.paid_label}
                  </Col>
                  <Col
                    className="mpu-widget-text"
                    md="3"
                    style={{
                      color: paidPercentage > 0 ? "#00B69B" : "#FF0049",
                    }}
                  >
                    <img
                      src={paidPercentage > 0 ? riseImage : downfallImage}
                      alt="trend indicator"
                    />
                    {paidPercentage}%
                  </Col>
                  <Col className="mpu-widget-text ps-5" md="4">
                    {item.paid_mins}
                  </Col>
                </Row>
              </Card>
            </Col>
          );
        })
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100px" }}
        >
          <Col className="spinner-border" role="status" md="4">
            <span className="visually-hidden">Loading...</span>
          </Col>
        </div>
      )}
    </React.Fragment>
  );
};

export default Widgets;
