import { createSlice } from "@reduxjs/toolkit";
import { getTitleContributionData } from "./thunk";

export const initialState = {
  chartData: [],
  loader: true,
  error: "",
};

const TitleContributionSlice = createSlice({
  name: "TitleContribution",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getTitleContributionData.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getTitleContributionData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.chartData = action.payload.data.chart_data;
        state.loader = false;
      }
    });
    builder.addCase(getTitleContributionData.rejected, (state, action) => {
      state.error = action.error.message;
      state.loader = false;
    });
  },
});

export default TitleContributionSlice.reducer;
