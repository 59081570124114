import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "reactstrap";
import { getGeoPerStateData } from "../../../slices/ConsumptionHighlights/GeoPerState/thunk";
import { paidUserData as chartData } from "../../../common/data/ConnsumptionInsights/paidUserData";

const arrayColors = [
  "#146588",
  "#00898D",
  "#2BA558",
  //   "#1B2431",
  //   "#78AD2F",
  //   "#2BA558",
  //   "#009978",
  //   "#00898D",
  //   "#007893",
];

const formatMonth = (dateString) => {
  if (dateString === "Total") {
    return dateString;
  }
  const dateObject = new Date(dateString);
  const monthFull = dateObject.toLocaleString("default", { month: "long" });
  const yearFull = dateObject.getFullYear();
  return `${monthFull} ${yearFull}`;
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function PaidUserTable() {
  // const { chartData, loader } = useSelector(
  //   (state) => state.PaidUserContribution
  // );
  //   console.log("ChartData from PaidUser", chartData);
  //   const [loader, setLoader] = useState(true);
  const [existingUsers, setExistingUsers] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (
      chartData &&
      chartData.existing_users &&
      chartData.new_users &&
      chartData.existing_users.length > 0 &&
      chartData.new_users.length > 0
    ) {
      const existingUsersData = chartData.existing_users.map((item) => ({
        start_day_of_month: formatMonth(item.start_day_of_month),
        user_status: capitalizeFirstLetter(item.user_status),
        Users: (parseInt(item.users, 10) || 0).toLocaleString(),
        MOU: (parseInt(item.mou, 10) || 0).toLocaleString(),
        MPU: (parseInt(item.mpu, 10) || 0).toLocaleString(),
      }));
      const newUserDetails = chartData.new_users.map((item) => ({
        start_day_of_month: formatMonth(item.start_day_of_month),
        user_status: capitalizeFirstLetter(item.user_status),
        Users: (parseInt(item.users, 10) || 0).toLocaleString(),
        MOU: (parseInt(item.mou, 10) || 0).toLocaleString(),
        MPU: (parseInt(item.mpu, 10) || 0).toLocaleString(),
      }));
      setExistingUsers(existingUsersData);
      setNewUsers(newUserDetails);
      setLoader(false);
      //   console.log("New Users", newUserDetails);
      //   console.log("Existing Users", existingUsersData);
      //   setLoader(false);
    }
  }, [chartData]);

  const headers = [
    { colSpan: 1, label: "Months", color: "#1B2431" },
    { colSpan: 3, label: "New Users", color: "#1B2431" },
    { colSpan: 3, label: "Existing Users", color: "#1B2431" },
  ];

  const subHeaders = ["Users", "MOU", "MPU"];

  return (
    <React.Fragment>
      {!loader ? (
        <div className="table-responsive sh-table-styling mb-4">
          <Table className="table-bordered table-nowrap mb-0" size="sm">
            <thead className="text-white text-center">
              <tr className="table-row sh-table-header">
                {headers.map((header, index) => (
                  <th
                    key={index}
                    colSpan={header.colSpan}
                    style={{ backgroundColor: header.color }}
                  >
                    {header.label}
                  </th>
                ))}
              </tr>
              <tr className="table-row sh-table-header">
                <th
                  style={{ backgroundColor: "transparent", color: "white" }}
                ></th>
                {subHeaders.map((subHeader, index) => (
                  <th
                    key={`new-${index}`}
                    style={{
                      backgroundColor: arrayColors[index],
                    }}
                  >
                    {subHeader}
                  </th>
                ))}
                {subHeaders.map((subHeader, index) => (
                  <th
                    key={`existing-${index}`}
                    style={{
                      backgroundColor: arrayColors[index],
                    }}
                  >
                    {subHeader}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {existingUsers.map((value, index) => {
                const isLastRow = index === existingUsers.length - 1;
                const rowStyle = isLastRow
                  ? {
                      backgroundColor: "#E8E8E8",
                      color: "#000000",
                      fontWeight: "700",
                      textAlign: "center",
                    }
                  : { textAlign: "center" };

                return (
                  <tr key={index} className="sh-table-text" style={rowStyle}>
                    <td style={{ textAlign: "left" }}>
                      {value.start_day_of_month}
                    </td>
                    <td>{newUsers[index]?.Users || "0"}</td>
                    <td>{newUsers[index]?.MOU || "0"}</td>
                    <td>{newUsers[index]?.MPU || "0"}</td>
                    <td>{value.Users}</td>
                    <td>{value.MOU}</td>
                    <td>{value.MPU}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "280px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default PaidUserTable;
