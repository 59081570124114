import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import { packwiseTableDataFromState } from "../../../../common/data/BusinessOverview/packwiseData";

const tableHeaderColors = [
  "#1B2431",
  "#78AD2F",
  "#2BA558",
  "#009978",
  "#00898D",
  "#007893",
];

// Define the desired order for the packs
const packOrder = [
  "Monthly",
  "Quarterly",
  "Half Yearly",
  "Yearly",
  "Two Yearly",
  "Total",
];

function PackwiseTable() {
  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [loader, setLoader] = useState(true);

  // Fetch status and data from the Redux store
  // const dataStatus = useSelector((state) => state.PackwiseContribution.status);
  // const packwiseTableDataFromState = useSelector(
  //   (state) => state.PackwiseContribution.tableData
  // );

  useEffect(() => {
    // Only process data if status is 'succeeded'
    if (loader && packwiseTableDataFromState?.length > 0) {
      const tableObject = packwiseTableDataFromState[0];
      const headerValues = Object.keys(tableObject);

      // Format the header names
      const formattedHeaderValues = headerValues.map((key) => {
        switch (key) {
          case "pack":
            return "Pack";
          case "subscription":
            return "Subscriptions";
          case "percentage_contribution":
            return "% Contribution";
          case "revenue":
            return "Revenue";
          case "arpu":
            return "ARPU";
          default:
            return key;
        }
      });

      // Sort the data based on the desired pack order
      const sortedTableData = [...packwiseTableDataFromState].sort((a, b) => {
        const packAIndex = packOrder.indexOf(a.pack);
        const packBIndex = packOrder.indexOf(b.pack);
        return packAIndex - packBIndex;
      });

      // Format data for the table
      const formattedTableData = sortedTableData.map((item) =>
        Object.values(item).map((value, index) => {
          // Convert string numbers to integers and format with commas
          if (typeof value === "string" && !isNaN(value)) {
            return Number(value).toLocaleString();
          }
          // Convert numbers to integers and format with commas
          if (typeof value === "number") {
            return value.toLocaleString();
          }
          return value ?? "N/A";
        })
      );

      // Set data and headers
      setTableData(formattedTableData);
      setTableHeaders(formattedHeaderValues);
      setLoader(false);
    }
  }, [packwiseTableDataFromState]);

  // Determine loading state based on the status
  // const isLoading = dataStatus === "loading";

  return (
    <React.Fragment>
      {loader ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "400px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="table-responsive ms-4 me-4 mt-4 sh-table-styling">
          <Table className="table-nowrap table-bordered mb-0" size="sm">
            <thead className="sh-table-header text-center">
              <tr className="table-row">
                {tableHeaders.map((header, index) => (
                  <th
                    scope="col"
                    style={{ backgroundColor: `${tableHeaderColors[index]}` }}
                    key={index}
                    className="text-center"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((eachRow, rowIndex) => {
                const packName = packwiseTableDataFromState[rowIndex].pack;
                const isTotalRow = packName === "Total";

                return (
                  <tr
                    key={rowIndex}
                    className="sh-table-text"
                    style={{
                      backgroundColor: isTotalRow ? "#E8E8E8" : "transparent",
                    }}
                  >
                    {eachRow.map((eachColumn, colIndex) => (
                      <td
                        key={colIndex}
                        className={
                          tableHeaders[colIndex] === "Pack"
                            ? "text-start align-middle"
                            : "text-center align-middle"
                        }
                      >
                        {eachColumn}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </React.Fragment>
  );
}

export default PackwiseTable;
