import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Card, Row, Col } from "reactstrap";

import {
  getLastMonthDateRange,
  getMTDDateRange,
} from "../../../helpers/dateUtils";
import DateDropdown from "../../HealthMatrix/DateDropdown";
import InstallAndUninstallChart from "./InstallAndUninstallChart";
import InstallAndUninstallTable from "./InstallAndUninstallTable";
import { getInstallAndUninstallData } from "../../../slices/HealthMatrix/InstallAndUninstallAnalysis/thunk";

const InstallAndUninstallAnalysis = () => {
  const dispatch = useDispatch();

  const [customDateRange, setCustomDateRange] = useState(
    getLastMonthDateRange()
  );
  const [selectedPlatform, setSelectedPlatform] = useState("all");

  const fetchData = async (dateRange, platform = selectedPlatform) => {
    const dataObject = {
      ...dateRange,
      // start_date: "2023-06-01",
      // end_date: "2024-06-19",
      period: "month",
      daily_wise_data: true,
      platforms: platform,
    };
    // dispatch(getInstallAndUninstallData(dataObject));
  };

  useEffect(() => {
    fetchData(customDateRange);
  }, [customDateRange, selectedPlatform]);

  const handleDateRangeChange = (range) => {
    setCustomDateRange(range);
  };

  const handleRadioChange = (e) => {
    const selectedFilterType = e.target.id;
    setSelectedPlatform(selectedFilterType);
    fetchData(customDateRange, selectedFilterType);
  };

  return (
    <React.Fragment>
      <Container fluid className="customContainerStyle">
        <Row>
          <Col md="12" style={{ padding: "20px" }}>
            <h4 className="card-heading-text">
              Install and Uninstall Analysis
            </h4>
          </Col>
          <Col md="12" className="d-flex justify-content-between">
            <div className="d-flex align-items-center ms-4">
              {["all", "android", "itunes", "tv", "mi"].map((platform) => (
                <div
                  className="d-flex justify-content-center me-2"
                  key={platform}
                >
                  <input
                    type="radio"
                    id={platform}
                    name="install_Uninstall"
                    className="sh-radio-button me-1"
                    defaultChecked={platform === "all"}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor={platform} className="sh-radio-text">
                    {platform.charAt(0).toUpperCase() +
                      platform.slice(1).replace(/Tv/, " TV")}
                  </label>
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-end m-2">
              <DateDropdown
                onDateRangeChange={handleDateRangeChange}
                options={["Last Month", "3 Months", "Custom"]}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="card-styling" style={{ height: "330px" }}>
              <Row>
                <Col md="6">
                  <InstallAndUninstallTable />
                </Col>
                <Col md="6">
                  <InstallAndUninstallChart dataColors='["#146588","#00898D"]' />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default InstallAndUninstallAnalysis;
