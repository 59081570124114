import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import { autoRenewalData as data } from "../../../common/data/RevenueMatrix/autoRenewalData";

const AutoRenewalTransactionsChart = ({ dataColors }) => {
  // const { data, loader } = useSelector(
  //   (state) => state.AutoRenewalTransactions
  // );

  const [revenueSeries, setRevenueSeries] = useState([]);
  const [subsSeries, setSubsSeries] = useState([]);
  const [successRateSeries, setSuccessRateSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loader, setLoader] = useState(true);

  const chartColumnStacked100Colors = getChartColorsArray(dataColors);

  useEffect(() => {
    if (!data) return;

    const chartData = { ...data }; // Create a copy of the data to avoid mutating original data
    const categories = Object.keys(chartData).filter(
      (category) => category !== "grand"
    ); // Filter out 'grand'
    const packsSet = new Set();

    // Collect all unique packwise types excluding "Total"
    categories.forEach((category) => {
      chartData[category]
        .filter((item) => item.packwise !== "Total") // Filter out "Total"
        .forEach((item) => {
          packsSet.add(item.packwise);
        });
    });

    const packs = Array.from(packsSet);
    const revenueSeries = [];
    const subsSeries = [];
    const successRateSeries = [];

    packs.forEach((pack) => {
      const revenueData = [];
      const subsData = [];
      const successRateData = [];
      categories.forEach((category) => {
        const categoryData = chartData[category]
          .filter((item) => item.packwise !== "Total") // Filter out "Total"
          .find((item) => item.packwise === pack);
        revenueData.push(categoryData ? parseInt(categoryData.revenue, 10) : 0);
        subsData.push(categoryData ? parseInt(categoryData.renewed, 10) : 0);
        successRateData.push(
          categoryData
            ? parseFloat(categoryData.success_rate.replace("%", ""))
            : 0
        );
      });
      revenueSeries.push({ name: pack, data: revenueData });
      subsSeries.push({ name: pack, data: subsData });
      successRateSeries.push({ name: pack, data: successRateData });
    });

    setRevenueSeries(revenueSeries);
    setSubsSeries(subsSeries);
    setSuccessRateSeries(successRateSeries);
    setCategories(categories);
    setLoader(false);
  }, [data]);

  const options = {
    chart: {
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val !== undefined
            ? parseInt(val.toFixed(0)).toLocaleString()
            : ""; // Format y-axis values in thousands
        },
      },
      min: 0,
    },
    xaxis: {
      categories: categories.map((category) => category.toUpperCase()),
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
    },
    colors: chartColumnStacked100Colors,
    tooltip: {
      enabled: true,
      // x: {
      //   show: true,
      //   formatter: function (val) {
      //     return val !== undefined ? val : ''; // Display actual values on x-axis in tooltip
      //   },
      // },
      y: {
        formatter: function (val) {
          return val !== undefined
            ? parseInt(val.toFixed(0)).toLocaleString()
            : ""; // Display actual values in tooltip without formatting
        },
      },
    },
  };

  return (
    <React.Fragment>
      {!loader ? (
        <ReactApexChart
          dir="ltr"
          className="apex-charts"
          series={successRateSeries}
          options={options}
          type="bar"
          height={450}
        />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "450px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AutoRenewalTransactionsChart;
