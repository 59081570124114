// import React, { useState } from "react";
// import { Button } from "reactstrap";

// const ToggleButton = () => {
//   const [isTotalSelected, setIsTotalSelected] = useState(true);

//   const selectTotal = () => {
//     setIsTotalSelected(true);
//   };

//   const selectPaid = () => {
//     setIsTotalSelected(false);
//   };

//   return (
//     <div
//       className="d-inline-flex align-items-center"
//       style={{
//         borderRadius: "24px",
//         border: "1px solid #999999",
//         backgroundColor: "#EFEFEF",
//       }}
//     >
//       <Button
//         color={isTotalSelected ? "primary" : "secondary"}
//         onClick={selectTotal}
//         style={{
//           borderRadius: "24px",
//           backgroundColor: isTotalSelected ? "#1b3449" : "#e9ebef",
//           color: isTotalSelected ? "white" : "grey",
//           border: "1px solid #e9ebef",
//           boxShadow: "none",
//         }}
//       >
//         Total
//       </Button>
//       <Button
//         color={!isTotalSelected ? "primary" : "secondary"}
//         onClick={selectPaid}
//         style={{
//           borderRadius: "24px",
//           backgroundColor: !isTotalSelected ? "#1b3449" : "#e9ebef",
//           color: !isTotalSelected ? "white" : "grey",
//           border: "1px solid #e9ebef",
//           boxShadow: "none",
//         }}
//       >
//         Paid
//       </Button>
//     </div>
//   );
// };

// export default ToggleButton;

import React, { useState } from "react";
import { Button } from "reactstrap";

const ToggleButton = ({ onToggle }) => {
  const [isTotalSelected, setIsTotalSelected] = useState(true);

  const selectTotal = () => {
    setIsTotalSelected(true);
    onToggle(true); // Notify parent component
  };

  const selectPaid = () => {
    setIsTotalSelected(false);
    onToggle(false); // Notify parent component
  };

  return (
    <div
      className="d-inline-flex align-items-center"
      style={{
        borderRadius: "24px",
        border: "1px solid #999999",
        backgroundColor: "#EFEFEF",
      }}
    >
      <Button
        color={isTotalSelected ? "primary" : "secondary"}
        onClick={selectTotal}
        style={{
          borderRadius: "24px",
          backgroundColor: isTotalSelected ? "#1b3449" : "#e9ebef",
          color: isTotalSelected ? "white" : "grey",
          border: "1px solid #e9ebef",
          boxShadow: "none",
        }}
      >
        Total
      </Button>
      <Button
        color={!isTotalSelected ? "primary" : "secondary"}
        onClick={selectPaid}
        style={{
          borderRadius: "24px",
          backgroundColor: !isTotalSelected ? "#1b3449" : "#e9ebef",
          color: !isTotalSelected ? "white" : "grey",
          border: "1px solid #e9ebef",
          boxShadow: "none",
        }}
      >
        Paid
      </Button>
    </div>
  );
};

export default ToggleButton;

