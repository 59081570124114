import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { hourlyData } from "../../../common/data/ConnsumptionInsights/hourlyData";
import { hourlyData as chartData } from "../../../common/data/ConnsumptionInsights/hourlyData";

const BasicHeatmap = ({ dataColors }) => {
  const [formattedHours, setFormattedHours] = useState([]);
  const [series, setSeries] = useState([]);
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  // const { chartData, loader, error } = useSelector(
  //   (state) => state.HourlyConsumptionAnalysis
  // );

  useEffect(() => {
    if (chartData) {
      const hours = Array.from(
        new Set(Object.values(chartData).flatMap(Object.keys))
      ).sort((a, b) => a - b);
      const dates = Object.keys(chartData);

      const formattedHours = hours.map((hour) => {
        const nextHour = (parseInt(hour, 10) + 1) % 24;
        return `${hour.padStart(2, "0")}:00 - ${nextHour
          .toString()
          .padStart(2, "0")}:00 hrs`;
      });

      setFormattedHours(formattedHours);

      const transformedData = formattedHours.map((formattedHour, index) => ({
        name: formattedHour,
        data: dates.map((date) => ({
          x: date,
          y: chartData[date][hours[index]] || 0, // use 0 or any default value if no data for specific hour
        })),
      }));

      setSeries(transformedData.reverse());
      // Reverse the transformed data
      setLoader(false);
    }
  }, [chartData]);

  const options = {
    chart: {
      height: 800,
      type: "heatmap",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: dataColors || ["#00B69B", "#FF0049"],
    title: {
      show: false,
      text: "",
      style: {
        fontWeight: 500,
      },
    },
    yaxis: {
      labels: {
        formatter: (val) => formattedHours[val] || val,
      },
    },
  };

  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="heatmap"
      height={800}
    />
  );
};

export { BasicHeatmap };
