import { createSlice } from "@reduxjs/toolkit";
import { getHourlyConsumptionAnalysisData } from "./thunk";

export const initialState = {
  chartData: [],
  loader: false,
  error: "",
};

const HourlyConsumptionAnalysisSlice = createSlice({
  name: "Hourly Consumption Analysis",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(
      getHourlyConsumptionAnalysisData.pending,
      (state, action) => {
        state.loader = true;
      }
    );
    builder.addCase(
      getHourlyConsumptionAnalysisData.fulfilled,
      (state, action) => {
        if (action.payload.success) {
          state.chartData = action.payload.data.chart_data;
          state.loader = false;
        }
      }
    );
    builder.addCase(
      getHourlyConsumptionAnalysisData.rejected,
      (state, action) => {
        state.error = action.error.message;
        state.loader = false;
      }
    );
  },
});

export default HourlyConsumptionAnalysisSlice.reducer;
