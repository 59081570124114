const totalPlb = 1000000;

const b2b = 500000;
const b2c = 400000;
const plbChartData = [
  {
    b2b: 12158,
    b2c: 1538,
    date: "2024-06-01T00:00:00.000Z",
  },
  {
    b2b: 14090,
    b2c: 1713,
    date: "2024-06-02T00:00:00.000Z",
  },
  {
    b2b: 15446,
    b2c: 1663,
    date: "2024-06-03T00:00:00.000Z",
  },
  {
    b2b: 14587,
    b2c: 1329,
    date: "2024-06-04T00:00:00.000Z",
  },
  {
    b2b: 12980,
    b2c: 1186,
    date: "2024-06-05T00:00:00.000Z",
  },
  {
    b2b: 13372,
    b2c: 1238,
    date: "2024-06-06T00:00:00.000Z",
  },
  {
    b2b: 13869,
    b2c: 1159,
    date: "2024-06-07T00:00:00.000Z",
  },
  {
    b2b: 14088,
    b2c: 902,
    date: "2024-06-08T00:00:00.000Z",
  },
  {
    b2b: 14710,
    b2c: 1286,
    date: "2024-06-09T00:00:00.000Z",
  },
  {
    b2b: 12711,
    b2c: 1630,
    date: "2024-06-10T00:00:00.000Z",
  },
  {
    b2b: 13284,
    b2c: 1395,
    date: "2024-06-11T00:00:00.000Z",
  },
  {
    b2b: 12208,
    b2c: 1279,
    date: "2024-06-12T00:00:00.000Z",
  },
  {
    b2b: 13375,
    b2c: 1061,
    date: "2024-06-13T00:00:00.000Z",
  },
  {
    b2b: 14369,
    b2c: 1005,
    date: "2024-06-14T00:00:00.000Z",
  },
  {
    b2b: 17446,
    b2c: 1040,
    date: "2024-06-15T00:00:00.000Z",
  },
  {
    b2b: 13331,
    b2c: 1187,
    date: "2024-06-16T00:00:00.000Z",
  },
  {
    b2b: 15788,
    b2c: 1384,
    date: "2024-06-17T00:00:00.000Z",
  },
  {
    b2b: 13441,
    b2c: 1195,
    date: "2024-06-18T00:00:00.000Z",
  },
];

export { totalPlb, b2b, b2c, plbChartData };
