import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import { titleChartData as chartData } from "../../../common/data/RevenueMatrix/titleContribution";

const ContributionPie = ({ dataColors }) => {
  var chartPieBasicColors = getChartColorsArray(dataColors);
  const [pieData, setPieData] = useState([]);
  const [labelsArray, setLabelsArray] = useState([]);
  const [loader, setLoader] = useState(true);
  // const { chartData, loader } = useSelector((state) => state.TitleContribution);

  useEffect(() => {
    if (chartData.length > 0) {
      // console.log("Title Contribution Chart Data", chartData);
      setPieData(
        chartData.map((item) => {
          let number = item.revenue;
          const roundedNumber = number;
          return roundedNumber;
        })
      );
      setLabelsArray(chartData.map((item) => item.title));
      setLoader(false);
    }
  }, [chartData]);

  //   const series = [15, 18, 15, 7, 9, 10, 6, 3, 7, 5, 5];
  const series = pieData;
  // console.log("series of Title Contribution", series);
  var options = {
    chart: {
      type: "pie",
    },
    markers: {
      size: 0,
    },
    labels: labelsArray,
    legend: {
      position: "right",
      horizontalAlign: "center",
    },
    dataLabels: {
      formatter: function (val, opts) {
        return parseFloat(val.toFixed(3)) + "%";
      },
      //   dropShadow: {
      //     enabled: false,
      //   },
    },
    colors: chartPieBasicColors,
    title: {
      text: "% Contribution",
      align: "center",
      offsetX: -100,
      // offsetY: 340,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#000000",
      },
    },
  };

  return (
    <React.Fragment>
      {!loader ? (
        <ReactApexChart
          dir="ltr"
          className="apex-charts pt-2"
          series={series}
          options={options}
          type="pie"
          height={350}
        />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "374px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ContributionPie;

{
  /* <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="pie"
      height="100%"
    /> */
}
