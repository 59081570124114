import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import ReactApexChart from "react-apexcharts";
import { formatMonthArray } from "../../../helpers/formatMonth";
import { data as streamDataFromState } from "../../../common/data/HealthMatrix/streamData";

const StreamChart = ({ dataColors }) => {
  var chartColors = getChartColorsArray(dataColors);
  const [streamData, setStreamData] = useState([]);
  const [didNotStreamData, setDidNotStreamData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [loader, setLoader] = useState(true);

  // const streamDataFromState = useSelector(
  //   (state) => state.StreamAndDidNotStream?.data
  // );

  // const { loader } = useSelector((state) => state.StreamAndDidNotStream);

  useEffect(() => {
    if (streamDataFromState) {
      setStreamData(
        streamDataFromState.daily_wise_data?.map((obj) => obj.stream)
      );
      setDidNotStreamData(
        streamDataFromState.daily_wise_data?.map((obj) => obj.not_stream)
      );

      const labels = streamDataFromState.daily_wise_data?.map(
        (obj) => obj.date
      );
      const formattedDates = labels.map(formatMonthArray);
      // console.log(formattedDates, "formattedDates");
      setLabels(formattedDates);
      setLoader(false);
    }
  }, [streamDataFromState]);

  var series = [
    {
      name: "Stream",
      data: streamData || [],
    },
    {
      name: "Did Not Stream",
      data: didNotStreamData || [],
    },
  ];

  var options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: chartColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 4,
      curve: "smooth",
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val !== undefined ? (val / 1000).toFixed(0) + "" : ""; // Format y-axis values in thousands
        },
      },
      title: {
        text: "DATA IN THOUSANDS",
      },
      min: 0,
      tickAmount: 6, // Adjust this as per your requirement
    },
    xaxis: {
      labels: {
        show: false,
        formatter: function (val) {
          return val !== undefined ? val : ""; // Display actual values on x-axis
        },
      },
      axisBorder: {
        show: true,
      },
      categories: labels,
    },
    markers: {
      size: 4,
      colors: chartColors,
      strokeColors: "#fff",
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    tooltip: {
      enabled: true,
      // x: {
      //   show: true,
      //   formatter: function (val) {
      //     return val !== undefined ? val : ""; // Display actual values on x-axis in tooltip
      //   },
      // },
      y: {
        formatter: function (val) {
          return val !== undefined
            ? parseInt(val.toFixed(0)).toLocaleString()
            : ""; // Display actual values in tooltip without formatting
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  return (
    <React.Fragment>
      {!loader ? (
        <ReactApexChart
          dir="ltr"
          height="320px"
          options={options}
          series={series}
          type="line"
          className="apex-charts"
        />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "320px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default StreamChart;
