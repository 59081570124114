// state.tableData = action.payload?.data?.chart_data;

const tabelData = [
  {
    period: "Daily",
    due_for_renewal: "0",
    success: "0",
    churn_out: "0",
    percentage_success: 0,
  },
  {
    period: "Half Yearly",
    due_for_renewal: "330",
    success: "150",
    churn_out: "200",
    percentage_success: 40.00,
  },
  {
    period: "Monthly",
    due_for_renewal: "7000",
    success: "1000",
    churn_out: "5000",
    percentage_success: 50.00,
  },
  {
    period: "Quarterly",
    due_for_renewal: "60000",
    success: "59000",
    churn_out: "5000",
    percentage_success: 90.00,
  },
  {
    period: "Two Yearly",
    due_for_renewal: "11000",
    success: "2000",
    churn_out: "9700",
    percentage_success: 18.00,
  },
  {
    period: "Weekly",
    due_for_renewal: "0",
    success: "0",
    churn_out: "0",
    percentage_success: 0,
  },
  {
    period: "Yearly",
    due_for_renewal: "66000",
    success: "33000",
    churn_out: "33000",
    percentage_success: 50.00,
  },
  {
    period: "Total",
    due_for_renewal: 150551,
    success: 96539,
    churn_out: 54012,
    percentage_success: "60.00",
  },
];

export { tabelData };
