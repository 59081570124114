import React, { useState, useEffect } from "react";
import getChartColorsArray from "../../../../Components/Common/ChartsDynamicColor";
import ReactApexChart from "react-apexcharts";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";


import { formatMonthArray } from "../../../../helpers/formatMonth";
import { dailyWiseData } from "../../../../common/data/BusinessOverview/acquisitionData";

const types = [
  "Performance Bollywood",
  "Performance HollyWood",
  "Total Performance", 
  "Auto Renewals",
  "Performance Tollywood",
];

const getDataByPeriod = (data, type, key) => {
  return {
    name: type,
    data: data
      .filter((item) => item.type === type)
      .map((item) => {
        const value = item[key];
        return isNaN(value) ? value : parseInt(value, 10);
      }),
  };
};

const AcquisitionChart = ({ dataColors, selectedValue }) => {
  var chartColors = getChartColorsArray(dataColors);
  const [loader, setLoader] = useState(true);

  // const dataStatus = useSelector((state) => state.Acquisition.status);
  // const dailyWiseData = useSelector((state) => state.Acquisition.chartData);

  const [subs, setSubs] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [spends, setSpends] = useState([]);
  const [cps, setCps] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    if (loader) {
      const subsData = types.map((type) =>
        getDataByPeriod(dailyWiseData, type, "subs")
      );
      const revenueData = types.map((type) =>
        getDataByPeriod(dailyWiseData, type, "revenue")
      );
      const spendsData = types.map((type) =>
        getDataByPeriod(dailyWiseData, type, "spends")
      );
      const cpsData = types.map((type) =>
        getDataByPeriod(dailyWiseData, type, "cps")
      );

      const labelsData = types.map((type) =>
        getDataByPeriod(dailyWiseData, type, "created_at_time")
      );

      const formattedDates = labelsData[0]?.data.map(formatMonthArray);

      setSubs(subsData);
      setRevenue(revenueData);
      setSpends(spendsData);
      setCps(cpsData);
      setLabels(formattedDates);
      setLoader(false);
    }
  }, [dailyWiseData]);

  const getSeries = (selectedValue) => {
    let series;
    switch (selectedValue) {
      case "subs":
        series = subs ? subs : [];
        break;
      case "revenue":
        series = revenue ? revenue : [];
        break;
      case "spends":
        series = spends ? spends : [];
        break;
      case "cps":
        series = cps ? cps : [];
        break;
      default:
        series = [];
    }
    return series;
  };

  var options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: chartColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 4,
      curve: "smooth",
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val !== undefined ? (val / 1000).toFixed(0) + "" : ""; // Format y-axis values in thousands
        },
      },
      title: {
        text: "DATA IN THOUSANDS",
      },
      min: 0,
      tickAmount: 6, // Adjust this as per your requirement
      // You can set specific min and max ranges
      // min: 0,
      // max: 50000, // Example max value, adjust based on your data
    },
    xaxis: {
      labels: {
        show: false, // Hide x-axis labels
        formatter: function (val) {
          return val !== undefined ? val : ""; // Display actual values on x-axis
        },
      },
      axisBorder: {
        show: false,
      },
      categories: labels,
    },
    markers: {
      size: 4,
      colors: chartColors,
      strokeColors: "#fff",
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    tooltip: {
      enabled: true,
      // x: {
      //   show: true,
      //   formatter: function (val) {
      //     return val !== undefined ? val : ''; // Display actual values on x-axis in tooltip
      //   },
      // },
      y: {
        formatter: function (val) {
          return val !== undefined
            ? parseInt(val.toFixed(0)).toLocaleString()
            : ""; // Display actual values in tooltip without formatting
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };
  return (
    <React.Fragment>
      {!loader ? (
        <ReactApexChart
          dir="ltr"
          height="100%"
          options={options}
          series={getSeries(selectedValue)}
          type="line"
          className="apex-charts"
        />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "330px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AcquisitionChart;
