import { createAsyncThunk } from "@reduxjs/toolkit";

// import needed API helpers.
import { getTotalMOU as getTotalMOUDataApi } from "../../helpers/fakebackend_helper";
import { getTotalMPU as getTotalMPUDataApi } from "../../helpers/fakebackend_helper";
import {getPaidMOU as getPaidMOUDataApi } from "../../helpers/fakebackend_helper";
import {getPaidMPU as getPaidMPUDataApi } from "../../helpers/fakebackend_helper";
import {getTop10Movies as getTop10MoviesApi } from "../../helpers/fakebackend_helper";

export const getTotalMOUData = createAsyncThunk(
  "MOUsOverview/totalMOUData",
  async (data) => {
    try {
      const response = getTotalMOUDataApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getTotalMPUData = createAsyncThunk(
  "MOUsOverview/totalMPUData",
  async (data) => {
    try {
      const response = getTotalMPUDataApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getPaidMOUData = createAsyncThunk(
  "MOUsOverview/paidMOUData",
  async (data) => {
    try {
      const response = getPaidMOUDataApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getPaidMPUData = createAsyncThunk(
  "MOUsOverview/paidMPUData",
  async (data) => {
    try {
      const response = getPaidMPUDataApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);


export const getTop10MoviesData = createAsyncThunk(
  "MOUsOverview/top10MoviesData",
  async (data) => {
    try {
      const response = getTop10MoviesApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);