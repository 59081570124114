import { createAsyncThunk } from "@reduxjs/toolkit";

import { getHourlyTrendIN as getHourlyTrendINApi } from "../../../helpers/fakebackend_helper";
export const getHourlyTrendINData = createAsyncThunk(
  "TodaysHighlights/HourlyTrendIN",
  async (data) => {
    try {
      const response = getHourlyTrendINApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
