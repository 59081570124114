// state.chartData = action.payload.data.chart_data;

const contentChartData = [
  {
    content_type: "show",
    streams: "30000",
    percentage_contribution_streams: 20,
    mou: 90000,
    percentage_contribution_mou: 20,
  },
  {
    content_type: "movie",
    streams: "30000",
    percentage_contribution_streams: 90,
    mou: 40000,
    percentage_contribution_mou: 90,
  },
  {
    content_type: "others",
    streams: "10000",
    percentage_contribution_streams: 50,
    mou: 30000,
    percentage_contribution_mou: 50,
  },
  {
    content_type: "clips",
    streams: "20000",
    percentage_contribution_streams: 20,
    mou: 10000,
    percentage_contribution_mou: 20,
  },
  {
    content_type: "total",
    streams: 70000,
    percentage_contribution_streams: 100,
    mou: 50000,
    percentage_contribution_mou: 100,
  },
];

export { contentChartData };
