import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import CountUp from "react-countup";
import { Card, Col, CardTitle, CardText } from "reactstrap";
import riseImage from "../../../assets/images/saranyuImages/Group 4.svg";
import downfallImage from "../../../assets/images/saranyuImages/Group 4 (1).svg";
import { color } from "highcharts";
import {
  totalMOUData,
  paidMOUData,
} from "../../../common/data/EcomDashboard/mouOverview";

const MOUsWidgets = (props) => {
  const { columnSpace } = props;
  const [widgets, setWidgets] = useState([]);
  const [loader1, setLoader1] = useState(true);
  const [loader2, setLoader2] = useState(true);
  // const chartLoaderMou = useSelector(
  //   (state) => state.MOUsOverviewData.chartLoaderMou
  // );
  // const paidMouWidgetLoader = useSelector(
  //   (state) => state.MOUsOverviewData.paidMouWidgetLoader
  // );

  // const totalMOUData = useSelector(
  //   (state) => state.MOUsOverviewData.totalMOUData
  // );

  // const paidMOUData = useSelector(
  //   (state) => state.MOUsOverviewData.paidMOUData
  // );

  useEffect(() => {
    if (totalMOUData && paidMOUData) {
      const widgets = [
        {
          chart_name: totalMOUData.chart_name,
          percentage: totalMOUData.percentage,
          chart_data: totalMOUData.chart_data,
        },
        {
          chart_name: paidMOUData.chart_name,
          percentage: paidMOUData.percentage,
          chart_data: paidMOUData.chart_data,
        },
      ];
      setWidgets(widgets);
      setLoader1(false);
      setLoader2(false);
    }
  }, [totalMOUData, paidMOUData]);

  function getSuffix(value) {
    switch (true) {
      case value >= 100000:
        return "L";
      case value >= 1000:
        return "K";
      default:
        return "";
    }
  }

  function getRoundedValue(value) {
    switch (true) {
      case value >= 100000:
        return Math.round(value / 100000);
      case value >= 1000:
        return Math.round(value / 1000);
      default:
        return value;
    }
  }

  return (
    <React.Fragment>
      {!loader1 && !loader2 ? (
        widgets.map((widgetCard, index) => {
          // const percentage = Number(widgetCard.percentage.toFixed(2));
          const percentage =
            widgetCard.percentage !== undefined
              ? Math.abs(Number(widgetCard.percentage.toFixed(2)))
              : 0;
          const isPositive = widgetCard.percentage > 0;
          return (
            <Col md={columnSpace} key={index}>
              <Card className="mou-widget-card d-flex justify-content-between flex-row">
                <div className="d-flex flex-column justify-content-between">
                  <CardTitle className="widget-name">
                    {widgetCard.chart_name}
                  </CardTitle>
                  <CardText
                    className="widget-percentage d-flex align-items-center mt-3"
                    style={{ color: isPositive ? "#00B69B" : "#FF0049" }}
                  >
                    <img
                      src={isPositive ? riseImage : downfallImage}
                      alt="trend indicator"
                    />
                    {percentage}%
                  </CardText>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center widget-counter">
                  <CountUp
                    start={0}
                    end={getRoundedValue(widgetCard.chart_data)}
                    decimals={1}
                    duration={4}
                    suffix={getSuffix(widgetCard.chart_data)}
                  />
                </div>
              </Card>
            </Col>
          );
        })
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100px" }}
        >
          <Col className="spinner-border" role="status" md="8">
            <span className="visually-hidden">Loading...</span>
          </Col>
        </div>
      )}
    </React.Fragment>
  );
};

export default MOUsWidgets;
