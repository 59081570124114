const paidMOUData = {
  chart_name: "Paid MAU",
  percentage: 30.329506134004404,
  chart_data: "265727",
};
const paidMPUData = {
  chart_name: "Paid MPU",
  percentage: 0,
  chart_data: {
    paid_mpu: 93.95243088054349,
    Users: "95089",
  },
};
const totalMOUData = {
  chart_name: "Total MOU",
  percentage: 5.811465410390299,
  chart_data: 10000000.905837797,
};
const MOUChartData = [
  {
    date: "2024-06-01",
    mou: 184281.8267184834,
  },
  {
    date: "2024-06-02",
    mou: 192501.0186408682,
  },
  {
    date: "2024-06-03",
    mou: 68960.9658117685,
  },
  {
    date: "2024-06-04",
    mou: 86982.7794732996,
  },
  {
    date: "2024-06-05",
    mou: 66182.4075307508,
  },
  {
    date: "2024-06-06",
    mou: 135195.9387703866,
  },
  {
    date: "2024-06-07",
    mou: 128508.969965567,
  },
  {
    date: "2024-06-08",
    mou: 131939.4308987383,
  },
  {
    date: "2024-06-09",
    mou: 133090.073283739,
  },
  {
    date: "2024-06-10",
    mou: 113688.0466976909,
  },
  {
    date: "2024-06-11",
    mou: 119610.2224701738,
  },
  {
    date: "2024-06-12",
    mou: 134738.0751952387,
  },
  {
    date: "2024-06-13",
    mou: 111328.9267076433,
  },
  {
    date: "2024-06-14",
    mou: 168881.9124852617,
  },
  {
    date: "2024-06-15",
    mou: 130632.795417526,
  },
  {
    date: "2024-06-16",
    mou: 167416.1702637596,
  },
  {
    date: "2024-06-17",
    mou: 171067.0662112876,
  },
  {
    date: "2024-06-18",
    mou: 198228.2792957202,
  },
];
const totalMPUData = {
  chart_name: "Total MPU",
  percentage: 0,
  chart_data: {
    total_mpu: 76.19017807632103,
    Users: "427815",
  },
};
const MPUChartData = [
  {
    total_mpu: 46.02795187146982,
    users: "18311",
    date: "2024-06-01",
  },
  {
    total_mpu: 46.584026723113595,
    users: "19857",
    date: "2024-06-02",
  },
  {
    total_mpu: 43.124380327169476,
    users: "15991",
    date: "2024-06-03",
  },
  {
    total_mpu: 38.41834633953007,
    users: "22641",
    date: "2024-06-04",
  },
  {
    total_mpu: 38.97687912430807,
    users: "16980",
    date: "2024-06-05",
  },
  {
    total_mpu: 52.362753738347465,
    users: "25819",
    date: "2024-06-06",
  },
  {
    total_mpu: 50.15928844518227,
    users: "25620",
    date: "2024-06-07",
  },
  {
    total_mpu: 49.01472790053622,
    users: "26917",
    date: "2024-06-08",
  },
  {
    total_mpu: 46.98122125551553,
    users: "28326",
    date: "2024-06-09",
  },
  {
    total_mpu: 48.52582839401509,
    users: "23445",
    date: "2024-06-10",
  },
  {
    total_mpu: 46.6068499595728,
    users: "24237",
    date: "2024-06-11",
  },
  {
    total_mpu: 48.19853354267654,
    users: "23543",
    date: "2024-06-12",
  },
  {
    total_mpu: 44.9967174146746,
    users: "24698",
    date: "2024-06-13",
  },
  {
    total_mpu: 46.96756991542774,
    users: "24887",
    date: "2024-06-14",
  },
  {
    total_mpu: 48.835384501089514,
    users: "26633",
    date: "2024-06-15",
  },
];
const top10MoviesData = [
  {
    video_name: " Kalki 2898 AD",
    Users: "1000",
    MOU: 4000.0724609999,
  },
  {
    video_name: "Maidaan",
    Users: "1000",
    MOU: 2000.52459068334,
  },
  {
    video_name: "Laapataa Ladies",
    Users: "9000",
    MOU: 10000.70307185,
  },
  {
    video_name: " Fighter",
    Users: "7000",
    MOU: 1000.9295685332,
  },
  {
    video_name: "Srikanth",
    Users: "6000",
    MOU: 2000.464438166666,
  },
  {
    video_name: "Sarfira",
    Users: "6000",
    MOU: 5000.95,
  },
  {
    video_name: "The Greatest of All Time",
    Users: "5000",
    MOU: 9000.08403333332,
  },
  {
    video_name: " Welcome to the Jungle",
    Users: "5000",
    MOU: 3000.477971816668,
  },
  {
    video_name: "Ek Anjaan Rishtey Ka Guilt 2",
    Users: "4000",
    MOU: 1000.7044110667,
  },
  {
    video_name: "Kanguva",
    Users: "3000",
    MOU: 4000.34538333334,
  },
];

export {
  paidMOUData,
  paidMPUData,
  totalMOUData,
  MOUChartData,
  totalMPUData,
  MPUChartData,
  top10MoviesData,
};
