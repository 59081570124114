import { createAsyncThunk } from "@reduxjs/toolkit";

import { getInorganicTransactions as getInorganicTransactionsApi } from "../../../helpers/fakebackend_helper";

export const getInorganicTransactionsData = createAsyncThunk(
  "RevenueMatrix/Inorganic Transactions",
  async (data) => {
    try {
      const response = getInorganicTransactionsApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
