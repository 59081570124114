import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import { formatMonthArray } from "../../../helpers/formatMonth";

const PLBAnalysisChart = ({ dataColors, series, labels }) => {
  var chartColors = getChartColorsArray(dataColors);

  const formattedDates = labels.map(formatMonthArray);
  var options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: chartColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 4,
      curve: "smooth",
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val !== undefined ? (val / 1000).toFixed(0) + "" : ""; // Format y-axis values in thousands
        },
      },
      title: {
        text: "DATA IN THOUSANDS",
      },
      min: 0,
      tickAmount: 6, // Adjust this as per your requirement
      // You can set specific min and max ranges
      // min: 0,
      // max: 50000, // Example max value, adjust based on your data
    },
    xaxis: {
      labels: {
        show: false,
        formatter: function (val) {
          return val !== undefined ? val : ""; // Display actual values on x-axis
        },
      },
      axisBorder: {
        show: false,
      },
      categories: formattedDates,
    },
    markers: {
      size: 4,
      colors: chartColors,
      strokeColors: "#fff",
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    tooltip: {
      enabled: true,
      // x: {
      //   show: true,
      //   formatter: function (val) {
      //     return val !== undefined ? val : ''; // Display actual values on x-axis in tooltip
      //   },
      // },
      y: {
        formatter: function (val) {
          return val !== undefined
            ? parseInt(val.toFixed(0)).toLocaleString()
            : ""; // Display actual values in tooltip without formatting
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  return (
    <ReactApexChart
      dir="ltr"
      height="100%"
      options={options}
      series={series}
      type="line"
      className="apex-charts"
    />
  );
};

export default PLBAnalysisChart;
