import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "reactstrap";
import { getGeoPerStateData } from "../../../slices/ConsumptionHighlights/GeoPerState/thunk";
import { selectStateData } from "../../../slices/ConsumptionHighlights/GeoPerState/selectors"; // Adjust the import path as necessary

const arrayColors = [
  "#1B2431",
  "#78AD2F",
  "#2BA558",
  "#009978",
  "#00898D",
  "#007893",
];

function GeoPerStateTable({ customDateRange, location }) {
  const dispatch = useDispatch();
  const { filter_type, ...locationData } = location;
  const locationName = Object.values(locationData)[0];

  const filters = useMemo(
    () => ({
      ...customDateRange,
      ...location,
    }),
    [customDateRange, location]
  );

  const stateData = useSelector((state) =>
    selectStateData(state, filter_type, locationName)
  );

  const [headerValues, setHeaderValues] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    dispatch(
      getGeoPerStateData({ filters, filterType: filter_type, locationName })
    );
  }, [dispatch, filters, filter_type, locationName]);

  useEffect(() => {
    if (stateData && stateData.length > 0) {
      const data = stateData.map((item) => ({
        Titles: item.video_name,
        Users: (parseInt(item.Users, 10) || 0).toLocaleString(),
        MOU: (parseInt(item.mou, 10) || 0).toLocaleString(),
        MPU: (parseInt(item.mpu, 10) || 0).toLocaleString(),
      }));
      const headers = Object.keys(data[0]);
      setHeaderValues(headers);
      setLoader(false);
    }
  }, [stateData]);

  return (
    <React.Fragment>
      {!loader ? (
        <div
          className="table-responsive sh-table-styling"
          style={{ margin: "20px" }}
        >
          <Table className="table-bordered table-nowrap mb-0" size="sm">
            <thead className="text-white text-center">
              <tr className="table-row sh-table-header">
                <th
                  colSpan={headerValues.length}
                  style={{ backgroundColor: "#1A3E5A", color: "white" }}
                >
                  {locationName}
                </th>
              </tr>
              <tr className="table-row sh-table-header">
                {headerValues.map((value, index) => (
                  <th
                    scope="col"
                    style={
                      index === 0
                        ? {
                            width: "50%",
                            backgroundColor: arrayColors[index],
                          }
                        : { backgroundColor: arrayColors[index] }
                    }
                    key={index}
                  >
                    {value}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {stateData.map((valueObj, index) => (
                <tr key={index} className="sh-table-text">
                  {Object.values(valueObj).map((value, i) => (
                    <td
                      key={i}
                      className="align-middle"
                      style={{ textAlign: i === 0 ? "left" : "center" }}
                    >
                      {value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "260px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default GeoPerStateTable;
