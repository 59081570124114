import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Card } from "reactstrap";
import SubscriptionsChart from "./subscriptionsChart";
import { getSubsNonGujaratiData } from "../../../slices/RevenueMatrix/Subscription/nonGujaratiThunk";
import { nonGujaratiChartData } from "../../../common/data/RevenueMatrix/subscriptionData";

function Gujarati({ selectedPlatform, selectedRegion, customDateRange }) {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    ...customDateRange,
    platforms: selectedPlatform,
    region: selectedRegion,
    filter_type: "all",
    type: "non_gujurati",
    daily_wise_data: true,
  });
  const [nonGujaratiData, setNonGujaratiData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("all");
  const [loader, setLoader] = useState(true);

  // const { nonGujaratiChartData, loader } = useSelector(
  //   (state) => state.SubsNonGujarati
  // );

  useEffect(() => {
    if (nonGujaratiChartData.length > 0) {
      setNonGujaratiData(nonGujaratiChartData);
      setLoader(false);
    }
  }, [nonGujaratiChartData]);

  // Update filters whenever selectedPlatform, selectedRegion, or customDateRange changes
  useEffect(() => {
    setFilters({
      // start_date: customDateRange.startDate,
      // end_date: customDateRange.endDate,
      ...filters,
      ...customDateRange,
      platforms: selectedPlatform,
      region: selectedRegion,
      filter_type: selectedOption,
      type: "non_gujurati",
    });
  }, [selectedPlatform, selectedRegion, customDateRange, selectedOption]);

  // Fetch data when filters or selectedOption change
  useEffect(() => {
    // dispatch(getSubsNonGujaratiData(filters));
    // console.log("non-gujarati dispatch");
  }, [filters]);

  const handleRadioChange = (event) => {
    const value = event.target.id;
    setSelectedOption(value);
  };

  return (
    <React.Fragment>
      <Col md="6">
        <Card className="card-styling">
          <Row>
            <Col style={{ padding: "20px" }}>
              <h4 className="card-heading-text">Non Gujarati</h4>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <div className="d-flex align-items-center">
                <div className="d-flex justify-content-center me-2">
                  <input
                    type="radio"
                    id="all"
                    name="non-gujarati-subscriptions"
                    className="sh-radio-button me-1"
                    checked={selectedOption === "all"}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="all" className="sh-radio-text">
                    All
                  </label>
                </div>
                <div className="d-flex justify-content-center me-2">
                  <input
                    type="radio"
                    id="performance"
                    name="non-gujarati-subscriptions"
                    className="sh-radio-button me-1"
                    checked={selectedOption === "performance"}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="performance" className="sh-radio-text">
                    Performance
                  </label>
                </div>
                <div className="d-flex justify-content-center me-2">
                  <input
                    type="radio"
                    id="organic"
                    name="non-gujarati-subscriptions"
                    className="sh-radio-button me-1"
                    checked={selectedOption === "organic"}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="organic" className="sh-radio-text">
                    Organic
                  </label>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="mb-3">
              <SubscriptionsChart
                dataColors='["#146588", "#FFA600","#2BA558"]'
                data={nonGujaratiData}
                loader={loader}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </React.Fragment>
  );
}

export default Gujarati;
