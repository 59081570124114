import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Card } from "reactstrap";

// import DateDropdown from "../../../helpers/DateDropdown"; // Adjusted import path
import DateDropdown from "../../HealthMatrix/DateDropdown";
import FilterSection from "../../RevenueMatrix/Subscription/filterSection"; // Adjusted import path
import OrganicTable from "./OrganicTable";
import OrganicChart from "./OrganicChart";
import { getLastMonthDateRange } from "../../../helpers/dateUtils";

import { getOrganicTransactionsData } from "../../../slices/RevenueMatrix/OrganicTransactions/thunk";
import { organicData as data } from "../../../common/data/RevenueMatrix/organicData";

const filterOptions = {
  platforms: {
    All: "android,ios,web ",
    Android: "android",
    Web: "web",
    // Tv: "external",
    iOS: "ios",
  },
  region: {
    All: "IN,ROW",
    India: "IN",
    ROW: "ROW",
  },
};

const OrganicTransactions = () => {
  const dispatch = useDispatch();

  const [customDateRange, setCustomDateRange] = useState(
    getLastMonthDateRange()
  );
  const [selectedValue, setSelectedValue] = useState("revenue");

  // const data = useSelector((state) => state.OrganicTransactions.data);

  const [filters, setFilters] = useState({
    start_date: customDateRange.startDate,
    end_date: customDateRange.endDate,
    daily_wise_data: true,
    platforms: filterOptions.platforms.All,
    region: filterOptions.region.All,
  });

  const fetchData = async (dateRange) => {
    const dataObject = {
      ...filters,
      ...dateRange,
    };
    // dispatch(getOrganicTransactionsData(dataObject));
  };

  useEffect(() => {
    fetchData(customDateRange);
  }, [customDateRange, filters]);

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const handleDateRangeChange = (range) => {
    setCustomDateRange(range);
    setFilters((prevFilters) => ({
      ...prevFilters,
      start_date: range.startDate,
      end_date: range.endDate,
    }));
  };

  const handleResetFilters = () => {
    setFilters({
      daily_wise_data: true,
      platforms: filterOptions.platforms.All,
      region: filterOptions.region.All,
    });
  };

  const handleRadioChange = (e) => {
    setSelectedValue(e.target.id);
  };

  return (
    <React.Fragment>
      <Container fluid className="customContainerStyle">
        <Row>
          <Col style={{ padding: "20px" }}>
            <h4 className="card-heading-text">Organic Transactions</h4>
          </Col>
        </Row>
        {data ? (
          <React.Fragment>
            <Row className="mb-4">
              <Col className="d-flex justify-content-between">
                <FilterSection
                  filterOptions={filterOptions}
                  filters={filters}
                  handleFilterChange={handleFilterChange}
                  handleResetFilters={handleResetFilters}
                />
                <DateDropdown
                  onDateRangeChange={handleDateRangeChange}
                  options={["Last Month", "MTD", "3 Months", "Custom"]}
                />
              </Col>
            </Row>
            <Row>
              <Col md="7">
                <OrganicTable
                  style={{ height: "450px", overflow: "hidden" }}
                ></OrganicTable>
              </Col>
              <Col md="5">
                <Card className="card-styling " style={{ height: "450px" }}>
                  <Row className="mt-2">
                    <Col className="d-flex justify-content-center">
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-center me-2">
                          <input
                            type="radio"
                            id="revenue"
                            name="organic"
                            className="sh-radio-button me-1"
                            defaultChecked
                            onChange={handleRadioChange}
                          />
                          <label htmlFor="revenue" className="sh-radio-text">
                            Revenue
                          </label>
                        </div>
                        <div className="d-flex justify-content-center me-2">
                          <input
                            type="radio"
                            id="subscriptions"
                            name="organic"
                            className="sh-radio-button me-1"
                            onChange={handleRadioChange}
                          />
                          <label
                            htmlFor="subscriptions"
                            className="sh-radio-text"
                          >
                            Subscriptions
                          </label>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <OrganicChart
                        dataColors='["#146588","#007893","#009978","#2BA558","#78AD2F"]'
                        selectedValue={selectedValue}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <div style={{ height: "350px" }}></div>
        )}
      </Container>
    </React.Fragment>
  );
};

export default OrganicTransactions;
