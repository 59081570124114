import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import {
  selectTodayResult,
  selectYesterdayResult,
  selectLastWeekResult,
} from "./selectors";
import {
  todayResult as reduxTodayResult,
  yesterdayResult as reduxYesterdayResult,
  lastWeekResult as reduxLastWeekResult,
} from "../../../common/data/TodaysInsights/hourlyIndiaData";

const IndiaChart = ({ dataColors, showSeriesType }) => {
  // const reduxTodayResult = useSelector(selectTodayResult);
  // const reduxYesterdayResult = useSelector(selectYesterdayResult);
  // const reduxLastWeekResult = useSelector(selectLastWeekResult);

  const [todayResult, setTodayResult] = useState([]);
  const [yesterdayResult, setYesterdayResult] = useState([]);
  const [lastWeekResult, setLastWeekResult] = useState([]);
  const [times, setTimes] = useState([]);

  const IndiaChartColors = getChartColorsArray(dataColors);

  useEffect(() => {
    if (reduxTodayResult && reduxYesterdayResult && reduxLastWeekResult) {
      const formatData = (data) => {
        if (!data || !data.daywise) return [];
        return Object.keys(data.daywise).map((key) => ({
          timestamp: key,
          revenue: data.daywise[key].revenue,
          transactions: data.daywise[key].transactions,
        }));
      };

      const formatTimes = (data) => {
        if (!data || !data.daywise) return [];
        return Object.keys(data.daywise).map((key) => {
          const date = new Date(key);
          const hours = date.getUTCHours().toString().padStart(2, "0");
          return `${hours}:00`;
        });
      };

      setTodayResult(formatData(reduxTodayResult));
      setYesterdayResult(formatData(reduxYesterdayResult));
      setLastWeekResult(formatData(reduxLastWeekResult));
      setTimes(formatTimes(reduxYesterdayResult));
    }
  }, [reduxTodayResult, reduxYesterdayResult, reduxLastWeekResult]);

  const revenueSeries = [
    {
      name: "Today",
      data: todayResult.map((item) => item.revenue),
    },
    {
      name: "Yesterday",
      data: yesterdayResult.map((item) => item.revenue),
    },
    {
      name: "Last Week This Day",
      data: lastWeekResult.map((item) => item.revenue),
    },
  ];

  const transactionsSeries = [
    {
      name: "Today",
      data: todayResult.map((item) => item.transactions),
    },
    {
      name: "Yesterday",
      data: yesterdayResult.map((item) => item.transactions),
    },
    {
      name: "Last Week This Day",
      data: lastWeekResult.map((item) => item.transactions),
    },
  ];

  const series =
    showSeriesType === "transactions" ? transactionsSeries : revenueSeries;

  const options = {
    chart: {
      height: 380,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: IndiaChartColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 3, 3],
      curve: "smooth",
    },
    markers: {
      size: 4,
      strokeColors: "#fff",
      strokeWidth: 2,
      hover: {
        sizeOffset: 2,
      },
    },
    xaxis: {
      categories: times,
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val !== undefined ? (val / 1000).toFixed(0) + "" : ""; // Format y-axis values in thousands
        },
      },
      title: {
        text: "DATA IN THOUSANDS",
      },
      min: 0,
      tickAmount: 6,
    },
    tooltip: {
      enabled: true,
      y: {
        show: true,
        title: {
          formatter: function (val) {
            return (
              val +
              (showSeriesType === "transactions" ? " transactions" : " revenue")
            );
          },
        },
        formatter: function (val) {
          return val !== undefined
            ? parseInt(val.toFixed(0)).toLocaleString()
            : ""; // Display actual values in tooltip without formatting
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  return (
    <ReactApexChart
      dir="ltr"
      options={options}
      series={series}
      type="line"
      height={360}
      className="apex-charts"
    />
  );
};

export { IndiaChart };
