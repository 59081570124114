// selectors.js
import { createSelector } from 'reselect';

const selectHourlyTrend = (state) => state.HourlyTrendROW;

export const selectTodayResult = createSelector(
  [selectHourlyTrend],
  (hourlyTrendROW) => hourlyTrendROW.todayResult
);

export const selectYesterdayResult = createSelector(
  [selectHourlyTrend],
  (hourlyTrendROW) => hourlyTrendROW.yesterdayResult
);

export const selectLastWeekResult = createSelector(
  [selectHourlyTrend],
  (hourlyTrendROW) => hourlyTrendROW.lastWeekResult
);
