import { createSlice } from "@reduxjs/toolkit";
import { getFTWData } from "./thunk";

export const initialState = {
  chartData: [],
  loader: false,
  error: "",
};

const FTWSlice = createSlice({
  name: "FTW",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getFTWData.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getFTWData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.chartData = action.payload.chart_data;
        state.loader = false;
      }
    });
    builder.addCase(getFTWData.rejected, (state, action) => {
      state.error = action.error.message;
      state.loader = false;
    });
  },
});

export default FTWSlice.reducer;
