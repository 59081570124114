import { createSlice } from "@reduxjs/toolkit";
import { getHourlyTrendINData } from "./thunk";

export const initialState = {
  todayResult: {},
  yesterdayResult: {},
  lastWeekResult: {},
  error: "",
  loader: false,
};

const HourlyTrendINSlice = createSlice({
  name: "HourlyTrendIN",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHourlyTrendINData.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getHourlyTrendINData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.loader = false;
        state.todayResult = action.payload.todayResult;
        state.yesterdayResult = action.payload.yesterdayResult;
        state.lastWeekResult = action.payload.lastWeekResult;
      }
    });
    builder.addCase(getHourlyTrendINData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });
  },
});

export default HourlyTrendINSlice.reducer;
