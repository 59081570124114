import { createSlice } from "@reduxjs/toolkit";
import { getContentTypeData } from "./thunk";

export const initialState = {
  chartData: [],
  loader: false,
  error: "",
};

const ContentTypeAnalysisSlice = createSlice({
  name: "ContentTypeAnalysis",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getContentTypeData.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getContentTypeData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.chartData = action.payload.data.chart_data;
        //   console.log(action.payload.data.chart_data);
        state.loader = false;
      }
    });
    builder.addCase(getContentTypeData.rejected, (state, action) => {
      state.error = action.error.message;
      state.loader = false;
    });
  },
});

export default ContentTypeAnalysisSlice.reducer;
