import { createAsyncThunk } from "@reduxjs/toolkit";

// import needed API helpers.
import { getInstallAndUninstall as getInstallAndUninstallApi } from "../../../helpers/fakebackend_helper";

export const getInstallAndUninstallData = createAsyncThunk(
  "HealthMatrix/InstallAndUninstallAnalysis",
  async (data) => {
    try {
      //console.log("Calling getInstallAndUninstall");
      const response = getInstallAndUninstallApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
