import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import { ftwData as chartData } from "../../../common/data/ConnsumptionInsights/ftwData";

const arrayColors = [
  "#1B2431",
  "#78AD2F",
  "#2BA558",
  "#009978",
  "#00898D",
  "#007893",
];

function FTWTable() {
  const [tableData, setTableData] = useState([]);
  const [headerValues, setHeaderValues] = useState([]);
  const [loader, setLoader] = useState(true);
  //   const [loader, setLoader] = useState(true);

  // const { chartData, loader } = useSelector((state) => state.FTW);
  //   console.log("ChartData of Content Type Analysis", chartData);

  useEffect(() => {
    if (chartData && chartData.length > 0) {
      const data = chartData.map((item) => ({
        "Titles Name": item.title,
        MOU: parseFloat(
          (parseFloat(item.mou) || 0).toFixed(2)
        ).toLocaleString(),
        Users: (parseInt(item.count, 10) || 0).toLocaleString(),
        MPU: parseFloat(
          (parseFloat(item.mpu) || 0).toFixed(2)
        ).toLocaleString(),
      }));
      const headers = Object.keys(data[0]);
      //   console.log("Headers", headers);
      setHeaderValues(headers);
      const contentTypes = ["show", "movie", "clips", "others", "total"];
      //   const formattedData = contentTypes.map((type) => {
      //     const item = chartData.find((item) => item.content_type === type);
      //     return [
      //       item.content_type.charAt(0).toUpperCase() +
      //         item.content_type.slice(1),
      //       (parseInt(item.streams, 10) || 0).toLocaleString(),
      //       (
      //         parseInt(item.percentage_contribution_streams, 10) || 0
      //       ).toLocaleString(),
      //       (parseInt(item.mou, 10) || 0).toLocaleString(),
      //       (
      //         parseInt(item.percentage_contribution_mou, 10) || 0
      //       ).toLocaleString(),
      //     ];
      //   });
      //   console.log(data);
      setTableData(data);
      setLoader(false);
    }
  }, [chartData]);
  return (
    <React.Fragment>
      {!loader ? (
        <div className="table-responsive sh-table-styling">
          <Table className="table-bordered table-nowrap mb-0" size="sm">
            <thead className="text-white text-center">
              <tr className="table-row sh-table-header">
                {headerValues.map((value, index) => (
                  <th
                    scope="col"
                    style={
                      index === 0
                        ? { width: "70%", backgroundColor: arrayColors[index] }
                        : { backgroundColor: arrayColors[index] }
                    }
                    key={index}
                  >
                    {value}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((valueObj, index) => (
                <tr key={index} className="sh-table-text">
                  {Object.values(valueObj).map((value, i) => (
                    <td
                      key={i}
                      className="align-middle"
                      style={{ textAlign: i === 0 ? "left" : "center" }}
                    >
                      {value}
                    </td>
                  ))}
                  {/* {valuesArr.map((value, i) => (
                    <td
                      key={i}
                      className="align-middle"
                      style={{ textAlign: i === 0 ? "left" : "center" }}
                    >
                      {value}
                    </td>
                  ))} */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "350px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default FTWTable;
