// state.chartData = action.payload.data.chart_data;

const hourlyData = {
  "2024-01-11": {
    0: 2,
    1: 4,
    2: 6,
    3: 8,
    4: 10,
    5: 12,
    6: 14,
    7: 16,
    8: 18,
    9: 20,
    10: 24,
    11: 28,
    12: 32,
    13: 38,
    14: 44,
    15: 52,
    16: 42,
    17: 100,
    18: 80,
    19: 44,
    20: 24,
    21: 8,
    22: 4,
    23: 2,
  },
  "2024-01-12": {
    0: 2,
    1: 4,
    2: 6,
    3: 8,
    4: 10,
    5: 12,
    6: 14,
    7: 16,
    8: 18,
    9: 20,
    10: 24,
    11: 28,
    12: 32,
    13: 38,
    14: 44,
    15: 52,
    16: 42,
    17: 100,
    18: 80,
    19: 44,
    20: 24,
    21: 8,
    22: 4,
    23: 2,
  },
  "2024-01-13": {
    0: 2,
    1: 4,
    2: 6,
    3: 8,
    4: 10,
    5: 12,
    6: 14,
    7: 16,
    8: 18,
    9: 20,
    10: 24,
    11: 28,
    12: 32,
    13: 38,
    14: 44,
    15: 52,
    16: 42,
    17: 100,
    18: 80,
    19: 44,
    20: 24,
    21: 8,
    22: 4,
    23: 2,
  },
  "2024-01-14": {
    0: 2,
    1: 4,
    2: 6,
    3: 8,
    4: 10,
    5: 12,
    6: 14,
    7: 16,
    8: 18,
    9: 20,
    10: 24,
    11: 28,
    12: 32,
    13: 38,
    14: 44,
    15: 52,
    16: 42,
    17: 100,
    18: 80,
    19: 44,
    20: 24,
    21: 8,
    22: 4,
    23: 2,
  },
  "2024-01-15": {
    0: 2,
    1: 4,
    2: 6,
    3: 8,
    4: 10,
    5: 12,
    6: 14,
    7: 16,
    8: 18,
    9: 20,
    10: 24,
    11: 28,
    12: 32,
    13: 38,
    14: 44,
    15: 52,
    16: 42,
    17: 100,
    18: 80,
    19: 44,
    20: 24,
    21: 8,
    22: 4,
    23: 2,
  },
  "2024-01-16": {
    0: 2,
    1: 4,
    2: 6,
    3: 8,
    4: 10,
    5: 12,
    6: 14,
    7: 16,
    8: 18,
    9: 20,
    10: 24,
    11: 28,
    12: 32,
    13: 38,
    14: 44,
    15: 52,
    16: 42,
    17: 100,
    18: 80,
    19: 44,
    20: 24,
    21: 8,
    22: 4,
    23: 2,
  },
  "2024-01-17": {
    0: 2,
    1: 4,
    2: 6,
    3: 8,
    4: 10,
    5: 12,
    6: 14,
    7: 16,
    8: 18,
    9: 20,
    10: 24,
    11: 28,
    12: 32,
    13: 38,
    14: 44,
    15: 52,
    16: 42,
    17: 100,
    18: 80,
    19: 44,
    20: 24,
    21: 8,
    22: 4,
    23: 2,
  },
  "2024-03-12": {
    0: 4,
    1: 9,
    2: 10,
    3: 15,
    4: 24,
    5: 17,
    6: 37,
    7: 51,
    8: 64,
    9: 85,
    10: 56,
    11: 47,
    12: 41,
    13: 58,
    14: 92,
    15: 79,
    16: 130,
    17: 135,
    18: 136,
    19: 71,
    20: 41,
    21: 20,
    22: 9,
    23: 8,
  },
  "2024-03-13": {
    0: 2,
    1: 5,
    2: 5,
    3: 17,
    4: 30,
    5: 39,
    6: 60,
    7: 148,
    8: 79,
    9: 95,
    10: 67,
    11: 52,
    12: 47,
    13: 55,
    14: 62,
    15: 78,
    16: 132,
    17: 153,
    18: 99,
    19: 93,
    20: 49,
    21: 26,
    22: 11,
    23: 4,
  },
  "2024-03-14": {
    0: 2,
    1: 4,
    2: 6,
    3: 8,
    4: 10,
    5: 12,
    6: 14,
    7: 16,
    8: 18,
    9: 20,
    10: 24,
    11: 28,
    12: 32,
    13: 38,
    14: 44,
    15: 52,
    16: 42,
    17: 100,
    18: 80,
    19: 44,
    20: 24,
    21: 8,
    22: 4,
    23: 2,
  },
  "2024-03-15": {
    0: 2,
    1: 4,
    2: 7,
    3: 11,
    4: 22,
    5: 40,
    6: 33,
    7: 56,
    8: 88,
    9: 79,
    10: 68,
    11: 72,
    12: 42,
    13: 60,
    14: 42,
    15: 66,
    16: 71,
    17: 115,
    18: 109,
    19: 105,
    20: 40,
    21: 16,
    22: 20,
    23: 7,
  },
  "2024-03-16": {
    0: 2,
    1: 4,
    2: 6,
    3: 8,
    4: 10,
    5: 12,
    6: 14,
    7: 16,
    8: 18,
    9: 20,
    10: 24,
    11: 28,
    12: 32,
    13: 38,
    14: 44,
    15: 52,
    16: 42,
    17: 100,
    18: 80,
    19: 44,
    20: 24,
    21: 8,
    22: 4,
    23: 2,
  },
  "2024-03-18": {
    0: 2,
    1: 6,
    2: 9,
    3: 17,
    4: 17,
    5: 21,
    6: 25,
    7: 36,
    8: 57,
    9: 56,
    10: 54,
    11: 48,
    12: 46,
    13: 26,
    14: 48,
    15: 76,
    16: 108,
    17: 134,
    18: 118,
    19: 98,
    20: 34,
    21: 11,
    22: 5,
    23: 3,
  },
  "2024-03-19": {
    0: 3,
    1: 8,
    2: 7,
    3: 8,
    4: 11,
    5: 22,
    6: 37,
    7: 62,
    8: 69,
    9: 108,
    10: 79,
    11: 56,
    12: 26,
    13: 55,
    14: 39,
    15: 69,
    16: 119,
    17: 117,
    18: 112,
    19: 98,
    20: 48,
    21: 7,
    22: 6,
    23: 8,
  },
  "2024-03-20": {
    0: 2,
    1: 4,
    2: 6,
    3: 8,
    4: 10,
    5: 12,
    6: 14,
    7: 16,
    8: 18,
    9: 20,
    10: 24,
    11: 28,
    12: 32,
    13: 38,
    14: 44,
    15: 52,
    16: 42,
    17: 100,
    18: 80,
    19: 44,
    20: 24,
    21: 8,
    22: 4,
    23: 2,
  },
  "2024-03-21": {
    0: 3,
    1: 7,
    2: 25,
    3: 7,
    4: 22,
    5: 24,
    6: 33,
    7: 38,
    8: 65,
    9: 80,
    10: 80,
    11: 61,
    12: 54,
    13: 46,
    14: 57,
    15: 71,
    16: 90,
    17: 107,
    18: 90,
    19: 91,
    20: 29,
    21: 13,
    22: 3,
    23: 6,
  },
  "2024-03-22": {
    1: 6,
    2: 13,
    3: 11,
    4: 17,
    5: 29,
    6: 24,
    7: 62,
    8: 91,
    9: 69,
    10: 58,
    11: 56,
    12: 35,
    13: 43,
    14: 32,
    15: 52,
    16: 76,
    17: 103,
    18: 86,
    19: 79,
    20: 25,
    21: 11,
    22: 3,
    23: 1,
  },
  "2024-03-23": {
    0: 3,
    1: 4,
    2: 5,
    3: 5,
    4: 14,
    5: 34,
    6: 47,
    7: 64,
    8: 72,
    9: 60,
    10: 17,
    11: 18,
    12: 23,
    13: 12,
    14: 10,
    15: 12,
    16: 18,
    17: 22,
    18: 46,
    19: 78,
    20: 37,
    21: 19,
    22: 11,
    23: 6,
  },
  "2024-03-24": {
    0: 3,
    1: 2,
    2: 5,
    3: 11,
    4: 14,
    5: 25,
    6: 26,
    7: 56,
    8: 73,
    9: 70,
    10: 76,
    11: 33,
    12: 27,
    13: 21,
    14: 31,
    15: 34,
    16: 54,
    17: 113,
    18: 104,
    19: 92,
    20: 55,
    21: 17,
    22: 13,
    23: 4,
  },
  "2024-03-25": {
    0: 2,
    1: 4,
    2: 6,
    3: 8,
    4: 10,
    5: 12,
    6: 14,
    7: 16,
    8: 18,
    9: 20,
    10: 24,
    11: 28,
    12: 32,
    13: 38,
    14: 44,
    15: 52,
    16: 42,
    17: 100,
    18: 80,
    19: 44,
    20: 24,
    21: 8,
    22: 4,
    23: 2,
  },
  "2024-03-26": {
    0: 2,
    1: 6,
    2: 6,
    3: 13,
    4: 11,
    5: 28,
    6: 27,
    7: 39,
    8: 78,
    9: 41,
    10: 54,
    11: 61,
    12: 44,
    13: 29,
    14: 39,
    15: 67,
    16: 71,
    17: 78,
    18: 88,
    19: 47,
    20: 32,
    21: 15,
    22: 9,
    23: 2,
  },
  "2024-03-27": {
    0: 1,
    2: 11,
    3: 11,
    4: 8,
    5: 17,
    6: 29,
    7: 42,
    8: 60,
    9: 66,
    10: 58,
    11: 64,
    12: 26,
    13: 18,
    14: 35,
    15: 47,
    16: 75,
    17: 92,
    18: 89,
    19: 72,
    20: 33,
    21: 19,
    22: 10,
    23: 4,
  },
  "2024-03-28": {
    1: 3,
    2: 10,
    3: 9,
    4: 17,
    5: 21,
    6: 27,
    7: 34,
    8: 80,
    9: 84,
    10: 52,
    11: 34,
    12: 39,
    13: 40,
    14: 58,
    15: 122,
    16: 81,
    17: 78,
    18: 100,
    19: 64,
    20: 30,
    21: 18,
    23: 2,
  },
  "2024-03-29": {
    0: 2,
    1: 4,
    2: 6,
    3: 8,
    4: 10,
    5: 12,
    6: 14,
    7: 16,
    8: 18,
    9: 20,
    10: 24,
    11: 28,
    12: 32,
    13: 38,
    14: 44,
    15: 52,
    16: 42,
    17: 100,
    18: 80,
    19: 44,
    20: 24,
    21: 8,
    22: 4,
    23: 2,
  },
  "2024-03-30": {
    0: 2,
    1: 8,
    2: 17,
    3: 18,
    4: 22,
    5: 26,
    6: 63,
    7: 52,
    8: 75,
    9: 75,
    10: 85,
    11: 51,
    12: 49,
    13: 48,
    14: 44,
    15: 42,
    16: 75,
    17: 121,
    18: 124,
    19: 79,
    20: 40,
    21: 14,
    22: 12,
    23: 4,
  },
  "2024-03-31": {
    0: 1,
    1: 7,
    2: 6,
    3: 7,
    4: 6,
    5: 26,
    6: 68,
    7: 68,
    8: 70,
    9: 74,
    10: 55,
    11: 47,
    12: 35,
    13: 23,
    14: 38,
    15: 54,
    16: 87,
    17: 87,
    18: 116,
    19: 71,
    20: 20,
    21: 12,
  },
};

export { hourlyData };
