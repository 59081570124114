// data: [],
// state.data = action.payload?.data;
const data = {
  chart_name: "Install Uninstall",
  chart_data: [
    {
      Months: "Jan 24",
      Install: 195000,
      Uninstall: 220000,
      "Uninstall/install": "-",
    },
    {
      Months: "Feb 24",
      Install: 130000,
      Uninstall: 188000,
      "Uninstall/install": "-",
    },
    {
      Months: "Mar 24",
      Install: 160000,
      Uninstall: 200000,
      "Uninstall/install": "-",
    },
    {
      Months: "Apr 24",
      Install: 290000,
      Uninstall: 2700000,
      "Uninstall/install": "94.98%",
    },
    {
      Months: "May 24",
      Install: 380000,
      Uninstall: 340000,
      "Uninstall/install": "90.61%",
    },
    {
      Months: "Jun 24",
      Install: 250000,
      Uninstall: 240000,
      "Uninstall/install": "93.38%",
    },
  ],
};
const chartData = [
  {
    Months: "Jan 24",
    Install: 190000,
    Uninstall: 220000,
    "Uninstall/install": "-",
  },
  {
    Months: "Feb 24",
    Install: 130000,
    Uninstall: 180000,
    "Uninstall/install": "-",
  },
  {
    Months: "Mar 24",
    Install: 160000,
    Uninstall: 200000,
    "Uninstall/install": "-",
  },
  {
    Months: "Apr 24",
    Install: 290000,
    Uninstall: 270000,
    "Uninstall/install": "94.98%",
  },
  {
    Months: "May 24",
    Install: 300000,
    Uninstall: 340000,
    "Uninstall/install": "90.61%",
  },
  {
    Months: "Jun 24",
    Install: 250000,
    Uninstall: 240000,
    "Uninstall/install": "93.38%",
  },
];

export { data, chartData };
