import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTopTransactingRegions as getTopTransactingRegionsApi } from "../../../helpers/fakebackend_helper";
import { getTopTransactingCountries as getTopTransactingCountriesApi } from "../../../helpers/fakebackend_helper";

export const getTopTRegionsData = createAsyncThunk(
  "Todays Highlights/Top Transacting Regions",
  async (data) => {
    try {
      const response = getTopTransactingRegionsApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getTopTCountriesData = createAsyncThunk(
  "Todays Highlights/Top Transacting Countries",
  async (data) => {
    try {
      const response = getTopTransactingCountriesApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
