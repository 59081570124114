import { createAsyncThunk } from "@reduxjs/toolkit";

import { getHourlyTrendROW as getHourlyTrendROWApi } from "../../../helpers/fakebackend_helper";
export const getHourlyTrendROWData = createAsyncThunk(
  "TodaysHighlights/HourlyTrendROW",
  async (data) => {
    try {
      const response = getHourlyTrendROWApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
