import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody } from "reactstrap";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
const dataColors = '["#146588","#00898D","#2BA558","#BAAF00","#FFA600"]';
import {
  totalDau,
  paidDau,
} from "../../../common/data/BusinessOverview/dauChartData";

import { formatMonth } from "../../../helpers/formatMonth";

const DauChart = ({ dataColors }) => {
  const [totalDauData, setTotalDauData] = useState();
  const [paidDauData, setPaidDauData] = useState();
  const [labels, setLabels] = useState();
  const [loader1, setLoader1] = useState(true);
  const [loader2, setLoader2] = useState(true);

  // const totalDau = useSelector((state) => state.DauChart?.totalDau);
  // const paidDau = useSelector(
  //   (state) => state.BusinessOverviewWidgets?.paidDau
  // );
  // const loader1 = useSelector((state) => state.DauChart.loader);
  // //  console.log("Dau chart PaidDau ssss", paidDau);
  // const loader2 = useSelector((state) => state.BusinessOverviewWidgets.loader);

  useEffect(() => {
    if (totalDau && paidDau) {
      const totalDauChartData = totalDau.map((data) => data.dau);
      const paidDauChartData = paidDau.map((data) => data.pdau);
      const labels = paidDau.map((data) => formatMonth(data.date));
      setTotalDauData(totalDauChartData);
      setPaidDauData(paidDauChartData);
      setLabels(labels);
      setLoader1(false);
      setLoader2(false);
    }
  }, [totalDau, paidDau]);

  var GradientChartsColors = getChartColorsArray(dataColors);

  const series = [
    {
      name: "Total DAU",
      data: totalDauData ? totalDauData : [],
    },
    {
      name: "Paid DAU",
      data: paidDauData ? paidDauData : [],
    },
  ];
  var options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 4,
      curve: "smooth",
    },
    colors: GradientChartsColors,
    yaxis: {
      labels: {
        formatter: function (val) {
          return val !== undefined ? (val / 1000).toFixed(0) + "" : ""; // Format y-axis values in thousands
        },
      },
      title: {
        text: "DATA IN THOUSANDS",
      },
      min: 0,
      tickAmount: 6, // Adjust this as per your requirement
      // You can set specific min and max ranges
      // min: 0,
      // max: 50000, // Example max value, adjust based on your data
    },
    xaxis: {
      labels: {
        show: false,
        formatter: function (val) {
          return val !== undefined ? val : ""; // Display actual values on x-axis
        },
      },
      axisBorder: {
        show: false,
      },
      categories: labels,
    },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shade: "dark",
    //     gradientToColors: ["#0ab39c"],
    //     shadeIntensity: 1,
    //     type: "horizontal",
    //     opacityFrom: 1,
    //     opacityTo: 1,
    //     stops: [0, 100, 100, 100],
    //   },
    // },
    markers: {
      size: 4,
      colors: GradientChartsColors,
      strokeColors: "#fff",
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    tooltip: {
      enabled: true,
      // x: {
      //   show: true,
      //   formatter: function (val) {
      //     return val !== undefined ? val : ''; // Display actual values on x-axis in tooltip
      //   },
      // },
      y: {
        formatter: function (val) {
          return val !== undefined
            ? parseInt(val.toFixed(0)).toLocaleString()
            : ""; // Display actual values in tooltip without formatting
        },
      },
    },
  };

  return (
    <React.Fragment>
      {!loader1 && !loader2 ? (
        <Card className="dau-chart-card card-styling">
          <CardBody>
            <h4
              className="card-title card-heading-text mb-0"
              style={{ color: "#1B2431" }}
            >
              DAU
            </h4>
            {/* <div> */}
            <ReactApexChart
              dir="ltr"
              options={options}
              series={series}
              type="line"
              height={400}
              className="apex-charts"
            />
            {/* </div> */}
          </CardBody>
        </Card>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "500px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export { DauChart };
