import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Table } from "reactstrap";

const tableHeaderColors = ["#1B2431", "#78AD2F", "#2BA558"];
import { streamChartData as streamTableDataFromState } from "../../../common/data/HealthMatrix/streamData";

function StreamTableCard() {
  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [loader, setLoader] = useState(true);

  // const streamTableDataFromState = useSelector(
  //   (state) => state.StreamAndDidNotStream?.data?.chart_data
  // );

  // const { loader } = useSelector((state) => state.StreamAndDidNotStream);

  useEffect(() => {
    if (streamTableDataFromState && streamTableDataFromState.length > 0) {
      const tableObject = streamTableDataFromState[0];
      const headerValues = Object.keys(tableObject);

      const formattedHeaderValues = headerValues.map((key) => {
        switch (key) {
          case "content_type":
            return "Content Type";
          case "count":
            return "Users";
          case "percentage_contribution":
            return "% Contribution";
          default:
            return key;
        }
      });

      const formattedTableData = streamTableDataFromState.map((item) =>
        Object.values(item).map((value, index) => {
          if (headerValues[index] === "percentage_contribution") {
            return `${value}%`;
          } else if (!isNaN(value)) {
            return Number(parseInt(value)).toLocaleString();
          }
          return value;
        })
      );

      setTableData(formattedTableData);
      setTableHeaders(formattedHeaderValues);
      setLoader(false);
    }
  }, [streamTableDataFromState]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          {!loader ? (
            <div className="table-responsive ms-4 me-4 mt-4 sh-table-styling">
              <Table className="table-nowrap table-bordered mb-0" size="md">
                <thead className="sh-table-header text-center">
                  <tr className="table-row">
                    {tableHeaders.map((header, index) => (
                      <th
                        scope="col"
                        style={{
                          backgroundColor: `${tableHeaderColors[index]}`,
                        }}
                        key={index}
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((eachRow, rowIndex) => (
                    <tr
                      key={rowIndex}
                      className="sh-table-text"
                      style={
                        rowIndex === tableData.length - 1
                          ? {
                              backgroundColor: "#E8E8E8",
                              fontWeight: 700,
                            }
                          : {}
                      }
                    >
                      {eachRow.map((eachColumn, colIndex) => (
                        <td
                          key={colIndex}
                          className={
                            tableHeaders[colIndex] === "Content Type"
                              ? "text-start align-middle"
                              : "text-center align-middle"
                          }
                        >
                          {eachColumn}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "300px" }}
            >
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default StreamTableCard;
