const geoChartData = [
  {
    city: "Ahmedabad",
    users: "1015",
    pc_users: 30,
    mou: 500000,
    pc_mou: 35,
    mpu: 475,
  },
  {
    city: "Surat",
    users: "1015",
    pc_users: 30,
    mou: 500000,
    pc_mou: 35,
    mpu: 475,
  },
  {
    city: "Mumbai",
    users: "1015",
    pc_users: 30,
    mou: 500000,
    pc_mou: 35,
    mpu: 475,
  },
  {
    city: "Vadodara",
    users: "1015",
    pc_users: 30,
    mou: 500000,
    pc_mou: 35,
    mpu: 475,
  },
  {
    city: "Rajkot",
    users: "1015",
    pc_users: 30,
    mou: 500000,
    pc_mou: 35,
    mpu: 475,
  },
  {
    city: "Pune",
    users: "1015",
    pc_users: 30,
    mou: 500000,
    pc_mou: 35,
    mpu: 475,
  },
  {
    city: "Bengaluru",
    users: "1015",
    pc_users: 30,
    mou: 500000,
    pc_mou: 35,
    mpu: 475,
  },
  {
    city: "Delhi",
    users: "1015",
    pc_users: 30,
    mou: 500000,
    pc_mou: 35,
    mpu: 475,
  },
  {
    city: "Hyderabad",
    users: "1015",
    pc_users: 30,
    mou: 500000,
    pc_mou: 35,
    mpu: 475,
  },
  {
    city: "Lucknow",
    users: "1015",
    pc_users: 30,
    mou: 500000,
    pc_mou: 35,
    mpu: 475,
  },
];

export { geoChartData };
