// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   Container,
//   Card,
//   Row,
//   Col,
//   UncontrolledDropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
// } from "reactstrap";

// import DateDropdown from "../../HealthMatrix/DateDropdown";
// import StreamTableCard from "./StreamTableCard";
// import StreamChartCard from "./StreamChartCard";

// import { getStreamAndDidNotStreamData } from "../../../slices/HealthMatrix/StreamAndDidNotStream/thunk";
// import filterImage from "../../../assets/images/saranyuImages/filter.svg";
// import resetFilterImage from "../../../assets/images/saranyuImages/Reset Filter.svg";
// import { getMTDDateRange } from "../../../helpers/dateUtils";

// const filterOptions = {
//   user_type: {
//     All: "Paid,Free",
//     Paid: "Paid",
//     Free: "Free",
//   },
//   platforms: {
//     All: "android,web,tv,ios",
//     Android: "android",
//     Web: "web",
//     Tv: "tv",
//     iOS: "ios",
//   },
//   region: {
//     All: "india,row",
//     India: "india",
//     ROW: "row",
//   },
//   acq_type: {
//     All: "Type1,Type2",
//     Type1: "Type1",
//     Type2: "Type2",
//   },
//   instrument: {
//     All: "Instrument1,Instrument2",
//     Instrument1: "Instrument1",
//     Instrument2: "Instrument2",
//   },
// };

// const StreamAndDidNotStream = () => {
//   const dispatch = useDispatch();
//   const streamData = useSelector((state) => state.StreamAndDidNotStream.data);

//   const [customDateRange, setCustomDateRange] = useState(getMTDDateRange());
//   const [filters, setFilters] = useState({
//     start_date: customDateRange.startDate,
//     end_date: customDateRange.endDate,
//     daily_wise_data: true,
//     platforms: "android,ios,web",
//     user_type: "Paid,Free",
//     region: "All",
//   });

//   const [selectedUserType, setSelectedUserType] = useState("User Type");
//   const [selectedPlatform, setSelectedPlatform] = useState("Platform");
//   const [selectedRegion, setSelectedRegion] = useState("Region");

//   const fetchData = async (dateRange) => {
//     const dataObject = {
//       ...filters,
//       ...dateRange,
//     };
//     dispatch(getStreamAndDidNotStreamData(dataObject));
//   };

//   useEffect(() => {
//     fetchData(customDateRange);
//   }, [customDateRange, filters]);

//   const handleFilterChange = (filterType, value) => {
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       [filterType]: value,
//     }));

//     // Update the selected filter state
//     switch (filterType) {
//       case "user_type":
//         setSelectedUserType(value || "User Type");
//         break;
//       case "platforms":
//         setSelectedPlatform(value || "Platform");
//         break;
//       case "region":
//         setSelectedRegion(value || "Region");
//         break;
//       default:
//         break;
//     }
//   };

//   const handleDateRangeChange = (range) => {
//     setCustomDateRange(range);
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       start_date: range.startDate,
//       end_date: range.endDate,
//     }));
//   };

//   const handleResetFilters = () => {
//     // const defaultDateRange = getMTDDateRange();
//     // setCustomDateRange(defaultDateRange);
//     setFilters({
//       // start_date: defaultDateRange.startDate,
//       // end_date: defaultDateRange.endDate,
//       daily_wise_data: true,
//       platforms: "android,ios,web",
//       user_type: "Paid,Free",
//       region: "All",
//     });
//     setSelectedUserType("User Type");
//     setSelectedPlatform("Platform");
//     setSelectedRegion("Region");
//   };

//   return (
//     <React.Fragment>
//       <Container fluid className="customContainerStyle">
//         <Row>
//           <Col md="12" style={{ padding: "20px" }}>
//             <h4 className="card-heading-text">Stream v/s Did Not Stream</h4>
//           </Col>
//         </Row>
//         <Row>
//           <Col className="mb-4 d-flex justify-content-between">
//             <div className="d-flex justify-content-start">
//               <button className="sh-funnel-filter">
//                 <img src={filterImage} alt="filter" />
//               </button>
//               <UncontrolledDropdown>
//                 <DropdownToggle
//                   tag="button"
//                   className="sh-filter-dropdown sh-filter-text"
//                   id="dropdownMenuButton"
//                 >
//                   &nbsp; &nbsp;{selectedUserType} &nbsp;&nbsp;
//                   <i className="mdi mdi-chevron-down"></i>
//                 </DropdownToggle>
//                 <DropdownMenu>
//                   {["Paid", "Free"].map((type) => (
//                     <DropdownItem
//                       key={type}
//                       onClick={() =>
//                         handleFilterChange(
//                           "user_type",
//                           type === "User Type" ? "" : type
//                         )
//                       }
//                     >
//                       {type}
//                     </DropdownItem>
//                   ))}
//                 </DropdownMenu>
//               </UncontrolledDropdown>
//               <UncontrolledDropdown>
//                 <DropdownToggle
//                   tag="button"
//                   className="sh-filter-dropdown sh-filter-text"
//                   id="dropdownMenuButton"
//                 >
//                   &nbsp; &nbsp;{selectedPlatform} &nbsp;&nbsp;
//                   <i className="mdi mdi-chevron-down"></i>
//                 </DropdownToggle>
//                 <DropdownMenu>
//                   {["Android", "iOS"].map((platform) => (
//                     <DropdownItem
//                       key={platform}
//                       onClick={() =>
//                         handleFilterChange(
//                           "platforms",
//                           platform === "Platform" ? "" : platform.toLowerCase()
//                         )
//                       }
//                     >
//                       {platform}
//                     </DropdownItem>
//                   ))}
//                 </DropdownMenu>
//               </UncontrolledDropdown>
//               <UncontrolledDropdown>
//                 <DropdownToggle
//                   tag="button"
//                   className="sh-filter-dropdown sh-filter-text"
//                   id="dropdownMenuButton"
//                 >
//                   &nbsp; &nbsp;{selectedRegion} &nbsp;&nbsp;
//                   <i className="mdi mdi-chevron-down"></i>
//                 </DropdownToggle>
//                 <DropdownMenu>
//                   {["IN", "ROW"].map((region) => (
//                     <DropdownItem
//                       key={region}
//                       onClick={() =>
//                         handleFilterChange(
//                           "region",
//                           region === "Region" ? "" : region
//                         )
//                       }
//                     >
//                       {region}
//                     </DropdownItem>
//                   ))}
//                 </DropdownMenu>
//               </UncontrolledDropdown>
//               <button className="sh-filter-reset" onClick={handleResetFilters}>
//                 <img src={resetFilterImage} alt="reset filter" />
//               </button>
//             </div>
//             <DateDropdown
//               onDateRangeChange={handleDateRangeChange}
//               options={["MTD", "Last Month", "3 Months", "Custom"]}
//             />
//           </Col>
//         </Row>
//         <Row>
//           <Col>
//             <Card className="card-styling" style={{ height: "320px" }}>
//               <Row>
//                 <Col md="6">{streamData && <StreamTableCard />}</Col>
//                 <Col md="6">{streamData && <StreamChartCard />}</Col>
//               </Row>
//             </Card>
//           </Col>
//         </Row>
//       </Container>
//     </React.Fragment>
//   );
// };

// export default StreamAndDidNotStream;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Card, Row, Col } from "reactstrap";

import DateDropdown from "../../HealthMatrix/DateDropdown";
import StreamTableCard from "./StreamTableCard";
import StreamChartCard from "./StreamChartCard";

import { getStreamAndDidNotStreamData } from "../../../slices/HealthMatrix/StreamAndDidNotStream/thunk";
import FilterSection from "../../RevenueMatrix/Subscription/filterSection"; // Adjusted import path
import { getMTDDateRange } from "../../../helpers/dateUtils";
import { data as streamData } from "../../../common/data/HealthMatrix/streamData";

const filterOptions = {
  user_type: {
    All: "Paid,Free",
    Paid: "Paid",
    Free: "Free",
  },
  platforms: {
    All: "android,web,tv,ios",
    Android: "android",
    Web: "web",
    Tv: "tv",
    iOS: "ios",
  },
  region: {
    All: "india,row",
    India: "india",
    ROW: "row",
  },
};

const StreamAndDidNotStream = () => {
  const dispatch = useDispatch();
  // const streamData = useSelector((state) => state.StreamAndDidNotStream.data);

  const [customDateRange, setCustomDateRange] = useState(getMTDDateRange());
  const [filters, setFilters] = useState({
    start_date: customDateRange.startDate,
    end_date: customDateRange.endDate,
    daily_wise_data: true,
    platforms: filterOptions.platforms.All,
    user_type: filterOptions.user_type.All,
    region: filterOptions.region.All,
  });

  const fetchData = async (dateRange) => {
    const dataObject = {
      ...filters,
      ...dateRange,
    };
    // dispatch(getStreamAndDidNotStreamData(dataObject));
  };

  useEffect(() => {
    fetchData(customDateRange);
  }, [customDateRange, filters]);

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const handleDateRangeChange = (range) => {
    setCustomDateRange(range);
    setFilters((prevFilters) => ({
      ...prevFilters,
      start_date: range.startDate,
      end_date: range.endDate,
    }));
  };

  const handleResetFilters = () => {
    setFilters({
      daily_wise_data: true,
      platforms: filterOptions.platforms.All,
      user_type: filterOptions.user_type.All,
      region: filterOptions.region.All,
    });
  };

  return (
    <React.Fragment>
      <Container fluid className="customContainerStyle">
        <Row>
          <Col md="12" style={{ padding: "20px" }}>
            <h4 className="card-heading-text">Stream v/s Did Not Stream</h4>
          </Col>
        </Row>
        <Row>
          <Col className="mb-4 d-flex justify-content-between">
            <FilterSection
              filterOptions={filterOptions}
              filters={filters}
              handleFilterChange={handleFilterChange}
              handleResetFilters={handleResetFilters}
            />
            <DateDropdown
              onDateRangeChange={handleDateRangeChange}
              options={["MTD", "Last Month", "3 Months", "Custom"]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="card-styling" style={{ height: "320px" }}>
              <Row>
                <Col md="6">{streamData && <StreamTableCard />}</Col>
                <Col md="6">{streamData && <StreamChartCard />}</Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default StreamAndDidNotStream;
