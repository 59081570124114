import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";

const tableHeaderColors = ["#1B2431", "#78AD2F", "#2BA558", "#009978"];
import { chartData as installAndUninstallDataFromState } from "../../../common/data/HealthMatrix/installAndUninstallData";

function InstallAndUninstallTable() {
  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [loader, setLoader] = useState(true);

  // const installAndUninstallDataFromState = useSelector(
  //   (state) => state.InstallAndUninstallAnalysis?.data?.chart_data
  // );

  // const { loader } = useSelector((state) => state.InstallAndUninstallAnalysis);

  useEffect(() => {
    if (
      installAndUninstallDataFromState &&
      installAndUninstallDataFromState.length > 0
    ) {
      const tableObject = installAndUninstallDataFromState[0];
      const headerValues = Object.keys(tableObject);

      const formattedHeaderValues = headerValues.map((key) => {
        switch (key) {
          case "Months":
            return "Months";
          case "Install":
            return "Install";
          case "Uninstall":
            return "Uninstall";
          case "Uninstall/install":
            return "Uninstall/install";
          default:
            return key;
        }
      });

      const formattedTableData = installAndUninstallDataFromState.map((item) =>
        Object.values(item).map((value, index) => {
          if (headerValues[index] === "percentage_contribution") {
            return `${value}%`;
          } else if (!isNaN(value)) {
            return Number(parseInt(value)).toLocaleString();
          }
          return value;
        })
      );

      setTableData(formattedTableData);
      setTableHeaders(formattedHeaderValues);
      setLoader(false);
    } else {
      setTableData([]);
      setTableHeaders([]);
      setLoader(true);
    }
  }, [installAndUninstallDataFromState]);

  return (
    <React.Fragment>
      {!loader && tableData.length > 0 ? (
        <div className="table-responsive ms-4 me-4 mt-4 sh-table-styling">
          <Table className="table-nowrap table-bordered mb-0" size="sm">
            <thead className="sh-table-header text-center">
              <tr className="table-row">
                {tableHeaders.map((header, index) => (
                  <th
                    scope="col"
                    style={{ backgroundColor: `${tableHeaderColors[index]}` }}
                    key={index}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((eachRow, rowIndex) => (
                <tr key={rowIndex} className="sh-table-text">
                  {eachRow.map((eachColumn, colIndex) => (
                    <td
                      key={colIndex}
                      className={
                        tableHeaders[colIndex] === "Months"
                          ? "text-start align-middle"
                          : "text-center align-middle"
                      }
                    >
                      {eachColumn}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "330px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default InstallAndUninstallTable;
