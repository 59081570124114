import React, { useEffect, useRef, useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import calendarImage from "../../assets/images/saranyuImages/calander.svg";

import {
  getMTDDateRange,
  getLastMonthDateRange,
  getQTDDateRange,
  getLastSixMonthsDateRange,
  getYTDDateRange,
} from "../../helpers/dateUtils";

const dateRangeFunctions = {
  MTD: getMTDDateRange,
  "Last Month": getLastMonthDateRange,
  "3 Months": getQTDDateRange,
  "6 Months": getLastSixMonthsDateRange,
  "1 Year": getYTDDateRange,
  Custom: getMTDDateRange,
};

const DateDropdown = ({ onDateRangeChange, options }) => {
  const [dropdownValue, setDropdown] = useState(options[0]);
  const [showCalender, setShowCalender] = useState(false);
  const [dateRange, setDateRange] = useState(["01 Jun 2024", "19 Jun 2024"]);
  const flatpickrRef = useRef(null);

  const handleDropdownItemClick = (newDropdownValue) => {
    if (newDropdownValue === dropdownValue) return;

    setDropdown(newDropdownValue);
    setShowCalender(newDropdownValue === "Custom");

    if (newDropdownValue !== "Custom") {
      const range = dateRangeFunctions[newDropdownValue]();
      onDateRangeChange(range);
    }
  };

  const handleCustomDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const [start, end] = selectedDates;
      const formattedStartDate = `${start.getFullYear()}-${String(
        start.getMonth() + 1
      ).padStart(2, "0")}-${String(start.getDate()).padStart(2, "0")}`;
      const formattedEndDate = `${end.getFullYear()}-${String(
        end.getMonth() + 1
      ).padStart(2, "0")}-${String(end.getDate()).padStart(2, "0")}`;
      const customRange = {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      };
      onDateRangeChange(customRange);
    }
  };

  useEffect(() => {
    if (showCalender && dropdownValue === "Custom" && flatpickrRef.current) {
      flatpickrRef.current.flatpickr.open();
    }
  }, [showCalender, dropdownValue]);

  return (
    <div className="d-flex">
      {showCalender && dropdownValue === "Custom" ? (
        <Flatpickr
          ref={flatpickrRef}
          className="form-control dash-filter-picker sh-flatpickr"
          options={{
            mode: "range",
            dateFormat: "d M, Y",
            defaultDate: dateRange,
            maxDate: "today", // Disable dates beyond today
          }}
          value={dateRange}
          onChange={(selectedDates) =>
            setDateRange(
              selectedDates.map(
                (date) =>
                  `${date.getDate()} ${date.toLocaleString("default", {
                    month: "short",
                  })}, ${date.getFullYear()}`
              )
            )
          }
          onClose={handleCustomDateChange}
        />
      ) : null}
      <UncontrolledDropdown>
        <DropdownToggle
          tag="button"
          className="sh-dropdown d-flex justify-content-between align-items-center"
          id="dropdownMenuButton"
        >
          <img
            src={calendarImage}
            alt="calender"
            className="sh-calendar-icon"
          />
          <p className="dateDropdown-text">{dropdownValue}</p>
          <i className="mdi mdi-chevron-down"></i>
        </DropdownToggle>
        <DropdownMenu>
          {options.map((option) => (
            <DropdownItem
              key={option}
              onClick={() => handleDropdownItemClick(option)}
            >
              {option}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default DateDropdown;
