// import React from "react";
// import ReactApexChart from "react-apexcharts";

// import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";

// const formatMonth = (dateString) => {
//   const dateObject = new Date(dateString);
//   const monthAbbr = dateObject
//     .toLocaleString("default", { month: "short" })
//     .toUpperCase();
//   const yearLastTwoDigits = dateObject.getFullYear().toString().slice(-2);
//   return `${monthAbbr}'${yearLastTwoDigits}`;
// };

// const SubscriptionsChart = ({ dataColors, data, loader }) => {
//   const monthArray = data.map((item) => formatMonth(item.month));
//   // console.log("data ssss", data);
//   var chartLineColumnColors = getChartColorsArray(dataColors);

//   const series = [
//     {
//       name: "Subscriptions",
//       type: "column",
//       // data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160],
//       data: data.map((item) => parseInt(item.subscriptions)),
//     },
//     {
//       name: "ARPU",
//       type: "line",
//       // data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
//       data: data.map((item) => parseInt(item.ARPU)),
//     },
//     {
//       name: "CPS",
//       type: "line",
//       // data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
//       data: data.map((item) => parseInt(item.CPS)),
//     },
//   ];
//   console.log("series of subs", series);
//   const options = {
//     chart: {
//       toolbar: {
//         show: !1,
//       },
//     },
//     stroke: {
//       width: [0, 4, 4],
//     },
//     markers: {
//       size: 5,
//       strokeColors: "#fff",
//       strokeWidth: 2,
//       hover: {
//         size: 7,
//         sizeOffset: 3,
//       },
//     },

//     xaxis: {
//       categories: monthArray,
//       labels: {
//         show: false,
//         formatter: function (val) {
//           return val !== undefined ? val : ""; // Display actual values on x-axis
//         },
//       },
//     },

//     yaxis: [
//       {
//         labels: {
//           formatter: function (val) {
//             return val !== undefined ? (val / 1000).toFixed(0) + "" : "";
//           },
//         },
//         title: {
//           text: "DATA IN THOUSANDS",
//           style: {
//             fontWeight: 600,
//           },
//         },
//         min: 0,
//         tickAmount: 6,
//       },
//       {
//         opposite: true,
//         labels: {
//           formatter: function (val) {
//             return val !== undefined ? (val / 1000).toFixed(0) + "" : "";
//           },
//         },
//         title: {
//           text: "DATA IN THOUSANDS",
//           style: {
//             fontWeight: 600,
//           },
//         },
//         min: 0,
//         tickAmount: 6,
//       },
//     ],
//     tooltip: {
//       enabled: true,
//       x: {
//         show: true,
//         formatter: function (val) {
//           return val !== undefined ? val : ""; // Display actual values on x-axis in tooltip
//         },
//       },
//       y: {
//         formatter: function (val) {
//           return val !== undefined
//             ? parseInt(val.toFixed(0)).toLocaleString()
//             : ""; // Display actual values in tooltip without formatting
//         },
//       },
//     },
//     colors: chartLineColumnColors,
//   };
//   return (
//     <React.Fragment>
//       {!loader ? (
//         <ReactApexChart
//           dir="ltr"
//           className="apex-charts"
//           options={options}
//           series={series}
//           type="line"
//           height={400}
//         />
//       ) : (
//         <div
//           className="d-flex justify-content-center align-items-center"
//           style={{ height: "400px" }}
//         >
//           <div className="spinner-border" role="status">
//             <span className="visually-hidden">Loading...</span>
//           </div>
//         </div>
//       )}
//     </React.Fragment>
//   );
// };

// export default SubscriptionsChart;

import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";

const formatMonth = (dateString) => {
  const dateObject = new Date(dateString);
  const monthAbbr = dateObject
    .toLocaleString("default", { month: "short" })
    .toUpperCase();
  const yearLastTwoDigits = dateObject.getFullYear().toString().slice(-2);
  return `${monthAbbr}'${yearLastTwoDigits}`;
};

const SubscriptionsChart = ({ dataColors, data, loader }) => {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      const subscriptionsData = data.map((item) =>
        parseInt(item.subscriptions || 0)
      );
      const ARPUData = data.map((item) => parseInt(item.ARPU || 0));
      const CPSData = data.map((item) => parseInt(item.CPS || 0));

      setSeries([
        {
          name: "Subscriptions",
          type: "column",
          data: subscriptionsData,
        },
        {
          name: "ARPU",
          type: "line",
          data: ARPUData,
        },
        {
          name: "CPS",
          type: "line",
          data: CPSData,
        },
      ]);
    } else {
      setSeries([
        {
          name: "Subscriptions",
          type: "column",
          data: [],
        },
        {
          name: "ARPU",
          type: "line",
          data: [],
        },
        {
          name: "CPS",
          type: "line",
          data: [],
        },
      ]);
    }
  }, [data]);

  const monthArray = data.map((item) => formatMonth(item.month));

  var chartLineColumnColors = getChartColorsArray(dataColors);

  const options = {
    chart: {
      toolbar: {
        show: !1,
      },
    },
    stroke: {
      width: [0, 4, 4],
    },
    markers: {
      size: 5,
      strokeColors: "#fff",
      strokeWidth: 2,
      hover: {
        size: 7,
        sizeOffset: 3,
      },
    },
    xaxis: {
      categories: monthArray,
      labels: {
        show: false,
        formatter: function (val) {
          return val !== undefined ? val : ""; // Display actual values on x-axis
        },
      },
    },
    yaxis: [
      {
        labels: {
          formatter: function (val) {
            return val !== undefined ? (val / 1000).toFixed(0) + "" : "";
          },
        },
        title: {
          text: "DATA IN THOUSANDS",
          style: {
            fontWeight: 600,
          },
        },
        min: 0,
        tickAmount: 6,
      },
      {
        opposite: true,
        labels: {
          formatter: function (val) {
            return val !== undefined ? (val / 1000).toFixed(0) + "" : "";
          },
        },
        title: {
          text: "DATA IN THOUSANDS",
          style: {
            fontWeight: 600,
          },
        },
        min: 0,
        tickAmount: 6,
      },
    ],
    tooltip: {
      enabled: true,
      x: {
        show: true,
        formatter: function (val) {
          return val !== undefined ? val : ""; // Display actual values on x-axis in tooltip
        },
      },
      y: {
        formatter: function (val) {
          return val !== undefined
            ? parseInt(val.toFixed(0)).toLocaleString()
            : ""; // Display actual values in tooltip without formatting
        },
      },
    },
    colors: chartLineColumnColors,
  };

  return (
    <React.Fragment>
      {!loader && series.length > 0 ? (
        <ReactApexChart
          dir="ltr"
          className="apex-charts"
          options={options}
          series={series}
          type="line"
          height={400}
        />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "400px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SubscriptionsChart;
