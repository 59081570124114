import { createSlice } from "@reduxjs/toolkit";

import {
  getDauData,
  getPaidDauData,
  getStreamingDauData,
  getMauData,
  getPaidMauData,
  getStreamingMauData,
} from "./thunk";

const initialState = {
  dauData: {},
  paidDauData: {},
  streamingDauData: {},
  mauData: {},
  paidMauData: {},
  streamingMauData: {},
  paidDau: null,
  errorMsg: "",
  loader: true,
  status: "idle",
};

const WidgetsSlice = createSlice({
  name: "DashboardWidgets",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getDauData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.dauData = action.payload.data;
        state.status = "succeeded";
      }
    });
    builder.addCase(getDauData.rejected, (state, action) => {
      state.errorMsg = action.error.message;
      state.status = "failed";
    });

    builder.addCase(getPaidDauData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.paidDauData = action.payload.data;
        state.paidDau = action.payload.data.daily_wise_data;
        state.loader = false;
        state.status = "succeeded";
      }
    });
    builder.addCase(getPaidDauData.rejected, (state, action) => {
      state.errorMsg = action.error.message;
      state.status = "failed";
    });

    builder.addCase(getStreamingDauData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.streamingDauData = action.payload.data;
        state.status = "succeeded";
      }
    });
    builder.addCase(getStreamingDauData.rejected, (state, action) => {
      state.errorMsg = action.error.message;
      state.status = "failed";
    });

    builder.addCase(getMauData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.mauData = action.payload.data;
        state.status = "succeeded";
      }
    });
    builder.addCase(getMauData.rejected, (state, action) => {
      state.errorMsg = action.error.message;
      state.status = "failed";
    });

    builder.addCase(getPaidMauData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.paidMauData = action.payload.data;
        state.status = "succeeded";
      }
    });
    builder.addCase(getPaidMauData.rejected, (state, action) => {
      state.errorMsg = action.error.message;
      state.status = "failed";
    });

    builder.addCase(getStreamingMauData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.streamingMauData = action.payload.data;
        state.status = "succeeded";
      }
    });
    builder.addCase(getStreamingMauData.rejected, (state, action) => {
      state.errorMsg = action.error.message;
      state.status = "failed";
    });
  },
});

export default WidgetsSlice.reducer;
