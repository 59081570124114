// src/components/StreamChartCard.js

import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

import StreamChart from "./StreamChart";
import calendarImage from "../../../assets/images/saranyuImages/calander.svg";

function StreamChartCard() {
  return (
    <React.Fragment>
      {/* <div className="d-flex align-items-center justify-content-center">
            <div className="d-flex justify-content-center me-2">
              <input
                type="radio"
                id="All"
                name="plb"
                className="sh-radio-button me-1"
                defaultChecked
              />
              <label htmlFor="All" className="sh-radio-text">
                Stream
              </label>
            </div>
            <div className="d-flex justify-content-center">
              <input
                type="radio"
                id="India"
                name="plb"
                className="sh-radio-button me-1"
              />
              <label htmlFor="India" className="sh-radio-text">
                Did Not Stream
              </label>
            </div>
          </div> */}

      <StreamChart dataColors='["#146588","#00898D"]' />
    </React.Fragment>
  );
}

export default StreamChartCard;
