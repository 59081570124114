// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { Row, Col, Table } from "reactstrap";

// const headerColors = [
//   "#1B2431",
//   "#78AD2F",
//   "#2BA558",
//   "#009978",
//   "#00898D",
//   "#007893",
// ];

// const categoryColors = ["#FFA600", "#BAAF00", "#78AD2F"];

// const headerArray = [
//   "Performance",
//   "Packs",
//   "Due for Renewal",
//   "Renewed",
//   "Revenue",
//   "ARPU",
//   "Success Rate",
// ];

// const packOrder = [
//   "Weekly",
//   "Monthly",
//   "Quarterly",
//   "Half Yearly",
//   "Non Gujarati - Half Yearly",
//   "Gujarati - Half Yearly",
//   "Bollywood Yearly",
//   "yearly",
//   "Yearly",
//   "Two Yearly",
//   "Total",
//   "Total",
// ];

// function AutoRenewalTransactionsTable() {
//   const [tableData, setTableData] = useState([]);
//   const [loading, setLoading] = useState(true);

//   const { data } = useSelector((state) => state.AutoRenewalTransactions);

//   useEffect(() => {
//     if (data && Object.keys(data).length > 0) {
//       const formattedData = Object.keys(data).reduce((acc, performanceKey) => {
//         if (performanceKey !== "grand") {
//           const performanceData = data[performanceKey]
//             .map((pack) => ({
//               packs: pack.packwise,
//               dueForRenewal: pack.due_for_renewal,
//               renewed: pack.renewed,
//               revenue: pack.revenue,
//               arpu: pack.arpu,
//               successRate: pack.success_rate,
//             }))
//             .sort((a, b) => {
//               // Sort based on packOrder array
//               const indexA = packOrder.indexOf(a.packs);
//               const indexB = packOrder.indexOf(b.packs);
//               if (indexA === -1 || indexB === -1) return 0; // Handle cases where packs might not be found in packOrder
//               return indexA - indexB;
//             });

//           acc.push({
//             performance: `${performanceKey
//               .charAt(0)
//               .toUpperCase()}${performanceKey.slice(1)}`,
//             data: performanceData,
//           });
//         }
//         return acc;
//       }, []);

//       setTableData(formattedData);
//       setLoading(false);
//     }
//   }, [data]);

//   const grandTotalData = data?.grand?.[0] || null;

//   return (
//     <React.Fragment>
//       <Row>
//         <Col style={{ height: "750px", overflow: "hidden" }}>
//           {!loading && (
//             <div className="table-responsive sh-table-styling">
//               <Table className="table-nowrap table-bordered mb-0" size="sm">
//                 <thead className="sh-table-header text-center">
//                   <tr className="table-row">
//                     {headerArray.map((value, index) => (
//                       <th
//                         scope="col"
//                         key={index}
//                         style={{
//                           backgroundColor:
//                             headerColors[index % headerColors.length],
//                           padding: "0.5rem",
//                           color: "rgba(255, 255, 255, 1)", // Ensure header text color is white
//                         }}
//                       >
//                         {value}
//                       </th>
//                     ))}
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {tableData.map((category, i) => (
//                     <React.Fragment key={i}>
//                       <tr
//                         style={{
//                           backgroundColor:
//                             categoryColors[i % categoryColors.length],
//                           color: "white",
//                           fontWeight: "700",
//                           fontSize: "16px",
//                         }}
//                       >
//                         <td
//                           rowSpan={category.data.length + 1}
//                           className="text-center align-middle"
//                           style={{ padding: "0.5rem" }}
//                         >
//                           {category.performance}
//                         </td>
//                       </tr>
//                       {category.data.map((item, j) => (
//                         <tr
//                           key={j}
//                           style={{
//                             backgroundColor:
//                               item.packs && item.packs.toLowerCase() === "total"
//                                 ? categoryColors[i % categoryColors.length]
//                                 : "inherit",
//                             color:
//                               item.packs && item.packs.toLowerCase() === "total"
//                                 ? "white"
//                                 : "inherit",
//                             fontSize: "16px",
//                           }}
//                         >
//                           <td style={{ padding: "0.24rem" }}>{item.packs}</td>
//                           <td style={{ padding: "0.24rem" }}>
//                             {item.dueForRenewal}
//                           </td>
//                           <td style={{ padding: "0.24rem" }}>{item.renewed}</td>
//                           <td style={{ padding: "0.24rem" }}>{item.revenue}</td>
//                           <td style={{ padding: "0.24rem" }}>{item.arpu}</td>
//                           <td style={{ padding: "0.24rem" }}>
//                             {item.successRate}
//                           </td>
//                         </tr>
//                       ))}
//                     </React.Fragment>
//                   ))}
//                   {grandTotalData && (
//                     <tr
//                       style={{
//                         backgroundColor: "#146588",
//                         color: "white",
//                         fontWeight: "700",
//                         fontSize: "16px",
//                       }}
//                     >
//                       <td className="text-center" style={{ padding: "0.5rem" }}>
//                         Grand Total
//                       </td>
//                       <td style={{ padding: "0.5rem" }}>
//                         {grandTotalData.packwise}
//                       </td>
//                       <td style={{ padding: "0.5rem" }}>
//                         {grandTotalData.revenue}
//                       </td>
//                       <td style={{ padding: "0.5rem" }}>
//                         {grandTotalData.due_for_renewal}
//                       </td>
//                       <td style={{ padding: "0.5rem" }}>
//                         {grandTotalData.renewed}
//                       </td>
//                       <td style={{ padding: "0.5rem" }}>
//                         {grandTotalData.arpu}
//                       </td>
//                       <td style={{ padding: "0.5rem" }}>
//                         {grandTotalData.success_rate}
//                       </td>
//                     </tr>
//                   )}
//                 </tbody>
//               </Table>
//             </div>
//           )}
//         </Col>
//       </Row>
//     </React.Fragment>
//   );
// }

// export default AutoRenewalTransactionsTable;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Table } from "reactstrap";
import { autoRenewalData as data } from "../../../common/data/RevenueMatrix/autoRenewalData";

const headerColors = [
  "#1B2431",
  "#78AD2F",
  "#2BA558",
  "#009978",
  "#00898D",
  "#007893",
];

const categoryColors = ["#FFA600", "#BAAF00", "#78AD2F"];

const headerArray = [
  "Performance",
  "Packs",
  "Due for Renewal",
  "Renewed",
  "Revenue",
  "ARPU",
  "Success Rate",
];

const packOrder = [
  "Weekly",
  "Monthly",
  "Quarterly",
  "Half Yearly",
  "Non Gujarati - Half Yearly",
  "Gujarati - Half Yearly",
  "Bollywood Yearly",
  "yearly",
  "Yearly",
  "Two Yearly",
  "Total",
  "Total",
];

function AutoRenewalTransactionsTable() {
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(true);
  // const [loading, setLoading] = useState(true);

  // const { data, loader } = useSelector(
  //   (state) => state.AutoRenewalTransactions
  // );

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const formattedData = Object.keys(data).reduce((acc, performanceKey) => {
        if (performanceKey !== "grand") {
          const performanceData = data[performanceKey]
            .map((pack) => ({
              packs: pack.packwise,
              dueForRenewal: parseInt(pack.due_for_renewal, 10) || 0,
              renewed: parseInt(pack.renewed, 10) || 0,
              revenue: parseInt(pack.revenue, 10) || 0,
              arpu: parseInt(pack.arpu, 10) || 0,
              successRate: parseInt(pack.success_rate, 10) || 0,
            }))
            .sort((a, b) => {
              const indexA = packOrder.indexOf(a.packs);
              const indexB = packOrder.indexOf(b.packs);
              if (indexA === -1 || indexB === -1) return 0;
              return indexA - indexB;
            });

          acc.push({
            performance: `${performanceKey
              .charAt(0)
              .toUpperCase()}${performanceKey.slice(1)}`,
            data: performanceData,
          });
        }
        return acc;
      }, []);

      setTableData(formattedData);
      setLoader(false);
      // setLoading(false);
    }
  }, [data]);

  const grandTotalData = data?.grand?.[0] || null;

  return (
    <React.Fragment>
      <Row>
        <Col style={{ height: "750px", overflow: "hidden" }}>
          {!loader ? (
            <div className="table-responsive sh-table-styling">
              <Table className="table-nowrap table-bordered mb-0" size="sm">
                <thead className="sh-table-header text-center">
                  <tr className="table-row">
                    {headerArray.map((value, index) => (
                      <th
                        scope="col"
                        key={index}
                        style={{
                          backgroundColor:
                            headerColors[index % headerColors.length],
                          padding: "0.5rem",
                          color: "rgba(255, 255, 255, 1)",
                          textAlign: "center",
                        }}
                      >
                        {value}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((category, i) => (
                    <React.Fragment key={i}>
                      <tr
                        style={{
                          backgroundColor:
                            categoryColors[i % categoryColors.length],
                          color: "white",
                          fontWeight: "700",
                          fontSize: "16px",
                        }}
                      >
                        <td
                          rowSpan={category.data.length + 1}
                          className="text-center align-middle"
                          style={{ padding: "0.5rem" }}
                        >
                          {category.performance}
                        </td>
                      </tr>
                      {category.data.map((item, j) => (
                        <tr
                          key={j}
                          style={{
                            backgroundColor:
                              item.packs && item.packs.toLowerCase() === "total"
                                ? categoryColors[i % categoryColors.length]
                                : "inherit",
                            color:
                              item.packs && item.packs.toLowerCase() === "total"
                                ? "white"
                                : "inherit",
                            fontSize: "16px",
                          }}
                        >
                          <td style={{ padding: "0.24rem", textAlign: "left" }}>
                            {item.packs}
                          </td>
                          <td
                            style={{ padding: "0.24rem", textAlign: "center" }}
                          >
                            {item.dueForRenewal.toLocaleString()}
                          </td>
                          <td
                            style={{ padding: "0.24rem", textAlign: "center" }}
                          >
                            {item.renewed.toLocaleString()}
                          </td>
                          <td
                            style={{ padding: "0.24rem", textAlign: "center" }}
                          >
                            {item.revenue.toLocaleString()}
                          </td>
                          <td
                            style={{ padding: "0.24rem", textAlign: "center" }}
                          >
                            {item.arpu.toLocaleString()}
                          </td>
                          <td
                            style={{ padding: "0.24rem", textAlign: "center" }}
                          >
                            {item.successRate.toLocaleString()}%
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                  {grandTotalData && (
                    <tr
                      style={{
                        backgroundColor: "#146588",
                        color: "white",
                        fontWeight: "700",
                        fontSize: "16px",
                      }}
                    >
                      <td className="text-center" style={{ padding: "0.5rem" }}>
                        Grand Total
                      </td>
                      <td style={{ padding: "0.5rem", textAlign: "left" }}>
                        {grandTotalData.packwise}
                      </td>
                      <td style={{ padding: "0.5rem", textAlign: "center" }}>
                        {parseInt(
                          grandTotalData.due_for_renewal,
                          10
                        ).toLocaleString()}
                      </td>
                      <td style={{ padding: "0.5rem", textAlign: "center" }}>
                        {parseInt(grandTotalData.renewed, 10).toLocaleString()}
                      </td>
                      <td style={{ padding: "0.5rem", textAlign: "center" }}>
                        {parseInt(grandTotalData.revenue, 10).toLocaleString()}
                      </td>
                      <td style={{ padding: "0.5rem", textAlign: "center" }}>
                        {parseInt(grandTotalData.arpu, 10).toLocaleString()}
                      </td>
                      <td style={{ padding: "0.5rem", textAlign: "center" }}>
                        {parseInt(
                          grandTotalData.success_rate,
                          10
                        ).toLocaleString()}
                        %
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "450px" }}
            >
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default AutoRenewalTransactionsTable;
