const revenueData = [
  {
    month: "2024-01-01",
    Gujarati_Spend: 5001152,
    Gujarati_Revenue: 6534678,
    Non_Gujarati_Spend: 4253989,
    Non_Gujarati_Revenue: 1586322,
  },
  {
    month: "2024-02-01",
    Gujarati_Spend: 6821152,
    Gujarati_Revenue: 6565670,
    Non_Gujarati_Spend: 5253989,
    Non_Gujarati_Revenue: 1545822,
  },
  {
    month: "2024-03-01",
    Gujarati_Spend: 3251152,
    Gujarati_Revenue: 4254678,
    Non_Gujarati_Spend: 3253989,
    Non_Gujarati_Revenue: 1286322,
  },
  {
    month: "2024-04-01",
    Gujarati_Spend: 4201152,
    Gujarati_Revenue: 7540607,
    Non_Gujarati_Spend: 3183989,
    Non_Gujarati_Revenue: 1656322,
  },
  {
    month: "2024-05-01",
    Gujarati_Spend: 5939355,
    Gujarati_Revenue: 12687259,
    Non_Gujarati_Spend: 10508394,
    Non_Gujarati_Revenue: 6487700,
  },
  {
    month: "2024-06-01",
    Gujarati_Spend: 5204090,
    Gujarati_Revenue: 1792270,
    Non_Gujarati_Spend: 5926516,
    Non_Gujarati_Revenue: 2755685,
  },
];

export { revenueData };
