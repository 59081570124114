// state.allchartData = action.payload.data.chart_data.all;
// state.gujaratiChartData = action.payload.data.chart_data.gdata;
// state.nonGujaratiChartData = action.payload.data.chart_data.non_gdata;

const allchartData = [
  {
    month: "2023-04-01",
    subscriptions: 26596,
    ARPU: 441,
    CPS: 141,
  },
  {
    month: "2023-05-01",
    subscriptions: 21810,
    ARPU: 448,
    CPS: 249,
  },
  {
    month: "2023-06-01",
    subscriptions: 31566,
    ARPU: 492,
    CPS: 443,
  },
  {
    month: "2023-07-01",
    subscriptions: 34100,
    ARPU: 527,
    CPS: 164,
  },
  {
    month: "2023-08-01",
    subscriptions: 30974,
    ARPU: 561,
    CPS: 305,
  },
  {
    month: "2023-09-01",
    subscriptions: 19276,
    ARPU: 522,
    CPS: 325,
  },
  {
    month: "2023-10-01",
    subscriptions: 12504,
    ARPU: 504,
    CPS: 254,
  },
  {
    month: "2023-11-01",
    subscriptions: 17858,
    ARPU: 536,
    CPS: 777,
  },
  {
    month: "2023-12-01",
    subscriptions: 17316,
    ARPU: 575,
    CPS: 46,
  },
  {
    month: "2024-01-01",
    subscriptions: 15996,
    ARPU: 532,
    CPS: 72,
  },
  {
    month: "2024-02-01",
    subscriptions: 15842,
    ARPU: 483,
    CPS: 56,
  },
  {
    month: "2024-03-01",
    subscriptions: 26370,
    ARPU: 392,
    CPS: 53,
  },
  {
    month: "2024-04-01",
    subscriptions: 24058,
    ARPU: 477,
    CPS: 235,
  },
  {
    month: "2024-05-01",
    subscriptions: 50186,
    ARPU: 482,
    CPS: 207,
  },
  {
    month: "2024-06-01",
    subscriptions: 23792,
    ARPU: 499,
    CPS: 280,
  },
];
const gujaratiChartData = [
  {
    month: "2023-04-01",
    subscriptions: 13298,
    ARPU: 441,
    CPS: 141,
  },
  {
    month: "2023-05-01",
    subscriptions: 10905,
    ARPU: 448,
    CPS: 249,
  },
  {
    month: "2023-06-01",
    subscriptions: 15783,
    ARPU: 492,
    CPS: 443,
  },
  {
    month: "2023-07-01",
    subscriptions: 17050,
    ARPU: 527,
    CPS: 164,
  },
  {
    month: "2023-08-01",
    subscriptions: 15487,
    ARPU: 561,
    CPS: 305,
  },
  {
    month: "2023-09-01",
    subscriptions: 9638,
    ARPU: 522,
    CPS: 325,
  },
  {
    month: "2023-10-01",
    subscriptions: 6252,
    ARPU: 504,
    CPS: 254,
  },
  {
    month: "2023-11-01",
    subscriptions: 8929,
    ARPU: 536,
    CPS: 777,
  },
  {
    month: "2023-12-01",
    subscriptions: 8658,
    ARPU: 575,
    CPS: 46,
  },
  {
    month: "2024-01-01",
    subscriptions: 7998,
    ARPU: 532,
    CPS: 72,
  },
  {
    month: "2024-02-01",
    subscriptions: 7921,
    ARPU: 483,
    CPS: 56,
  },
  {
    month: "2024-03-01",
    subscriptions: 13185,
    ARPU: 392,
    CPS: 53,
  },
  {
    month: "2024-04-01",
    subscriptions: 12029,
    ARPU: 477,
    CPS: 235,
  },
  {
    month: "2024-05-01",
    subscriptions: 25093,
    ARPU: 482,
    CPS: 207,
  },
  {
    month: "2024-06-01",
    subscriptions: 11896,
    ARPU: 499,
    CPS: 280,
  },
];
const nonGujaratiChartData = [
  {
    month: "2023-04-01",
    subscriptions: 13298,
    ARPU: 441,
    CPS: 141,
  },
  {
    month: "2023-05-01",
    subscriptions: 10905,
    ARPU: 448,
    CPS: 249,
  },
  {
    month: "2023-06-01",
    subscriptions: 15783,
    ARPU: 492,
    CPS: 443,
  },
  {
    month: "2023-07-01",
    subscriptions: 17050,
    ARPU: 527,
    CPS: 164,
  },
  {
    month: "2023-08-01",
    subscriptions: 15487,
    ARPU: 561,
    CPS: 305,
  },
  {
    month: "2023-09-01",
    subscriptions: 9638,
    ARPU: 522,
    CPS: 325,
  },
  {
    month: "2023-10-01",
    subscriptions: 6252,
    ARPU: 504,
    CPS: 254,
  },
  {
    month: "2023-11-01",
    subscriptions: 8929,
    ARPU: 536,
    CPS: 777,
  },
  {
    month: "2023-12-01",
    subscriptions: 8658,
    ARPU: 575,
    CPS: 46,
  },
  {
    month: "2024-01-01",
    subscriptions: 7998,
    ARPU: 532,
    CPS: 72,
  },
  {
    month: "2024-02-01",
    subscriptions: 7921,
    ARPU: 483,
    CPS: 56,
  },
  {
    month: "2024-03-01",
    subscriptions: 13185,
    ARPU: 392,
    CPS: 53,
  },
  {
    month: "2024-04-01",
    subscriptions: 12029,
    ARPU: 477,
    CPS: 235,
  },
  {
    month: "2024-05-01",
    subscriptions: 25093,
    ARPU: 482,
    CPS: 207,
  },
  {
    month: "2024-06-01",
    subscriptions: 11896,
    ARPU: 499,
    CPS: 280,
  },
];

export { allchartData, gujaratiChartData, nonGujaratiChartData };
