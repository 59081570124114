// regionsChartData: [],
// CountriesChartData: [],

const regionsChartData = [
  {
    city: "GJ",
    transaction: 50,
  },
  {
    city: "MH",
    transaction: 30,
  },
  {
    city: "MP",
    transaction: 20,
  },
  {
    city: "KA",
    transaction: 10,
  },
  {
    city: "DL",
    transaction: 10,
  },
  {
    city: "RJ",
    transaction: 10,
  },
  {
    city: "UP",
    transaction: 10,
  },
  {
    city: "WB",
    transaction: 10,
  },
  {
    city: "PB",
    transaction: 50,
  },
];

const CountriesChartData = [
  {
    country: "IN",
    transaction: 50,
  },
  {
    country: "US",
    transaction: 50,
  },
  {
    country: "CA",
    transaction: 40,
  },
  {
    country: "GB",
    transaction: 50,
  },
  {
    country: "MY",
    transaction: 50,
  },
  {
    country: "AU",
    transaction: 30,
  },
  {
    country: "PK",
    transaction: 30,
  },
  {
    country: "BD",
    transaction: 20,
  },
  {
    country: "KE",
    transaction: 20,
  },
  {
    country: "KR",
    transaction: 20,
  },
];

export { regionsChartData, CountriesChartData };
