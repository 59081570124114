import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Card,
} from "reactstrap";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

//import images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import dropDownImg from "../../assets/images/saranyuImages/man-438081_960_720.svg";
import dropDownIcon from "../../assets/images/saranyuImages/Profile.svg";

const ProfileDropdown = () => {
  const profiledropdownData = createSelector(
    (state) => state.Profile,
    (state) => ({
      user: state.user,
    })
  );
  // Inside your component
  const { user } = useSelector(profiledropdownData);

  const [userName, setUserName] = useState("Admin");

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      setUserName(
        process.env.REACT_APP_DEFAULTAUTH === "fake"
          ? obj.username === undefined
            ? user.first_name
              ? user.first_name
              : obj.data.first_name
            : "Admin" || "Admin"
          : process.env.REACT_APP_DEFAULTAUTH === "firebase"
          ? obj.email && obj.email
          : "Admin"
      );
    }
  }, [userName, user]);

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  return (
    <React.Fragment>
      <Card className="topbar-user d-flex flex-row justify-content-between mb-0">
        <div className="d-flex align-items-center ms-3">
          <img
            className="rounded-circle header-profile-user"
            src={avatar1}
            alt="Header Avatar"
          />
          <div className="text-start ms-xl-2 d-flex flex-column">
            <span className="d-xl-inline-block ms-1 fw-medium user-name-text">
              {/* {userName} */}
              Saranyu
            </span>
            <span className="d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
              Admin
            </span>
          </div>
        </div>
        <Dropdown
          isOpen={isProfileDropdown}
          toggle={toggleProfileDropdown}
          className="ms-sm-3 header-item"
        >
          <DropdownToggle tag="button" type="button" className="btn">
            <div>
              <img src={dropDownIcon} />
            </div>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <h6 className="dropdown-header dropdown-item-text">
              Welcome {userName}!
            </h6>
            <DropdownItem className="p-0  dropdown-item-text">
              <Link to="/profile" className="dropdown-item">
                <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                <span className="align-middle">Profile</span>
              </Link>
            </DropdownItem>
            {/* <DropdownItem className="p-0">
              <Link to="/apps-chat" className="dropdown-item">
                <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{" "}
                <span className="align-middle">Messages</span>
              </Link>
            </DropdownItem>
            <DropdownItem className="p-0">
              <Link to="#" className="dropdown-item">
                <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{" "}
                <span className="align-middle">Taskboard</span>
              </Link>
            </DropdownItem>
            <DropdownItem className="p-0">
              <Link to="/pages-faqs" className="dropdown-item">
                <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{" "}
                <span className="align-middle">Help</span>
              </Link>
            </DropdownItem>
            <div className="dropdown-divider"></div>
            <DropdownItem className="p-0">
              <Link to="/pages-profile" className="dropdown-item">
                <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{" "}
                <span className="align-middle">
                  Balance : <b>$5971.67</b>
                </span>
              </Link>
            </DropdownItem>
            <DropdownItem className="p-0">
              <Link to="/pages-profile-settings" className="dropdown-item">
                <span className="badge bg-success-subtle text-success mt-1 float-end">
                  New
                </span>
                <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
                <span className="align-middle">Settings</span>
              </Link>
            </DropdownItem>
            <DropdownItem className="p-0">
              <Link to="/auth-lockscreen-basic" className="dropdown-item">
                <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>{" "}
                <span className="align-middle">Lock screen</span>
              </Link>
            </DropdownItem> */}
            <DropdownItem className="p-0  dropdown-item-text">
              <Link to="/logout" className="dropdown-item">
                <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
                <span className="align-middle" data-key="t-logout">
                  Logout
                </span>
              </Link>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Card>
    </React.Fragment>
  );
};

export default ProfileDropdown;
