import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Container,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import filterImage from "../../../assets/images/saranyuImages/filter.svg";
import resetFilterImage from "../../../assets/images/saranyuImages/Reset Filter.svg";
import PackContributionChart from "./packContributionChart";
import { getLastSixMonthsDateRange } from "../../../helpers/dateUtils";
import DateDropdown from "../../HealthMatrix/DateDropdown";
import { getPackMonthlyData } from "../../../slices/RevenueMatrix/PackContribution/thunk";

function PackContribution() {
  const dispatch = useDispatch();
  const [customDateRange, setCustomDateRange] = useState(
    getLastSixMonthsDateRange()
  );
  const [filters, setFilters] = useState({
    start_date: customDateRange.startDate,
    end_date: customDateRange.endDate,
    daily_wise_data: true,
    day_wise_report: true,
    platforms: "android,ios,web,external",
    region: "india,row",
    acq_type: "organic,performance_guj,performance_edgy,affiliate,auto_renewal",
    source: "google,fb",
  });
  const [selectedPlatform, setSelectedPlatform] = useState("Platform");
  const [selectedRegion, setSelectedRegion] = useState("Region");
  const [selectedAcqType, setSelectedAcqType] = useState("Acq Type");
  const [selectedSource, setSelectedSource] = useState("Source");

  const fetchData = async (dateRange) => {
    const dataObject = {
      ...filters,
      ...dateRange,
    };
    dispatch(getPackMonthlyData(dataObject));
  };

  useEffect(() => {
    fetchData(customDateRange);
  }, [customDateRange, filters]);

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));

    switch (filterType) {
      case "platforms":
        setSelectedPlatform(value || "Platform");
        break;
      case "region":
        setSelectedRegion(value || "Region");
        break;
      case "acq_type":
        setSelectedAcqType(value || "Acq Type");
        break;
      case "source":
        setSelectedSource(value || "Source");
        break;
      default:
        break;
    }
  };

  const handleDateRangeChange = (range) => {
    setCustomDateRange(range);
    setFilters((prevFilters) => ({
      ...prevFilters,
      start_date: range.startDate,
      end_date: range.endDate,
    }));
  };
  const handleResetFilters = () => {
    setFilters({
      daily_wise_data: true,
      platforms: "android,ios,web,external",
      region: "india,row",
    });
    setSelectedPlatform("Platform");
    setSelectedRegion("Region");
    setSelectedAcqType("Acq Type");
    setSelectedSource("Source");
  };
  return (
    <React.Fragment>
      <Container
        fluid
        className="customContainerStyle"
        style={{ height: "527px" }}
      >
        <Row>
          <Col style={{ padding: "20px" }}>
            <h4 className="card-heading-text">Pack Contribution</h4>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between">
            <div className="d-flex justify-content-center ms-1">
              <button className="sh-funnel-filter">
                <img src={filterImage} alt="filter" />
              </button>
              <UncontrolledDropdown>
                <DropdownToggle
                  tag="button"
                  className="sh-filter-dropdown sh-filter-text"
                  id="dropdownMenuButton"
                  style={{ width: "200px" }}
                >
                  &nbsp; &nbsp;{selectedAcqType} &nbsp;&nbsp;
                  <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                  {[
                    "Organic",
                    "Performance Guj",
                    "Performance Edgy",
                    "Affiliate",
                    "Auto renewal",
                  ].map((acqType) => (
                    <DropdownItem
                      key={acqType}
                      onClick={() =>
                        handleFilterChange(
                          "acq_type",
                          acqType === "Acq Type" ? "" : acqType.toLowerCase()
                        )
                      }
                    >
                      {acqType}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown>
                <DropdownToggle
                  tag="button"
                  className="sh-filter-dropdown sh-filter-text"
                  id="dropdownMenuButton"
                >
                  &nbsp; &nbsp;{selectedSource} &nbsp;&nbsp;
                  <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                  {["Google", "FB"].map((source) => (
                    <DropdownItem
                      key={source}
                      onClick={() =>
                        handleFilterChange(
                          "source",
                          source === "Source" ? "" : source.toLowerCase()
                        )
                      }
                    >
                      {source}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown>
                <DropdownToggle
                  tag="button"
                  className="sh-filter-dropdown sh-filter-text"
                  id="dropdownMenuButton"
                >
                  &nbsp; &nbsp;{selectedPlatform} &nbsp;&nbsp;
                  <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                  {["Android", "iOS", "Web", "Tv"].map((platform) => (
                    <DropdownItem
                      key={platform}
                      onClick={() =>
                        handleFilterChange(
                          "platforms",
                          platform === "Platform" ? "" : platform.toLowerCase()
                        )
                      }
                    >
                      {platform}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown>
                <DropdownToggle
                  tag="button"
                  className="sh-filter-dropdown sh-filter-text"
                  id="dropdownMenuButton"
                >
                  &nbsp; &nbsp;{selectedRegion} &nbsp;&nbsp;
                  <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                  {["India", "ROW"].map((region) => (
                    <DropdownItem
                      key={region}
                      onClick={() =>
                        handleFilterChange(
                          "region",
                          region === "Region" ? "IN" : region.toLowerCase()
                        )
                      }
                    >
                      {region}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
              <button className="sh-filter-reset" onClick={handleResetFilters}>
                <img src={resetFilterImage} alt="reset filter" />
              </button>
            </div>
            <DateDropdown
              onDateRangeChange={handleDateRangeChange}
              options={["6 Months", "Custom"]}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <PackContributionChart dataColors='["#146588", "#007893","#00898D","#009978","#78AD2F"]' />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default PackContribution;
