import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, CardTitle, Col, Row } from "reactstrap";
import CountUp from "react-countup";
import riseImage from "../../../assets/images/saranyuImages/Group 4.svg";
import downfallImage from "../../../assets/images/saranyuImages/Group 4 (1).svg";
import { stickinessWidgetsData } from "../../../common/data/BusinessOverview/widgetsData";

const StickinessCard = ({ columnSpace }) => {
  const [widgets, setWidgets] = useState([]);
  const [loader, setLoader] = useState(true);
  // const [widgets, setWidgets] = useState([]);
  // const [loader, setLoader] = useState(true);

  // const StickinessReducer = useSelector((state) => state.StickinessReducer);

  // useEffect(() => {
  //   if (
  //     StickinessReducer.platformData &&
  //     StickinessReducer.streamingData &&
  //     StickinessReducer.paidData
  //   ) {
  // const allWidgets = [
  //   StickinessReducer.platformData,
  //   StickinessReducer.streamingData,
  //   StickinessReducer.paidData,
  // ];

  //     const allWidgetsFilled = allWidgets.every(
  //       (widget) => widget.chart_name && widget.chart_data !== undefined
  //     );

  //     if (allWidgetsFilled) {
  //       setWidgets(allWidgets);
  //       setLoader(false);
  //     }
  //   }
  // }, [
  //   StickinessReducer.platformData,
  //   StickinessReducer.streamingData,
  //   StickinessReducer.paidData,
  // ]);

  useEffect(() => {
    if (
      stickinessWidgetsData.platformData &&
      stickinessWidgetsData.streamingData &&
      stickinessWidgetsData.paidData
    ) {
      const allWidgets = [
        stickinessWidgetsData.platformData,
        stickinessWidgetsData.streamingData,
        stickinessWidgetsData.paidData,
      ];

      const allWidgetsFilled = allWidgets.every(
        (widget) => widget.chart_name && widget.chart_data !== undefined
      );

      if (allWidgetsFilled) {
        setWidgets(allWidgets);
        setLoader(false);
      }
    }
  }, [
    stickinessWidgetsData.platformData,
    stickinessWidgetsData.streamingData,
    stickinessWidgetsData.paidData,
  ]);

  return (
    <React.Fragment>
      {!loader ? (
        <Card className="stickiness-card d-flex flex-column justify-content-between">
          <CardTitle className="stickiness-title">Stickiness</CardTitle>
          <Row className="d-flex justify-content-between mt-3">
            {widgets.map((widgetCard, index) => {
              const percentage =
                widgetCard.percentage !== undefined ? widgetCard.percentage : 0;

              return (
                <Col md={columnSpace} key={index}>
                  <Card
                    className="stickiness-widget-card d-flex justify-content-between flex-row"
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      border: "1px solid #FFFFFF66",
                    }}
                  >
                    <div className="d-flex flex-column justify-content-between">
                      <div className="stickiness-card-name">
                        {widgetCard.chart_name.replace("Stickiness ", "")}
                      </div>
                      <div
                        className={`stickiness-card-percentage d-flex align-items-center mt-2`}
                        style={{
                          color: percentage > 0 ? "#00B69B" : "#FF0049",
                        }}
                      >
                        <img
                          src={percentage > 0 ? riseImage : downfallImage}
                          alt="Percentage change"
                        />
                        {Math.abs(percentage)}%
                      </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center widget-counter">
                      <CountUp
                        start={0}
                        end={widgetCard.chart_data}
                        decimals={0}
                        duration={4}
                        suffix="%"
                      />
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Card>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "400px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default StickinessCard;
