const tableDataFromState = [
  {
    type: "Performance Bollywood",
    revenue: "4000",
    spends: "5000",
    subs: "8000",
    cps: "1000",
    arpu: "4000",
  },
  {
    type: "Performance Hollywood",
    revenue: "1000",
    spends: "7000",
    subs: "3000",
    cps: "1000",
    arpu: "5000",
  },
  {
    type: "Total Performance",
    revenue: "5000",
    spends: "1000",
    subs: "1000",
    cps: "1000",
    arpu: "5000",
  },
  {
    type: "Auto Renewals",
    revenue: "6000",
    spends: "0",
    subs: "1000",
    cps: "0",
    arpu: "1000",
  },
  {
    type: "Performance Tollywood",
    revenue: "8000",
    spends: "3000",
    subs: "1000",
    cps: "3000",
    arpu: "6000",
  },
  {
    type: "Grand Total",
    revenue: "10000",
    spends: "10000",
    subs: "4000",
    cps: "1000",
    arpu: "500",
  },
];

const dailyWiseData = [
  {
    created_at_time: "2023-06-01",
    type: "Performance HollyWood",
    revenue: "100251",
    spends: "128989",
    subs: "19851",
    cps: "650",
    arpu: "505",
  },
  {
    created_at_time: "2023-07-01",
    type: "Performance HollyWood",
    revenue: "559433",
    spends: "201087",
    subs: "10478",
    cps: "1919",
    arpu: "534",
  },
  {
    created_at_time: "2023-08-01",
    type: "Performance HollyWood",
    revenue: "102463",
    spends: "200636",
    subs: "1990",
    cps: "1008",
    arpu: "515",
  },
  {
    created_at_time: "2023-09-01",
    type: "Performance HollyWood",
    revenue: "439393",
    spends: "10460539",
    subs: "8825",
    cps: "1185",
    arpu: "498",
  },
  {
    created_at_time: "2023-10-01",
    type: "Performance HollyWood",
    revenue: "320060",
    spends: "7374968",
    subs: "6560",
    cps: "1124",
    arpu: "488",
  },
  {
    created_at_time: "2023-11-01",
    type: "Performance HollyWood",
    revenue: "383548",
    spends: "8238586",
    subs: "7569",
    cps: "1088",
    arpu: "507",
  },
  {
    created_at_time: "2023-12-01",
    type: "Performance HollyWood",
    revenue: "907538",
    spends: "1049751",
    subs: "1759",
    cps: "597",
    arpu: "516",
  },
  {
    created_at_time: "2024-01-01",
    type: "Performance HollyWood",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2024-02-01",
    type: "Performance HollyWood",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2024-03-01",
    type: "Performance HollyWood",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2024-04-01",
    type: "Performance HollyWood",
    revenue: "165632",
    spends: "3183989",
    subs: "3423",
    cps: "930",
    arpu: "484",
  },
  {
    created_at_time: "2024-05-01",
    type: "Performance HollyWood",
    revenue: "648770",
    spends: "10508394",
    subs: "14212",
    cps: "739",
    arpu: "456",
  },
  {
    created_at_time: "2024-06-01",
    type: "Performance HollyWood",
    revenue: "275585",
    spends: "5926516",
    subs: "6033",
    cps: "982",
    arpu: "457",
  },
  {
    created_at_time: "2023-06-01",
    type: "Performance Bollywood",
    revenue: "842771",
    spends: "15424344",
    subs: "6346",
    cps: "2431",
    arpu: "606",
  },
  {
    created_at_time: "2023-07-01",
    type: "Performance Bollywood",
    revenue: "612388",
    spends: "29570472",
    subs: "4665",
    cps: "6339",
    arpu: "560",
  },
  {
    created_at_time: "2023-08-01",
    type: "Performance Bollywood",
    revenue: "39953",
    spends: "1854142",
    subs: "970",
    cps: "1911",
    arpu: "557",
  },
  {
    created_at_time: "2023-09-01",
    type: "Performance Bollywood",
    revenue: "543233",
    spends: "4048451",
    subs: "2701",
    cps: "1499",
    arpu: "571",
  },
  {
    created_at_time: "2023-10-01",
    type: "Performance Bollywood",
    revenue: "618354",
    spends: "3814807",
    subs: "2963",
    cps: "1287",
    arpu: "546",
  },
  {
    created_at_time: "2023-11-01",
    type: "Performance Bollywood",
    revenue: "241160",
    spends: "8237420",
    subs: "5471",
    cps: "1506",
    arpu: "592",
  },
  {
    created_at_time: "2023-12-01",
    type: "Performance Bollywood",
    revenue: "572677",
    spends: "539782",
    subs: "972",
    cps: "555",
    arpu: "589",
  },
  {
    created_at_time: "2024-01-01",
    type: "Performance Bollywood",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2024-02-01",
    type: "Performance Bollywood",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2024-03-01",
    type: "Performance Bollywood",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2024-04-01",
    type: "Performance Bollywood",
    revenue: "754067",
    spends: "2201152",
    subs: "1521",
    cps: "1447",
    arpu: "496",
  },
  {
    created_at_time: "2024-05-01",
    type: "Performance Bollywood",
    revenue: "268725",
    spends: "2939355",
    subs: "2661",
    cps: "1105",
    arpu: "477",
  },
  {
    created_at_time: "2024-06-01",
    type: "Performance Bollywood",
    revenue: "792273",
    spends: "5204090",
    subs: "3811",
    cps: "1366",
    arpu: "470",
  },
  {
    created_at_time: "2023-06-01",
    type: "Affiliate",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2023-07-01",
    type: "Affiliate",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2023-08-01",
    type: "Affiliate",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2023-09-01",
    type: "Affiliate",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2023-10-01",
    type: "Affiliate",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2023-11-01",
    type: "Affiliate",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2023-12-01",
    type: "Affiliate",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2024-01-01",
    type: "Affiliate",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2024-02-01",
    type: "Affiliate",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2024-03-01",
    type: "Affiliate",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2024-04-01",
    type: "Affiliate",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2024-05-01",
    type: "Affiliate",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2024-06-01",
    type: "Affiliate",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2023-06-01",
    type: "Total Performance",
    revenue: "867935",
    spends: "28323267",
    subs: "26197",
    cps: "1081",
    arpu: "529",
  },
  {
    created_at_time: "2023-07-01",
    type: "Total Performance",
    revenue: "206725",
    spends: "49679194",
    subs: "15143",
    cps: "3281",
    arpu: "542",
  },
  {
    created_at_time: "2023-08-01",
    type: "Total Performance",
    revenue: "564590",
    spends: "3860503",
    subs: "2960",
    cps: "1304",
    arpu: "529",
  },
  {
    created_at_time: "2023-09-01",
    type: "Total Performance",
    revenue: "937166",
    spends: "14508990",
    subs: "11526",
    cps: "1259",
    arpu: "515",
  },
  {
    created_at_time: "2023-10-01",
    type: "Total Performance",
    revenue: "818955",
    spends: "11189775",
    subs: "9523",
    cps: "1175",
    arpu: "506",
  },
  {
    created_at_time: "2023-11-01",
    type: "Total Performance",
    revenue: "776643",
    spends: "16476006",
    subs: "13040",
    cps: "1263",
    arpu: "543",
  },
  {
    created_at_time: "2023-12-01",
    type: "Total Performance",
    revenue: "480215",
    spends: "1589533",
    subs: "2731",
    cps: "582",
    arpu: "542",
  },
  {
    created_at_time: "2024-01-01",
    type: "Total Performance",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2024-02-01",
    type: "Total Performance",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2024-03-01",
    type: "Total Performance",
    revenue: "0",
    spends: "0",
    subs: "0",
    cps: "0",
    arpu: "0",
  },
  {
    created_at_time: "2024-04-01",
    type: "Total Performance",
    revenue: "410389",
    spends: "5385141",
    subs: "4944",
    cps: "1089",
    arpu: "488",
  },
  {
    created_at_time: "2024-05-01",
    type: "Total Performance",
    revenue: "756426",
    spends: "13447749",
    subs: "16873",
    cps: "797",
    arpu: "460",
  },
  {
    created_at_time: "2024-06-01",
    type: "Total Performance",
    revenue: "447958",
    spends: "11130606",
    subs: "9844",
    cps: "1131",
    arpu: "462",
  },
  {
    created_at_time: "2023-06-01",
    type: "Auto Renewals",
    revenue: "491673",
    spends: "0",
    subs: "8853",
    cps: "0",
    arpu: "394",
  },
  {
    created_at_time: "2023-07-01",
    type: "Auto Renewals",
    revenue: "244968",
    spends: "0",
    subs: "10465",
    cps: "0",
    arpu: "406",
  },
  {
    created_at_time: "2023-08-01",
    type: "Auto Renewals",
    revenue: "362249",
    spends: "0",
    subs: "10223",
    cps: "0",
    arpu: "427",
  },
  {
    created_at_time: "2023-09-01",
    type: "Auto Renewals",
    revenue: "872245",
    spends: "0",
    subs: "12261",
    cps: "0",
    arpu: "397",
  },
  {
    created_at_time: "2023-10-01",
    type: "Auto Renewals",
    revenue: "438815",
    spends: "0",
    subs: "11413",
    cps: "0",
    arpu: "424",
  },
  {
    created_at_time: "2023-11-01",
    type: "Auto Renewals",
    revenue: "262371",
    spends: "0",
    subs: "9684",
    cps: "0",
    arpu: "440",
  },
  {
    created_at_time: "2023-12-01",
    type: "Auto Renewals",
    revenue: "918191",
    spends: "0",
    subs: "11381",
    cps: "0",
    arpu: "432",
  },
  {
    created_at_time: "2024-01-01",
    type: "Auto Renewals",
    revenue: "440800",
    spends: "0",
    subs: "10066",
    cps: "0",
    arpu: "441",
  },
  {
    created_at_time: "2024-02-01",
    type: "Auto Renewals",
    revenue: "447557",
    spends: "0",
    subs: "9183",
    cps: "0",
    arpu: "441",
  },
  {
    created_at_time: "2024-03-01",
    type: "Auto Renewals",
    revenue: "383923",
    spends: "0",
    subs: "9664",
    cps: "0",
    arpu: "454",
  },
  {
    created_at_time: "2024-04-01",
    type: "Auto Renewals",
    revenue: "787571",
    spends: "0",
    subs: "9999",
    cps: "0",
    arpu: "479",
  },
  {
    created_at_time: "2024-05-01",
    type: "Auto Renewals",
    revenue: "937058",
    spends: "0",
    subs: "10066",
    cps: "0",
    arpu: "490",
  },
  {
    created_at_time: "2024-06-01",
    type: "Auto Renewals",
    revenue: "565350",
    spends: "0",
    subs: "13001",
    cps: "0",
    arpu: "505",
  },
  {
    created_at_time: "2023-06-01",
    type: "Performance Tollywood",
    revenue: "770507",
    spends: "6986778",
    subs: "15783",
    cps: "443",
    arpu: "492",
  },
  {
    created_at_time: "2023-07-01",
    type: "Performance Tollywood",
    revenue: "983772",
    spends: "2791613",
    subs: "17050",
    cps: "164",
    arpu: "527",
  },
  {
    created_at_time: "2023-08-01",
    type: "Performance Tollywood",
    revenue: "682246",
    spends: "4722144",
    subs: "15487",
    cps: "305",
    arpu: "561",
  },
  {
    created_at_time: "2023-09-01",
    type: "Performance Tollywood",
    revenue: "533054",
    spends: "3134055",
    subs: "9638",
    cps: "325",
    arpu: "522",
  },
  {
    created_at_time: "2023-10-01",
    type: "Performance Tollywood",
    revenue: "152246",
    spends: "1588969",
    subs: "6252",
    cps: "254",
    arpu: "504",
  },
  {
    created_at_time: "2023-11-01",
    type: "Performance Tollywood",
    revenue: "486698",
    spends: "6938483",
    subs: "8929",
    cps: "777",
    arpu: "536",
  },
  {
    created_at_time: "2023-12-01",
    type: "Performance Tollywood",
    revenue: "479308",
    spends: "394118",
    subs: "8658",
    cps: "46",
    arpu: "575",
  },
  {
    created_at_time: "2024-01-01",
    type: "Performance Tollywood",
    revenue: "457514",
    spends: "573646",
    subs: "7998",
    cps: "72",
    arpu: "532",
  },
  {
    created_at_time: "2024-02-01",
    type: "Performance Tollywood",
    revenue: "827752",
    spends: "444677",
    subs: "7921",
    cps: "56",
    arpu: "483",
  },
  {
    created_at_time: "2024-03-01",
    type: "Performance Tollywood",
    revenue: "173851",
    spends: "701274",
    subs: "13185",
    cps: "53",
    arpu: "392",
  },
  {
    created_at_time: "2024-04-01",
    type: "Performance Tollywood",
    revenue: "538478",
    spends: "2831137",
    subs: "12029",
    cps: "235",
    arpu: "477",
  },
  {
    created_at_time: "2024-05-01",
    type: "Performance Tollywood",
    revenue: "2085489",
    spends: "5184207",
    subs: "25093",
    cps: "207",
    arpu: "482",
  },
  {
    created_at_time: "2024-06-01",
    type: "Performance Tollywood",
    revenue: "541267",
    spends: "3330343",
    subs: "11896",
    cps: "280",
    arpu: "499",
  },
];

export { tableDataFromState, dailyWiseData };
