import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Highcharts from "highcharts";
import VennModule from "highcharts/modules/venn.js";

VennModule(Highcharts);
import HighchartsReact from "highcharts-react-official";

// const apiResponse = [{
//             sets: ['gujarati'],
//             value: 95
//         }, {
//             sets: ['bollywood'],
//             value: 94
//         }, {
//             sets: ['others'],
//             value: 90
//         }, {
//             sets: ['gujarati', 'bollywood'],
//             value: 12,
//             name: '40%'
//         }, {
//             sets: ['gujarati', 'others'],
//             value: 13,
//             name: '30%'
//         }, {
//             sets: ['bollywood', 'others'],
//             value: 10,
//             name: '20%'
//         }, {
//             sets: ['bollywood', 'others', 'gujarati'],
//             value: 15,
//             name: '23%'
//         }]

// Define a function to calculate percentage and format the response
const formatData = (data) => {
  const totalValue = Object.values(data).reduce((acc, value) => acc + value, 0);

  const response = Object.entries(data).map(([key, value]) => {
    const sets = key.split("_");
    const percentage = ((value / totalValue) * 100).toFixed(2) + "%";
    const result = {
      sets: sets,
      value: value,
    };
    if (sets.length > 1) {
      result.name = value + "%";
    }
    return result;
  });

  return response;
};

const VennDiagram = () => {
  const [data, setData] = useState([]);

  const usersContribution = useSelector(
    (state) => state.PlatformCategoryData.usersCategoryContribution
  );
  
  useEffect(() => {
    if (usersContribution) {
      const apiResponse = formatData(usersContribution);
      setData(apiResponse);
    }
  }, [usersContribution]);

  const vennOptions = {
    title: {
      text: "",
    },
    credits: { enabled: false },
    chart: {
      backgroundColor: "#F9F9F9",
      borderWidth: 0,
      borderColor: "#000",
      borderRadius: 0,
      className: "",
      animation: false,
      height: 240,
      width: 240,
    },
    colors: [
      "#00898D",
      "#007893",
      "#146588",
      "#009978",
      "#2BA558",
      "#78AD2F",
      "#BAAF00",
    ],
    series: [
      {
        type: "venn",
        name: "",
        data: data,
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={vennOptions} />
      <div className="d-flex justify-content-center align-content-center">
        <h4>Users</h4>
      </div>
    </div>
  );
};

export default VennDiagram;
