import { createSlice } from "@reduxjs/toolkit";
import { getPlbAnalysisData } from "./thunk";

export const initialState = {
  totalPlb: 0,
  b2b: 0,
  b2c: 0,
  plbChartData: null,
  status: "idle",
  error: {},
  loader: false,
};

const PLBAnalysisSlice = createSlice({
  name: "PLBAnalysis",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getPlbAnalysisData.pending, (state) => {
      state.status = "loading";
      state.loader = true;
    });
    builder.addCase(getPlbAnalysisData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.status = "succeeded";
        state.totalPlb = action.payload.data.chart_data.total_plb;
        state.b2b = action.payload.data.chart_data.b2b;
        state.b2c = action.payload.data.chart_data.b2c;
        state.plbChartData = action.payload.data.chart_data.daily_wise_data;
        state.loader = false;
      }
    });
    builder.addCase(getPlbAnalysisData.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
      state.loader = false;
    });
  },
});

export default PLBAnalysisSlice.reducer;
