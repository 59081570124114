import { createAsyncThunk } from "@reduxjs/toolkit";

// import needed API helpers.
import { getDauChart as getDauChartApi } from "../../../helpers/fakebackend_helper";

export const getDauChartData = createAsyncThunk(
  "dashboard/dauChart",
  async (data) => {
    try {
      const response = getDauChartApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
