import { createAsyncThunk } from "@reduxjs/toolkit";

import { getTopTitles as getTopTitlesApi } from "../../../helpers/fakebackend_helper";

export const getTopTitlesData = createAsyncThunk(
  "Consumption Highlights/Top Titles",
  async (data) => {
    try {
      const response = getTopTitlesApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
