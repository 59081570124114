module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    // API_URL: "https://datalake.shemaroome.com:3001/",
    //API_URL: "https://api-node.themesbrand.website",
    //API_URL:"http://10.4.3.229/api/"
    API_URL: "https://datalake.shemaroome.com:3001/",
  },
};
