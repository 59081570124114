// state.chartData = action.payload.chart_data;

const ftwData = [
  {
    title: "Ek Anjaan Rishtey Ka Guilt 3",
    count: "125",
    mou: 26500,
    mpu: 200,
  },
  {
    title: "Pyaar Ishq Aur Possession",
    count: "125",
    mou: 26500,
    mpu: 200,
  },
  {
    title: "Angithee 3",
    count: "125",
    mou: 26500,
    mpu: 200,
  },
  {
    title: "Kamthaan",
    count: "125",
    mou: 26500,
    mpu: 200,
  },
  {
    title: "Lagan Special",
    count: "125",
    mou: 26500,
    mpu: 200,
  },
  {
    title: "Naadi Dosh",
    count: "125",
    mou: 26500,
    mpu: 200,
  },
  {
    title: "Shakeela",
    count: "125",
    mou: 26500,
    mpu: 200,
  },
  {
    title: "Angithee 2",
    count: "125",
    mou: 26500,
    mpu: 200,
  },
  {
    title: "Vash",
    count: "125",
    mou: 26500,
    mpu: 200,
  },
  {
    title: "Hu Ane Tu",
    count: "125",
    mou: 26500,
    mpu: 200,
  },
];

export { ftwData };
