import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, Col } from "reactstrap";

import {
  getDauData,
  getStreamingDauData,
} from "../../../slices/HealthMatrix/ActiveUserAnalysis/thunk";

import AnalysisChart from "./analysisChart";
import { getMTDDateRange } from "../../../helpers/dateUtils";
import DateDropdown from "../../HealthMatrix/DateDropdown";

function AnalysisChartCard() {
  const dispatch = useDispatch();
  const [customDateRange, setCustomDateRange] = useState(getMTDDateRange());
  const [selectedValue, setSelectedValue] = useState("DAU");
  const [loading, setLoading] = useState(true);

  const fetchData = async (dateRange) => {
    setLoading(true);
    const bodyObject = {
      ...dateRange,
      period: "month",
      daily_wise_data: true,
      prev: true,
    };

    // await Promise.all([
    //   dispatch(getDauData(bodyObject)),
    //   dispatch(getStreamingDauData(bodyObject)),
    // ]);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(customDateRange);
  }, [customDateRange]);

  const handleDateRangeChange = (range) => {
    setCustomDateRange(range);
  };

  const handleRadioChange = (e) => {
    setSelectedValue(e.target.id);
  };

  return (
    <React.Fragment>
      <Col md="6">
        <Card className="card-styling" style={{ height: "388px" }}>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center justify-content-center ms-4">
              <div className="d-flex justify-content-center me-2">
                <input
                  type="radio"
                  id="DAU"
                  name="DAU"
                  className="sh-radio-button me-1"
                  defaultChecked
                  onChange={handleRadioChange}
                />
                <label htmlFor="DAU" className="sh-radio-text">
                  DAU
                </label>
              </div>
              <div className="d-flex justify-content-center me-2">
                <input
                  type="radio"
                  id="StreamingDAU"
                  name="DAU"
                  className="sh-radio-button me-1"
                  onChange={handleRadioChange}
                />
                <label htmlFor="StreamingDAU" className="sh-radio-text">
                  Streaming DAU
                </label>
              </div>
            </div>
            <div className="d-flex justify-content-end flex-row p-3">
              <DateDropdown
                onDateRangeChange={handleDateRangeChange}
                options={["MTD", "Last Month", "3 Months", "Custom"]}
              />
            </div>
          </div>

          {!loading ? (
            <AnalysisChart
              selectedValue={selectedValue}
              dataColors='["#146588","#00898D"]'
            />
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "300px" }}
            >
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </Card>
      </Col>
    </React.Fragment>
  );
}

export default AnalysisChartCard;
