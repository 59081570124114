import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import DateDropdown from "../../HealthMatrix/DateDropdown";
import SubscriptionsChart from "./subscriptionsChart";
import { getYTDDateRange } from "../../../helpers/dateUtils";
import { getSubsArpuCpsData } from "../../../slices/RevenueMatrix/Subscription/thunk";
import Gujarati from "./gujarati";
import NonGujarati from "./nonGujarati";
import FilterSection from "./filterSection";
import { allchartData } from "../../../common/data/RevenueMatrix/subscriptionData";

const filterOptions = {
  platforms: {
    All: "android,web,tv,ios",
    Android: "android",
    Web: "web",
    Tv: "tv",
    iOS: "ios",
  },
  region: {
    All: "india,row",
    India: "india",
    ROW: "row",
  },
};

function Subscription() {
  const dispatch = useDispatch();
  const [customDateRange, setCustomDateRange] = useState(getYTDDateRange());
  const [filters, setFilters] = useState({
    start_date: customDateRange.start_date,
    end_date: customDateRange.end_date,
    platforms: filterOptions.platforms.All,
    region: filterOptions.region.All,
    filter_type: "all",
    type: "all",
  });
  const [selectedOption, setSelectedOption] = useState("all");
  const [allData, setAllData] = useState([]);
  const [loader, setLoader] = useState(true);

  // const { allchartData, loader } = useSelector((state) => state.SubsApruCps);

  useEffect(() => {
    if (allchartData.length > 0) {
      setAllData(allchartData);
      setLoader(false);
    }
  }, [allchartData]);

  useEffect(() => {
    setSelectedOption(filters.filter_type);
  }, [filters.filter_type]);

  const fetchData = async (dateRange) => {
    const dataObject = {
      ...filters,
      ...dateRange,
    };
    // dispatch(getSubsArpuCpsData(dataObject));
    // console.log("all dispatch");
  };

  useEffect(() => {
    fetchData(customDateRange);
  }, [customDateRange, filters]);

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const handleDateRangeChange = (range) => {
    setCustomDateRange(range);
    setFilters((prevFilters) => ({
      ...prevFilters,
      start_date: range.startDate,
      end_date: range.endDate,
    }));
  };

  const handleResetFilters = () => {
    setFilters({
      daily_wise_data: true,
      platforms: filterOptions.platforms.All,
      user_type: "Paid,Free",
      region: filterOptions.region.All,
      filter_type: "all",
      type: "all",
    });
    setSelectedOption("all");
  };

  const handleRadioChange = (event) => {
    const value = event.target.id;
    setSelectedOption(value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      filter_type: value,
    }));
  };

  return (
    <React.Fragment>
      <Container fluid className="customContainerStyle">
        <Row>
          <Col style={{ padding: "20px" }}>
            <h4 className="card-heading-text">Subscription, ARPU & CPS</h4>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-between">
            <FilterSection
              filterOptions={filterOptions}
              filters={filters}
              handleFilterChange={handleFilterChange}
              handleResetFilters={handleResetFilters}
            />
            <div className="d-flex align-items-center me-5">
              <div className="d-flex justify-content-center me-2">
                <input
                  type="radio"
                  id="all"
                  name="subscriptions"
                  className="sh-radio-button me-1"
                  checked={selectedOption === "all"}
                  onChange={handleRadioChange}
                />
                <label htmlFor="all" className="sh-radio-text">
                  All
                </label>
              </div>
              <div className="d-flex justify-content-center me-2">
                <input
                  type="radio"
                  id="performance"
                  name="subscriptions"
                  className="sh-radio-button me-1"
                  checked={selectedOption === "performance"}
                  onChange={handleRadioChange}
                />
                <label htmlFor="performance" className="sh-radio-text">
                  Performance
                </label>
              </div>
              <div className="d-flex justify-content-center me-2">
                <input
                  type="radio"
                  id="organic"
                  name="subscriptions"
                  className="sh-radio-button me-1"
                  checked={selectedOption === "organic"}
                  onChange={handleRadioChange}
                />
                <label htmlFor="organic" className="sh-radio-text">
                  Organic
                </label>
              </div>
            </div>
            <DateDropdown
              onDateRangeChange={handleDateRangeChange}
              options={["1 Year", "6 Months", "Custom"]}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3" style={{ height: "450px" }}>
            <SubscriptionsChart
              dataColors='["#146588", "#FFA600","#2BA558"]'
              data={allData}
              loader={loader}
            />
          </Col>
        </Row>
        <Row>
          <Gujarati
            selectedPlatform={filters.platforms}
            selectedRegion={filters.region}
            customDateRange={customDateRange}
          />
          <NonGujarati
            selectedPlatform={filters.platforms}
            selectedRegion={filters.region}
            customDateRange={customDateRange}
          />
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Subscription;
