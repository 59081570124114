// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   Card,
//   Col,
//   UncontrolledDropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
//   Table,
// } from "reactstrap";

// import { getActiveUserAnalysisData } from "../../../slices/HealthMatrix/ActiveUserAnalysis/thunk";
// import DateDropdown from "../../HealthMatrix/DateDropdown";
// import filterImage from "../../../assets/images/saranyuImages/filter.svg";
// import resetFilterImage from "../../../assets/images/saranyuImages/Reset Filter.svg";
// import { getQTDDateRange } from "../../../helpers/dateUtils";

// const formatDate = (dateString) => {
//   const date = new Date(dateString);
//   const options = { month: "short" };
//   const month = new Intl.DateTimeFormat("en-US", options).format(date);
//   const year = date.getFullYear().toString().slice(-2);
//   return `${month}-${year}`;
// };

// const arrayColors = [
//   "#1B2431",
//   "#78AD2F",
//   "#2BA558",
//   "#009978",
//   "#00898D",
//   "#007893",
//   "#009978",
//   "#78AD2F",
//   "#2BA558",
//   "#009978",
//   "#00898D",
//   "#007893",
//   "#2BA558",
// ];
// const arrayTypes = ["DAU", "MAU", "Streaming DAU", "Streaming MAU"];

// const filterOptions = {
//   user_type: {
//     All: "Paid,Free",
//     Paid: "Paid",
//     Free: "Free",
//   },
//   platforms: {
//     All: "android,web,tv,ios",
//     Android: "android",
//     Web: "web",
//     Tv: "tv",
//     iOS: "ios",
//   },
//   region: {
//     All: "india,row",
//     India: "india",
//     ROW: "row",
//   },
// };

// function AnalysisTableCard() {
//   const dispatch = useDispatch();
//   const tableDataFromState = useSelector(
//     (state) => state.ActiveUserAnalysis?.tableData
//   );
//   const tabelLoader = useSelector(
//     (state) => state.ActiveUserAnalysis?.tableLoader
//   );

//   const [customDateRange, setCustomDateRange] = useState(getQTDDateRange());
//   const [filters, setFilters] = useState({
//     start_date: customDateRange.startDate,
//     end_date: customDateRange.endDate,
//     platforms: "android,ios,web,external",
//     user_type: "Paid,Free",
//     region: "india,row",
//   });

//   const [selectedUserType, setSelectedUserType] = useState("User Type");
//   const [selectedPlatform, setSelectedPlatform] = useState("Platform");
//   const [selectedRegion, setSelectedRegion] = useState("Region");
//   const [headerValues, setHeaderValues] = useState([]);
//   const [combinedArrays, setCombinedArrays] = useState([]);

//   const fetchData = async (dateRange) => {
//     const dataObject = {
//       ...filters,
//       ...dateRange,
//     };
//     dispatch(getActiveUserAnalysisData(dataObject));
//   };

//   useEffect(() => {
//     fetchData(customDateRange);
//   }, [customDateRange, filters]);

//   const handleFilterChange = (filterType, value) => {
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       [filterType]: value,
//     }));

//     switch (filterType) {
//       case "user_type":
//         setSelectedUserType(value || "User Type");
//         break;
//       case "platforms":
//         setSelectedPlatform(value || "Platform");
//         break;
//       case "region":
//         setSelectedRegion(value || "Region");
//         break;
//       default:
//         break;
//     }
//   };

//   const handleDateRangeChange = (range) => {
//     setCustomDateRange(range);
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       start_date: range.startDate,
//       end_date: range.endDate,
//     }));
//   };

//   const handleResetFilters = () => {
//     setFilters({
//       daily_wise_data: true,
//       platforms: "android,ios,web,external",
//       user_type: "Paid,Free",
//       region: "india,row",
//     });
//     setSelectedUserType("User Type");
//     setSelectedPlatform("Platform");
//     setSelectedRegion("Region");
//   };

//   useEffect(() => {
//     if (!tabelLoader && tableDataFromState) {
//       const reversedArray = [...tableDataFromState].reverse();
//       const headerArray = reversedArray.map((obj) => formatDate(obj.month));
//       setHeaderValues(["Type", ...headerArray]);

//       const combinedArray = reversedArray.map((item) => [
//         item.dau ? Number(parseInt(item.dau)).toLocaleString() : "N/A",
//         item.mau ? Number(parseInt(item.mau)).toLocaleString() : "N/A",
//         item.streaming_dau
//           ? Number(parseInt(item.streaming_dau)).toLocaleString()
//           : "N/A",
//         item.streaming_mau
//           ? Number(parseInt(item.streaming_mau)).toLocaleString()
//           : "N/A",
//       ]);

//       setCombinedArrays(combinedArray);
//     }
//   }, [tabelLoader, tableDataFromState]);

//   return (
//     <React.Fragment>
//       <Col md="6">
//         <Card className="card-styling " style={{ height: "388px" }}>
//           <div className="d-flex justify-content-between p-3">
//             <div className="d-flex justify-content-center ms-1">
//               <button className="sh-funnel-filter">
//                 <img src={filterImage} alt="filter" />
//               </button>
//               <UncontrolledDropdown>
//                 <DropdownToggle
//                   tag="button"
//                   className="sh-filter-dropdown sh-filter-text"
//                   id="dropdownMenuButton"
//                 >
//                   &nbsp; &nbsp;{selectedUserType} &nbsp;&nbsp;
//                   <i className="mdi mdi-chevron-down"></i>
//                 </DropdownToggle>
//                 <DropdownMenu>
//                   {["Paid", "Free"].map((type) => (
//                     <DropdownItem
//                       key={type}
//                       onClick={() =>
//                         handleFilterChange(
//                           "user_type",
//                           type === "User Type" ? "" : type
//                         )
//                       }
//                     >
//                       {type}
//                     </DropdownItem>
//                   ))}
//                 </DropdownMenu>
//               </UncontrolledDropdown>
//               <UncontrolledDropdown>
//                 <DropdownToggle
//                   tag="button"
//                   className="sh-filter-dropdown sh-filter-text"
//                   id="dropdownMenuButton"
//                 >
//                   &nbsp; &nbsp;{selectedPlatform} &nbsp;&nbsp;
//                   <i className="mdi mdi-chevron-down"></i>
//                 </DropdownToggle>
//                 <DropdownMenu>
//                   {["Android", "iOS", "Web", "Tv"].map((platform) => (
//                     <DropdownItem
//                       key={platform}
//                       onClick={() =>
//                         handleFilterChange(
//                           "platforms",
//                           platform === "Platform" ? "" : platform.toLowerCase()
//                         )
//                       }
//                     >
//                       {platform}
//                     </DropdownItem>
//                   ))}
//                 </DropdownMenu>
//               </UncontrolledDropdown>
//               <UncontrolledDropdown>
//                 <DropdownToggle
//                   tag="button"
//                   className="sh-filter-dropdown sh-filter-text"
//                   id="dropdownMenuButton"
//                 >
//                   &nbsp; &nbsp;{selectedRegion} &nbsp;&nbsp;
//                   <i className="mdi mdi-chevron-down"></i>
//                 </DropdownToggle>
//                 <DropdownMenu>
//                   {["India", "ROW"].map((region) => (
//                     <DropdownItem
//                       key={region}
//                       onClick={() =>
//                         handleFilterChange(
//                           "region",
//                           region === "Region" ? "IN" : region.toLowerCase()
//                         )
//                       }
//                     >
//                       {region}
//                     </DropdownItem>
//                   ))}
//                 </DropdownMenu>
//               </UncontrolledDropdown>
//               <button className="sh-filter-reset" onClick={handleResetFilters}>
//                 <img src={resetFilterImage} alt="reset filter" />
//               </button>
//             </div>
//             <DateDropdown
//               onDateRangeChange={handleDateRangeChange}
//               options={["3 Months", "6 Months", "1 Year"]}
//             />
//           </div>

//           <div className="table-responsive ms-4 me-4 mt-4 sh-table-styling">
//             <Table className="table-nowrap table-bordered mb-0" size="sm">
//               <thead className="text-white text-center">
//                 <tr className="table-row sh-table-header">
//                   {headerValues.map((value, index) => (
//                     <th
//                       scope="col"
//                       style={{ backgroundColor: `${arrayColors[index]}` }}
//                       key={index}
//                       className="text-center"
//                     >
//                       {value}
//                     </th>
//                   ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {arrayTypes.map((value, index) => (
//                   <tr key={index} className="sh-table-text">
//                     <td className="text-start align-middle">{value}</td>
//                     {combinedArrays.map((arr, i) => (
//                       <td key={i} className="text-center align-middle">
//                         {arr[index]}
//                       </td>
//                     ))}
//                   </tr>
//                 ))}
//               </tbody>
//             </Table>
//           </div>
//         </Card>
//       </Col>
//     </React.Fragment>
//   );
// }

// export default AnalysisTableCard;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Table } from "reactstrap";

import { getActiveUserAnalysisData } from "../../../slices/HealthMatrix/ActiveUserAnalysis/thunk";
import DateDropdown from "../../HealthMatrix/DateDropdown";
import FilterSection from "../../RevenueMatrix/Subscription/filterSection"; // Adjust the import path as needed
import { getQTDDateRange } from "../../../helpers/dateUtils";
import { tableData as tableDataFromState } from "../../../common/data/HealthMatrix/activeUserAnalysisData";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { month: "short" };
  const month = new Intl.DateTimeFormat("en-US", options).format(date);
  const year = date.getFullYear().toString().slice(-2);
  return `${month}-${year}`;
};

const arrayColors = [
  "#1B2431",
  "#78AD2F",
  "#2BA558",
  "#009978",
  "#00898D",
  "#007893",
  "#009978",
  "#78AD2F",
  "#2BA558",
  "#009978",
  "#00898D",
  "#007893",
  "#2BA558",
];

const arrayTypes = ["DAU", "MAU", "Streaming DAU", "Streaming MAU"];

const filterOptions = {
  user_type: {
    All: "Paid,Free",
    Paid: "Paid",
    Free: "Free",
  },
  platforms: {
    All: "android,web,tv,ios",
    Android: "android",
    Web: "web",
    Tv: "tv",
    iOS: "ios",
  },
  region: {
    All: "india,row",
    India: "india",
    ROW: "row",
  },
};

function AnalysisTableCard() {
  const dispatch = useDispatch();
  // const tableDataFromState = useSelector(
  //   (state) => state.ActiveUserAnalysis?.tableData
  // );
  // const { tableLoader } = useSelector((state) => state.ActiveUserAnalysis);
  const [loader, setLoader] = useState(true);

  const [customDateRange, setCustomDateRange] = useState(getQTDDateRange());
  const [filters, setFilters] = useState({
    start_date: customDateRange.start_date,
    end_date: customDateRange.end_date,
    platforms: filterOptions.platforms.All,
    user_type: filterOptions.user_type.All,
    region: filterOptions.region.All,
  });

  const [headerValues, setHeaderValues] = useState([]);
  const [combinedArrays, setCombinedArrays] = useState([]);

  const fetchData = async (dateRange) => {
    const dataObject = {
      ...filters,
      ...dateRange,
    };
    // dispatch(getActiveUserAnalysisData(dataObject));
  };

  useEffect(() => {
    fetchData(customDateRange);
  }, [customDateRange, filters]);

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const handleDateRangeChange = (range) => {
    setCustomDateRange(range);
    setFilters((prevFilters) => ({
      ...prevFilters,
      start_date: range.startDate,
      end_date: range.endDate,
    }));
  };

  const handleResetFilters = () => {
    setFilters({
      platforms: filterOptions.platforms.All,
      user_type: filterOptions.user_type.All,
      region: filterOptions.region.All,
    });
  };

  useEffect(() => {
    if (loader && tableDataFromState.length > 0) {
      const reversedArray = [...tableDataFromState].reverse();
      const headerArray = reversedArray.map((obj) => formatDate(obj.month));
      setHeaderValues(["Type", ...headerArray]);

      const combinedArray = reversedArray.map((item) => [
        item.dau ? Number(parseInt(item.dau)).toLocaleString() : "N/A",
        item.mau ? Number(parseInt(item.mau)).toLocaleString() : "N/A",
        item.streaming_dau
          ? Number(parseInt(item.streaming_dau)).toLocaleString()
          : "N/A",
        item.streaming_mau
          ? Number(parseInt(item.streaming_mau)).toLocaleString()
          : "N/A",
      ]);

      setCombinedArrays(combinedArray);
      setLoader(false);
    }
  }, [tableDataFromState]);

  return (
    <React.Fragment>
      <Col md="6">
        <Card className="card-styling" style={{ height: "388px" }}>
          <div className="d-flex justify-content-end p-3">
            <DateDropdown
              onDateRangeChange={handleDateRangeChange}
              options={["3 Months", "6 Months", "1 Year"]}
            />
          </div>
          <div className=" d-flex justify-content-start ms-4">
            <FilterSection
              filterOptions={filterOptions}
              filters={filters}
              handleFilterChange={handleFilterChange}
              handleResetFilters={handleResetFilters}
            />
          </div>
          {!loader ? (
            <div className="table-responsive ms-4 me-4 mt-4 sh-table-styling">
              <Table className="table-nowrap table-bordered mb-0" size="sm">
                <thead className="text-white text-center">
                  <tr className="table-row sh-table-header">
                    {headerValues.map((value, index) => (
                      <th
                        scope="col"
                        style={{ backgroundColor: `${arrayColors[index]}` }}
                        key={index}
                        className="text-center"
                      >
                        {value}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {arrayTypes.map((value, index) => (
                    <tr key={index} className="sh-table-text">
                      <td className="text-start align-middle">{value}</td>
                      {combinedArrays.map((arr, i) => (
                        <td key={i} className="text-center align-middle">
                          {arr[index]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "200px" }}
            >
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </Card>
      </Col>
    </React.Fragment>
  );
}

export default AnalysisTableCard;
