import { createSlice } from "@reduxjs/toolkit";
import {
  getTotalMOUData,
  getTotalMPUData,
  getPaidMOUData,
  getPaidMPUData,
  getTop10MoviesData,
} from "./thunk";

export const initialState = {
  MPUChartData: null,
  MOUChartData: null,
  paidMOUData: null,
  paidMPUData: null,
  totalMOUData: null,
  totalMPUData: null,
  top10MoviesData: null,
  paidMpuWidgetLoader: true,
  paidMouWidgetLoader: true,
  top10MoviesDataLoader: true,
  chartLoaderMou: true,
  chartLoaderMpu: true,
  error: {},
};

const MOUsOverViewSlice = createSlice({
  name: "MOUsOverview",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getTotalMOUData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.totalMOUData = action.payload.data;
        state.MOUChartData = action.payload.data.daily_wise_data;
        state.chartLoaderMou = false;
      }
    });
    builder.addCase(getTotalMOUData.rejected, (state, action) => {
      state.error = action.error.message;
      state.chartLoaderMou = true;
    });
    builder.addCase(getTotalMPUData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.totalMPUData = action.payload.data;
        state.MPUChartData = action.payload.data.daily_wise_data;
        state.chartLoaderMpu = false;
      }
    });
    builder.addCase(getTotalMPUData.rejected, (state, action) => {
      state.error = action.error.message;
      state.chartLoaderMpu = true;
    });

    builder.addCase(getPaidMOUData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.paidMOUData = action.payload.data;
        state.paidMouWidgetLoader = false;
      }
    });
    builder.addCase(getPaidMOUData.rejected, (state, action) => {
      state.error = action.error.message;
      state.paidMouWidgetLoader = true;
    });

    builder.addCase(getPaidMPUData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.paidMPUData = action.payload.data;
        state.paidMpuWidgetLoader = false;
      }
    });
    builder.addCase(getPaidMPUData.rejected, (state, action) => {
      state.error = action.error.message;
      state.paidMpuWidgetLoader = true;
    });

    builder.addCase(getTop10MoviesData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.top10MoviesData = action.payload.data?.chart_data;
        state.top10MoviesDataLoader = false;
      }
    });
    builder.addCase(getTop10MoviesData.rejected, (state, action) => {
      state.error = action.error.message;
      state.top10MoviesDataLoader = true;
    });
  },
});

export default MOUsOverViewSlice.reducer;
