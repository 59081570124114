import React from "react";
import { Card, Col, Container, Row } from "reactstrap";

// import Components
import BreadCrumb from "../../Components/Common/BreadCrumb";
import HourlyTrendIndia from "./HourlyTrendIndia";
import HourlyTrendRow from "./HourlyTrendROW";
import TopTransactingLocations from "./TopTransactingLocations";

const TodaysHighlights = () => {
  document.title = "Todays Highlights | Saranyu -  Admin & Dashboard";
  return (
    <React.Fragment>
      <div className="page-content">
        <TopTransactingLocations />
        <HourlyTrendIndia />
        <HourlyTrendRow />
      </div>
    </React.Fragment>
  );
};

export default TodaysHighlights;
