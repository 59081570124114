import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import { revenueData as chartData } from "../../../common/data/RevenueMatrix/revenueTrendData";

const formatMonth = (dateString) => {
  const dateObject = new Date(dateString);
  const monthAbbr = dateObject
    .toLocaleString("default", { month: "short" })
    .toUpperCase();
  const yearLastTwoDigits = dateObject.getFullYear().toString().slice(-2);
  return `${monthAbbr}'${yearLastTwoDigits}`;
};

const RevenueColumnChart = ({ dataColors }) => {
  // const { chartData, loader } = useSelector((state) => state.RevenueTrend);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    if (chartData.length > 0 && loader) {
      // console.log("Chart Data Revenue Trend", chartData);
      setData(chartData);
      setLoader(false);
    }
  }, [chartData, loader]);
  var columnChartColors = getChartColorsArray(dataColors);
  // console.log("chartdata revenue", chartData);

  const series = [
    {
      name: "Gujarati Spend",
      group: "Gujarati",
      data: data.map((item) => item.Gujarati_Spend),
    },
    {
      name: "Non Gujarati Spend",
      group: "Non Gujarati",
      data: data.map((item) => item.Non_Gujarati_Spend),
    },
    {
      name: "Gujarati Revenue",
      group: "Gujarati",
      data: data.map((item) => item.Gujarati_Revenue),
    },
    {
      name: "Non Gujarati Revenue",
      group: "Non Gujarati",
      data: data.map((item) => item.Non_Gujarati_Revenue),
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 355,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    dataLabels: {
      enabled: false,
      formatter: (val) => {
        return val / 100000 + "L";
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      //   categories: ["JAN'24", "FEB'24", "MAR'24", "APR'24", "MAY'24", "JUN'24"],
      categories: data.map((item) => formatMonth(item.month)),
    },
    fill: {
      opacity: 1,
    },
    colors: columnChartColors,
    yaxis: {
      labels: {
        formatter: function (val) {
          return val !== undefined ? (val / 100000).toFixed(0) + "" : "";
        },
      },
      title: {
        text: "DATA IN LAKHS",
        style: {
          fontWeight: 600,
        },
      },
      min: 0,
      tickAmount: 4,
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        formatter: function (val) {
          return val !== undefined ? val : ""; // Display actual values on x-axis in tooltip
        },
      },
      y: {
        show: true,
        formatter: function (val) {
          return val !== undefined
            ? parseInt(val.toFixed(0)).toLocaleString()
            : ""; // Display actual values in tooltip without formatting
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    color: columnChartColors,
  };

  return (
    <div>
      {!loader ? (
        <ReactApexChart
          dir="ltr"
          className="apex-charts"
          series={series}
          options={options}
          type="bar"
          height={355}
        />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "350px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default RevenueColumnChart;
