// state.data = action.payload.data.chart_data;

const organicData = {
  gujarati: [
    {
      packwise: "Quarterly",
      revenue: "32000",
      subs: "70",
      arpu: 400,
      subs_percent: 14.34108527131783,
      revenue_percent: 9.52454523171085,
    },
    {
      packwise: "Monthly",
      revenue: "30000",
      subs: "100",
      arpu: 200,
      subs_percent: 3.10077519379845,
      revenue_percent: 0.9460071752742722,
    },
    {
      packwise: "Half Yearly",
      revenue: "4000",
      subs: "80",
      arpu: 500,
      subs_percent: 1.550387596899225,
      revenue_percent: 1.2279672284574645,
    },
    {
      packwise: "Yearly",
      revenue: "200000",
      subs: "300",
      arpu: "600",
      subs_percent: 69.76744186046511,
      revenue_percent: 72.9865036507736,
    },
    {
      packwise: "Two Yearly",
      revenue: "51000",
      subs: "50",
      arpu: "888",
      subs_percent: 11.24031007751938,
      revenue_percent: 15.314976713783807,
    },
    {
      packwise: "Total",
      revenue: "330000.50",
      subs: "500",
      arpu: "600.27",
      subs_percent: 100,
      revenue_percent: 100,
    },
  ],
  non_gujarati: [
    {
      packwise: "Monthly",
      revenue: "3000",
      subs: "200",
      arpu: 100,
      subs_percent: 3.111111111111111,
      revenue_percent: 0.8928413299817346,
    },
    {
      packwise: "Half Yearly",
      revenue: "1600",
      subs: "200",
      arpu: 500,
      subs_percent: 4.148148148148148,
      revenue_percent: 3.81907506738815,
    },
    {
      packwise: "Two Yearly",
      revenue: "76000.00",
      subs: "700",
      arpu: "900.71",
      subs_percent: 11.555555555555555,
      revenue_percent: 18.081867008910706,
    },
    {
      packwise: "Yearly",
      revenue: "23000.50",
      subs: "300",
      arpu: "600.30",
      subs_percent: 50.22222222222222,
      revenue_percent: 55.259463563171295,
    },
    {
      packwise: "Quarterly",
      revenue: "90000",
      subs: "200",
      arpu: 400,
      subs_percent: 30.962962962962965,
      revenue_percent: 21.946753030548113,
    },
    {
      packwise: "Total",
      revenue: "42000.50",
      subs: "600",
      arpu: "600.38",
      subs_percent: 100,
      revenue_percent: 100,
    },
  ],
  grand: [
    {
      revenue: "760052.00",
      subs: 1191,
      arpu: "638.16",
    },
  ],
};

export { organicData };
