export function getMTDDateRange() {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  let startDate;
  let endDate;

  if (currentDay >= 3) {
    startDate = new Date(currentYear, currentMonth, 1);
    endDate = new Date(currentYear, currentMonth, currentDay - 1);
  } else {
    startDate = new Date(currentYear, currentMonth - 1, 1);
    endDate = new Date(currentYear, currentMonth, 0);
  }

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return {
    start_date: formatDate(startDate),
    end_date: formatDate(endDate),
  };
}

export function getLastMonthDateRange() {
  const date = new Date();
  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();

  const startDate = new Date(currentYear, currentMonth - 1, 1);
  const endDate = new Date(currentYear, currentMonth, 0);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return {
    start_date: formatDate(startDate),
    end_date: formatDate(endDate),
  };
}

// export function getQTDDateRange() {
//   const date = new Date();
//   const currentDay = date.getDate();
//   const currentMonth = date.getMonth();
//   const currentYear = date.getFullYear();

//   // Calculate the start date of the last 3 months
//   const startMonth = currentMonth - 2;
//   let startDate;
//   if (startMonth < 0) {
//     startDate = new Date(currentYear - 1, startMonth + 12, 1);
//   } else {
//     startDate = new Date(currentYear, startMonth, 1);
//   }

//   // Calculate the end date as the current date minus 2 days
//   const endDate = new Date(currentYear, currentMonth, currentDay - 2);

//   const formatDate = (date) => {
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const day = String(date.getDate()).padStart(2, "0");
//     return `${year}-${month}-${day}`;
//   };

//   return {
//     start_date: formatDate(startDate),
//     end_date: formatDate(endDate),
//   };
// }

export function getQTDDateRange() {
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth();
  const currentDay = date.getDate();

  // Calculate the end date as the last day of the previous month
  const endDate = new Date(currentYear, currentMonth, 0);

  // Calculate the start date as the first day of the month three months ago
  let startYear = currentYear;
  let startMonth = currentMonth - 3;
  if (startMonth < 0) {
    startYear -= 1;
    startMonth += 12;
  }
  const startDate = new Date(startYear, startMonth, 1);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return {
    start_date: formatDate(startDate),
    end_date: formatDate(endDate),
  };
}

export function getLastSixMonthsDateRange() {
  const date = new Date();
  const currentDay = date.getDate();
  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();

  // Calculate the start date of the last 6 months
  const startMonth = currentMonth - 5;
  let startDate;
  if (startMonth < 0) {
    startDate = new Date(currentYear - 1, startMonth + 12, 1);
  } else {
    startDate = new Date(currentYear, startMonth, 1);
  }

  // Calculate the end date as the current date minus 2 days
  const endDate = new Date(currentYear, currentMonth, currentDay - 2);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return {
    start_date: formatDate(startDate),
    end_date: formatDate(endDate),
  };
}

export function getYTDDateRange() {
  const date = new Date();
  const currentDay = date.getDate();
  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();

  // Calculate the start date of the last year
  const startDate = new Date(currentYear - 1, currentMonth, 1);

  // Calculate the end date as the current date minus 2 days
  const endDate = new Date(currentYear, currentMonth, currentDay - 2);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return {
    start_date: formatDate(startDate),
    end_date: formatDate(endDate),
  };
}
