import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "reactstrap";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const arrayColors = [
  "#1B2431",
  "#78AD2F",
  "#2BA558",
  "#009978",
  "#00898D",
  "#007893",
];

function TopTranactingTable({ chartData, loader }) {
  const [headerValues, setHeaderValues] = useState([]);

  useEffect(() => {
    if (chartData && chartData.length > 0) {
      const headers = Object.keys(chartData[0]).map((item) =>
        capitalizeFirstLetter(item)
      );
      setHeaderValues(headers);
      //   setLoader(false);
    }
  }, [chartData]);

  return (
    <React.Fragment>
      {!loader ? (
        <div className="table-responsive sh-table-styling ms-4 me-4">
          <Table className="table-bordered table-nowrap mb-0" size="sm">
            <thead className="text-white text-center">
              <tr className="table-row sh-table-header">
                {headerValues.map((value, index) => (
                  <th
                    scope="col"
                    style={{ backgroundColor: arrayColors[index] }}
                    key={index}
                  >
                    {value}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-center">
              {chartData.map((valueObj, index) => (
                <tr key={index} className="table-row sh-table-text">
                  {Object.values(valueObj).map((value, i) => (
                    <td
                      key={i}
                      className="align-middle"
                      //   style={{ textAlign: i === 0 ? "left" : "center" }}
                    >
                      {value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "260px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default TopTranactingTable;
