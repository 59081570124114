import { createAsyncThunk } from "@reduxjs/toolkit";
import { getContentTypeAnalysis as getContentTypeApi } from "../../../helpers/fakebackend_helper";

export const getContentTypeData = createAsyncThunk(
  "Consumption Highlights/Content Type Analysis",
  async (data) => {
    try {
      const response = getContentTypeApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
