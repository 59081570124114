import React from "react";
import {
  Container,
  Row,
  Card,
  CardBody,
  CardText,
  CardHeader,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
} from "reactstrap";
// // import Components
// import BreadCrumb from "../../Components/Common/BreadCrumb";

// import { SimplePie } from "./ContentTypeAnalysisPieChart";

// import { StackedColumn } from "./PaidUserContributionChart";

import calendarImage from "../../assets/images/saranyuImages/calander.svg";
// import groupImage from "../../assets/images/saranyuImages/Group 328.svg";
// import b2cImage from "../../assets/images/saranyuImages/Group 160.svg";
// import b2bImage from "../../assets/images/saranyuImages/Group 161.svg";
import filterImage from "../../assets/images/saranyuImages/filter.svg";
import vennDiagram from "../../assets/images/saranyuImages/VennDiagram.png";
import resetFilterImage from "../../assets/images/saranyuImages/Reset Filter.svg";
import ContentTypeAnalysis from "./ContentTypeAnalysis";
import FTW from "./FTW";
import TopTitles from "./TopTitles";
import GeographicContribution from "./GeographicContribution";
import HourlyConsumptionAnalysis from "./HourlyConsumptionAnalysis";
import PaidUserContribution from "./PaidUserContribution";

const ConsumptionInsights = () => {
  document.title = "Consumption Highlights | Saranyu - Admin & Dashboard";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Row>
            <Card style={{ borderRadius: "12px", border: "1px solid #CCCCCC" }}>
              <CardHeader
                style={{
                  borderBottom: "0px",
                }}
              >
                <h1
                  style={{
                    fontFamily: "Arial",
                    fontSize: "24px",
                    fontWeight: 700,
                    lineHeight: "20px",
                    textAlign: "left",
                    color: "#000000",
                  }}
                  className="card-title mb-0"
                >
                  Category Contribution
                </h1>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12" className="mb-4 d-flex justify-content-between">
                    <div className="d-flex justify-content-start ms-2">
                      <button
                        className="sh-filter-dropdown"
                        style={{
                          borderTopLeftRadius: "12px",
                          borderBottomLeftRadius: "12px",
                        }}
                      >
                        <img src={filterImage} />
                      </button>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="button"
                          className="sh-filter-dropdown sh-radio-text"
                          id="dropdownMenuButton"
                        >
                          &nbsp; &nbsp;Platform &nbsp;&nbsp;
                          <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>Action</DropdownItem>
                          <DropdownItem>Another action</DropdownItem>
                          <DropdownItem>Something else here</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="button"
                          className="sh-filter-dropdown sh-radio-text"
                          id="dropdownMenuButton"
                        >
                          &nbsp; &nbsp;Region &nbsp;&nbsp;
                          <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>Action</DropdownItem>
                          <DropdownItem>Another action</DropdownItem>
                          <DropdownItem>Something else here</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="button"
                          className="sh-filter-dropdown sh-radio-text"
                          id="dropdownMenuButton"
                        >
                          &nbsp; &nbsp;State &nbsp;&nbsp;
                          <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>Action</DropdownItem>
                          <DropdownItem>Another action</DropdownItem>
                          <DropdownItem>Something else here</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="button"
                          className="sh-filter-dropdown sh-radio-text"
                          id="dropdownMenuButton"
                        >
                          &nbsp; &nbsp;Acq Type &nbsp;&nbsp;
                          <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>Action</DropdownItem>
                          <DropdownItem>Another action</DropdownItem>
                          <DropdownItem>Something else here</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="button"
                          className="sh-filter-dropdown sh-radio-text"
                          id="dropdownMenuButton"
                        >
                          &nbsp; &nbsp;Instrument &nbsp;&nbsp;
                          <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>Action</DropdownItem>
                          <DropdownItem>Another action</DropdownItem>
                          <DropdownItem>Something else here</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <button
                        className="sh-filter-dropdown"
                        style={{
                          borderTopRightRadius: "12px",
                          borderBottomRightRadius: "12px",
                        }}
                      >
                        <img src={resetFilterImage} />
                      </button>
                    </div>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="button"
                        className="sh-dropdown"
                        id="dropdownMenuButton"
                      >
                        <img
                          src={calendarImage}
                          alt="calender"
                          className="sh-calendar-icon"
                        />
                        &nbsp; &nbsp;Last Month &nbsp;&nbsp;
                        <i className="mdi mdi-chevron-down"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem>Action</DropdownItem>
                        <DropdownItem>Another action</DropdownItem>
                        <DropdownItem>Something else here</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                </Row>
                <Row>
                  <div className="d-flex justify-content-evenly ms-2">
                    <img
                      src={vennDiagram}
                      style={{ width: "352px", height: "347.5px" }}
                    />
                    <img
                      src={vennDiagram}
                      style={{ width: "352px", height: "347.5px" }}
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Row> */}
          <ContentTypeAnalysis />
          <PaidUserContribution />
          <FTW />
          <TopTitles />
          <GeographicContribution />
          <HourlyConsumptionAnalysis />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ConsumptionInsights;
