import React from "react";
import { useSelector } from "react-redux";
import { Col, Row, Table } from "reactstrap";
import {
  todayResult,
  yesterdayResult,
  lastWeekResult,
} from "../../../common/data/TodaysInsights/hourlyROWData";

const tableHeaderColors = [
  "#1B2431",
  "#78AD2F",
  "#2BA558",
  "#009978",
  "#00898D",
  "#007893",
];

import {
  selectTodayResult,
  selectYesterdayResult,
  selectLastWeekResult,
} from "./selectors";

function HourlyTrendROWTable() {
  // const todayResult = useSelector(selectTodayResult);
  // const yesterdayResult = useSelector(selectYesterdayResult);
  // const lastWeekResult = useSelector(selectLastWeekResult);

  const headers = [
    { label: "Pack", backgroundColor: "#1B2431" },
    { label: "Today", backgroundColor: "#78AD2F" },
    { label: "Yesterday", backgroundColor: "#2BA558" },
    { label: "Last Week", backgroundColor: "#009978" },
  ];

  const getUniquePacks = () => {
    const packs = new Set([
      ...Object.keys(todayResult),
      ...Object.keys(yesterdayResult),
      ...Object.keys(lastWeekResult),
    ]);
    packs.delete("daywise"); // Remove 'daywise' if present
    return Array.from(packs);
  };

  const packs = getUniquePacks();

  const parseNumber = (value) => {
    if (value === null || value === undefined) return 0;
    if (typeof value === "string") return parseFloat(value) || 0;
    return value;
  };

  const renderTableRows = (type) => {
    return packs.map((pack) => (
      <tr key={pack}>
        <td>{pack}</td>
        <td style={{ textAlign: "center" }}>
          {parseNumber(todayResult[pack]?.[type]).toLocaleString()}
        </td>
        <td style={{ textAlign: "center" }}>
          {parseNumber(yesterdayResult[pack]?.[type]).toLocaleString()}
        </td>
        <td style={{ textAlign: "center" }}>
          {parseNumber(lastWeekResult[pack]?.[type]).toLocaleString()}
        </td>
      </tr>
    ));
  };

  const calculateTotal = (type) => {
    const total = (data) =>
      packs.reduce((sum, pack) => sum + parseNumber(data[pack]?.[type]), 0);

    return {
      todayTotal: total(todayResult).toLocaleString(),
      yesterdayTotal: total(yesterdayResult).toLocaleString(),
      lastWeekTotal: total(lastWeekResult).toLocaleString(),
    };
  };

  const revenueTotals = calculateTotal("revenue");
  const transactionTotals = calculateTotal("transactions");

  return (
    <React.Fragment>
      <Row className="mb-4">
        <Col>
          <h4 className="table-header-th">Revenue</h4>
          <div className="table-responsive sh-table-styling">
            <Table className="table-nowrap table-bordered mb-0" size="sm">
              <thead className="text-white text-center">
                <tr className="table-row">
                  {headers.map((header, index) => (
                    <th
                      key={index}
                      scope="col"
                      style={{ backgroundColor: header.backgroundColor }}
                    >
                      {header.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {renderTableRows("revenue")}
                <tr className="fw-bold" style={{ backgroundColor: "#E8E8E8" }}>
                  <td>Total</td>
                  <td style={{ textAlign: "center" }}>
                    {revenueTotals.todayTotal.toLocaleString()}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {revenueTotals.yesterdayTotal.toLocaleString()}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {revenueTotals.lastWeekTotal.toLocaleString()}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
        <Col>
          <h4 className="table-header-th">Transaction</h4>
          <div className="table-responsive sh-table-styling">
            <Table className="table-nowrap table-bordered mb-0" size="sm">
              <thead className="text-white text-center">
                <tr className="table-row">
                  {headers.map((header, index) => (
                    <th
                      key={index}
                      scope="col"
                      style={{ backgroundColor: header.backgroundColor }}
                    >
                      {header.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {renderTableRows("transactions")}
                <tr className="fw-bold" style={{ backgroundColor: "#E8E8E8" }}>
                  <td>Total</td>
                  <td style={{ textAlign: "center" }}>
                    {transactionTotals.todayTotal.toLocaleString()}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {transactionTotals.yesterdayTotal.toLocaleString()}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {transactionTotals.lastWeekTotal.toLocaleString()}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default HourlyTrendROWTable;
