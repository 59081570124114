import { createAsyncThunk } from "@reduxjs/toolkit";

import { getAutoRenewalTransactions as getAutoRenewalTransactionsApi } from "../../../helpers/fakebackend_helper";

export const getAutoRenewalTransactionsData = createAsyncThunk(
  "RevenueMatrix/AutoRenewal Transactions",
  async (data) => {
    try {
      const response = getAutoRenewalTransactionsApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
