import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import { paidUserData as chartData } from "../../../common/data/ConnsumptionInsights/paidUserData";

const formatMonth = (dateString) => {
  const dateObject = new Date(dateString);
  const monthAbbr = dateObject
    .toLocaleString("default", { month: "short" })
    .toUpperCase();
  const yearLastTwoDigits = dateObject.getFullYear().toString().slice(-2);
  return `${monthAbbr}'${yearLastTwoDigits}`;
};

const PaidUserChart = ({ dataColors }) => {
  const columnChartColors = getChartColorsArray(dataColors);
  const [categoriesData, setCategoriesData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Users");
  const [existingData, setExistingData] = useState([]);
  const [newUsersData, setNewUsersData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [loader, setLoader] = useState(true);
  // const { chartData, loader } = useSelector(
  //   (state) => state.PaidUserContribution
  // );

  useEffect(() => {
    if (
      chartData &&
      chartData.existing_users &&
      chartData.new_users &&
      chartData.existing_users.length > 0 &&
      chartData.new_users.length > 0
    ) {
      const formatData = (data) =>
        data
          .filter((item) => item.start_day_of_month !== "Total")
          .map((item) => ({
            categoryData: formatMonth(item.start_day_of_month),
            Users: item.users,
            MOU: item.mou,
          }));

      const filteredExistingData = formatData(chartData.existing_users);
      const filteredNewUsersData = formatData(chartData.new_users);

      setCategoriesData(filteredExistingData.map((item) => item.categoryData));
      setExistingData(filteredExistingData);
      setNewUsersData(filteredNewUsersData);
      setSelectedOption("Users");
      updateSeriesData("Users", filteredExistingData, filteredNewUsersData);
      setLoader(false);
    }
  }, [chartData]);

  const updateSeriesData = (option, existing, newUsers) => {
    setSeriesData([
      {
        name: "New Users",
        data: newUsers.map((item) => item[option]),
      },
      {
        name: "Existing Users",
        data: existing.map((item) => item[option]),
      },
    ]);
  };

  const handleRadioChange = (event) => {
    const value = event.target.id;
    setSelectedOption(value);
    updateSeriesData(value, existingData, newUsersData);
  };

  const options = {
    chart: {
      type: "bar",
      height: 200,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    dataLabels: {
      enabled: false,
      formatter: (val) => `${val / 100000}L`,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: categoriesData,
    },
    fill: {
      opacity: 1,
    },
    colors: columnChartColors,
    yaxis: {
      labels: {
        formatter: (val) =>
          val !== undefined ? `${(val / 10000).toFixed(0)}` : "",
      },
      //   title: {
      //     text: "DATA IN LAKHS",
      //     style: {
      //       fontWeight: 600,
      //     },
      //   },
      min: 0,
      tickAmount: 5,
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        formatter: (val) => (val !== undefined ? val : ""),
      },
      y: {
        show: true,
        formatter: (val) =>
          val !== undefined ? parseInt(val.toFixed(0)).toLocaleString() : "",
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    color: columnChartColors,
  };

  return (
    <React.Fragment>
      {!loader ? (
        <React.Fragment>
          <div className="d-flex align-items-center mt-2 justify-content-center">
            <div className="d-flex justify-content-center me-2">
              <input
                type="radio"
                id="Users"
                name="PaidUserChart"
                className="sh-radio-button me-1"
                checked={selectedOption === "Users"}
                onChange={handleRadioChange}
              />
              <label htmlFor="Users" className="sh-radio-text">
                Users
              </label>
            </div>
            <div className="d-flex justify-content-center me-2">
              <input
                type="radio"
                id="MOU"
                name="PaidUserChart"
                className="sh-radio-button me-1"
                checked={selectedOption === "MOU"}
                onChange={handleRadioChange}
              />
              <label htmlFor="MOU" className="sh-radio-text">
                MOU
              </label>
            </div>
          </div>
          <ReactApexChart
            dir="ltr"
            className="apex-charts"
            series={seriesData}
            options={options}
            type="bar"
            height={300}
          />
        </React.Fragment>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "350px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default PaidUserChart;
