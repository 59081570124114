import { createSlice } from "@reduxjs/toolkit";
import { getPackMonthlyData } from "./thunk";

export const initialState = {
  chartData: [],
  loader: true,
  error: "",
};

const PackMonthlySlice = createSlice({
  name: "PackMonthly",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getPackMonthlyData.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getPackMonthlyData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.chartData = action.payload.data.daily_wise_data;
        state.loader = false;
      }
    });
    builder.addCase(getPackMonthlyData.rejected, (state, action) => {
      state.error = action.error.message;
      state.loader = false;
    });
  },
});

export default PackMonthlySlice.reducer;
