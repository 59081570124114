import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import { contentChartData as chartData } from "../../../common/data/ConnsumptionInsights/contentData";

const arrayColors = [
  "#1B2431",
  "#78AD2F",
  "#2BA558",
  "#009978",
  "#00898D",
  "#007893",
];

function ContentTypeTable({ loader }) {
  const [tableData, setTableData] = useState([]);
  const [headerValues, setHeaderValues] = useState([]);
  // const [loader, setLoader] = useState(true);

  // const { chartData } = useSelector((state) => state.ContentType);
  //   console.log("ChartData of Content Type Analysis", chartData);

  useEffect(() => {
    if (chartData && chartData.length > 0) {
      const headers = Object.keys(chartData[0]).map((key) => {
        switch (key) {
          case "content_type":
            return "Content Type";
          case "streams":
            return "No. of Streams";
          case "percentage_contribution_streams":
            return "% Contribution";
          case "mou":
            return "MOU’s";
          case "percentage_contribution_mou":
            return "% Contribution";
          default:
            return key;
        }
      });
      setHeaderValues(headers);
      //   const contentTypes = ["show", "movie", "clips", "others", "total"];
      const contentTypes = chartData.map((item) => item.content_type);
      const formattedData = contentTypes.map((type) => {
        const item = chartData.find((item) => item.content_type === type);
        return [
          item.content_type.charAt(0).toUpperCase() +
            item.content_type.slice(1),
          (parseInt(item.streams, 10) || 0).toLocaleString(),
          (
            parseInt(item.percentage_contribution_streams, 10) || 0
          ).toLocaleString(),
          (parseInt(item.mou, 10) || 0).toLocaleString(),
          (
            parseInt(item.percentage_contribution_mou, 10) || 0
          ).toLocaleString(),
        ];
      });
      //   console.log(formattedData);
      setTableData(formattedData);
      // setLoader(false);
    }
  }, [chartData]);
  return (
    <React.Fragment>
      {!loader ? (
        <div className="table-responsive sh-table-styling">
          <Table className="table-bordered table-nowrap mb-0" size="sm">
            <thead className="text-white text-center">
              <tr className="table-row sh-table-header">
                {headerValues.map((value, index) => (
                  <th
                    scope="col"
                    style={{ backgroundColor: `${arrayColors[index]}` }}
                    key={index}
                  >
                    {value}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((valuesArr, index) => (
                <tr
                  key={index}
                  className="sh-table-text"
                  style={{
                    backgroundColor:
                      index === tableData.length - 1 ? "#E8E8E8" : "",
                  }}
                >
                  {valuesArr.map((value, i) => (
                    <td
                      key={i}
                      className="align-middle"
                      style={{ textAlign: i === 0 ? "left" : "center" }}
                    >
                      {value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center mt-4"
          style={{ height: "200px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default ContentTypeTable;
