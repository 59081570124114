const platformContribution = {
  chart_name: "Platform Wise Contribution",
  chart_data: {
    Users: [
      {
        device_properties_device_platform: "web",
        platform_count: "4492679",
        users: "22738428",
        platform_percentage: 19.76,
      },
      {
        device_properties_device_platform: "external",
        platform_count: "2902696",
        users: "22738428",
        platform_percentage: 12.77,
      },
      {
        device_properties_device_platform: "android",
        platform_count: "14091637",
        users: "22738428",
        platform_percentage: 61.97,
      },
      {
        device_properties_device_platform: "ios",
        platform_count: "1251416",
        users: "22738428",
        platform_percentage: 5.5,
      },
    ],
    mou: [
      {
        device_properties_device_platform: "web",
        mou_platform: 411666831.44878614,
        total_mou: 600124572.7487862,
        platform_percentage: 68.6,
      },
      {
        device_properties_device_platform: "external",
        mou_platform: 50262351,
        total_mou: 600124572.7487862,
        platform_percentage: 8.38,
      },
      {
        device_properties_device_platform: "android",
        mou_platform: 126864429.56666666,
        total_mou: 600124572.7487862,
        platform_percentage: 21.14,
      },
      {
        device_properties_device_platform: "ios",
        mou_platform: 11330960.733333332,
        total_mou: 600124572.7487862,
        platform_percentage: 1.89,
      },
    ],
  },
};
const usersPlatformContribution = [
  {
    device_properties_device_platform: "Web",
    platform_count: "4492679",
    users: "22738428",
    platform_percentage: 19.76,
  },
  {
    device_properties_device_platform: "TV",
    platform_count: "2902696",
    users: "22738428",
    platform_percentage: 12.77,
  },
  {
    device_properties_device_platform: "Android App",
    platform_count: "14091637",
    users: "22738428",
    platform_percentage: 61.97,
  },
  {
    device_properties_device_platform: "iOS",
    platform_count: "1251416",
    users: "22738428",
    platform_percentage: 5.5,
  },
];
const MOUPlatformContribution = [
  {
    device_properties_device_platform: "web",
    mou_platform: 411666831.44878614,
    total_mou: 600124572.7487862,
    platform_percentage: 68.6,
  },
  {
    device_properties_device_platform: "external",
    mou_platform: 50262351,
    total_mou: 600124572.7487862,
    platform_percentage: 8.38,
  },
  {
    device_properties_device_platform: "android",
    mou_platform: 126864429.56666666,
    total_mou: 600124572.7487862,
    platform_percentage: 21.14,
  },
  {
    device_properties_device_platform: "ios",
    mou_platform: 11330960.733333332,
    total_mou: 600124572.7487862,
    platform_percentage: 1.89,
  },
];

export {
  platformContribution,
  usersPlatformContribution,
  MOUPlatformContribution,
};
