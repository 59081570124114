const packwiseTableDataFromState = [
  {
    pack: "Quarterly",
    subscription: 2000,
    revenue: 3000,
    arpu: 400,
    percentage_contribution: "4.00",
  },
  {
    pack: "Yearly",
    subscription: 1000,
    revenue: 1000,
    arpu: 500,
    percentage_contribution: "9.00",
  },
  {
    pack: "Two Yearly",
    subscription: 3000,
    revenue: 2000,
    arpu: 900,
    percentage_contribution: "7.00",
  },
  {
    pack: "Monthly",
    subscription: 1000,
    revenue: 2000,
    arpu: 55,
    percentage_contribution: "3.00",
  },
  {
    pack: "Half Yearly",
    subscription: 3000,
    revenue: 1000,
    arpu: 54,
    percentage_contribution: "0.70",
  },
  {
    pack: "Weekly",
    subscription: 800,
    revenue: 200,
    arpu: 1,
    percentage_contribution: "0.10",
  },
  {
    pack: "Total",
    subscription: 4000,
    revenue: 2000,
    arpu: 500,
    percentage_contribution: 100,
  },
];
const dailyWiseData = [
  {
    created_at_time: "2024-08-01",
    period: "Quarterly",
    subscription: 405,
    revenue: 178956,
    arpu: 441,
  },
  {
    created_at_time: "2024-08-01",
    period: "Yearly",
    subscription: 346,
    revenue: 251141,
    arpu: 725,
  },
  {
    created_at_time: "2024-08-01",
    period: "Two Yearly",
    subscription: 101,
    revenue: 92689,
    arpu: 917,
  },
  {
    created_at_time: "2024-08-01",
    period: "Half Yearly",
    subscription: 7,
    revenue: 4193,
    arpu: 599,
  },
  {
    created_at_time: "2024-08-01",
    period: "Monthly",
    subscription: 3,
    revenue: 649,
    arpu: 216,
  },
  {
    created_at_time: "2024-08-02",
    period: "Quarterly",
    subscription: 380,
    revenue: 169130,
    arpu: 445,
  },
  {
    created_at_time: "2024-08-02",
    period: "Yearly",
    subscription: 363,
    revenue: 266086,
    arpu: 733,
  },
  {
    created_at_time: "2024-08-02",
    period: "Two Yearly",
    subscription: 233,
    revenue: 204817,
    arpu: 879,
  },
  {
    created_at_time: "2024-08-02",
    period: "Half Yearly",
    subscription: 3,
    revenue: 1797,
    arpu: 599,
  },
  {
    created_at_time: "2024-08-02",
    period: "Monthly",
    subscription: 2,
    revenue: 500,
    arpu: 250,
  },
  {
    created_at_time: "2024-08-03",
    period: "Yearly",
    subscription: 440,
    revenue: 319130,
    arpu: 725,
  },
  {
    created_at_time: "2024-08-03",
    period: "Quarterly",
    subscription: 414,
    revenue: 181879,
    arpu: 439,
  },
  {
    created_at_time: "2024-08-03",
    period: "Two Yearly",
    subscription: 230,
    revenue: 220107,
    arpu: 956,
  },
  {
    created_at_time: "2024-08-03",
    period: "Half Yearly",
    subscription: 3,
    revenue: 1797,
    arpu: 599,
  },
  {
    created_at_time: "2024-08-03",
    period: "Monthly",
    subscription: 1,
    revenue: 199,
    arpu: 199,
  },
  {
    created_at_time: "2024-08-04",
    period: "Quarterly",
    subscription: 403,
    revenue: 178294,
    arpu: 442,
  },
  {
    created_at_time: "2024-08-04",
    period: "Yearly",
    subscription: 377,
    revenue: 274281,
    arpu: 727,
  },
  {
    created_at_time: "2024-08-04",
    period: "Two Yearly",
    subscription: 174,
    revenue: 166474,
    arpu: 956,
  },
  {
    created_at_time: "2024-08-04",
    period: "Half Yearly",
    subscription: 2,
    revenue: 1198,
    arpu: 599,
  },
  {
    created_at_time: "2024-08-04",
    period: "Monthly",
    subscription: 2,
    revenue: 648,
    arpu: 324,
  },
  {
    created_at_time: "2024-08-05",
    period: "Quarterly",
    subscription: 408,
    revenue: 180812,
    arpu: 443,
  },
  {
    created_at_time: "2024-08-05",
    period: "Yearly",
    subscription: 344,
    revenue: 247149,
    arpu: 718,
  },
  {
    created_at_time: "2024-08-05",
    period: "Two Yearly",
    subscription: 147,
    revenue: 144240,
    arpu: 981,
  },
  {
    created_at_time: "2024-08-05",
    period: "Monthly",
    subscription: 3,
    revenue: 899,
    arpu: 299,
  },
  {
    created_at_time: "2024-08-05",
    period: "Half Yearly",
    subscription: 1,
    revenue: 599,
    arpu: 599,
  },
];

export { packwiseTableDataFromState, dailyWiseData };
