import React, { useEffect, useRef, useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import calendarImage from "../../../assets/images/saranyuImages/calander.svg";

import {
  getMTDDateRange,
  getLastMonthDateRange,
  getQTDDateRange,
  getYTDDateRange,
} from "../../../helpers/dateUtils";

const DateDropdown = ({ onDateRangeChange }) => {
  const [dropdownValue, setDropdown] = useState("MTD");
  const [showCalender, setShowCalender] = useState(false);
  const [dateRange, setDateRange] = useState(["01 Jun 2024", "19 Jun 2024"]);
  const flatpickrRef = useRef(null);

  const handleDropdownItemClick = (
    dateRangeFunc,
    newDropdownValue,
    showCalenderValue
  ) => {
    if (newDropdownValue === dropdownValue) return;

    setDropdown(newDropdownValue);
    setShowCalender(showCalenderValue);

    if (newDropdownValue !== "Custom") {
      const range = dateRangeFunc();
      onDateRangeChange(range);
    }
  };

  const handleCustomDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const [start, end] = selectedDates;
      const formattedStartDate = `${start.getFullYear()}-${String(
        start.getMonth() + 1
      ).padStart(2, "0")}-${String(start.getDate()).padStart(2, "0")}`;
      const formattedEndDate = `${end.getFullYear()}-${String(
        end.getMonth() + 1
      ).padStart(2, "0")}-${String(end.getDate()).padStart(2, "0")}`;
      const customRange = {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      };
      onDateRangeChange(customRange);
    }
  };

  useEffect(() => {
    if (showCalender && dropdownValue === "Custom" && flatpickrRef.current) {
      flatpickrRef.current.flatpickr.open();
    }
  }, [showCalender, dropdownValue]);

  return (
    <div className="d-flex">
      {showCalender && dropdownValue === "Custom" ? (
        <Flatpickr
          ref={flatpickrRef}
          className="form-control dash-filter-picker sh-flatpickr"
          options={{
            mode: "range",
            dateFormat: "d M, Y",
            defaultDate: dateRange,
          }}
          value={dateRange}
          onChange={(selectedDates) =>
            setDateRange(
              selectedDates.map(
                (date) =>
                  `${date.getDate()} ${date.toLocaleString("default", {
                    month: "short",
                  })}, ${date.getFullYear()}`
              )
            )
          }
          onClose={handleCustomDateChange}
        />
      ) : null}
      <UncontrolledDropdown>
        <DropdownToggle
          tag="button"
          className="sh-dropdown d-flex justify-content-between align-items-center"
          id="dropdownMenuButton"
        >
          <img
            src={calendarImage}
            alt="calender"
            className="sh-calendar-icon"
          />
          <p className="dateDropdown-text">{dropdownValue}</p>
          {/* {dropdownValue} */}
          <i className="mdi mdi-chevron-down"></i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() =>
              handleDropdownItemClick(getMTDDateRange, "MTD", false)
            }
          >
            MTD
          </DropdownItem>
          <DropdownItem
            onClick={() =>
              handleDropdownItemClick(
                getLastMonthDateRange,
                "Last Month",
                false
              )
            }
          >
            Last Month
          </DropdownItem>
          <DropdownItem
            onClick={() =>
              handleDropdownItemClick(getQTDDateRange, "3 Months", false)
            }
          >
            3 Months
          </DropdownItem>
          <DropdownItem
            onClick={() =>
              handleDropdownItemClick(getYTDDateRange, "1 Year", false)
            }
          >
            1 Year
          </DropdownItem>
          <DropdownItem
            onClick={() =>
              handleDropdownItemClick(getMTDDateRange, "Custom", true)
            }
          >
            Custom
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default DateDropdown;
