import React from "react";
import RevenueTrend from "./RevenueTrend";
import Subscription from "./Subscription";
import TitleContribution from "./TitleContribution";
import PackContribution from "./PackContribution";

import InorganicTransactions from "./InorganicTransactions/index";
import OrganicTransactions from "./OrganicTransactions/index";
import AutoRenewalTransactions from "./AutoRenewalTransactions/index";
import DeviceContribution from "./DeviceContribution/index";

const RevenueMatrix = () => {
  document.title = "Revenue Matrix | Saranyu - Admin & Dashboard";
  return (
    <React.Fragment>
      <div className="page-content">
        <RevenueTrend />
        {/* Layout Two */}
        <TitleContribution />
        {/* Layout Three */}
        <Subscription />
        {/* Layout Four */}
        {/* <PackContribution /> */}
        {/* Layout Five */}
        <InorganicTransactions />
        {/* Layout Six */}
        <OrganicTransactions />
        {/* Layout Seven */}
        <AutoRenewalTransactions />
        {/* Layout Eight */}
        {/* <DeviceContribution /> */}
      </div>
    </React.Fragment>
  );
};

export default RevenueMatrix;
