import { createSlice } from "@reduxjs/toolkit";
import { getAutoRenewalTransactionsData } from "./thunk";

export const initialState = {
  data: {},
  status: "idle",
  error: null,
  loader: false,
};

const AutoRenewalTransactionsSlice = createSlice({
  name: "AutoRenewalTransactions",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getAutoRenewalTransactionsData.pending, (state, action) => {
      state.status = "loading";
      state.loader = true;
    });
    builder.addCase(
      getAutoRenewalTransactionsData.fulfilled,
      (state, action) => {
        if (action.payload.success) {
          state.status = "succeeded";
          state.data = action.payload.data.chart_data;
          state.loader = false;
        }
      }
    );
    builder.addCase(
      getAutoRenewalTransactionsData.rejected,
      (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.loader = false;
      }
    );
  },
});

export default AutoRenewalTransactionsSlice.reducer;
