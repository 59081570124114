import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import ReactApexChart from "react-apexcharts";
import { usersPlatformContribution as usersPlatform } from "../../../common/data/EcomDashboard/contributionData";

const UserSimpleDonut = ({ dataColors }) => {
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [loader, setLoader] = useState(true);

  // const status = useSelector((state) => state.PlatformCategoryData.status);
  // const usersPlatform = useSelector(
  //   (state) => state.PlatformCategoryData.usersPlatformContribution
  // );
  // const { platformLoader } = useSelector((state) => state.PlatformCategoryData);
  // console.log("usersPlatform ", usersPlatform);

  useEffect(() => {
    if (usersPlatform) {
      const series = usersPlatform.map((user) =>
        parseInt(user.platform_count, 10)
      );

      const labels = usersPlatform.map((user) => {
        switch (user.device_properties_device_platform) {
          case "external":
            return "Tv";
          case "web":
            return "Web";
          case "android":
            return "Android App";
          case "ios":
            return "iOS";
          default:
            return user.device_properties_device_platform;
        }
      });

      //const labels = usersPlatform.map(user => user.device_properties_device_platform);
      setSeries(series);
      setLabels(labels);
      setLoader(false);
    }
  }, [usersPlatform]);

  // const series = [44, 55, 41, 17]
  var options = {
    chart: {
      height: 300,
      type: "donut",
    },
    // labels: ['Android App', 'Web', 'Tv', 'iOS'],
    labels: labels,
    legend: {
      position: "bottom",
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },
    colors: ["#009978", "#146588", "#007893", "#00898D"],
    tooltip: {
      enabled: true,
      // x: {
      //   show: true,
      //   formatter: function (val) {
      //     return val !== undefined ? val : ''; // Display actual values on x-axis in tooltip
      //   },
      // },
      y: {
        formatter: function (val) {
          return val !== undefined
            ? parseInt(val.toFixed(0)).toLocaleString()
            : ""; // Display actual values in tooltip without formatting
        },
      },
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: true,
        offsetX: 0,
        offsetY: 0,
        customScale: 1,
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 10,
        },
        donut: {
          background: "transparent",
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              label: "Users",
              fontSize: "22px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#1A3E5A",
              formatter: function (w) {
                const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                const inLakhs = total / 100000;
                return `${inLakhs.toFixed()} L`;
                // return formatTotal(total);
              },
            },
          },
        },
      },
    },
  };
  return (
    <React.Fragment>
      {!loader ? (
        <ReactApexChart
          dir="ltr"
          className="apex-charts"
          series={series}
          options={options}
          type="donut"
          height={300}
        />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "300px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default UserSimpleDonut;
