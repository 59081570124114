import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

import VennDiagram from "./UserContributionChart";
import MOUVennDiagram from "./MOUContributionChart";
import MOUSimpleDonut from "./MOUPlatformChart";
import UserSimpleDonut from "./UsersPlatformChart";

import ToggleButton from "../ToggleButton";

import {
  getCategoryContributionData,
  getPlatformContributionData,
} from "../../../slices/HealthMatrix/platformCategory/thunk";

import {
  getMTDDateRange,
  getLastMonthDateRange,
  getQTDDateRange,
} from "../../../helpers/dateUtils";

import DateDropdown from "../BusinessOverview/DateDropdown";

const PlatformAndCategoryContribution = () => {
  const [loader, setLoader] = useState(true);
  const [customDateRange, setCustomDateRange] = useState(getMTDDateRange());
  const [isCategoryTotalSelected, setIsCategoryTotalSelected] = useState(true);
  const [isPlatformTotalSelected, setIsPlatformTotalSelected] = useState(true);

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    fetchData(customDateRange);
  }, [dispatch]);

  const fetchData = (dateRange) => {
    const someObject = {
      ...dateRange,
      period: "month",
      daily_wise_data: true,
      filter_type: "Total",
    };
    // dispatch(getCategoryContributionData(someObject));
    // dispatch(getPlatformContributionData(someObject));
    setLoader(false);
  };

  const handleDateRangeChange = (range) => {
    setCustomDateRange(range);
    fetchData(range);
  };

  const handleCategoryToggle = (isTotal) => {
    if (isTotal !== isCategoryTotalSelected) {
      setIsCategoryTotalSelected(isTotal);
      const categoryDataObject = {
        ...customDateRange,
        period: "month",
        filter_type: isTotal ? "Total" : "Paid",
      };
      // dispatch(getCategoryContributionData(categoryDataObject));
      // console.log(
      //   `isCategoryTotalSelected Toggle: ${isTotal ? "Total" : "Paid"}`
      // );
    }
  };

  const handlePlatformToggle = (isTotal) => {
    if (isTotal !== isPlatformTotalSelected) {
      setIsPlatformTotalSelected(isTotal);

      const platformDataObject = {
        ...customDateRange,
        period: "month",
        filter_type: isTotal ? "Total" : "Paid",
      };
      // dispatch(getPlatformContributionData(platformDataObject));
      // console.log(
      //   `setIsPlatformTotalSelected Toggle: ${isTotal ? "Total" : "Paid"}`
      // );
    }
  };

  return (
    <React.Fragment>
      <Card className="customContainerStyle" style={{ height: "556px" }}>
        <Row>
          <Col
            md="12"
            className="d-flex justify-content-end"
            style={{ padding: "15px" }}
          >
            <DateDropdown
              onDateRangeChange={handleDateRangeChange}
              options={["Last Month", "3 Months", "1 Year", "Custom"]}
            />
          </Col>
        </Row>
        <Row>
          {!loader ? (
            <Col lg={6}>
              <Card className="card-styling" style={{ height: "436px" }}>
                <CardHeader
                  className="d-flex justify-content-between"
                  style={{
                    borderBottom: "0px",
                    backgroundColor: "#F9F9F9",
                    borderRadius: "12px",
                  }}
                >
                  <h4 className="card-title mb-0 card-heading-text-black">
                    Category Contribution
                  </h4>
                  <ToggleButton onToggle={handleCategoryToggle} />
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="d-flex justify-content-evenly ms-2">
                      {/* <VennDiagram /> */}
                      <MOUVennDiagram />
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ) : (
            <Col lg={6}>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "436px" }}
              >
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </Col>
          )}
          {!loader ? (
            <Col xl={6}>
              <Card className="card-styling" style={{ height: "436px" }}>
                <CardHeader
                  className="d-flex justify-content-between"
                  style={{
                    borderBottom: "0px",
                    backgroundColor: "#F9F9F9",
                    borderRadius: "12px",
                  }}
                >
                  <h4 className="card-title mb-0 card-heading-text-black">
                  Platform Contribution
                  </h4>
                  <ToggleButton onToggle={handlePlatformToggle} />
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col >
                      <UserSimpleDonut dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]' />
                    </Col>
                    {/* <Col xl={6}>
                      <MOUSimpleDonut dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]' />
                    </Col> */}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ) : (
            <Col xl={6}>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "436px" }}
              >
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </Card>
    </React.Fragment>
  );
};

export default PlatformAndCategoryContribution;
