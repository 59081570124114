import { createSlice } from "@reduxjs/toolkit";
import { getTopTRegionsData, getTopTCountriesData } from "./thunk";

export const initialState = {
  regionsChartData: [],
  regionsLoader: false,
  CountriesChartData: [],
  countriesLoader: false,
  regionsError: "",
  countriesError: "",
};

const TopTLocationsSlice = createSlice({
  name: "TopTransactingLocations",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getTopTRegionsData.pending, (state, action) => {
      state.regionsLoader = true;
    });
    builder.addCase(getTopTRegionsData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.regionsChartData = action.payload.data;
        state.regionsLoader = false;
      }
    });
    builder.addCase(getTopTRegionsData.rejected, (state, action) => {
      state.regionsError = action.error.message;
      state.regionsLoader = false;
    });
    builder.addCase(getTopTCountriesData.pending, (state, action) => {
      state.countriesLoader = true;
    });
    builder.addCase(getTopTCountriesData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.CountriesChartData = action.payload.data;
        state.countriesLoader = false;
      }
    });
    builder.addCase(getTopTCountriesData.rejected, (state, action) => {
      state.countriesError = action.error.message;
      state.countriesLoader = false;
    });
  },
});

export default TopTLocationsSlice.reducer;
