import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import {
  getTopTRegionsData,
  getTopTCountriesData,
} from "../../../slices/TodaysHighlights/TopTransactingLocations/thunk";
import TopTranactingTable from "./topTransactingTable";
import {
  regionsChartData,
  CountriesChartData,
} from "../../../common/data/TodaysInsights/transactionsData";

function TopTransactingLocations() {
  const dispatch = useDispatch();
  const [regionsData, setRegionsData] = useState([]);
  const [countriesData, setCountriesData] = useState([]);
  const [regionsLoader, setRegionsLoader] = useState(true);
  const [countriesLoader, setCountriesLoader] = useState(true);
  useEffect(() => {
    const dataObject = {};
    // dispatch(getTopTRegionsData(dataObject));
    // dispatch(getTopTCountriesData(dataObject));
  }, []);

  // const {
  //   regionsChartData,
  //   regionsLoader,
  //   CountriesChartData,
  //   countriesLoader,
  // } = useSelector((state) => state.TopTLocations);

  useEffect(() => {
    if (regionsChartData.length > 0 && CountriesChartData.length > 0) {
      setRegionsData(regionsChartData);
      setCountriesData(CountriesChartData);
      setCountriesLoader(false);
      setRegionsLoader(false);
    }
  }, [regionsChartData, CountriesChartData]);

  return (
    <React.Fragment>
      <Row>
        <Col md="6">
          <Container
            fluid
            className="customContainerStyle"
            style={{ height: "578px" }}
          >
            <Row>
              <Col style={{ padding: "20px" }}>
                <h4 className="card-heading-text">Top Transacting Regions </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <TopTranactingTable
                  chartData={regionsData}
                  loader={regionsLoader}
                />
              </Col>
            </Row>
          </Container>
        </Col>
        <Col md="6">
          <Container
            fluid
            className="customContainerStyle"
            style={{ height: "578px" }}
          >
            <Row>
              <Col style={{ padding: "20px" }}>
                <h4 className="card-heading-text">
                  Top Transacting Countries (ROW)
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <TopTranactingTable
                  chartData={countriesData}
                  loader={countriesLoader}
                />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default TopTransactingLocations;
