import { createAsyncThunk } from "@reduxjs/toolkit";

// import needed API helpers.
import { getPackwiseContribution as getPackwiseContributionApi } from "../../../helpers/fakebackend_helper";

export const getPackwiseData = createAsyncThunk(
  "Dashboard/packwiseContribution",
  async (data) => {
    try {
      const response = await getPackwiseContributionApi(data);
      // console.log("data Object Packwise", data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
