import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Table } from "reactstrap";
import { inorganicData as data } from "../../../common/data/RevenueMatrix/inorganicData";

const headerColors = [
  "#1B2431",
  "#78AD2F",
  "#2BA558",
  "#009978",
  "#00898D",
  "#007893",
];

const categoryColors = ["#FFA600", "#BAAF00", "#78AD2F"];

const headerArray = [
  "Performance",
  "Packs",
  "Revenue",
  "Subscriptions",
  "ARPU",
];

const packOrder = [
  "Weekly",
  "Monthly",
  "Quarterly",
  "Half Yearly",
  "Bollywood Yearly",
  "yearly",
  "Yearly",
  "Two Yearly",
  "Total",
];

function InorganicTable() {
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(true);

  // const { data, status, loader } = useSelector(
  //   (state) => state.InorganicTransactions
  // );

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const formattedData = Object.keys(data).reduce((acc, performanceKey) => {
        if (performanceKey !== "grand") {
          const performanceData = data[performanceKey]
            .map((pack) => ({
              packwise: pack.packwise,
              revenue: parseInt(pack.revenue, 10) || 0,
              subs: parseInt(pack.subs, 10) || 0,
              arpu: parseInt(pack.arpu, 10) || 0,
            }))
            .sort((a, b) => {
              const indexA = packOrder.indexOf(a.packwise);
              const indexB = packOrder.indexOf(b.packwise);
              return indexA - indexB;
            });

          acc.push({
            performance: `${performanceKey
              .charAt(0)
              .toUpperCase()}${performanceKey.slice(1)}`,
            data: performanceData,
          });
        }
        return acc;
      }, []);

      setTableData(formattedData);
      setLoader(false);
    }
  }, [data]);

  const grandTotalData = data?.grand?.[0] || null;

  return (
    <React.Fragment>
      <Row>
        <Col style={{ height: "750px", overflow: "hidden" }}>
          {!loader ? (
            <div className="table-responsive sh-table-styling">
              <Table className="table-nowrap table-bordered mb-0" size="sm">
                <thead className="sh-table-header text-center">
                  <tr className="table-row">
                    {headerArray.map((value, index) => (
                      <th
                        scope="col"
                        key={index}
                        style={{
                          backgroundColor:
                            headerColors[index % headerColors.length],
                          padding: "0.5rem",
                          textAlign: "center",
                          color: "rgba(255, 255, 255, 1)", // Ensure header text color is white
                        }}
                      >
                        {value}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((category, i) => (
                    <React.Fragment key={i}>
                      <tr
                        style={{
                          backgroundColor:
                            categoryColors[i % categoryColors.length],
                          color: "white",
                          fontWeight: "700",
                          fontSize: "16px",
                        }}
                      >
                        <td
                          rowSpan={category.data.length + 1}
                          className="text-center align-middle"
                          style={{ padding: "0.5rem" }}
                        >
                          {category.performance}
                        </td>
                      </tr>
                      {category.data.map((item, j) => (
                        <tr
                          key={j}
                          style={{
                            backgroundColor:
                              item.packwise.toLowerCase() === "total"
                                ? categoryColors[i % categoryColors.length]
                                : "inherit",
                            color:
                              item.packwise.toLowerCase() === "total"
                                ? "white"
                                : "inherit",
                            fontSize: "16px",
                          }}
                        >
                          <td style={{ padding: "0.24rem", textAlign: "left" }}>
                            {item.packwise}
                          </td>
                          <td
                            style={{ padding: "0.24rem", textAlign: "center" }}
                          >
                            {item.revenue.toLocaleString()}
                          </td>
                          <td
                            style={{ padding: "0.24rem", textAlign: "center" }}
                          >
                            {item.subs.toLocaleString()}
                          </td>
                          <td
                            style={{ padding: "0.24rem", textAlign: "center" }}
                          >
                            {item.arpu ? item.arpu.toLocaleString() : ""}
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                  {grandTotalData && (
                    <tr
                      style={{
                        backgroundColor: "#146588",
                        color: "white",
                        fontWeight: "700",
                        fontSize: "16px",
                      }}
                    >
                      <td className="text-center" style={{ padding: "0.5rem" }}>
                        Grand Total
                      </td>
                      <td style={{ padding: "0.5rem", textAlign: "left" }}>
                        {grandTotalData.packwise}
                      </td>
                      <td style={{ padding: "0.5rem", textAlign: "center" }}>
                        {parseInt(grandTotalData.revenue, 10).toLocaleString()}
                      </td>
                      <td style={{ padding: "0.5rem", textAlign: "center" }}>
                        {parseInt(grandTotalData.subs, 10).toLocaleString()}
                      </td>
                      <td style={{ padding: "0.5rem", textAlign: "center" }}>
                        {grandTotalData.arpu
                          ? parseInt(grandTotalData.arpu, 10).toLocaleString()
                          : ""}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "400px" }}
            >
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default InorganicTable;
