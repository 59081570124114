import { createAsyncThunk } from "@reduxjs/toolkit";

// import needed API helpers.
import { getDau as getDauApi } from "../../../helpers/fakebackend_helper";
import { getPaidDau as getPaidDauApi } from "../../../helpers/fakebackend_helper";
import { getStreamingDau as getStreamingDauApi } from "../../../helpers/fakebackend_helper";
import { getMau as getMauApi } from "../../../helpers/fakebackend_helper";
import { getPaidMau as getPaidMauApi } from "../../../helpers/fakebackend_helper";
import { getStreamingMau as getStreamingMauApi } from "../../../helpers/fakebackend_helper";

export const getDauData = createAsyncThunk("dashboard/dau", async (data) => {
  try {
    const response = getDauApi(data);

    return response;
  } catch (error) {
    return error;
  }
});

export const getPaidDauData = createAsyncThunk(
  "dashboard/paidDau",
  async (data) => {
    try {
      const response = getPaidDauApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getStreamingDauData = createAsyncThunk(
  "dashboard/streamingDau",
  async (data) => {
    try {
      const response = getStreamingDauApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getMauData = createAsyncThunk("dashboard/mau", async (data) => {
  try {
    const response = getMauApi(data);
    return response;
  } catch (error) {
    return error;
  }
});

export const getPaidMauData = createAsyncThunk(
  "dashboard/paidMau",
  async (data) => {
    try {
      const response = getPaidMauApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getStreamingMauData = createAsyncThunk(
  "dashboard/streamingMau",
  async (data) => {
    try {
      const response = getStreamingMauApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
