import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, Col, Container, Row, Table } from "reactstrap";
import { ROWChart } from "./rowChart";
import ROWStackedColumn from "./rowStackedColumn";

import { getHourlyTrendROWData } from "../../../slices/TodaysHighlights/HourlyTrendROW/thunk";

import HourlyTrendROWTable from "./HourlyTableROW";

function HourlyTrendROW() {
  const dispatch = useDispatch();

  // Separate state for each component's radio buttons
  const [chartSelectedValue, setChartSelectedValue] = useState("revenue");
  const [columnSelectedValue, setColumnSelectedValue] = useState("revenue");

  const fetchData = async (country) => {
    const dataObject = {
      filter_type: country, //IN,ROW
    };
    // dispatch(getHourlyTrendROWData(dataObject));
  };

  useEffect(() => {
    fetchData("ROW");
  }, [dispatch]);

  // Handlers for radio button changes
  const handleChartRadioChange = (e) => {
    setChartSelectedValue(e.target.id);
  };

  const handleColumnRadioChange = (e) => {
    setColumnSelectedValue(e.target.id);
  };

  return (
    <React.Fragment>
      <Container fluid className="customContainerStyle">
        <Row>
          <Col style={{ padding: "20px" }}>
            <h4 className="card-heading-text">Hourly Trend : ROW</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Container
              fluid
              className="customContainerStyle"
              style={{ height: "430px" }}
            >
              <Row>
                <Col className="d-flex flex-row justify-content-center mt-4">
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-center me-2">
                      <input
                        type="radio"
                        id="revenue"
                        name="hourlyTrendIndiaROW"
                        className="sh-radio-button me-1"
                        defaultChecked
                        onChange={handleChartRadioChange}
                      />
                      <label htmlFor="revenue" className="sh-radio-text">
                        Revenue
                      </label>
                    </div>
                    <div className="d-flex justify-content-center me-2">
                      <input
                        type="radio"
                        id="transactions"
                        name="hourlyTrendIndiaROW"
                        className="sh-radio-button me-1"
                        onChange={handleChartRadioChange}
                      />
                      <label htmlFor="transactions" className="sh-radio-text">
                        Transaction
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ROWChart
                    dataColors='["#146588", "#00898D","#2BA558"]'
                    showSeriesType={chartSelectedValue}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <HourlyTrendROWTable />
        <Row>
          <Col>
            <Container
              fluid
              className="customContainerStyle"
              style={{ height: "430px" }}
            >
              <Row>
                <Col className="d-flex flex-row justify-content-center mt-4">
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-center me-2">
                      <input
                        type="radio"
                        id="revenue"
                        name="hourlyTrendROW_column"
                        className="sh-radio-button me-1"
                        defaultChecked
                        onChange={handleColumnRadioChange}
                      />
                      <label htmlFor="revenue" className="sh-radio-text">
                        Revenue
                      </label>
                    </div>
                    <div className="d-flex justify-content-center me-2">
                      <input
                        type="radio"
                        id="transactions"
                        name="hourlyTrendROW_column"
                        className="sh-radio-button me-1"
                        onChange={handleColumnRadioChange}
                      />
                      <label htmlFor="transactions" className="sh-radio-text">
                        Transaction
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ROWStackedColumn
                    showSeriesType={columnSelectedValue}
                    dataColors='["#146588", "#007893","#00898D","#009978","#2BA558"]'
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default HourlyTrendROW;
