import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPaidUserContribution as getPaidUserContributionApi } from "../../../helpers/fakebackend_helper";

export const getPaidUserContributionData = createAsyncThunk(
  "Consumption Highlights/Paid User Contribution",
  async (data) => {
    try {
      const response = getPaidUserContributionApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
