import { createAsyncThunk } from "@reduxjs/toolkit";

// import needed API helpers.
import { getPLBAnalysis as getPLBAnalysisApi } from "../../helpers/fakebackend_helper";

export const getPlbAnalysisData = createAsyncThunk(
  "PLBAnalysis",
  async (data) => {
    try {
      const response = getPLBAnalysisApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
