import { createSlice } from "@reduxjs/toolkit";
import { getInorganicTransactionsData } from "./thunk";

export const initialState = {
  data: {},
  status: "idle",
  error: null,
  loader: false,
};

const InorganicTransactionsSlice = createSlice({
  name: "InorganicTransactions",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getInorganicTransactionsData.pending, (state, action) => {
      state.status = "loading";
      state.loader = true;
    });
    builder.addCase(getInorganicTransactionsData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.status = "succeeded";
        state.data = action.payload.data.chart_data;
        state.loader = false;
      }
    });
    builder.addCase(getInorganicTransactionsData.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
      state.loader = false;
    });
  },
});

export default InorganicTransactionsSlice.reducer;
