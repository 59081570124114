import { createAsyncThunk } from "@reduxjs/toolkit";

import { getAutoDebit as getgetAutoDebitApi } from "../../../helpers/fakebackend_helper";

export const getAutoDebitData = createAsyncThunk(
  "HealthMatrix/Auto Debit Mandate",
  async (data) => {
    try {
      const response = getgetAutoDebitApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
