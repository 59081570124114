import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Card, Col, Table } from "reactstrap";
import AutoDebitFunnelChart from "./autoDebitFunnelChart";
import { tabelData as autoDebitDataFromState } from "../../../common/data/HealthMatrix/autoDebitData";

const convertAndFormat = (value) => {
  const numberValue = parseInt(value);
  if (!isNaN(numberValue)) {
    return Number(numberValue).toLocaleString();
  }
  return value;
};

const headerColors = [
  "#1B2431",
  "#78AD2F",
  "#2BA558",
  "#009978",
  "#00898D",
  "#007893",
];

const headerArray = [
  "Titles",
  "Due For Renewal",
  "Churn Out",
  "Success",
  "% Success",
];

const displayOrder = [
  "Monthly",
  "Quarterly",
  "Half Yearly",
  "Yearly",
  "2 Yearly",
  "Total",
];

function AutoDebitTable() {
  const [tableData, setTableData] = useState([]);
  const [headerValues, setHeaderValues] = useState([]);
  const [loader, setLoader] = useState(true);

  // const autoDebitDataFromState = useSelector(
  //   (state) => state.AutoDebit?.tableData
  // );

  // const { loader } = useSelector((state) => state.AutoDebit);

  useEffect(() => {
    if (autoDebitDataFromState && autoDebitDataFromState.length > 0) {
      const headerValues = Object.keys(autoDebitDataFromState[0]);

      const formattedHeaderValues = headerValues.map((key) => {
        switch (key) {
          case "period":
            return "Titles";
          case "due_for_renewal":
            return "Due For Renewal";
          case "success":
            return "Success";
          case "churn_out":
            return "Churn Out";
          case "percentage_success":
            return "% Success";
          default:
            return key;
        }
      });

      const formattedData = autoDebitDataFromState.map((item) => {
        const period = item.period === "Two Yearly" ? "2 Yearly" : item.period;
        return {
          period: period,
          due_for_renewal: item.due_for_renewal,
          churn_out: item.churn_out,
          success: item.success,
          percentage_success: item.percentage_success,
        };
      });

      const orderedFormattedData = displayOrder
        .map((period) => {
          const item = formattedData.find((data) => data.period === period);
          if (item) {
            return [
              period,
              convertAndFormat(item.due_for_renewal),
              convertAndFormat(item.churn_out),
              convertAndFormat(item.success),
              `${convertAndFormat(item.percentage_success)}%`,
            ];
          }
          return null;
        })
        .filter((item) => item !== null);

      setTableData(orderedFormattedData);
      setHeaderValues(formattedHeaderValues);
      setLoader(false);
    }
  }, [autoDebitDataFromState]);

  return (
    <React.Fragment>
      <Card className="card-styling" style={{ height: "399px" }}>
        <Row>
          <Col md="7">
            {!loader ? (
              <div className="table-responsive ms-4 me-4 mt-4 sh-table-styling">
                <Table className="table-nowrap table-bordered mb-0" size="sm">
                  <thead className="text-white text-center sh-table-header">
                    <tr className="table-row">
                      {headerArray.map((value, index) => (
                        <th
                          scope="col"
                          style={{ backgroundColor: `${headerColors[index]}` }}
                          key={index}
                        >
                          {value}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((obj, i) => (
                      <tr
                        key={i}
                        className="sh-table-text"
                        style={
                          i === tableData.length - 1
                            ? {
                                backgroundColor: "#E8E8E8",
                                fontWeight: 700,
                              }
                            : {}
                        }
                      >
                        {obj.map((value, index) => (
                          <td
                            key={index}
                            className={
                              headerArray[index] === "Titles"
                                ? "text-start align-middle"
                                : "text-center align-middle"
                            }
                          >
                            {value}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "399px" }}
              >
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </Col>
          <Col md="5">
            <AutoDebitFunnelChart dataColors='["--vz-success"]' />
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
}

export default AutoDebitTable;
