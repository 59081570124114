import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";

//Calendar


// API Key
import APIKeyReducer from "./apiKey/reducer";

// importing New reducers
// BusinessOverview
import DashboardDauChartReducer from "./businessOverview/dauChart/reducer";
import BusinessOverviewReducer from "./businessOverview/widgetCard/reducer";
// import OttWidgetsReducer from "./businessOverview/ott/reducer";
import StickinessReducer from "./businessOverview/stickiness/reducer";

// AcquisitionDashboard;
import AcquisitionReducer from "./businessOverview/acquisitionDashboard/reducer";
// PackwiseContributionDashboard
import PackwiseContributionReducer from "./businessOverview/packwiseContribution/reducer";

import PlatformCategoryReducer from "./HealthMatrix/platformCategory/reducer";
import MOUsOverviewReducer from "./MOUsOverview/reducer";
// Health Matrix
// Active User Analysis
import ActiveUserAnalysisReducer from "./HealthMatrix/ActiveUserAnalysis/reducer";
// Closing PLB Analysis
import PLBAnalysisReducer from "./PlbAnalysis/reducer";
// Auto Debit Mandate
import AutoDebitReducer from "./HealthMatrix/AutoDebitMandate/reducer";

import StreamAndDidNotStreamReducer from "./HealthMatrix/StreamAndDidNotStream/reducer";
import InstallAndUninstallAnalysisReducer from "./HealthMatrix/InstallAndUninstallAnalysis/reducer";

// Revenue Matrix
// Revenue Trend
import RevenueTrendReducer from "./RevenueMatrix/RevenueTrend/reducer";
// Title Contribution
import TitleContributionReducer from "./RevenueMatrix/TitleContribution/reducer";
// Subs ARPU CPS All
import SubsApruCpsReducer from "./RevenueMatrix/Subscription/reducer";
// Subs ARPU CPS Gujarati
import SubsGujaratiReducer from "./RevenueMatrix/Subscription/gujaratiReducer";
// Subs ARPU CPS Gujarati
import SubsNonGujaratiReducer from "./RevenueMatrix/Subscription/nonGujaratiReducer";
// Pack Contribution Monthly
import PackMonthlyReducer from "./RevenueMatrix/PackContribution/reducer";

import InorganicTransactionsReducer from "./RevenueMatrix/InorganicTransactions/reducer";
import OrganicTransactionsReducer from "./RevenueMatrix/OrganicTransactions/reducer";
import AutoRenewalTransactionsReducer from "./RevenueMatrix/AutoRenewalTransactions/reducer";
import DeviceContributionReducer from "./RevenueMatrix/DeviceContribution/reducer";

// Consumption Highlights
// Content Type Analysis
import ContentTypeReducer from "./ConsumptionHighlights/ContentTypeAnalysis/reducer";
// Paid User Contribution
import PaidUserContributionReducer from "./ConsumptionHighlights/PaidUserContribution/reducer";
// FTW
import FTWReducer from "./ConsumptionHighlights/FTW/reducer";
// Top Titles
import TopTitlesReducer from "./ConsumptionHighlights/TopTitles/reducer";
// Geographic Contribution
import GeographicContributionReducer from "./ConsumptionHighlights/Geo/reducer";
// Geographic per State
import GeoPerStateReducer from "./ConsumptionHighlights/GeoPerState/reducer";

import HourlyConsumptionAnalysisReducer from "./ConsumptionHighlights/HourlyConsumptionAnalysis/reducer";

// Todays Highlights
// Top T Locations
import TopTLocationsReducer from "./TodaysHighlights/TopTransactingLocations/reducer";
import HourlyTrendINReducer from "./TodaysHighlights/HourlyTrendIN/reducer";
import HourlyTrendROWReducer from "./TodaysHighlights/HourlyTrendROW/reducer";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  ForgetPassword: ForgetPasswordReducer,
  Profile: ProfileReducer,
  APIKey: APIKeyReducer,
  // adding new reducers from here
  DauChart: DashboardDauChartReducer,
  BusinessOverviewWidgets: BusinessOverviewReducer,
  PlatformCategoryData: PlatformCategoryReducer,
  // OttReducer: OttWidgetsReducer,
  StickinessReducer: StickinessReducer,
  MOUsOverviewData: MOUsOverviewReducer,
  Acquisition: AcquisitionReducer,
  PackwiseContribution: PackwiseContributionReducer,
  ActiveUserAnalysis: ActiveUserAnalysisReducer,
  PLBAnalysis: PLBAnalysisReducer,
  AutoDebit: AutoDebitReducer,
  StreamAndDidNotStream: StreamAndDidNotStreamReducer,
  InstallAndUninstallAnalysis: InstallAndUninstallAnalysisReducer,
  RevenueTrend: RevenueTrendReducer,
  TitleContribution: TitleContributionReducer,
  SubsApruCps: SubsApruCpsReducer,
  PackMonthly: PackMonthlyReducer,
  InorganicTransactions: InorganicTransactionsReducer,
  OrganicTransactions: OrganicTransactionsReducer,
  AutoRenewalTransactions: AutoRenewalTransactionsReducer,
  DeviceContribution: DeviceContributionReducer,
  SubsGujarati: SubsGujaratiReducer,
  SubsNonGujarati: SubsNonGujaratiReducer,
  ContentType: ContentTypeReducer,
  FTW: FTWReducer,
  TopTitles: TopTitlesReducer,
  Geo: GeographicContributionReducer,
  GeoPerState: GeoPerStateReducer,
  HourlyConsumptionAnalysis: HourlyConsumptionAnalysisReducer,
  PaidUserContribution: PaidUserContributionReducer,
  TopTLocations: TopTLocationsReducer,
  HourlyTrendIN: HourlyTrendINReducer,
  HourlyTrendROW: HourlyTrendROWReducer,
});

export default rootReducer;
