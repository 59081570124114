import { createSlice } from "@reduxjs/toolkit";
import {
  getActiveUserAnalysisData,
  getDauData,
  getStreamingDauData,
} from "./thunk";

export const initialState = {
  tableData: [],
  dauData: [],
  prevDauData: [],
  streamingDauData: [],
  prevStreamingDauData: [],
  tableLoader: false,
  dauLoader: false,
  streamingDauLoader: false,
  tableError: "",
  chartError: "",
};

const ActiveUserAnalysisSlice = createSlice({
  name: "ActiveUserAnalysis",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getActiveUserAnalysisData.pending, (state, action) => {
      state.tableLoader = true;
    });
    builder.addCase(getActiveUserAnalysisData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.tableData = action.payload.data.chart_data;
        state.tableLoader = false;
      }
    });
    builder.addCase(getActiveUserAnalysisData.rejected, (state, action) => {
      state.tableError = action.error.message;
      state.tableLoader = false;
    });
    builder.addCase(getDauData.pending, (state, action) => {
      state.dauLoader = true;
    });
    builder.addCase(getDauData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.dauData = action.payload?.data?.daily_wise_data;
        state.prevDauData = action.payload?.data?.prev_date;
        state.dauLoader = false;
      }
    });
    builder.addCase(getDauData.rejected, (state, action) => {
      state.chartError = action.error.message;
      state.dauLoader = false;
    });
    builder.addCase(getStreamingDauData.pending, (state, action) => {
      state.streamingDauLoader = true;
    });
    builder.addCase(getStreamingDauData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.streamingDauData = action.payload?.data?.daily_wise_data;
        state.prevStreamingDauData = action.payload?.data?.prev_date;
        state.streamingDauLoader = false;
      }
    });
    builder.addCase(getStreamingDauData.rejected, (state, action) => {
      state.chartError = action.error.message;
      state.streamingDauLoader = false;
    });
  },
});

export default ActiveUserAnalysisSlice.reducer;
