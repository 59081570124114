// state.data = action.payload.data.chart_data;

const autoRenewalData = {
  gujarati: [
    {
      packwise: "Monthly",
      due_for_renewal: "45",
      renewed: "30",
      revenue: "4600.95",
      arpu: "150.86",
      success_rate: "60.44",
    },
    {
      packwise: "Half Yearly",
      due_for_renewal: "20",
      renewed: "10",
      revenue: "6200.50",
      arpu: "500.77",
      success_rate: "40.83",
    },
    {
      packwise: "Yearly",
      due_for_renewal: "800",
      renewed: "400",
      revenue: "47000.93",
      arpu: "1000.98",
      success_rate: "40.88",
    },
    {
      packwise: "Quarterly",
      due_for_renewal: "400",
      renewed: "200",
      revenue: "120000.50",
      arpu: "400.12",
      success_rate: "60.73",
    },
    {
      packwise: "Two Yearly",
      due_for_renewal: "600",
      renewed: "100",
      revenue: "130000.50",
      arpu: "900.49",
      success_rate: "40.37",
    },
    {
      packwise: "Total",
      due_for_renewal: "2000",
      renewed: "800",
      revenue: "730000.38",
      arpu: "800.44",
      success_rate: "40.80",
    },
  ],
  non_gujarati: [
    {
      packwise: "Monthly",
      due_for_renewal: "300",
      renewed: "200",
      revenue: "50000.99",
      arpu: "100.85",
      success_rate: "70.79",
    },
    {
      packwise: "Quarterly",
      due_for_renewal: "400",
      renewed: "300",
      revenue: "130000.82",
      arpu: "400.94",
      success_rate: "60.31",
    },
    {
      packwise: "Yearly",
      due_for_renewal: "600",
      renewed: "300",
      revenue: "24000.24",
      arpu: "700.12",
      success_rate: "50.39",
    },
    {
      packwise: "Half Yearly",
      due_for_renewal: "20",
      renewed: "60",
      revenue: "3000.50",
      arpu: "600.92",
      success_rate: "30.00",
    },
    {
      packwise: "Two Yearly",
      due_for_renewal: "100",
      renewed: "50",
      revenue: "55000.50",
      arpu: "1000.08",
      success_rate: "29.10",
    },
    {
      packwise: "Total",
      due_for_renewal: "1656",
      renewed: "970",
      revenue: "495802.05",
      arpu: "511.14",
      success_rate: "58.57",
    },
  ],
  grand: [
    {
      revenue: "1232793.43",
      due_for_renewal: "3663.00",
      renewed: 1849,
      arpu: "666.74",
      success_rate: "50.48",
    },
  ],
};

export { autoRenewalData };
