import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Table } from "reactstrap";
import { top10MoviesData as top10Data } from "../../../common/data/EcomDashboard/mouOverview";

const tableHeaderColors = [
  "#1B2431",
  "#1B2431",
  "#78AD2F",
  "#387790",
  "#00898D",
  "#007893",
];

function Top10Table() {
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(true);

  // const top10MoviesDataLoader = useSelector(
  //   (state) => state.MOUsOverviewData.top10MoviesDataLoader
  // );
  // const top10Data = useSelector(
  //   (state) => state.MOUsOverviewData.top10MoviesData
  // );

  const tableHeaders = ["Rank", "Title Name", "MOU", "Users"];

  useEffect(() => {
    if (top10Data && top10Data.length > 0) {
      const roundedData = top10Data.map((item, index) => [
        index + 1,
        item.video_name,
        (parseInt(item.MOU, 10) || 0).toLocaleString(),
        (parseInt(item.Users, 10) || 0).toLocaleString(),
      ]);

      setTableData(roundedData);
      setLoader(false);
    }
  }, [top10Data]);

  return (
    <React.Fragment>
      {!loader && top10Data && top10Data.length > 0 ? (
        <div className="table-responsive ms-4 me-4 mt-4 sh-table-styling">
          <Table
            className="table-nowrap table-bordered align-middle mb-0"
            size="sm"
          >
            <thead className="sh-table-header text-center">
              <tr className="table-row">
                {tableHeaders.map((header, index) => (
                  <th
                    scope="col"
                    style={{
                      backgroundColor:
                        tableHeaderColors[index % tableHeaderColors.length],
                      textAlign: "left",
                    }}
                    key={index}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-center">
              {tableData.map((eachRow, rowIndex) => (
                <tr key={rowIndex} className="sh-table-text">
                  {eachRow.map((eachColumn, colIndex) => (
                    <td
                      key={colIndex}
                      className={
                        tableHeaders[colIndex] === "Rank" ||
                        tableHeaders[colIndex] === "Title Name"
                          ? "text-start align-middle"
                          : "text-center align-middle"
                      }
                    >
                      {eachColumn}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "400px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default Top10Table;
