import React, { useState, useEffect } from "react";
import getChartColorsArray from "../../../../Components/Common/ChartsDynamicColor";
import ReactApexChart from "react-apexcharts";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import { formatMonthArray } from "../../../../helpers/formatMonth";
import { dailyWiseData } from "../../../../common/data/BusinessOverview/packwiseData";

const periods = ["Monthly", "Quarterly", "Half Yearly", "Two Yearly", "Yearly"];

const getDataByPeriod = (data, period, key) => {
  return {
    name: period,
    data: data
      .filter((item) => item.period === period)
      .map((item) => {
        const value = item[key];
        return isNaN(value) ? value : parseInt(value, 10);
      }),
  };
};

const PackwiseChart = ({ dataColors, selectedValue }) => {
  var chartColors = getChartColorsArray(dataColors);
  const [loader, setLoader] = useState(true);

  // const dataStatus = useSelector((state) => state.PackwiseContribution.status);
  // const dailyWiseData = useSelector(
  //   (state) => state.PackwiseContribution.chartData
  // );

  const [subscriptions, setSubscription] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [arpu, setArpu] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    if (loader) {
      const subscriptionData = periods.map((period) =>
        getDataByPeriod(dailyWiseData, period, "subscription")
      );
      const revenueData = periods.map((period) =>
        getDataByPeriod(dailyWiseData, period, "revenue")
      );
      const arpuData = periods.map((period) =>
        getDataByPeriod(dailyWiseData, period, "arpu")
      );
      const labelsData = periods.map((period) =>
        getDataByPeriod(dailyWiseData, period, "created_at_time")
      );

      const labels = Array.from(
        new Set(dailyWiseData.map((item) => item.created_at_time))
      );

      const formattedDates = labels.map(formatMonthArray);

      setSubscription(subscriptionData);
      setRevenue(revenueData);
      setArpu(arpuData);
      setLabels(formattedDates);
      setLoader(false);
    }
  }, [dailyWiseData]);

  const getSeries = (selectedValue) => {
    let series;
    switch (selectedValue) {
      case "subscriptions":
        series = subscriptions ? subscriptions : [];
        break;
      case "revenue":
        series = revenue ? revenue : [];
        break;
      case "arpu":
        series = arpu ? arpu : [];
        break;
      default:
        series = [];
    }
    return series;
  };

  // var series = getSeries(selectedValue);

  var options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 4,
      curve: "smooth",
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val !== undefined ? (val / 1000).toFixed(0) + "" : ""; // Format y-axis values in thousands
        },
      },
      title: {
        text: "DATA IN THOUSANDS",
      },
      min: 0,
      tickAmount: 6, // Adjust this as per your requirement
      // You can set specific min and max ranges
      // min: 0,
      // max: 50000, // Example max value, adjust based on your data
    },
    xaxis: {
      labels: {
        show: false,
        formatter: function (val) {
          return val !== undefined ? val : ""; // Display actual values on x-axis
        },
      },
      axisBorder: {
        show: false,
      },
      categories: labels,
    },
    colors: chartColors,
    markers: {
      size: 4,
      colors: chartColors,
      strokeColors: "#fff",
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    tooltip: {
      enabled: true,
      // x: {
      //   show: true,
      //   formatter: function (val) {
      //     return val !== undefined ? val : ""; // Display actual values on x-axis in tooltip
      //   },
      // },
      y: {
        formatter: function (val) {
          return val !== undefined
            ? parseInt(val.toFixed(0)).toLocaleString()
            : ""; // Display actual values in tooltip without formatting
        },
      },
    },
  };
  return (
    <React.Fragment>
      {!loader ? (
        <ReactApexChart
          dir="ltr"
          height="100%"
          options={options}
          series={getSeries(selectedValue)}
          type="line"
          className="apex-charts"
        />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "350px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default PackwiseChart;
