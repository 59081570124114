// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   Col,
//   Container,
//   Row,
//   Card,
//   UncontrolledDropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
//   CardTitle,
// } from "reactstrap";

// import filterImage from "../../../assets/images/saranyuImages/filter.svg";
// import resetFilterImage from "../../../assets/images/saranyuImages/Reset Filter.svg";

// import DateDropdown from "../../../helpers/DateDropdown";
// import AutoRenewalTransactionsTable from "./AutoRenewalTable";
// import AutoRenewalTransactionsChart from "./AutoRenewalChart";
// import { getMTDDateRange } from "../../../helpers/dateUtils";

// import { getAutoRenewalTransactionsData } from "../../../slices/RevenueMatrix/AutoRenewalTransactions/thunk";

// const filterOptions = {
//   platforms: {
//     All: "android,ios,web,external",
//     Android: "android",
//     Web: "web",
//     Tv: "external",
//     iOS: "ios",
//   },
//   region: {
//     All: "IN,ROW",
//     India: "IN",
//     ROW: "ROW",
//   },
// };

// const AutoRenewalTransactions = () => {
//   const dispatch = useDispatch();

//   const [customDateRange, setCustomDateRange] = useState(getMTDDateRange());

//   const [filters, setFilters] = useState({
//     start_date: customDateRange.startDate,
//     end_date: customDateRange.endDate,
//     daily_wise_data: true,
//     platforms: "android", // ios, web
//     region: "All",
//   });

//   const fetchData = async (dateRange) => {
//     const dataObject = {
//       ...filters,
//       ...dateRange,
//     };
//     dispatch(getAutoRenewalTransactionsData(dataObject));
//   };

//   useEffect(() => {
//     fetchData(customDateRange);
//   }, [customDateRange, filters]);

//   const [selectedFilters, setSelectedFilters] = useState({
//     platforms: "Platform",
//     region: "Region",
//   });

//   const handleFilterChange = (filterType, value) => {
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       [filterType]: value,
//     }));
//     setSelectedFilters((prevSelected) => ({
//       ...prevSelected,
//       [filterType]:
//         value || filterType.charAt(0).toUpperCase() + filterType.slice(1),
//     }));
//   };

//   const handleDateRangeChange = (range) => {
//     setCustomDateRange(range);
//     console.log(range);
//   };

//   const handleResetFilters = () => {
//     // const defaultDateRange = getMTDDateRange();
//     // setCustomDateRange(defaultDateRange);
//     setFilters({
//       // start_date: defaultDateRange.startDate,
//       // end_date: defaultDateRange.endDate,
//       daily_wise_data: true,
//       platforms: "android",
//       region: "All",
//     });
//     setSelectedFilters({
//       platforms: "Platform",
//       region: "Region",
//     });
//   };

//   return (
//     <React.Fragment>
//       <Container fluid className="customContainerStyle">
//         <Row>
//           <Col style={{ padding: "20px" }}>
//             <h4 className="card-heading-text">
//               Auto Renewal Transactions (Standing Mandate)
//             </h4>
//           </Col>
//         </Row>
//         <Row className="mb-4">
//           <Col className="d-flex justify-content-between">
//             <div className="d-flex justify-content-start ms-2">
//               <button className="sh-funnel-filter">
//                 <img src={filterImage} alt="filter" />
//               </button>
//               {["platforms", "region"].map((filter) => (
//                 <UncontrolledDropdown key={filter}>
//                   <DropdownToggle
//                     tag="button"
//                     className="sh-filter-dropdown sh-filter-text"
//                     id="dropdownMenuButton"
//                   >
//                     &nbsp; &nbsp;{selectedFilters[filter]} &nbsp;&nbsp;
//                     <i className="mdi mdi-chevron-down"></i>
//                   </DropdownToggle>
//                   <DropdownMenu>
//                     {filter === "platforms" &&
//                       ["Android", "iOS", "Web", "TV"].map((platform) => (
//                         <DropdownItem
//                           key={platform}
//                           onClick={() =>
//                             handleFilterChange(
//                               filter,
//                               platform === "Platform"
//                                 ? ""
//                                 : platform.toLowerCase()
//                             )
//                           }
//                         >
//                           {platform}
//                         </DropdownItem>
//                       ))}
//                     {filter === "region" &&
//                       ["IN", "ROW"].map((region) => (
//                         <DropdownItem
//                           key={region}
//                           onClick={() =>
//                             handleFilterChange(
//                               filter,
//                               region === "Region" ? "" : region
//                             )
//                           }
//                         >
//                           {region}
//                         </DropdownItem>
//                       ))}
//                   </DropdownMenu>
//                 </UncontrolledDropdown>
//               ))}
//               <button className="sh-filter-reset" onClick={handleResetFilters}>
//                 <img src={resetFilterImage} alt="reset filter" />
//               </button>
//             </div>
//             <DateDropdown
//               onDateRangeChange={handleDateRangeChange}
//               options={["MTD", "Last Month", "3 Months", "Custom"]}
//             />
//           </Col>
//         </Row>
//         <Row>
//           <Col md="8">
//             <AutoRenewalTransactionsTable />
//           </Col>
//           <Col md="4">
//             <Card className="card-styling ">
//               <Row>
//                 <Col>
//                   <h4 className="card-heading-text" style={{ padding: "20px" }}>
//                     Success Rate
//                   </h4>
//                 </Col>
//               </Row>
//               <Row>
//                 <Col>
//                   <AutoRenewalTransactionsChart dataColors='["#146588","#007893","#009978","#2BA558","#78AD2F"]' />
//                 </Col>
//               </Row>
//             </Card>
//           </Col>
//         </Row>
//       </Container>
//     </React.Fragment>
//   );
// };

// export default AutoRenewalTransactions;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Container, Row, Card } from "reactstrap";

import DateDropdown from "../../HealthMatrix/DateDropdown"; // Adjusted import path
import FilterSection from "../../RevenueMatrix/Subscription/filterSection"; // Adjusted import path
import AutoRenewalTransactionsTable from "./AutoRenewalTable";
import AutoRenewalTransactionsChart from "./AutoRenewalChart";
import { getMTDDateRange } from "../../../helpers/dateUtils";

import { getAutoRenewalTransactionsData } from "../../../slices/RevenueMatrix/AutoRenewalTransactions/thunk";

const filterOptions = {
  platforms: {
    All: "android,ios,web,external",
    Android: "android",
    Web: "web",
    Tv: "external",
    iOS: "ios",
  },
  region: {
    All: "IN,ROW",
    India: "IN",
    ROW: "ROW",
  },
};

const AutoRenewalTransactions = () => {
  const dispatch = useDispatch();

  const [customDateRange, setCustomDateRange] = useState(getMTDDateRange());

  const [filters, setFilters] = useState({
    start_date: customDateRange.startDate,
    end_date: customDateRange.endDate,
    daily_wise_data: true,
    platforms: filterOptions.platforms.All,
    region: filterOptions.region.All,
  });

  const fetchData = async (dateRange) => {
    const dataObject = {
      ...filters,
      ...dateRange,
    };
    // dispatch(getAutoRenewalTransactionsData(dataObject));
  };

  useEffect(() => {
    fetchData(customDateRange);
  }, [customDateRange, filters]);

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const handleDateRangeChange = (range) => {
    setCustomDateRange(range);
    setFilters((prevFilters) => ({
      ...prevFilters,
      start_date: range.startDate,
      end_date: range.endDate,
    }));
  };

  const handleResetFilters = () => {
    setFilters({
      daily_wise_data: true,
      platforms: filterOptions.platforms.All,
      region: filterOptions.region.All,
    });
  };

  return (
    <React.Fragment>
      <Container fluid className="customContainerStyle">
        <Row>
          <Col style={{ padding: "20px" }}>
            <h4 className="card-heading-text">
              Auto Renewal Transactions (Standing Mandate)
            </h4>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col className="d-flex justify-content-between">
            <FilterSection
              filterOptions={filterOptions}
              filters={filters}
              handleFilterChange={handleFilterChange}
              handleResetFilters={handleResetFilters}
            />
            <DateDropdown
              onDateRangeChange={handleDateRangeChange}
              options={["MTD", "Last Month", "3 Months", "Custom"]}
            />
          </Col>
        </Row>
        <Row>
          <Col md="8">
            <AutoRenewalTransactionsTable />
          </Col>
          <Col md="4">
            <Card className="card-styling ">
              <Row>
                <Col>
                  <h4 className="card-heading-text" style={{ padding: "20px" }}>
                    Success Rate
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  <AutoRenewalTransactionsChart dataColors='["#146588","#007893","#009978","#2BA558","#78AD2F"]' />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default AutoRenewalTransactions;
