import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardText,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";

import Flatpickr from "react-flatpickr";

import ToggleButton from "../ToggleButton";

import MOUsWidgets from "./MOUsWidget";
import Widgets from "./MPUWidaget";
import { Line } from "./MOUsOverviewChart";
import calendarImage from "../../../assets/images/saranyuImages/calander.svg";

import {
  getTotalMOUData,
  getTotalMPUData,
  getPaidMPUData,
  getPaidMOUData,
  getTop10MoviesData,
} from "../../../slices/MOUsOverview/thunk";

import Top10Table from "./Top10Table";

import {
  getMTDDateRange,
  getLastMonthDateRange,
  getQTDDateRange,
} from "../../../helpers/dateUtils";

import DateDropdown from "../BusinessOverview/DateDropdown";

const MOUsOverview = () => {
  const [customDateRange, setCustomDateRange] = useState(getMTDDateRange());
  const [isTotalSelected, setIsTotalSelected] = useState(true);
  const [filterType, setFilterType] = useState("all");

  const dispatch = useDispatch();

  const fetchData = async (dateRange) => {
    const someObject = {
      ...dateRange,
      period: "month",
      daily_wise_data: true,
    };

    const top10DataObject = {
      ...dateRange,
      period: "month",
      filter_type: filterType,
      user_type: isTotalSelected ? "Total" : "Paid",
    };

    // dispatch(getTotalMOUData(someObject));
    // dispatch(getTotalMPUData(someObject));
    // dispatch(getPaidMPUData(someObject));
    // dispatch(getPaidMOUData(someObject));
    // dispatch(getTop10MoviesData(top10DataObject));
  };

  useEffect(() => {
    fetchData(customDateRange);
  }, [dispatch]);

  const handleDateRangeChange = (range) => {
    setCustomDateRange(range);
    fetchData(range);
  };

  const handleRadioChange = (e) => {
    const selectedFilterType = e.target.id;
    setFilterType(selectedFilterType);

    const top10DataObject = {
      ...customDateRange,
      period: "month",
      filter_type: selectedFilterType,
      user_type: isTotalSelected ? "Total" : "Paid",
    };

    // dispatch(getTop10MoviesData(top10DataObject));
  };

  const handleToggle = (isTotal) => {
    if (isTotal !== isTotalSelected) {
      setIsTotalSelected(isTotal);
      const top10DataObject = {
        ...customDateRange,
        period: "month",
        filter_type: filterType,
        user_type: isTotal ? "Total" : "Paid",
      };
      // dispatch(getTop10MoviesData(top10DataObject));
    }
  };

  return (
    <React.Fragment>
      <Card className="customContainerStyle">
        <Row>
          <Col
            md="12"
            className="d-flex justify-content-between"
            style={{ padding: "15px" }}
          >
            <h4 className="card-heading-text-black">MOU’s Overview</h4>
            <DateDropdown
              onDateRangeChange={handleDateRangeChange}
              options={["Last Month", "3 Months", "1 Year", "Custom"]}
            />
          </Col>
        </Row>
        <Row>
          <MOUsWidgets columnSpace={4} />
          <Widgets columnSpace={4} />
        </Row>
        <Row>
          <Col lg={6}>
            <Card
              style={{
                backgroundColor: "#F9F9F9",
                borderRadius: "12px",
                border: "1px solid #CCCCCC",
                height: "454px",
              }}
            >
              <CardHeader
                className="d-flex justify-content-between"
                style={{
                  borderBottom: "0px",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "12px",
                }}
              >
                <h4
                  style={{
                    fontFamily: "Arial",
                    fontSize: "24px",
                    fontWeight: 700,
                    lineHeight: "20px",
                    textAlign: "left",
                    color: "#1B2431",
                  }}
                  className="card-title mb-0"
                >
                  Top 10
                </h4>
                <div className="d-flex justify-content-between flex-row m-2">
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-center me-2">
                      <input
                        type="radio"
                        id="all"
                        name="Top10Movies"
                        className="sh-radio-button me-1"
                        defaultChecked
                        onChange={handleRadioChange}
                      />
                      <label htmlFor="all" className="sh-radio-text">
                        All
                      </label>
                    </div>
                    <div className="d-flex justify-content-center me-2">
                      <input
                        type="radio"
                        id="gujurati"
                        name="Top10Movies"
                        className="sh-radio-button me-1"
                        onChange={handleRadioChange}
                      />
                      <label htmlFor="gujurati" className="sh-radio-text">
                        Gujarati
                      </label>
                    </div>
                    <div className="d-flex justify-content-center me-2">
                      <input
                        type="radio"
                        id="non-gujurati"
                        name="Top10Movies"
                        className="sh-radio-button me-1"
                        onChange={handleRadioChange}
                      />
                      <label htmlFor="non-gujurati" className="sh-radio-text">
                        Non Gujarati
                      </label>
                    </div>
                  </div>
                </div>
                <ToggleButton onToggle={handleToggle} />
              </CardHeader>

              <Col>
                <Top10Table />
              </Col>
            </Card>
          </Col>
          <Col lg={6}>
            <Card
              style={{
                backgroundColor: "#F9F9F9",
                borderRadius: "12px",
                border: "1px solid #CCCCCC",
                height: "454px",
              }}
            >
              <CardHeader
                style={{
                  borderBottom: "0px",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "12px",
                }}
              >
                <div className="d-flex justify-content-between">
                  <h4
                    style={{
                      fontFamily: "Arial",
                      fontSize: "24px",
                      fontWeight: 700,
                      lineHeight: "20px",
                      textAlign: "left",
                      color: "#146588",
                    }}
                    className="card-title mb-0"
                  >
                    MOU
                  </h4>
                  <h4
                    style={{
                      fontFamily: "Arial",
                      fontSize: "24px",
                      fontWeight: 700,
                      lineHeight: "20px",
                      textAlign: "left",
                      color: "#FFA600",
                    }}
                  >
                    MPU
                  </h4>
                </div>
                <div className="d-flex justify-content-between">
                  <h6
                    style={{
                      fontFamily: "Arial",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "9px",
                      textAlign: "left",
                      color: "#292C2F99",
                    }}
                  >
                    Data In Thousands
                  </h6>
                  <h6
                    style={{
                      fontFamily: "Arial",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "9px",
                      textAlign: "left",
                      color: "#292C2F99",
                    }}
                  >
                    Data In Minutes
                  </h6>
                </div>
              </CardHeader>
              <CardBody>
                <Line dataColors='["--vz-primary", "--vz-success"]' />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};

export default MOUsOverview;
