import { createAsyncThunk } from "@reduxjs/toolkit";

import { getHourlyConsumptionAnalysis as getHourlyConsumptionAnalysisApi } from "../../../helpers/fakebackend_helper";

export const getHourlyConsumptionAnalysisData = createAsyncThunk(
  "Consumption Highlights/HourlyConsumptionAnalysis",
  async (data) => {
    try {
      const response = getHourlyConsumptionAnalysisApi(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);
