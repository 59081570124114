import { createSlice } from "@reduxjs/toolkit";

import {
  getStickinessPlatformData,
  getStickinessStreamData,
  getStickinessPaidData,
} from "./thunk";

const initialState = {
  platformData: {},
  streamingData: {},
  paidData: {},
  errorMsg: "",
};

const StickinessWidgetsSlice = createSlice({
  name: "StickinessWidgets",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getStickinessPlatformData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.platformData = action.payload.data;
      }
    });
    builder.addCase(getStickinessPlatformData.rejected, (state, action) => {
      state.errorMsg = action.error.message;
    });

    builder.addCase(getStickinessStreamData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.streamingData = action.payload.data;
      }
    });
    builder.addCase(getStickinessStreamData.rejected, (state, action) => {
      state.errorMsg = action.error.message;
    });

    builder.addCase(getStickinessPaidData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.paidData = action.payload.data;
      }
    });
    builder.addCase(getStickinessPaidData.rejected, (state, action) => {
      state.errorMsg = action.error.message;
    });
  },
});

export default StickinessWidgetsSlice.reducer;
