// // import { createSlice } from "@reduxjs/toolkit";
// // import { getGeoPerStateData } from "./thunk";

// // export const initialState = {
// //   chartData: [],
// //   loader: false,
// //   error: "",
// // };

// // const GeoPerStateSlice = createSlice({
// //   name: "Geopraphic Contributin per State",
// //   initialState,
// //   reducer: {},
// //   extraReducers: (builder) => {
// //     builder.addCase(getGeoPerStateData.pending, (state, action) => {
// //       state.loader = true;
// //     });
// //     builder.addCase(getGeoPerStateData.fulfilled, (state, action) => {
// //       state.chartData = action.payload.data.chart_data;
// //       state.loader = false;
// //     });
// //     builder.addCase(getGeoPerStateData.rejected, (state, action) => {
// //       state.error = action.error.message;
// //       state.loader = false;
// //     });
// //   },
// // });

// // export default GeoPerStateSlice.reducer;

// import { createSlice } from "@reduxjs/toolkit";
// import { getGeoPerStateData } from "./thunk";

// export const initialState = {
//   dataByState: {},
//   loader: false,
//   error: "",
// };

// const GeoPerStateSlice = createSlice({
//   name: "GeographicContributionPerState",
//   initialState,
//   reducers: {},
//   extraReducers: (builder) => {
//     builder.addCase(getGeoPerStateData.pending, (state, action) => {
//       state.loader = true;
//     });
//     builder.addCase(getGeoPerStateData.fulfilled, (state, action) => {
//       const { stateName, data } = action.payload;
//       state.dataByState[stateName] = data.chart_data;
//       state.loader = false;
//     });
//     builder.addCase(getGeoPerStateData.rejected, (state, action) => {
//       state.error = action.error.message;
//       state.loader = false;
//     });
//   },
// });

// export default GeoPerStateSlice.reducer;

// import { createSlice } from "@reduxjs/toolkit";
// import { getGeoPerStateData } from "./thunk";

// export const initialState = {
//   city: {},
//   state: {},
//   country: {},
//   loader: false,
//   error: "",
// };

// const GeoPerStateSlice = createSlice({
//   name: "GeographicContributionPerState",
//   initialState,
//   reducers: {},
//   extraReducers: (builder) => {
//     builder.addCase(getGeoPerStateData.pending, (state, action) => {
//       state.loader = true;
//     });
//     builder.addCase(getGeoPerStateData.fulfilled, (state, action) => {
//       const { filterType, locationName, data } = action.payload;
//       state[filterType][locationName] = data.chart_data;
//       state.loader = false;
//     });
//     builder.addCase(getGeoPerStateData.rejected, (state, action) => {
//       state.error = action.error.message;
//       state.loader = false;
//     });
//   },
// });

// export default GeoPerStateSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { getGeoPerStateData } from "./thunk";

export const initialState = {
  city: {},
  state: {},
  country: {},
  loader: false,
  error: "",
};

const GeoPerStateSlice = createSlice({
  name: "GeographicContributionPerState",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGeoPerStateData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getGeoPerStateData.fulfilled, (state, action) => {
      const { filterType, locationName, data } = action.payload;
      if (!state[filterType]) {
        state[filterType] = {};
      }
      state[filterType][locationName] = data.chart_data;
      state.loader = false;
    });
    builder.addCase(getGeoPerStateData.rejected, (state, action) => {
      state.error = action.error.message;
      state.loader = false;
    });
  },
});

export default GeoPerStateSlice.reducer;
