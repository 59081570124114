import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import ReactApexChart from "react-apexcharts";
import { chartData as installAndUninstallDataFromState } from "../../../common/data/HealthMatrix/installAndUninstallData";

const monthMapping = {
  Jan: "JAN",
  Feb: "FEB",
  Mar: "MAR",
  Apr: "APR",
  May: "MAY",
  Jun: "JUN",
  Jul: "JUL",
  Aug: "AUG",
  Sep: "SEP",
  Oct: "OCT",
  Nov: "NOV",
  Dec: "DEC",
};

const InstallAndUninstallChart = ({ dataColors }) => {
  var chartColors = getChartColorsArray(dataColors);

  const [installData, setInstallData] = useState([]);
  const [unInstallData, setUnInstallData] = useState([]);
  const [categories, setCategories] = useState([]);

  // const installAndUninstallDataFromState = useSelector(
  //   (state) => state.InstallAndUninstallAnalysis?.data?.chart_data
  // );

  // const { loader } = useSelector((state) => state.InstallAndUninstallAnalysis);
  const [loader, setLoader] = useState(true);

  // Function to remove commas from numbers and convert to integers
  //const parseNumber = (numString) => parseInt(numString.replace(/,/g, ""), 10);

  useEffect(() => {
    if (installAndUninstallDataFromState) {
      setInstallData(
        installAndUninstallDataFromState.map((obj) => parseInt(obj.Install))
      );
      setUnInstallData(
        installAndUninstallDataFromState.map((obj) => parseInt(obj.Uninstall))
      );
      setCategories(
        installAndUninstallDataFromState.map((item) => {
          // const [month, year] = item.Months.split("-");
          // return `${monthMapping[month]}'${year}`;
          return item.Months;
        })
      );
      setLoader(false);
    }
  }, [installAndUninstallDataFromState]);

  var series = [
    {
      name: "Install",
      data: installData || [],
    },
    {
      name: "Uninstall",
      data: unInstallData || [],
    },
  ];

  var options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: chartColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 4,
      curve: "straight",
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val !== undefined ? (val / 1000).toFixed(0) + "" : ""; // Format y-axis values in thousands
        },
      },
      title: {
        text: "DATA IN THOUSANDS",
      },
      min: 0,
      tickAmount: 6, // Adjust this as per your requirement
    },
    xaxis: {
      categories: categories,
      labels: {
        show: true,
        formatter: function (val) {
          return val !== undefined ? val : ""; // Display actual values on x-axis
        },
      },
      axisBorder: {
        show: true,
      },
    },
    markers: {
      size: 4,
      colors: chartColors,
      strokeColors: "#fff",
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    tooltip: {
      enabled: true,
      // x: {
      //   show: true,
      //   formatter: function (val) {
      //     return val !== undefined ? val : ''; // Display actual values on x-axis in tooltip
      //   },
      // },
      y: {
        formatter: function (val) {
          return val !== undefined
            ? parseInt(val.toFixed(0)).toLocaleString()
            : ""; // Display actual values in tooltip without formatting
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  return (
    <React.Fragment>
      {!loader && installAndUninstallDataFromState !== undefined ? (
        <ReactApexChart
          dir="ltr"
          height="330px"
          options={options}
          series={series}
          type="line"
          className="apex-charts"
        />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "330px" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default InstallAndUninstallChart;
