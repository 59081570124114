// state.data = action.payload.data;

const data = {
  chart_name: "Stream and Did Not Stream",
  chart_data: [
    {
      content_type: "Stream",
      count: 79087,
      percentage_contribution: 69,
    },
    {
      content_type: "Did Not Stream",
      count: 35457,
      percentage_contribution: 30,
    },
    {
      content_type: "Total",
      count: 114544,
      percentage_contribution: 100,
    },
  ],
  daily_wise_data: [
    {
      stream: "7666",
      not_stream: "5282",
      date: "2024-06-01",
    },
    {
      stream: "7733",
      not_stream: "5236",
      date: "2024-06-02",
    },
    {
      stream: "7224",
      not_stream: "5256",
      date: "2024-06-03",
    },
    {
      stream: "10392",
      not_stream: "7619",
      date: "2024-06-04",
    },
    {
      stream: "6584",
      not_stream: "3489",
      date: "2024-06-05",
    },
    {
      stream: "10603",
      not_stream: "4598",
      date: "2024-06-06",
    },
    {
      stream: "10296",
      not_stream: "4986",
      date: "2024-06-07",
    },
    {
      stream: "10554",
      not_stream: "5293",
      date: "2024-06-08",
    },
    {
      stream: "11318",
      not_stream: "6465",
      date: "2024-06-09",
    },
    {
      stream: "9044",
      not_stream: "5763",
      date: "2024-06-10",
    },
    {
      stream: "9457",
      not_stream: "6171",
      date: "2024-06-11",
    },
    {
      stream: "9212",
      not_stream: "5969",
      date: "2024-06-12",
    },
    {
      stream: "9495",
      not_stream: "6143",
      date: "2024-06-13",
    },
    {
      stream: "9513",
      not_stream: "6774",
      date: "2024-06-14",
    },
    {
      stream: "10167",
      not_stream: "7042",
      date: "2024-06-15",
    },
    {
      stream: "10223",
      not_stream: "6986",
      date: "2024-06-16",
    },
    {
      stream: "9419",
      not_stream: "6618",
      date: "2024-06-17",
    },
    {
      stream: "9216",
      not_stream: "7151",
      date: "2024-06-18",
    },
  ],
};

const streamChartData = [
  {
    content_type: "Stream",
    count: 79000,
    percentage_contribution: 70,
  },
  {
    content_type: "Did Not Stream",
    count: 35000,
    percentage_contribution: 30,
  },
  {
    content_type: "Total",
    count: 10000,
    percentage_contribution: 100,
  },
];

export { data, streamChartData };
