import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  Card,
  CardBody,
  CardText,
  CardHeader,
  Col,
  Container,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
} from "reactstrap";

import PlatformAndCategoryContribution from "./Contribution/mainIndex";
import MOUsOverview from "./MOUsOverview/mainIndex";

import vennDiagram from "../../assets/images/saranyuImages/VennDiagram.png";

import ToggleButton from "./ToggleButton";
import BusinessOverview from "./BusinessOverview/index";

const DashboardEcommerce = () => {
  document.title = "Dashboard | Saranyu - Admin & Dashboard";

  const [rightColumn, setRightColumn] = useState(true);
  const toggleRightColumn = () => {
    setRightColumn(!rightColumn);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BusinessOverview />
          <Row>
            <MOUsOverview />
          </Row>
          <Row>
            <PlatformAndCategoryContribution />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;
