import { createSlice } from "@reduxjs/toolkit";
import { getHourlyTrendROWData } from "./thunk";

export const initialState = {
  todayResult: {},
  yesterdayResult: {},
  lastWeekResult: {},
  error: "",
  loader: false,
};

const HourlyTrendROWSlice = createSlice({
  name: "HourlyTrendROW",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHourlyTrendROWData.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getHourlyTrendROWData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.loader = false;
        state.todayResult = action.payload.todayResult;
        state.yesterdayResult = action.payload.yesterdayResult;
        state.lastWeekResult = action.payload.lastWeekResult;
      }
    });
    builder.addCase(getHourlyTrendROWData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.error.message;
    });
  },
});

export default HourlyTrendROWSlice.reducer;
