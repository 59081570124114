import { createSlice } from "@reduxjs/toolkit";
import { getSubsNonGujaratiData } from "./nonGujaratiThunk";

export const initialState = {
  nonGujaratiChartData: [],
  loader: false,
  error: "",
};

const SubsNonGujaratiSlice = createSlice({
  name: "SubsArpuCps/Non Gujarati",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getSubsNonGujaratiData.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getSubsNonGujaratiData.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.nonGujaratiChartData = action.payload.data.chart_data.non_gdata;
        state.loader = false;
      }
    });
    builder.addCase(getSubsNonGujaratiData.rejected, (state, action) => {
      state.error = action.error.message;
      state.loader = false;
    });
  },
});

export default SubsNonGujaratiSlice.reducer;
